import React, { Component } from 'react'
import Col from 'react-bootstrap/esm/Col';
import '../card.scss';
import './videocard.scss'
import Image from 'react-bootstrap/esm/Image';
import { NumberFormatter } from "../../../utilities/numberformatter";

export class VideoCard extends Component {
    render() {
        return (
            <div key={this.props.index} className={this.props.CardClass}>
                <div className="icz-vcard">
                    <div className="icz-vcardheader">
                        <Image src={this.props.CardImg} className="icz-vcardimg" />
                    </div>
                    <div className="icz-vcardbody">
                        <Col lg={12} className="icz-detailsrowtitlewrapper">
                            <Col lg={8} className="icz-detailsdatatab">
                                <div className="icz-iconwrapper">
                                    {this.props.CardIcon}
                                </div>
                                <div className="icz-vcardtitlewrapper">
                                    <div className="icz-vcardhighlight"> 
                                    <NumberFormatter Number={this.props.RowOneCurrentValue} IsNumber={this.props.IsNumber} />
                                    </div>
                                    <div className="icz-vcardsubtitle">{this.props.CardSubTitle}</div>
                                </div>
                            </Col>
                            <Col lg={4} className="icz-detailsdatatab">
                                <div className="icz-vcardtitlewrapper">
                                   <div className="icz-vcardtitle">{this.props.CardView}</div>
                                   </div>
                            </Col>
                        </Col>
                    </div>
                </div>
            </div>
        )
    }
}
