import React, { Component } from "react"

export class Aryabot extends Component {
    render() {
        return (
            <h1>ARYABOT RUNNING</h1>
        )
    }
}

