import React, { Component } from "react"
import ProductComparison from '../../shared/components/compare/Social/ProductComparision'
import {products} from '../../shared/components/compare/Social/Products'
import Col from 'react-bootstrap/esm/Col';


export class CRM extends Component {
    render() {
       
      
        return (
           
            <div>
                <h1>CRM RUNNING</h1>
                <div className="icz-sectioncardwrapper w-100">
               <div className="icz-sectioncard">
                        <Col className="">
                            <ProductComparison products={products} />
                        </Col>
                  
                   </div> 
            </div>
            </div>
        )
    }
}

