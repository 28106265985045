import React, { useState, useEffect, useMemo } from "react"
import Table from 'react-bootstrap/Table'
import Wrapper from "../../../helpers/wrapper"
import TableHeader from "./TableHeader"
import TablePagination from "./TablePagination"
import TableSearch from "./TableSearch"
import Col from 'react-bootstrap/Col';

import './dataTable.scss';

const DataTable = (props) => {

    console.log('*********************************************************', props);

    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [sorting, setSorting] = useState({ field: "", order: "" })

    const [search, setSearch] = useState("");

    const ITEMS_PER_PAGE = props.TableSize;

    const headers = props.Headers;
    const tableData = props.TableData;
    const tableTitle = props.TableTitle;

    const isTableTitleEnable = props.isTableTitleEnable;
    const isPageInfoEnable = props.isPageInfoEnable;
    const isSearchEnable = props.isSearchEnable;

    const headerCount = headers.length;

    const commentsData = useMemo(() => {
        let computedComments = tableData;

        if (search) {
            computedComments = computedComments.filter(
                comment =>
                    comment.id.toString().includes(search.toLowerCase()) ||
                    comment.name.toLowerCase().includes(search.toLowerCase()) ||
                    comment.email.toLowerCase().includes(search.toLowerCase()) ||
                    comment.body.toLowerCase().includes(search.toLowerCase()) 
                   
            )
        }
        setTotalItems(computedComments.length);

        // SORTING
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field]))
        }

        // CURRENT PAGE SLICE
        return computedComments.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [tableData, currentPage, search, sorting]);

    return (
        <Wrapper>
            <div className="icz-tableWrapper">
                <div className="w-100 d-flex flex-wrap">
                    {isTableTitleEnable ? 
                    <Col md={6} className="d-flex justify-content-start align-items-center">
                        <h2 className="icz-cardtitle">{props.tableTitle}</h2>
                    </Col>
                    : ""}

                    {isSearchEnable ? 
                    <Col md={6} className="d-flex justify-content-end">
                        <TableSearch
                            onSearch={
                                (value) => {
                                    setSearch(value);
                                    setCurrentPage(1)
                                }
                            }
                        />
                    </Col>
                    : ""}

                </div>
                <Table className="icz-table">
                    <TableHeader
                        headers={headers}
                        onSorting={(field, order) =>
                            setSorting({ field, order })}
                    />
                    {totalItems !== 0 ?
                        <tbody className="icz-tableBody">
                            {tableData.map((td_info, index) => (
                                <tr key={index} className="icz-tr">
                                    {headers.map((th_info, index) => (
                                        <td key={index} className="icz-td" scrop="row">{td_info[th_info.field]}</td>
                                    ))}
                                </tr>
                            ))}

                            

                            {/* {commentsData.map(comment => (<tr key={comment.id} className="icz-tr">
                                <td className="icz-td" scrop="row">{comment.id}</td>
                                <td className="icz-td">{comment.name}</td>
                                <td className="icz-td">{comment.email}</td>
                                <td className="icz-td">{comment.body}</td>
                            </tr>))} */}



                        </tbody>
                        :
                        <tbody className="icz-tableBody">
                            <td colSpan={headerCount} className="p-10 text-center">
                                <h2 className="icz-cardtitle">No Data Available</h2>
                            </td>
                        </tbody>
                    }
                </Table>

                {isPageInfoEnable ? 
                <div className="w-100 d-flex flex-wrap">
                    <Col md={6} className="d-flex justify-content-start align-items-center">
                        {totalItems !== 0 ? <div className="">Page {currentPage} of {totalItems / ITEMS_PER_PAGE}</div> : ""}
                    </Col>
                    <Col md={6} className="d-flex justify-content-end">
                        {totalItems !== 0 ?
                            <TablePagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                                initialPage
                            />
                            : ""}
                    </Col>
                </div>
                : "" }
            </div>
        </Wrapper>
    )
}

export default DataTable;