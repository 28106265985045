import React, { Component } from 'react';
import { Col, Container } from 'react-bootstrap';
import "../../ecom/ecom.scss";
import Converstion_funnel from '../../../shared/components/charts/Converstion_funnel';
import Piechart from '../../../shared/components/charts/Piechart';
import Linegraph_sparkline from '../../../shared/components/charts/Linegraph_sparkline';
import { ChartCard } from '../../../shared/components/cards/chartcard/chartcard'
import { BarChart } from '../../../shared/components/charts/BarChart';
import { Carousel } from '../../../shared/components/carousel/carousel';
import PageHeader from '../../../layout/pageheader/pageheader';
import '../../../shared/components/cards/card.scss'
import IconWebsite from '../../../../assets/images/icons/websiteIcon';
import { Link } from 'react-router-dom'
import { DailydataDropdown } from '../../../shared/components/dropdown/dailydatadropdown';
import Wrapper from '../../../helpers/wrapper';
import Treemap from '../../../shared/components/charts/TreeMap';
import { LocationBarChart } from '../../../shared/components/charts/LocationBarChart';
import SankeyChart from '../../../shared/components/charts/SankeyChart';
// import DataTable from '../../../shared/components/Datatable/DataTable';
//import DataTable from '../../../shared/components/Datatable/'



export class Sales extends Component {

    constructor(props) {
        super(props);
        this.state = {
            treegraph: [{ "name": "Home", "children": [{ "name": "Kitchen", "value": 150 }] }, { "name": "Men", "children": [{ "name": "Casual", "value": 350 }, { "name": "Western", "value": 400 }] }, { "name": "Women", "children": [{ "name": "Western", "children": [{ "name": "Jeans", "value": 240 }] }, { "name": "Casual", "value": 100 }] }, { "name": "Lifestyle", "value": 180 }],
            dropdown_options: [{ "value": "Channel", "label": "Channel" }, { "value": "User Type", "label": "User Type" }, { "value": "Platform", "label": "Platform" }, { "value": "Source", "label": "Source" }],
            trafficpievalue: [{ "name": "Social", "value": 30 }, { "name": "Direct", "value": 30 }, { "name": "Website", "value": 50 }, { "name": "Email", "value": 40 }, { "name": "Organic", "value": 60 }],
            bargraphsession: [{ "name": "Jan", "points": 100 }, { "name": "Feb", "points": 200 }, { "name": "march", "points": 110 }, { "name": "April", "points": 100 }, { "name": "May", "points": 100 }, { "name": "June", "points": 100 }, { "name": "July", "points": 100 }, { "name": "Aug", "points": 100 }, { "name": "Sep", "points": 130 }, { "name": "Oct", "points": 140 }, { "name": "Nov", "points": 200 }, { "name": "Dec", "points": 180 }],
            linesparkgraph: [{ "date": "2012-07-27", "value": 13 }, { "date": "2012-07-28", "value": 10 }, { "date": "2012-07-29", "value": 15 }, { "date": "2012-07-30", "value": 15 }, { "date": "2012-07-31", "value": 15 }, { "date": "2012-08-01", "value": 15 }, { "date": "2012-08-02", "value": 15 }, { "date": "2012-08-03", "value": 19 }, { "date": "2012-08-04", "value": 16 }, { "date": "2012-08-05", "value": 15 }, { "date": "2012-08-06", "value": 19 }, { "date": "2012-08-07", "value": 17 }, { "date": "2012-08-08", "value": 18 }],
            locationdata: [{ "name": "Kids", "points": 100 }, { "name": "Women", "points": 100 }, { "name": "Men", "points": 100 }, { "name": "Home", "points": 130 }],
            scroll: '',
            funneldropdown_options: [{ "value": "Category", "label": "Category" }, { "value": "Category 1", "label": "Category 1" }, { "value": "Category 2", "label": "Category 2" }, { "value": "Category 3", "label": "Category 3" }, { "value": "Category 4", "label": "Category 4" }, { "value": "Category 5", "label": "Category 5" }],
            firstfunnelgraph: [{ "name": "The First", "value": 350 }, { "name": "The Secound", "value": 300 }, { "name": "The Third", "value": 150 }, { "name": "The Forth", "value": 120 }],
            treegraph: [{ "name": "Home", "children": [{ "name": "Kitchen", "value": 150 }] }, { "name": "Men", "children": [{ "name": "Casual", "value": 350 }, { "name": "Western", "value": 400 }] }, { "name": "Women", "children": [{ "name": "Western", "children": [{ "name": "Jeans", "value": 240 }] }, { "name": "Casual", "value": 100 }] }, { "name": "Lifestyle", "value": 180 }],
            table_header: [
                {'name':  'Categories','field': "category", 'sortable': false},
                {'name':  'Sales','field': "sales", 'sortable': false},
                {'name':  'Units','field': "units", 'sortable': false},
                {'name':  'Sales Mix','field': "salesmix", 'sortable': false},
            ],
            table_data: [
                {category: 'Category 1', sales: '100k',units: 'Mobile', salesmix: '30%'},
                {category: 'Category 2', sales: '200k',units: 'T.V.', salesmix: '30%'},
                {category: 'Category 3', sales: '220k',units: 'Mobile', salesmix: '25%'},
                {category: 'Category 4', sales: '200k',units: 'T.V.', salesmix: '20%'},
            ],
           
        }


    }

   


    handleCallback = (childData) => {
        this.setState({ daily_metrics_name: childData, is_daily_data_loaded: false });

    }
    handledatatableCallback = (childData) => {
        this.setState({ funneldropdown_options: childData, is_datatable_word_data_loaded: false });
    }

    render() {
        const CarouselSettings = {
            arrows: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            // initialSlide: 0,
            responsive: [

                {
                    breakpoint: 1202,
                    settings: {
                        arrows: false,
                        slidesToShow: 3,
                        slidesToScroll: 0,
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,

                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,

                    }
                }
            ]
        }
        return (
            <Wrapper>
                <Container className="p-0" fluid>
                    <PageHeader pageTitle="Sales" />
                    <div className="d-flex">
                        <Col className="icz-leftsection" sm={12} lg={3} xl={3}>
                            <div className="icz-card-wrap">
                                <Col className="icz-sectioncardwrapper ps-0">
                                    <div className="icz-leftsectioncard">
                                        <div className="d-flex">
                                            <Col sm={12} className="icz-cardheader icz-leftcardheader">

                                                <Col className="icz-cardtitle">
                                                    <div className="icz-title-wrap p-0">
                                                        <div className="text-left">
                                                            <h5 className="icz-cardtitle text-start mb-0">Sales By</h5>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className="icz-pagefilter">
                                                    <div className="icz-page-dropdown justify-content-end p-0">
                                                        <DailydataDropdown dropdown_options={this.state.dropdown_options} dropdown_placeholder="Select..." parentCallback={this.handleCallback} />
                                                    </div>
                                                </Col>
                                            </Col>
                                        </div>
                                        <div className="w-100">
                                            <Piechart card_id="icz-traffic-piechart" card_class="icz-leftsectionchart" graph-data={this.state.trafficpievalue} />
                                        </div>

                                    </div>
                                </Col>
                            </div>
                        </Col>
                        <Col className="icz-rightsection" sm={12} lg={9} xl={9}>
                            <div className="icz-title-wrap d-flex">
                                <Col sm={12}>
                                    <div className="icz-row">
                                        <Col><h5 className="icz-card-title align-items-start">Customer</h5></Col>
                                        <Col className="align-items-end text-end"><Link to='/ecom/customer' className='icz-link'>View More</Link></Col>
                                    </div>
                                </Col>
                            </div>
                            <div className="icz-carouselcontainer">
                                <Carousel
                                    Settings={CarouselSettings}
                                    class={"icz-cardcontainer"}
                                    SliderCards={[
                                        <ChartCard
                                            index="1"
                                            CardClass="icz-cardwrapper"
                                            CardIcon={<IconWebsite className="icz-icon" />}
                                            CardTitle="Customers"
                                            RowOneCurrentValue="482600"
                                            RowOnePreviousValue="403673"
                                            RowOneTitle="sales"
                                            IsNumber={true}
                                            chart={<Linegraph_sparkline card_id="icz-customer" card_class="icz-cardchart" graph-data={this.state.linesparkgraph} />}
                                        />,

                                        <ChartCard
                                            index="2"
                                            CardClass="icz-cardwrapper"
                                            CardIcon={<IconWebsite className="icz-icon" />}
                                            CardTitle="Average Basket Size"
                                            RowOneCurrentValue="1.74"
                                            RowOnePreviousValue="1.66"
                                            RowOneTitle="sales"
                                            IsNumber={true}
                                            chart={<Linegraph_sparkline card_id="icz-avg-basketsize" card_class="icz-cardchart" graph-data={this.state.linesparkgraph} />}
                                        />,
                                        <ChartCard
                                            index="3"
                                            CardClass="icz-cardwrapper"
                                            CardIcon={<IconWebsite className="icz-icon" />}
                                            CardTitle="Discount Amount"
                                            RowOneCurrentValue="78%"
                                            RowOnePreviousValue="83%"
                                            RowOneTitle="sales"
                                            IsNumber={true}
                                            chart={<Linegraph_sparkline card_id="icz-discountamt" card_class="icz-cardchart" graph-data={this.state.linesparkgraph} />}
                                        />,
                                        <ChartCard
                                            index="4"
                                            CardClass="icz-cardwrapper"
                                            CardIcon={<IconWebsite className="icz-icon" />}
                                            CardTitle="Coupon Penetration"
                                            RowOneCurrentValue="49089"
                                            RowOnePreviousValue="47637"
                                            RowOneTitle="sales"
                                            IsNumber={true}
                                            chart={<Linegraph_sparkline card_id="icz-coupon" card_class="icz-cardchart" graph-data={this.state.linesparkgraph} />}
                                        />,
                                        <ChartCard
                                            index="5"
                                            CardClass="icz-cardwrapper"
                                            CardIcon={<IconWebsite className="icz-icon" />}
                                            CardTitle=""
                                            RowOneCurrentValue="482600"
                                            RowOnePreviousValue="403673"
                                            RowOneTitle="sales"
                                            IsNumber={true}
                                            chart={<Linegraph_sparkline card_id="icz" card_class="icz-cardchart" graph-data={this.state.linesparkgraph} />}
                                        />,
                                    ]}>

                                </Carousel>
                            </div>
                            <div className="icz-carouselcontainer">
                                <Carousel
                                    Settings={CarouselSettings}
                                    class={"icz-cardcontainer"}
                                    SliderCards={[
                                        <ChartCard
                                            index="1"
                                            CardClass="icz-cardwrapper"
                                            CardIcon={<IconWebsite className="icz-icon" />}
                                            CardTitle="Revenue"
                                            RowOneCurrentValue="482600"
                                            RowOnePreviousValue="403673"
                                            RowOneTitle="sales"
                                            IsNumber={true}
                                            chart={<Linegraph_sparkline card_id="icz-revenue" card_class="icz-cardchart" graph-data={this.state.linesparkgraph} />}
                                        />,

                                        <ChartCard
                                            index="2"
                                            CardClass="icz-cardwrapper"
                                            CardIcon={<IconWebsite className="icz-icon" />}
                                            CardTitle="Transactions"
                                            RowOneCurrentValue="1.74"
                                            RowOnePreviousValue="1.66"
                                            RowOneTitle="sales"
                                            IsNumber={true}
                                            chart={<Linegraph_sparkline card_id="icz-transactions" card_class="icz-cardchart" graph-data={this.state.linesparkgraph} />}
                                        />,
                                        <ChartCard
                                            index="3"
                                            CardClass="icz-cardwrapper"
                                            CardIcon={<IconWebsite className="icz-icon" />}
                                            CardTitle="Average Order Value"
                                            RowOneCurrentValue="78%"
                                            RowOnePreviousValue="83%"
                                            RowOneTitle="sales"
                                            IsNumber={true}
                                            chart={<Linegraph_sparkline card_id="icz-avg-ordervalue" card_class="icz-cardchart" graph-data={this.state.linesparkgraph} />}
                                        />,
                                        <ChartCard
                                            index="4"
                                            CardClass="icz-cardwrapper"
                                            CardIcon={<IconWebsite className="icz-icon" />}
                                            CardTitle="Conversion Rate"
                                            RowOneCurrentValue="49089"
                                            RowOnePreviousValue="47637"
                                            RowOneTitle="sales"
                                            IsNumber={true}
                                            chart={<Linegraph_sparkline card_id="icz-conversion-rate" card_class="icz-cardchart" graph-data={this.state.linesparkgraph} />}
                                        />,
                                        <ChartCard
                                            index="5"
                                            CardClass="icz-cardwrapper"
                                            CardIcon={<IconWebsite className="icz-icon" />}
                                            CardTitle="Top Discount Code"
                                            RowOneCurrentValue="482600"
                                            RowOnePreviousValue="403673"
                                            RowOneTitle="sales"
                                            IsNumber={true}
                                            chart={<Linegraph_sparkline card_id="icz-discountcode" card_class="icz-cardchart" graph-data={this.state.linesparkgraph} />}
                                        />,

                                        <ChartCard
                                            index="6"
                                            CardClass="icz-cardwrapper"
                                            CardIcon={<IconWebsite className="icz-icon" />}
                                            CardTitle="Top Platform"
                                            RowOneCurrentValue="1.74"
                                            RowOnePreviousValue="1.66"
                                            RowOneTitle="sales"
                                            IsNumber={true}
                                            chart={<Linegraph_sparkline card_id="icz-topplatform" card_class="icz-cardchart" graph-data={this.state.linesparkgraph} />}
                                        />,
                                        <ChartCard
                                            index="7"
                                            CardClass="icz-cardwrapper"
                                            CardIcon={<IconWebsite className="icz-icon" />}
                                            CardTitle="Top Source"
                                            RowOneCurrentValue="78%"
                                            RowOnePreviousValue="83%"
                                            RowOneTitle="sales"
                                            IsNumber={true}
                                            chart={<Linegraph_sparkline card_id="icz-topsource" card_class="icz-cardchart" graph-data={this.state.linesparkgraph} />}
                                        />,
                                        <ChartCard
                                            index="8"
                                            CardClass="icz-cardwrapper"
                                            CardIcon={<IconWebsite className="icz-icon" />}
                                            CardTitle="Top Location"
                                            RowOneCurrentValue="49089"
                                            RowOnePreviousValue="47637"
                                            RowOneTitle="sales"
                                            IsNumber={true}
                                            chart={<Linegraph_sparkline card_id="icz-toplocation" card_class="icz-cardchart" graph-data={this.state.linesparkgraph} />}
                                        />,

                                    ]}>

                                </Carousel>
                            </div>
                            <div className="icz-row">
                                <Col className="icz-sectioncardwrapper pt-3" sm={12} lg={6}>
                                    <div className="icz-sectioncard">
                                        <div className="icz-cardheader">
                                            <Col className="icz-cardtitle">

                                            </Col>
                                        </div>
                                        <div className="">
                                            <Treemap card_id="icz-treemap" card_class="icz-sectionchart" graph-data={this.state.treegraph} />
                                        </div>
                                    </div>
                                </Col>
                                <Col className="icz-sectioncardwrapper pt-3" sm={12} lg={6}>
                                    <div className="icz-sectioncard">
                                        <div className="icz-cardheader">
                                            <Col className="icz-cardtitle">

                                            </Col>
                                        </div>
                                        <div className="">
                                            <LocationBarChart card_id="location-chart" card_class="icz-sectionchart" graph-data={this.state.locationdata} />
                                        </div>
                                    </div>
                                </Col>
                            </div>
                            <div className="icz-row">
                                <Col className="icz-sectioncardwrapper pt-3" sm={12} lg={6}>
                                    <div className="icz-sectioncard">
                                    <div className="icz-cardheader">
                                            <Col className="icz-cardtitle">
                                             
                                            </Col>

                                            <Col className="icz-cardfilter">
                                            <DailydataDropdown dropdown_options={this.state.funneldropdown_options} dropdown_placeholder="Select..." parentCallback={this.handledatatableCallback} />
                                            </Col>
                                        </div>
                                      
                                        <div className="">
                                            <Converstion_funnel card_id="icz-converstion-funnel" card_class="icz-sectionchart" graph-data={this.state.firstfunnelgraph} />
                                        </div>
                                    </div>
                                </Col>
                                <Col className="icz-sectioncardwrapper pt-3" sm={12} lg={6}>
                                    <div className="icz-sectioncard">
                                    <div className="icz-cardheader">
                                            <Col className="icz-cardtitle">
                                             
                                            </Col>

                                            <Col className="icz-cardfilter">
                                            <DailydataDropdown dropdown_options={this.state.funneldropdown_options} dropdown_placeholder="Select..." parentCallback={this.handledatatableCallback} />
                                            </Col>
                                        </div>
                                        <div className="">
                                            <Converstion_funnel card_id="icz-funnelsec" card_class="icz-sectionchart" graph-data={this.state.firstfunnelgraph} />
                                        </div>
                                    </div>
                                </Col>
                            </div>
                            <div className="icz-row">
                                <Col className="icz-sectioncardwrapper pt-3" sm={12} lg={12}>
                                    <div className="icz-sectioncard">
                                        <div className="icz-cardheader">
                                            <Col className="icz-cardtitle">

                                            </Col>
                                        </div>
                                        <div className="">
                                            <SankeyChart chart_id="icz-sankey" chart_class="icz-sectionchart" />
                                        </div>
                                    </div>
                                </Col>
                            </div>
                           
                               <div className="icz-row">
                                <Col className="icz-sectioncardwrapper pt-3" sm={12} lg={12}>
                                    <div className="icz-sectioncard">
                                        <div className="icz-cardheader d-none">
                                            <Col className="icz-cardtitle">

                                            </Col>
                                        </div>
                                        <div className="">
                                        {/* <DataTable Headers={this.state.table_header} TableSize={5} TableData={this.state.table_data}/>  */}
                                        </div>
                                    </div>
                                </Col>
                            </div>                 
                        </Col>
                    </div>
                </Container>
            </Wrapper>
        )
    }
}
