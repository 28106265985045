const IconLinkedinwhite = (props) => {
    return (
        <svg className={props.className} width="54" height="54" viewBox="0 0 54 54" fill="#ffffff" stroke="#ffffff" xmlns="http://www.w3.org/2000/svg">
            <g id="Group_523" data-name="Group 523" transform="translate(-607 -606)">
                
                <g id="Icon_feather-linkedin" data-name="Icon feather-linkedin" transform="translate(616 616)">
                    <path id="Path_266" data-name="Path 266" d="M24,12a9,9,0,0,1,9,9V31.5H27V21a3,3,0,0,0-6,0V31.5H15V21a9,9,0,0,1,9-9Z" fill="#ffffff" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    <path id="Path_267" data-name="Path 267" d="M3,13.5H9v18H3Z" fill="#ffffff" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    <path id="Path_268" data-name="Path 268" d="M9,6A3,3,0,1,1,6,3,3,3,0,0,1,9,6Z" fill="#ffffff" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                </g>
            </g>
        </svg>
    )
}

export default IconLinkedinwhite;