import React, { Component } from 'react';
import { Carousel } from '../../../shared/components/carousel/carousel';
import { SalesCard } from '../../../shared/components/cards/risalescard/risalescard';
import RiSalesHBarChart from '../../../shared/components/charts/RiSalesHBarChart';
import RiSalesVBarChart from '../../../shared/components/charts/RiSalesVBarChart';
import PieChartwithLegends from '../../../shared/components/charts/RiPieChartwithLegends';

export class StoreCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerltvdata: [{ "category": ">15k", "value": "3.2" }, { "category": "15-25k", "value": "2.2" }, { "category": "25-40k", "value": "4.4" }, { "category": "40k<", "value": "3.8" }],
      frequencydata: [{ "category": "1", "value": "35" }, { "category": "2-3", "value": "58" }, { "category": "3-4", "value": "52" }, { "category": "4<", "value": "37" }],
      membersdata: [{ "name": "BTF", "value": 55 }, { "name": "Non BTF", "value": 45 }],
      customerdata: [{ "name": "New", "value": 55 }, { "name": "Returning", "value": 45 }],
      audiencedata: [{ "category": ">18", "value": "1.7" }, { "category": "18-25", "value": "3.2" }, { "category": "25-35", "value": "3.8" }, { "category": "35-45", "value": "3.4" }, { "category": ">45", "value": "2.5" }],
      genderdata: [{ "name": "Male", "value": 55 }, { "name": "Female", "value": 20 }, { "name": "Others", "value": 25 }],
      customersharedata: [{ "name": "Active", "value": 55 }, { "name": "Lapsers", "value": 20 }, { "name": "Non Active", "value": 25 }],
      StoreCustomer_data:this.props.StoreCustomer_data,
    }
  }
  render() {
    const CarouselSettings = {
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 600,
          settings: {
            arrows: false,
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        }
      ]
    }


    
    const audience_age = this.state.StoreCustomer_data.audience_age;
    const audience_gender = this.state.StoreCustomer_data.audience_gender;
    const customer_member = this.state.StoreCustomer_data.customer_member;
    const customer_share = this.state.StoreCustomer_data.customer_share;
    const customer_type = this.state.StoreCustomer_data.customer_type;
    const customers_vs_customer_visits = this.state.StoreCustomer_data.customers_vs_customer_visits;
    const customers_vs_ltv_customer = this.state.StoreCustomer_data.customers_vs_ltv_customer;

    console.log("StoreCustomer_data",this.state.StoreCustomer_data);
    return (
      <div className="icz-carouselcontainer">
        <Carousel
          Settings={CarouselSettings}
          class={"icz-cardcontainer"}
          SliderCards={[
            <SalesCard
              index="1"
              CardClass="icz-rislaescardwrapper"
              CardTitle="Customer Type"
              chart={
              <PieChartwithLegends 
              card_id="customer_type" 
              card_class="icz-risalescardchart" 
              graph-data={customer_type} 
              section1_color={"#11a1fd"} 
              section2_color={"#ff9931"} />}
            />,
            <SalesCard
              index="1"
              CardClass="icz-rislaescardwrapper"
              CardTitle="Audience Age"
              chart={<RiSalesHBarChart
                 chart_id="audience_age"
                  chart_class="icz-risalescardchart" 
                  Ytitle={"(value in K)"} 
                  metric_1={"Age Group"} 
                  graph-data={audience_age}
                   bar_color={"#11a1fd"} />}
            />,
            <SalesCard
              index="1"
              CardClass="icz-rislaescardwrapper"
              CardTitle="Audience Gender"
              chart={<PieChartwithLegends 
                card_id="audience_gender"
                 card_class="icz-risalescardchart" 
                 graph-data={audience_gender} 
                 section1_color={"#ff9931"} 
                 section2_color={"#07c180"}
                  section3_color={"#5a75f9"} />}
            />,
            <SalesCard
              index="1"
              CardClass="icz-rislaescardwrapper"
              CardTitle="Frequency"
              chart={<RiSalesVBarChart
                 chart_id="frequency" 
                 chart_class="icz-risalescardchart"
                  Ytitle={"(value in K)"} 
                  metric_1={"No. of Visits"} 
                  graph-data={customers_vs_customer_visits}
                   bar_color={"#11a1fd"} />}
            />,
            <SalesCard
              index="1"
              CardClass="icz-rislaescardwrapper"
              CardTitle="Members"
              chart={<PieChartwithLegends 
                card_id="members" 
                card_class="icz-risalescardchart"
                 graph-data={customer_member} 
                 section1_color={"#5a75f9"} 
                 section2_color={"#07c180"} />}
            />,
            <SalesCard
              index="1"
              CardClass="icz-rislaescardwrapper"
              CardTitle="Customer LTV"
              chart={<RiSalesHBarChart 
                  chart_id="customer_ltv"
                  chart_class="icz-risalescardchart"
                  Ytitle={"(value in K)"} 
                  metric_1={"No. of Customers"} 
                  graph-data={customers_vs_ltv_customer}
                  bar_color={"#FF9931"} />}
            />,
            <SalesCard
            index="1"
            CardClass="icz-rislaescardwrapper"
            CardTitle="Customer Share"
            chart={<PieChartwithLegends
               card_id="customer_share" 
               card_class="icz-risalescardchart" 
               graph-data={customer_share}
                section1_color={"#ff9931"} 
                section2_color={"#07c180"} 
                section3_color={"#5a75f9"} />}
          />

          ]}>
        </Carousel>

      </div>
    );
  }

}

