import React, { Component } from 'react';
import Col from "react-bootstrap/esm/Col";
import Wrapper from "../../../../helpers/wrapper";
import { LocationDropdown } from "../../../../shared/components/dropdown/locationdropdown";
import { SourceDropdown } from "../../../../shared/components/dropdown/sourcedropdown";
import { DonutChart } from '../../../../shared/components/charts/DonutChart';
import { LocationBarChart } from '../../../../shared/components/charts/LocationBarChart';
import { MetricsLineChart } from '../../../../shared/components/charts/MetricsLineChart';
// import { SourceDatatable } from '../Datatable/SourceDatatable';
import {AdGoogleDatatable} from '../Datatable/AdGoogleDatatable';
// import KeywordGoogleDatatable from '../Datatable/KeywordGoogleDatatable';
import { NumberFormatter } from "../../../../shared/utilities/numberformatter";
import IconDesktop from '../../../../../assets/images/icons/desktopIcon'
import IconMobile from '../../../../../assets/images/icons/mobileIcon';
import IconTablet from '../../../../../assets/images/icons/tabletIcon';
import IconLaptop from '../../../../../assets/images/icons/laptopIcon';
import IconGoogleSearch from '../../../../../assets/images/icons/googlesearchIcon';
import IconGoogleDisplay from '../../../../../assets/images/icons/googledisplayIcon';
import IconAllDevices from '../../../../../assets/images/icons/alldevicesIcon';
import IconLeads from '../../../../../assets/images/icons/leadsIcon';
import IconCTR from '../../../../../assets/images/icons/ctrIcon';
import IconCTC from '../../../../../assets/images/icons/ctcIcon'    
import IconSearch from '../../../../../assets/images/icons/searchIcon';
import IconConversion from '../../../../../assets/images/icons/conversionIcon';
import IconCPL from '../../../../../assets/images/icons/cplIcon'
import IconImpressions from '../../../../../assets/images/icons/impressionsIcon';
import IconRemarketing from '../../../../../assets/images/icons/remarketingIcon';

import './google.scss'
import IconClicks from '../../../../../assets/images/icons/clicksIcon';
// import IndexTable from '../Datatable/IndexTable';
import { KeywordGoogleDatatable } from '../Datatable/KeywordGoogleDatatable';
import Newdemo from '../../../../shared/components/dropdown/Newdemo';
import IconCRM from '../../../../../assets/images/icons/crmIcon';
import {Dropdown} from "../../../../shared/components/dropdown/dropdown"

export class Google extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dropdown_options: [{"value": "impression", "label": "Impression"}, {"value": "clicks", "label": "Clicks"}, {"value": "spends", "label": "Spends"}, {"value": "leads", "label": "Leads"}, {"value": "appinstalls", "label": "App Installs"} ],
            metrics_dropdown_options: [{"value": "campaign", "label": "Campaign"}, {"value": "adgroup", "label": "Ad Group"}, {"value": "ad", "label": "AD"}, {"value": "keywords", "label": "Keywords"}],
            // leadspiedata: [{ "name": "Google Search", "value": 39 }, { "name": "Google Display", "value": 32 }, { "name": "Remarketing", "value": 7 }],
            leadspiedata: [],
            locationdata: [{ "name": "Assam", "points": 100 }, { "name": "Bihar", "points": 200 }, { "name": "Delhi", "points": 110 }, { "name": "Gujarat", "points": 100 }, { "name": "Haryana", "points": 100 }, { "name": "Karnataka", "points": 100 }, { "name": "Maharashtra", "points": 100 }, { "name": "Punjab", "points": 100 }, { "name": "Rajasthan", "points": 130 }, { "name": "Telangana", "points": 140 }],
            // locationdata: [],
            total_conversion: '',
            // metrics: [{"date":"2016","value":"13"},{"date":"2017","value":"23"},{"date":"2018","value":"25"},{"date":"2019","value":"31"},{"date":"2020","value":"29"},{"date":"2021","value":"33"}],
            metrics: [],
            is_select_data_loaded: false,
            select_google_data: [],
            is_impression_loaded: false,
            social_multiline_data: [],
            is_social_multiline_loaded: false,
            device_data: [],
            is_device_loaded: false,
            is_location_loaded: false,
            is_google_lead_source_loaded: false,
            total_leads_count: 0
        }

    }

    componentDidMount() {
        this.getImpression();
        this.getGoogleSelectMetrices();
        this.getGoogleDevicematrix();
        this.getLocationTypeData();
        this.getGoogleLeadSource();
    }

    getGoogleSelectMetrices() {
        const data = {
            'start_date': "2021-01-01",
            'end_date': "2021-12-31",
            'source': 'Google',
        }

        const url = "https://api1.icogz.com/api/performance/google_source_select/"
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => res.json())
            .then((result) => {
                this.setState({
                    is_device_loaded: true,
                    select_google_data: result
                });
            })
    }

    getGoogleDevicematrix() {
        const data = {
            'source': 'Google',
        }
        const url = "https://api1.icogz.com/api/performance/device/"
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => res.json())
            .then((result) => {
                this.setState({
                    is_select_data_loaded: true,
                    device_data: result
                });
            })
    }

    getImpression() {
        const data = {
            'start_date': "2021-01-01",
            'end_date': "2021-12-31",
            'source': 'Google',

        }

        const url = "https://api1.icogz.com/api/performance/google_graph_matrix/"
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json())
            .then((result) => {
                let current_impression_list = result['current_impression_list'];

                let data_list = [];
                current_impression_list.map((info, index) => {
                    try {
                        let a = {
                            "date": new Date(current_impression_list[index]['date_to_be_considered']),
                            "value": current_impression_list[index]['impressions'],
                        }
                        data_list.push(a);
                    } catch {
                        // 
                    }
                });

                this.setState({ is_social_multiline_loaded: true, metrics: data_list, final_impression: result['final_impression'] })
            },
                (error) => {
                    console.log("Error Result = ", error)
                }
            )
    }

    getLocationTypeData() {
        const data = {
            'start_date': '2021-10-01',
            'end_date': '2021-12-31',
            'source': 'Google',
        }
        const url = "https://api1.icogz.com/api/performance/location/"
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json())
            .then((result) => {
                let location_list = result['location_data'];

                let data_list = [];
                location_list.map((info, index) => {
                    try {
                        let a = {
                            "name": location_list[index]['state'],
                            "points": location_list[index]['location'],
                        }
                        data_list.push(a);
                    } catch {
                        // 
                    }
                });

                // console.log("-------- state_list Arjun  ----------- ", data_list);
                // this.setState({is_location_loaded: true, locationdata: data_list})
            });
    }

    getGoogleLeadSource() {
        const data = {
            'start_date': '2021-10-01',
            'end_date': '2021-12-31',
            'source': 'Google',
        }
        const url = "https://api1.icogz.com/api/performance/google-lead-source/"
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json())
            .then((result) => {

                let source_list = result['lead_source_list'];

                let data_list = [];
                let total_leads = 0;
                source_list.map((info, index) => {
                    try {
                        let a = {
                            "name": info['type'],
                            "value": info['total_conversion']
                        }
                        total_leads = total_leads + info['total_conversion'];
                        data_list.push(a);
                    } catch {
                        // 
                    }
                });

                console.log('//////////////////////////// data_list', data_list)
                this.setState({ is_google_lead_source_loaded: true, total_leads_count: total_leads, leadspiedata: data_list })
            });
    }



    render() {

        if (!this.state.is_google_lead_source_loaded || !this.state.is_select_data_loaded || !this.state.is_social_multiline_loaded || !this.state.is_device_loaded) {
            return <div></div>
        }

        console.log('**************************************************** leadspiedata', this.state.leadspiedata);

        let lead_list = this.state.leadspiedata;

        let total_conversion = 0;
        return (
            <Wrapper>
                <div className="icz-row">
                    <Col className="icz-sectioncardwrapper" sm={12} lg={6}>
                        <div className="icz-sectioncard">
                            <div className="icz-cardheader">
                                <Col className="icz-cardtitle">
                                    Leads
                                </Col>
                            </div>

                            <div className="icz-dchartsection icz-leadchartsection">
                                <Col className="" sm={12} lg={8}>
                                    <DonutChart card_id="leads-chart" card_class="icz-sectionchart" center_title="Google" graph-data={this.state.leadspiedata} />
                                </Col>
                                <Col sm={12} lg={4} className="icz-chartdetailssection">
                                    <div className="icz-detailsdatasection w-100">

                                        {this.state.leadspiedata.map((info, index) =>

                                            <div key={index} className="icz-detailsdatatab">
                                                <div className="icz-iconwrapper">
                                                    <IconSearch className="icz-googlescreenicon" />
                                                </div>
                                                <div className="icz-titlewrapper">
                                                    <div className="icz-title">
                                                        <NumberFormatter Number={info.value} IsNumber={true} />
                                                    </div>
                                                    <div className="icz-subtitle">{info.name}</div>
                                                </div>
                                            </div>
                                        )}


                                        <div className="icz-detailsdatatab icz-detailchartscroll">
                                            <div className="icz-iconwrapper">
                                                <IconClicks className="icz-icon" />
                                            </div>
                                            <div className="icz-titlewrapper">
                                                <div className="icz-title icz-highlight">
                                                    <NumberFormatter Number={this.state.total_leads_count} IsNumber={true} />
                                                </div>
                                                <div className="icz-subtitle icz-highlight">
                                                    Total Leads
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Col>
                            </div>

                        </div>
                    </Col>
                    <Col className="icz-sectioncardwrapper" sm={12} lg={6}>
                        <div className="icz-sectioncard">
                            <div className="icz-cardheader">
                                <Col className="icz-cardtitle">
                                    Location
                                </Col>
                                <Col className="icz-cardfilter">
                                    <LocationDropdown />
                                </Col>
                            </div>
                            <div className="">
                                <LocationBarChart card_id="locationChart" card_class="icz-sectionchart" graph-data={this.state.locationdata} />
                            </div>
                        </div>
                    </Col>
                </div>

                <div className="icz-row">
                    <div className="icz-sectioncardwrapper w-100">
                        <div className="icz-sectioncard">
                            <div className="icz-devicecardbody">
                                <Col className="icz-devicecardtitle">
                                    Device
                                </Col>
                                <div className="icz-devicesection">
                                    <div className="icz-devicelbldata">
                                        <div className="icz-iconwrapper align-item-center"><IconDesktop className="icz-icon" /></div>
                                        <div className="label-txt px-20" lg={12}>Desktop
                                            <p>{this.state.device_data['device_list'][2]['totaldevice']}</p>
                                        </div>
                                    </div>

                                    <div className="icz-devicelbldata">
                                        <div className="icz-iconwrapper align-item-center"><IconTablet className="icz-icon" /></div>
                                        <div className="label-txt px-20" lg={12}>Tablet
                                            <p>{this.state.device_data['device_list'][1]['totaldevice']}</p>
                                        </div>
                                    </div>

                                    <div className="icz-devicelbldata">
                                        <div className="icz-iconwrapper align-item-center"><IconMobile className="icz-icon" /></div>
                                        <div className="label-txt px-20" lg={12}>Mobile
                                            <p>{this.state.device_data['device_list'][3]['totaldevice']}</p>

                                        </div>
                                    </div>

                                    <div className="icz-devicelbldata">
                                        <div className="icz-iconwrapper align-item-center"><IconLaptop className="icz-icon" /></div>
                                        <div className="label-txt px-20" lg={12}>Other
                                            <p>{this.state.device_data['device_list'][0]['totaldevice']}</p>

                                        </div>
                                    </div>

                                    <div className="icz-devicelbldata">
                                        <div className="icz-iconwrapper align-item-center"><IconDesktop className="icz-icon" /></div>
                                        <div className="label-txt p-20" lg={12}>Total
                                            <p>{this.state.device_data['device_list'][0]['totaldevice'] + this.state.device_data['device_list'][1]['totaldevice'] + this.state.device_data['device_list'][2]['totaldevice'] + this.state.device_data['device_list'][3]['totaldevice']}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="icz-row">
                    <Col className="icz-sectioncardwrapper" sm={12} lg={12}>
                        <div className="icz-sectioncard">
                            <div className="icz-cardheader">
                                <Col className="icz-cardtitle">
                                    Performance
                                </Col>
                                <Col className="icz-cardfilter">
                                <Dropdown dropdown_options={this.state.dropdown_options} dropdown_placeholder="Impressions"  parentCallback={this.handleCallback}/>
                                </Col>
                            </div>
                            <div className="icz-dchartsection">
                                <Col className="" sm={12} lg={9}>
                                    <MetricsLineChart card_id="google_metrics" card_class="icz-sectionchart" graph-data={this.state.metrics} />
                                </Col>
                                <Col sm={12} lg={3} className="icz-chartdetailssection">
                                    <div className="icz-detailsdatasection icz-detailscroll">
                                        <div className="icz-detailsdatatab">
                                            <div className="icz-iconwrapper">
                                                <IconCRM className="icz-googlescreenicon" />
                                            </div>
                                            <div className="icz-titlewrapper">
                                                <div className="icz-title">
                                                    <NumberFormatter Number={this.state.select_google_data['google_filter_data'][0]['conversions_money']} IsNumber={true} />

                                                </div>
                                                <div className="icz-subtitle">
                                                    CTR
                                                </div>
                                            </div>
                                        </div>

                                        <div className="icz-detailsdatatab">
                                            <div className="icz-iconwrapper">
                                                <IconCRM className="icz-googlescreenicon" />
                                            </div>
                                            <div className="icz-titlewrapper">
                                                <div className="icz-title">
                                                    <NumberFormatter Number={this.state.select_google_data['google_filter_data'][0]['clicks'] + this.state.select_google_data['google_filter_data'][0]['conversions_money']} IsNumber={true} />

                                                </div>
                                                <div className="icz-subtitle">
                                                    Clicks:Conversion
                                                </div>
                                            </div>
                                        </div>

                                        <div className="icz-detailsdatatab">
                                            <div className="icz-iconwrapper">
                                                <IconCRM className="icz-googlescreenicon" />
                                            </div>
                                            <div className="icz-titlewrapper">
                                                <div className="icz-title">
                                                    <NumberFormatter Number={this.state.select_google_data['google_filter_data'][0]['conversions_money']} IsNumber={true} />
                                                </div>
                                                <div className="icz-subtitle">
                                                    Conversions
                                                </div>
                                            </div>
                                        </div>

                                        <div className="icz-detailsdatatab">
                                            <div className="icz-iconwrapper">
                                                <IconCRM className="icz-googlescreenicon" />
                                            </div>
                                            <div className="icz-titlewrapper">
                                                <div className="icz-title">

                                                    <NumberFormatter Number={this.state.select_google_data['google_filter_data'][0]['cpl']} IsNumber={true} />

                                                </div>
                                                <div className="icz-subtitle">
                                                    CPL
                                                </div>
                                            </div>
                                        </div>

                                        <div className="icz-detailsdatatab">
                                            <div className="icz-iconwrapper">
                                                <IconCRM className="icz-googlescreenicon" />
                                            </div>
                                            <div className="icz-titlewrapper">
                                                <div className="icz-title">
                                                    <NumberFormatter Number={this.state.select_google_data['google_filter_data'][0]['cpi']} IsNumber={true} />

                                                </div>
                                                <div className="icz-subtitle">
                                                    CPI
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="icz-detailsdatatab">
                                            <div className="icz-iconwrapper">
                                                <IconCRM className="icz-googlescreenicon" />
                                            </div>
                                            <div className="icz-titlewrapper">
                                                <div className="icz-title">
                                                    <NumberFormatter Number={this.state.select_google_data['google_filter_data'][0]['clicks'] + this.state.select_google_data['google_filter_data'][0]['conversions_money']} IsNumber={true} />

                                                </div>
                                                <div className="icz-subtitle">
                                                    Clicks:Conversion
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>

                                </Col>
                            </div>
                        </div>
                    </Col>
                </div>


                <div className="icz-row">
                    <div className="icz-sectioncardwrapper w-100">
                        <Col className="icz-sectioncardwrapper" sm={12} lg={12}>
                            <div className="icz-sectioncard">
                                <div className="icz-cardheader">
                                    <Col className="icz-cardtitle">
                                        Summary
                                    </Col>
                                    <Col className="icz-cardfilter">
                                    <Dropdown dropdown_options={this.state.metrics_dropdown_options} dropdown_placeholder="Campaign"  parentCallback={this.handleCallback}/>
                                    </Col>
                                </div>
                                <div className="">
                              {/* <SourceDatatable/> */}
                                </div>
                            </div>
                        </Col>
                    </div>
                </div>

            </Wrapper>
        )
    }
}
