import React from 'react'
import '../../cards/card.scss';
import '../comparecard.scss'
import Col from 'react-bootstrap/esm/Col';
import Image from 'react-bootstrap/esm/Image';
import { Item } from "semantic-ui-react";
import { Carousel } from '../../../../shared/components/carousel/carousel';

const ProductCard = ({
    product,
    addToCompare,
    removeFromCompare,
    selected }) => (
    <Col lg={6} className="icz-comparecardwrapper">
        <Item key={product.name} className='icz-comparecard'>
            <Col lg={12} className="icz-detailsdatasection">
                <Col lg={6} sm={12} xs={12}>
                    <Item.Image className="icz-imagewrapper">
                        <Image src={product.image} className="icz-image" />
                    </Item.Image>
                </Col>
                <Col lg={6} sm={12} xs={12} >
                    <Col lg={12} className="icz-detailsdatasection">
                        <Col lg={10} className="icz-text">
                            {product.time}
                        </Col>
                        <Col lg={2} className="icz-viewlink">
                            <Item.Extra>
                                {selected && selected.includes(product) ? (
                                    <input className="icz-checkbox" type="checkbox" onClick={(event) => removeFromCompare(product)} />
                                ) : (
                                    <input className="icz-checkbox" type="checkbox" onClick={(event) => addToCompare(product, event)} />
                                )}
                            </Item.Extra>
                        </Col>
                    </Col>
                    <Item.Content>
                        <Col lg={12} className='icz-detailsdatasection'>
                            <Col lg={6} className="icz-detailsdatatab icz-titlewrapper">
                                <div className="icz-iconwrapper">
                                    {product.minutesicon}
                                </div>
                                <div className="icz-titlewrapper">
                                    <div className="icz-title">
                                        <Item.Description> {product.minutesvalue}</Item.Description>
                                    </div>
                                    <div className="icz-subtitle">
                                        <Item.Description> {product.minutes}</Item.Description>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={6} className="icz-detailsdatatab icz-titlewrapper">
                                <div className="icz-iconwrapper">
                                    {product.viewsicon}
                                </div>
                                <div className="icz-titlewrapper">
                                    <div className="icz-title">
                                        <Item.Description>{product.viewsvalue}</Item.Description>
                                    </div>
                                    <div className="icz-subtitle">
                                        <Item.Description> {product.views}</Item.Description>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={6} className="icz-detailsdatatab icz-titlewrapper">
                                <div className="icz-iconwrapper">
                                    {product.likesicon}
                                </div>
                                <div className="icz-titlewrapper">
                                    <div className="icz-title">
                                        <Item.Description>{product.likesvalue}</Item.Description>
                                    </div>
                                    <div className="icz-subtitle">
                                        <Item.Description> {product.likes}</Item.Description>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={6} className="icz-detailsdatatab icz-titlewrapper">
                                <div className="icz-iconwrapper">
                                    {product.dislikesicon}
                                </div>
                                <div className="icz-titlewrapper">
                                    <div className="icz-title">
                                        <Item.Description>{product.dislikesvalue}</Item.Description>
                                    </div>
                                    <div className="icz-subtitle">
                                        <Item.Description>{product.dislikes}</Item.Description>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={6} className="icz-detailsdatatab icz-titlewrapper">
                                <div className="icz-iconwrapper">
                                    {product.rateicon}
                                </div>
                                <div className="icz-titlewrapper">
                                    <div className="icz-title">
                                        <Item.Description>{product.viewrate}</Item.Description>
                                    </div>
                                    <div className="icz-subtitle">
                                        <Item.Description>{product.rate}</Item.Description>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={6} className="icz-detailsdatatab icz-titlewrapper">
                                <div className="icz-iconwrapper">
                                    {product.completionicon}
                                </div>
                                <div className="icz-titlewrapper">
                                    <div className="icz-title">
                                        <Item.Description>{product.completionvalue}</Item.Description>
                                    </div>
                                    <div className="icz-subtitle">
                                        <Item.Description> {product.completion}</Item.Description>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={6} className="icz-detailsdatatab icz-titlewrapper">
                               
                            </Col>
                        </Col>
                    </Item.Content>
                    <Col lg={12} className='icz-titlewrapper'>
                        <div className="icz-viewlink">
                            {product.link}
                        </div>
                    </Col>
                </Col>

            </Col>

        </Item>
    </Col>

    // ]}>
    // </Carousel>
);

export default ProductCard;