const IconAryabot = (props) => {
    return (

        <svg className={props.className} width="54" height="54" viewBox="0 0 54 54" fill="none" stroke="#486F88" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.54 17.1431L12.61 13.3931" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M13.0279 11.276C13.3024 11.5372 13.4933 11.8738 13.5763 12.2434C13.6594 12.6131 13.631 12.999 13.4947 13.3525C13.3584 13.7059 13.1203 14.011 12.8106 14.2292C12.5009 14.4473 12.1334 14.5687 11.7547 14.5779C11.3759 14.5871 11.003 14.4838 10.683 14.2811C10.363 14.0783 10.1103 13.7852 9.95693 13.4388C9.80356 13.0924 9.75637 12.7083 9.82134 12.335C9.88632 11.9618 10.0605 11.6162 10.3219 11.342C10.672 10.9745 11.1538 10.761 11.6613 10.7486C12.1687 10.7362 12.6603 10.926 13.0279 11.276" />
            <path d="M44.573 12.0051C44.8474 12.2662 45.0383 12.6028 45.1214 12.9725C45.2045 13.3421 45.1761 13.728 45.0398 14.0815C44.9035 14.4349 44.6654 14.74 44.3556 14.9582C44.0459 15.1763 43.6784 15.2977 43.2997 15.3069C42.921 15.3161 42.548 15.2128 42.228 15.0101C41.908 14.8073 41.6554 14.5142 41.502 14.1678C41.3486 13.8214 41.3014 13.4373 41.3664 13.064C41.4314 12.6908 41.6056 12.3452 41.867 12.0711C42.2171 11.7035 42.6988 11.49 43.2063 11.4776C43.7138 11.4652 44.2054 11.655 44.573 12.0051" />
            <path d="M38.896 17.344L42.865 13.715" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M24.649 44.1551L23.977 41.0241C22.8635 40.714 21.7964 40.2566 20.804 39.6641L18.072 41.3431L14.366 37.5531L16.104 34.8641C15.6828 34.12 15.3361 33.3362 15.069 32.5241C23.38 32.5241 19.291 32.5151 39.883 32.5241C39.5863 33.4297 39.1909 34.2999 38.704 35.1191L40.382 37.8481L36.592 41.5481L33.899 39.8141C32.892 40.3838 31.8135 40.8166 30.692 41.1011L29.949 44.2161L24.649 44.1551ZM38.957 32.5181H15.071C14.9776 32.2334 14.8936 31.9444 14.819 31.6511L11.704 30.9091L11.763 25.6091L14.894 24.9371C15.204 23.8236 15.6614 22.7565 16.254 21.7641L14.576 19.0321L18.366 15.3271L21.057 17.0641C22.0635 16.4941 23.1417 16.0612 24.263 15.7771L25.004 12.6641L30.304 12.7241L30.976 15.8551C32.0909 16.1648 33.1593 16.6222 34.153 17.2151L36.884 15.5371L40.584 19.3271L38.85 22.0171C39.4199 23.024 39.8527 24.1025 40.137 25.2241L43.252 25.9671L43.192 31.2671L40.06 31.9391C40.006 32.1391 39.948 32.3271 39.884 32.5191L38.957 32.5181Z" strokeWidth="1.5" />
            <path d="M33.896 28.555C29.67 29.6445 25.2388 29.6606 21.005 28.602C20.2403 28.4026 19.5637 27.9547 19.0814 27.3287C18.5992 26.7028 18.3387 25.9342 18.341 25.144V25.083C18.3415 24.135 18.7185 23.2261 19.3891 22.556C20.0597 21.886 20.969 21.5097 21.917 21.51H33.04C33.9131 21.5108 34.7557 21.831 35.409 22.4102C36.0624 22.9894 36.4812 23.7876 36.5866 24.6543C36.692 25.521 36.4767 26.3964 35.9812 27.1153C35.4858 27.8342 34.7444 28.347 33.897 28.557" strokeWidth="1.5" />
            <path d="M24.553 25.635C24.5162 25.8699 24.4106 26.0885 24.2494 26.2631C24.0882 26.4378 23.8788 26.5607 23.6477 26.6162C23.4166 26.6717 23.1742 26.6573 22.9513 26.5748C22.7283 26.4924 22.5349 26.3456 22.3955 26.1531C22.2561 25.9606 22.177 25.731 22.1683 25.4935C22.1595 25.256 22.2215 25.0212 22.3464 24.819C22.4712 24.6168 22.6533 24.4561 22.8695 24.3575C23.0858 24.2589 23.3265 24.2267 23.561 24.265C23.8738 24.3161 24.1536 24.4889 24.3394 24.7456C24.5253 25.0022 24.6021 25.322 24.553 25.635" />
            <path d="M32.866 25.635C32.8292 25.8699 32.7235 26.0885 32.5624 26.2631C32.4012 26.4378 32.1918 26.5607 31.9607 26.6162C31.7295 26.6717 31.4872 26.6573 31.2642 26.5748C31.0413 26.4924 30.8479 26.3456 30.7085 26.1531C30.5691 25.9606 30.49 25.731 30.4813 25.4935C30.4725 25.256 30.5345 25.0212 30.6593 24.819C30.7842 24.6168 30.9663 24.4561 31.1825 24.3575C31.3988 24.2589 31.6394 24.2267 31.874 24.265C32.1867 24.3161 32.4666 24.4889 32.6524 24.7456C32.8383 25.0022 32.9151 25.322 32.866 25.635" />
        </svg>

    )
}

export default IconAryabot;