import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
am4core.addLicense("CH258896422");

export default class HighlightingLineChart extends Component {
    componentDidMount() {
        console.log("this is props ", this.props)

        let info = this.props
        let title1 = info['metric_1']
        let title2 = info['metric_2']
        let title3 = info['metric_3']
        let title4 = info['metric_4']
        let title5 = info['metric_5']
        let title6 = info['metric_6']

        let chart_id = info['chart_id'];
        let data = info['graph-data'];

        console.log("cardname"); //Avgsession_barchart
        console.log("data", data);

        let chart = am4core.create(chart_id, am4charts.XYChart);
        chart.fontFamily = "Ubuntu";//TO CHANGE FONT SIZE OF CHART LABELS
        chart.fontSize = 12;//TO CHANGE FONT SIZE OF CHART LABELS
        chart.data = data;

        chart.colors.list = [
            am4core.color("#11A1FD"),
            am4core.color("#FF9931"),
        ];

        // Create category axis
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.line.strokeOpacity = 0.2; // for reducing the x axis opacity 
        categoryAxis.renderer.line.stroke = am4core.color("#707070"); // for changing the x axis color
        categoryAxis.renderer.labels.template.fill = "#757575"; //TO CHANGE COLOR OF X AXIS LABELS
        categoryAxis.renderer.minGridDistance = 10;
        categoryAxis.dataFields.category = "month";
        categoryAxis.renderer.opposite = false;
        categoryAxis.tooltip.disabled = true;
        categoryAxis.startLocation = 0.5;
        categoryAxis.endLocation = 0;


        // Create value axis
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minGridDistance = 30;
        valueAxis.renderer.line.strokeOpacity = 0; // for reducing the x axis opacity 
        valueAxis.renderer.line.stroke = am4core.color("#707070"); // for changing the Y axis color
        valueAxis.renderer.grid.template.strokeWidth = 0; //disable horizontal lines
        valueAxis.renderer.labels.template.fill = "#757575"; //TO CHANGE COLOR OF Y AXIS LABELS
        valueAxis.renderer.line.strokeOpacity = 0.2; // for reducing the Y axis opacity 
        valueAxis.tooltip.disabled = true; // for disabling to the y axis tooltip
        valueAxis.renderer.labels.template.adapter.add("text", function(text) {
            return text + "%";
          });

        // Create series
        let series1 = chart.series.push(new am4charts.LineSeries());
        series1.dataFields.valueY = "value1";
        series1.dataFields.categoryX = "month";
        series1.name = title1;
        series1.tooltipText = "{name} in {categoryX}: {valueY}";
        series1.tooltip.getFillFromObject = false;
        series1.tooltip.background.fill = am4core.color("#213345");
        series1.tooltip.background.stroke = am4core.color("#213345");
        series1.tooltip.background.cornerRadius = 5;
        series1.tooltip.label.fontSize = 12;
        series1.tooltip.position = "pointer";
        var shadow = series1.tooltip.background.filters.getIndex(0);
        shadow.dx = 3;
        shadow.dy = 10;
        shadow.blur = 10;
        shadow.color = am4core.color("#14567829");
        series1.fill = am4core.color("#11A1FD");
        series1.stroke = am4core.color("#11A1FD");
        series1.strokeWidth = 1;
        series1.tensionX = 0.8;
        series1.legendSettings.valueText = "{valueY}";

        let series2 = chart.series.push(new am4charts.LineSeries());
        series2.dataFields.valueY = "value2";
        series2.dataFields.categoryX = "month";
        series2.name = title2;
        series2.tooltipText = "{name} in {categoryX}: {valueY}";
        series2.tooltip.getFillFromObject = false;
        series2.tooltip.background.fill = am4core.color("#213345");
        series2.tooltip.background.stroke = am4core.color("#213345");
        series2.tooltip.background.cornerRadius = 5;
        series2.tooltip.label.fontSize = 12;
        series2.tooltip.position = "pointer";
        var shadow = series2.tooltip.background.filters.getIndex(0);
        shadow.dx = 3;
        shadow.dy = 10;
        shadow.blur = 10;
        shadow.color = am4core.color("#14567829");
        series2.fill = am4core.color("#ff9931");
        series2.stroke = am4core.color("#ff9931");
        series2.strokeWidth = 1;
        series2.tensionX = 0.8;
        series2.legendSettings.valueText = "{valueY}";

        let series3 = chart.series.push(new am4charts.LineSeries());
        series3.dataFields.valueY = "value3";
        series3.dataFields.categoryX = "month";
        series3.name = title3;
        series3.tooltipText = "{name} in {categoryX}: {valueY}";
        series3.tooltip.getFillFromObject = false;
        series3.tooltip.background.fill = am4core.color("#213345");
        series3.tooltip.background.stroke = am4core.color("#213345");
        series3.tooltip.background.cornerRadius = 5;
        series3.tooltip.label.fontSize = 12;
        series3.tooltip.position = "pointer";
        var shadow = series3.tooltip.background.filters.getIndex(0);
        shadow.dx = 3;
        shadow.dy = 10;
        shadow.blur = 10;
        shadow.color = am4core.color("#14567829");
        series3.fill = am4core.color("#5a75f9");
        series3.stroke = am4core.color("#5a75f9");
        series3.strokeWidth = 1;
        series3.tensionX = 0.8;
        series3.legendSettings.valueText = "{valueY}";

        let series4 = chart.series.push(new am4charts.LineSeries());
        series4.dataFields.valueY = "value4";
        series4.dataFields.categoryX = "month";
        series4.name = title4;
        series4.tooltipText = "{name} in {categoryX}: {valueY}";
        series4.tooltip.getFillFromObject = false;
        series4.tooltip.background.fill = am4core.color("#213345");
        series4.tooltip.background.stroke = am4core.color("#213345");
        series4.tooltip.background.cornerRadius = 5;
        series4.tooltip.label.fontSize = 12;
        series4.tooltip.position = "pointer";
        var shadow = series4.tooltip.background.filters.getIndex(0);
        shadow.dx = 3;
        shadow.dy = 10;
        shadow.blur = 10;
        shadow.color = am4core.color("#14567829");
        series4.fill = am4core.color("#07c180");
        series4.stroke = am4core.color("#07c180");
        series4.strokeWidth = 1;
        series4.tensionX = 0.8;
        series4.legendSettings.valueText = "{valueY}";

        let series5 = chart.series.push(new am4charts.LineSeries());
        series5.dataFields.valueY = "value5";
        series5.dataFields.categoryX = "month";
        series5.name = title5;
        series5.tooltipText = "{name} in {categoryX}: {valueY}";
        series5.tooltip.getFillFromObject = false;
        series5.tooltip.background.fill = am4core.color("#213345");
        series5.tooltip.background.stroke = am4core.color("#213345");
        series5.tooltip.background.cornerRadius = 5;
        series5.tooltip.label.fontSize = 12;
        series5.tooltip.position = "pointer";
        var shadow = series5.tooltip.background.filters.getIndex(0);
        shadow.dx = 3;
        shadow.dy = 10;
        shadow.blur = 10;
        shadow.color = am4core.color("#14567829");
        series5.fill = am4core.color("#7d9eb5");
        series5.stroke = am4core.color("#7d9eb5");
        series5.strokeWidth = 1;
        series5.tensionX = 0.8;
        series5.legendSettings.valueText = "{valueY}";

        let series6 = chart.series.push(new am4charts.LineSeries());
        series6.dataFields.valueY = "value6";
        series6.dataFields.categoryX = "month";
        series6.name = title6;
        series6.tooltipText = "{name} in {categoryX}: {valueY}";
        series6.tooltip.getFillFromObject = false;
        series6.tooltip.background.fill = am4core.color("#213345");
        series6.tooltip.background.stroke = am4core.color("#213345");
        series6.tooltip.background.cornerRadius = 5;
        series6.tooltip.label.fontSize = 12;
        series6.tooltip.position = "pointer";
        var shadow = series6.tooltip.background.filters.getIndex(0);
        shadow.dx = 3;
        shadow.dy = 10;
        shadow.blur = 10;
        shadow.color = am4core.color("#14567829");
        series6.fill = am4core.color("#213345");
        series6.stroke = am4core.color("#213345");
        series6.strokeWidth = 1;
        series6.tensionX = 0.8;
        series6.legendSettings.valueText = "{valueY}";



        // Add chart cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = "zoomY";

        // add legend
        chart.legend = new am4charts.Legend();
        chart.legend.position = "right";
        chart.legend.scrollable = true;
        chart.legend.useDefaultMarker = true;
        let marker = chart.legend.markers.template.children.getIndex(0);
        marker.cornerRadius(12, 12, 12, 12);

        let markerTemplate = chart.legend.markers.template;
        markerTemplate.width = 15;
        markerTemplate.height = 15;


        chart.cursor = new am4charts.XYCursor();
        chart.padding(10, 0, 0, 0);


    }

    render() {
        let chart_class = this.props.chart_class;
        let chart_id = this.props.chart_id;
        return (
            <div>
                <div id={chart_id} className={chart_class}></div>
            </div>
        )
    }
}

