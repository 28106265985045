import React, { Component } from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);
am4core.addLicense("CH258896422");

export default class DateBasedAxisChart extends Component {
    componentDidMount() {


        let info = this.props
        let title1 = info['metric_1']
        let title2 = info['metric_2']
        let title3 = info['metric_3']
        let title4 = info['metric_4']
        let titleX = info['Xtitle']
        let titleY = info['Ytitle']

        let card_id = info['card_id'];
        let data = info['graph-data'];

        //console.log("cardname"); //Avgsession_barchart
        //  console.log("data", data);


        // Create chart instance
        let chart = am4core.create(card_id, am4charts.XYChart);

        chart.data = data
        chart.fontSize = 12;

        var colorSet = new am4core.ColorSet();
        colorSet.list = ["#11A1FD", "#FF9931", "#5A75F9", "#07C180", "#D51F30", "#4CC3FD", "#E96E7A", "#8298FB", "#3CD795", "#FFB866"].map(function (color) {
            return new am4core.color(color);
        });
        chart.colors = colorSet;

        // Create axes
        let xAxis = chart.xAxes.push(new am4charts.ValueAxis());
        xAxis.renderer.minGridDistance = 50;
        xAxis.renderer.line.strokeOpacity = 0.2; // for reducing the x axis opacity 
        xAxis.renderer.line.stroke = am4core.color("#707070"); // for changing the x axis color
        xAxis.renderer.labels.template.fill = "#757575"; //TO CHANGE COLOR OF X AXIS LABELS
        xAxis.startLocation = 0.5;
        xAxis.endLocation = 0;
        xAxis.title.text = titleX;

        let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        yAxis.renderer.minGridDistance = 50;
        yAxis.renderer.line.strokeOpacity = 0; // for reducing the x axis opacity 
        yAxis.renderer.line.stroke = am4core.color("#707070"); // for changing the Y axis color
        yAxis.renderer.grid.template.strokeWidth = 0; //disable horizontal lines
        yAxis.renderer.labels.template.fill = "#757575"; //TO CHANGE COLOR OF Y AXIS LABELS
        yAxis.renderer.line.strokeOpacity = 0.2; // for reducing the Y axis opacity 
        yAxis.title.text = titleY;

        // Create series #1
        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "y";
        series.dataFields.valueX = "x";
        series.dataFields.value = "value";
        series.strokeOpacity = 0;
        series.name = title1;
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color("#213345");
        series.tooltip.background.cornerRadius = 5;
        series.tooltip.label.padding(10, 10, 10, 10);
        series.tooltip.label.fontSize = 12;
        series.tooltip.pointerOrientation = "vertical";
        series.tooltip.label.minWidth = 100;
        var shadow = series.tooltip.background.filters.getIndex(0);
        shadow.dx = 3;
        shadow.dy = 10;
        shadow.blur = 10;
        shadow.color = am4core.color("#14567829");



        let bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.strokeOpacity = 0.2;
        bullet.stroke = am4core.color("#11A1FD");
        bullet.nonScalingStroke = true;
        bullet.tooltipText = "{name} - x: {valueX} y: {valueY}";
        series.heatRules.push({
            target: bullet.circle,
            min: 10,
            max: 20,
            property: "radius"
        });

        // Create series #2
        let series2 = chart.series.push(new am4charts.LineSeries());
        series2.dataFields.valueY = "y2";
        series2.dataFields.valueX = "x2";
        series2.dataFields.value = "value2";
        series2.strokeOpacity = 0;
        series2.name = title2;
        series2.tooltip.getFillFromObject = false;
        series2.tooltip.background.fill = am4core.color("#213345");
        series2.tooltip.background.cornerRadius = 5;
        series2.tooltip.label.padding(10, 10, 10, 10);
        series2.tooltip.label.fontSize = 12;
        series2.tooltip.pointerOrientation = "vertical";
        series2.tooltip.label.minWidth = 100;
        var shadow = series2.tooltip.background.filters.getIndex(0);
        shadow.dx = 3;
        shadow.dy = 10;
        shadow.blur = 10;
        shadow.color = am4core.color("#14567829");

        let bullet2 = series2.bullets.push(new am4charts.CircleBullet());
        bullet2.strokeOpacity = 0.2;
        bullet2.stroke = am4core.color("#FF9931");
        bullet2.nonScalingStroke = true;
        bullet2.tooltipText = "{name} - x: {valueX} y: {valueY}";
        series2.heatRules.push({
            target: bullet2.circle,
            min: 10,
            max: 20,
            property: "radius"
        });

         // Create series #3
         let series3 = chart.series.push(new am4charts.LineSeries());
         series3.dataFields.valueY = "y3";
         series3.dataFields.valueX = "x3";
         series3.dataFields.value = "value3";
         series3.strokeOpacity = 0;
         series3.name = title3;
         series3.tooltip.getFillFromObject = false;
         series3.tooltip.background.fill = am4core.color("#213345");
         series3.tooltip.background.cornerRadius = 5;
         series3.tooltip.label.padding(10, 10, 10, 10);
         series3.tooltip.label.fontSize = 12;
         series3.tooltip.pointerOrientation = "vertical";
         series3.tooltip.label.minWidth = 100;
         var shadow = series3.tooltip.background.filters.getIndex(0);
         shadow.dx = 3;
         shadow.dy = 10;
         shadow.blur = 10;
         shadow.color = am4core.color("#14567829");
 
         let bullet3 = series3.bullets.push(new am4charts.CircleBullet());
         bullet3.strokeOpacity = 0.2;
         bullet3.stroke = am4core.color("#5A75F9");
         bullet3.nonScalingStroke = true;
         bullet3.tooltipText = "{name} - x: {valueX} y: {valueY}";
         series3.heatRules.push({
             target: bullet3.circle,
             min: 10,
             max: 20,
             property: "radius"
         });

         // Create series #4
         let series4 = chart.series.push(new am4charts.LineSeries());
         series4.dataFields.valueY = "y4";
         series4.dataFields.valueX = "x4";
         series4.dataFields.value = "value4";
         series4.strokeOpacity = 0;
         series4.name = title4;
         series4.tooltip.getFillFromObject = false;
         series4.tooltip.background.fill = am4core.color("#213345");
         series4.tooltip.background.cornerRadius = 5;
         series4.tooltip.label.padding(10, 10, 10, 10);
         series4.tooltip.label.fontSize = 12;
         series4.tooltip.pointerOrientation = "vertical";
         series4.tooltip.label.minWidth = 100;
         var shadow = series4.tooltip.background.filters.getIndex(0);
         shadow.dx = 3;
         shadow.dy = 10;
         shadow.blur = 10;
         shadow.color = am4core.color("#14567829");
 
         let bullet4 = series4.bullets.push(new am4charts.CircleBullet());
         bullet4.strokeOpacity = 0.2;
         bullet4.stroke = am4core.color("#5A75F9");
         bullet4.nonScalingStroke = true;
         bullet4.tooltipText = "{name} - x: {valueX} y: {valueY}";
         series4.heatRules.push({
             target: bullet3.circle,
             min: 10,
             max: 20,
             property: "radius"
         });

         

        chart.legend = new am4charts.Legend();
        chart.legend.useDefaultMarker = true;
        chart.legend.labels.template.text = "{name}";
        chart.legend.fill = "rgba(112, 112, 112, 0.1)";
        chart.legend.opacity = 1;
        var markerTemplate = chart.legend.markers.template;
        let marker = chart.legend.markers.template.children.getIndex(0);
        marker.cornerRadius(12, 12, 12, 12);
        markerTemplate.width = 13;
        markerTemplate.height = 13;

    }

    render() {
        let card_class = this.props.card_class;
        let card_id = this.props.card_id;
        return (
            <div>
                <div id={card_id} className={card_class}></div>
            </div>
        )
    }
}
