import { Component } from 'react';
import { CountryOrigin } from '../../helpers/constant';


export class NumberFormatter extends Component {
    render() {

       
        let isnumber = this.props.IsNumber;
        let num = this.props.Number;

        if (num == null) {
            num = 0;
        }
        if(CountryOrigin === "india"){
            if (isnumber === 'money') {
                if (num >= 10000000) {
                    num = (num / 10000000).toFixed(1).replace(/\.0$/, '') + 'Cr';
                }
                if (num >= 100000) {
                    num = (num / 100000).toFixed(1).replace(/\.0$/, '') + 'L';
                }
                if (num >= 1000) {
                    num = (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
                }
            };
    
            if (isnumber === 'number') {
                if (num >= 1000000000) {
                    num = (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
                }
                if (num >= 1000000) {
                    num = (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
                }
                if (num >= 1000) {
                    num = (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
                }
            };
        }
        else if(CountryOrigin === "UK"){
            if (isnumber === 'money') {
                if (num >= 1000000000) {
                    num = (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
                }
                if (num >= 1000000) {
                    num = (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
                }
                if (num >= 1000) {
                    num = (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
                }
            };
            if (isnumber === 'number') {
                if (num >= 1000000000) {
                    num = (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
                }
                if (num >= 1000000) {
                    num = (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
                }
                if (num >= 1000) {
                    num = (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
                }
            };

        }
        else{
            if (isnumber === 'money') {
                if (num >= 10000000) {
                    num = (num / 10000000).toFixed(1).replace(/\.0$/, '') + 'Cr';
                }
                if (num >= 100000) {
                    num = (num / 100000).toFixed(1).replace(/\.0$/, '') + 'L';
                }
                if (num >= 1000) {
                    num = (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
                }
            };
    
            if (isnumber === 'number') {
                if (num >= 1000000000) {
                    num = (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
                }
                if (num >= 1000000) {
                    num = (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
                }
                if (num >= 1000) {
                    num = (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
                }
            };
        }
        

        if (isnumber === 'percentage') {
            num = num + `%`;
        };

        if (isnumber === 'salespersqft') {
            num = num + ` SPSF`;
        };

        return num;
    }
}
