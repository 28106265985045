import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.addLicense("CH258896422");
am4core.useTheme(am4themes_animated);

export default class RiBubbleChart extends Component {
    componentDidMount() {

        let info = this.props
        let title1 = info['metric_1']
        let title2 = info['metric_2']
        let title3 = info['metric_3']
        let title4 = info['metric_4']
        let titleX = info['Xtitle']
        let titleY = info['Ytitle']

        let card_id = info['card_id'];
        //let data = info['graph-data'];
        let data = (info['graph-data']);

        //console.log("cardname"); //Avgsession_barchart
        //  console.log("data", data);


        // Create chart instance
        let chart = am4core.create(card_id, am4charts.XYChart);
        //chart.exporting.menu = new am4core.ExportMenu();

        // FOR FORMATTING LARGE NUMBERS
        if (this.props.isnumber == 'money') {
            chart.numberFormatter.numberFormat = "#.##a";
            chart.numberFormatter.bigNumberPrefixes = [
                { "number": 1e+3, "suffix": "K" },
                { "number": 1e+5, "suffix": "L" },
                { "number": 1e+7, "suffix": "Cr" }
            ];
        } else {
            chart.numberFormatter.numberFormat = "#.##a";
            chart.numberFormatter.bigNumberPrefixes = [
                { "number": 1e+3, "suffix": "K" },
                { "number": 1e+6, "suffix": "M" },
                { "number": 1e+9, "suffix": "G" }
            ];
        }


        chart.data = data
        chart.fontSize = 12;

        var colorSet = new am4core.ColorSet();
        colorSet.list = ["#038673", "#db7823", "#d51f30"].map(function (color) {
            return new am4core.color(color);
        });
        chart.colors = colorSet;

        // Create axes
        let xAxis = chart.xAxes.push(new am4charts.ValueAxis());
        // xAxis.renderer.minGridDistance = 50;
        xAxis.renderer.line.strokeOpacity = 0.2; // for reducing the x axis opacity 
        xAxis.renderer.line.stroke = am4core.color("#707070"); // for changing the x axis color
        xAxis.renderer.labels.template.fill = "#757575"; //TO CHANGE COLOR OF X AXIS LABELS
        xAxis.startLocation = 0.5;
        xAxis.endLocation = 0;
        xAxis.title.text = titleX;


        let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        yAxis.renderer.minGridDistance = 50;
        yAxis.renderer.line.strokeOpacity = 0; // for reducing the x axis opacity 
        yAxis.renderer.line.stroke = am4core.color("#707070"); // for changing the Y axis color
        yAxis.renderer.grid.template.strokeWidth = 0; //disable horizontal lines
        yAxis.renderer.labels.template.fill = "#757575"; //TO CHANGE COLOR OF Y AXIS LABELS
        yAxis.renderer.line.strokeOpacity = 0.2; // for reducing the Y axis opacity 
        yAxis.title.text = titleY;



        // Create series #1
        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "y";
        series.dataFields.valueX = "x";
        series.dataFields.value = "value";
        series.strokeOpacity = 0;
        series.name = title1;
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color("#213345");
        series.tooltip.background.stroke = am4core.color("#213345");
        series.tooltip.background.cornerRadius = 5;
        series.tooltip.label.fontSize = 12;
        series.tooltip.position = "pointer";

        // series.tooltip.pointerOrientation = "vertical";
        var shadow = series.tooltip.background.filters.getIndex(0);
        shadow.dx = 3;
        shadow.dy = 10;
        shadow.blur = 10;
        shadow.color = am4core.color("#14567829");



        let bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.strokeOpacity = 0.2;
        bullet.nonScalingStroke = true;

        bullet.tooltipText = `[bold]{name}[/]
        ----
        NSV: [bold]{valueX}[/]
        Spends: [bold]{valueY}[/]
        ATS: [bold]{value}[/]`;

        series.heatRules.push({
            target: bullet.circle,
            min: 10,
            max: 20,
            property: "radius"
        });

        // Create series #2
        let series2 = chart.series.push(new am4charts.LineSeries());
        series2.dataFields.valueY = "y2";
        series2.dataFields.valueX = "x2";
        series2.dataFields.value = "value2";
        series2.strokeOpacity = 0;
        series2.name = title2;
        series2.tooltip.getFillFromObject = false;
        series2.tooltip.background.fill = am4core.color("#213345");
        series2.tooltip.background.stroke = am4core.color("#213345");
        series2.tooltip.background.cornerRadius = 5;
        series2.tooltip.label.fontSize = 12;
        series2.tooltip.position = "pointer";
        // series.tooltip.pointerOrientation = "vertical";
        var shadow = series2.tooltip.background.filters.getIndex(0);
        shadow.dx = 3;
        shadow.dy = 10;
        shadow.blur = 10;
        shadow.color = am4core.color("#14567829");

        let bullet2 = series2.bullets.push(new am4charts.CircleBullet());
        bullet2.strokeOpacity = 0.2;
        bullet2.nonScalingStroke = true;

        bullet2.tooltipText = `[bold]{name}[/]
        ----
        NSV: [bold]{valueX}[/]

        Spends: [bold]{valueY}[/]
        
        ATS: [bold]{value}[/]`;

        series2.heatRules.push({
            target: bullet2.circle,
            min: 10,
            max: 20,
            property: "radius"
        });

        // Create series #3
        let series3 = chart.series.push(new am4charts.LineSeries());
        series3.dataFields.valueY = "y3";
        series3.dataFields.valueX = "x3";
        series3.dataFields.value = "value3";
        series3.strokeOpacity = 0;
        series3.name = title3;
        series3.tooltip.getFillFromObject = false;
        series3.tooltip.background.fill = am4core.color("#213345");
        series3.tooltip.background.stroke = am4core.color("#213345");
        series3.tooltip.background.cornerRadius = 5;
        series3.tooltip.label.fontSize = 12;
        series3.tooltip.position = "pointer";
        // series.tooltip.pointerOrientation = "vertical";

        var shadow = series3.tooltip.background.filters.getIndex(0);
        shadow.dx = 3;
        shadow.dy = 10;
        shadow.blur = 10;
        shadow.color = am4core.color("#14567829");

        let bullet3 = series3.bullets.push(new am4charts.CircleBullet());
        bullet3.strokeOpacity = 0.2;
        bullet3.nonScalingStroke = true;

        bullet3.tooltipText = `[bold]{name}[/]
        ----
        NSV: [bold]{valueX}[/]

        Spends: [bold]{valueY}[/]
        
        ATS: [bold]{value}[/]`;

        series3.heatRules.push({
            target: bullet3.circle,
            min: 10,
            max: 20,
            property: "radius"
        });


    }

    render() {
        let card_class = this.props.card_class;
        let card_id = this.props.card_id;
        return (
            <div>
                <div id={card_id} className={card_class}></div>
            </div>
        )
    }
}