import React, { Component } from 'react';
import Col from "react-bootstrap/esm/Col";
import Wrapper from "../../../../helpers/wrapper";
import { SourceDropdown } from "../../../../shared/components/dropdown/sourcedropdown";
import { DonutChart } from '../../../../shared/components/charts/DonutChart';
import { LocationBarChart } from '../../../../shared/components/charts/LocationBarChart';
import { MetricsLineChart } from '../../../../shared/components/charts/MetricsLineChart';
// import { SourceDatatable } from '../Datatable/SourceDatatable';
import { NumberFormatter } from "../../../../shared/utilities/numberformatter";
import IconDesktop from '../../../../../assets/images/icons/desktopIcon'
import IconMobile from '../../../../../assets/images/icons/mobileIcon';
import IconTablet from '../../../../../assets/images/icons/tabletIcon';
import IconLaptop from '../../../../../assets/images/icons/laptopIcon';
import IconAllDevices from '../../../../../assets/images/icons/alldevicesIcon';
import IconCTR from '../../../../../assets/images/icons/ctrIcon';
import IconCTC from '../../../../../assets/images/icons/ctcIcon'
import IconConversion from '../../../../../assets/images/icons/conversionIcon';
import IconCPL from '../../../../../assets/images/icons/cplIcon'
import IconImpressions from '../../../../../assets/images/icons/impressionsIcon';
import { MetricsDropdown } from '../../../../shared/components/dropdown/metricsdropdown';
import '../Google/google.scss'
import IconClicks from '../../../../../assets/images/icons/clicksIcon';

export default class Twitter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            leadspiedata: [{"name":"App Installs","value":39},{"name":"Website Clicks","value":32},{"name":"Leads","value":7}],
            locationdata: [{"name": "Assam", "points": 100}, {"name": "Bihar", "points": 200},{"name": "Delhi", "points": 110},{"name": "Gujarat", "points": 100},{"name": "Haryana", "points": 100},{"name": "Karnataka", "points": 100},{"name": "Maharashtra", "points": 100},{"name": "Punjab", "points": 100},{"name": "Rajasthan", "points": 130},{"name": "Telangana", "points": 140}],
            metrics: [{"date":"2016","value":13},{"date":"2017","value":23},{"date":"2018","value":25},{"date":"2019","value":31},{"date":"2020","value":29},{"date":"2021","value":33}],
        }
    }
    render() {
        return (
            <Wrapper>
                <div className="icz-row">
                    <Col className="icz-sectioncardwrapper" sm={12} lg={6}>
                        <div className="icz-sectioncard">
                            <div className="icz-cardheader">
                                <Col className="icz-cardtitle">
                                  Conversions
                                </Col>
                            </div>

                            <div className="icz-leadchartsection icz-dchartsection">
                                <Col className="" sm={12} lg={8}>
                                    <DonutChart card_id="conversion-chart" card_class="icz-sectionchart" center_title="Twitter" graph-data={this.state.leadspiedata} />
                                </Col>
                                <Col sm={12} lg={4} className="icz-chartdetailssection icz-chartscroll">
                                    <div className="icz-detailsdatasection w-100">
                                        <div className="icz-detailsdatatab icz-detailchartscroll">
                                            <div className="icz-iconwrapper">
                                            <IconClicks className="icz-icon" />
                                            </div>
                                            <div className="icz-titlewrapper">
                                                <div className="icz-title">
                                                    <NumberFormatter Number={39} IsNumber={true} />
                                                </div>
                                                <div className="icz-subtitle">
                                                    App Installs
                                                </div>
                                            </div>
                                        </div>

                                        <div className="icz-detailsdatatab icz-detailchartscroll">
                                            <div className="icz-iconwrapper">
                                                <IconClicks className="icz-icon" />
                                            </div>
                                            <div className="icz-titlewrapper">
                                                <div className="icz-title">
                                                    <NumberFormatter Number={32} IsNumber={true} />
                                                </div>
                                                <div className="icz-subtitle">
                                                    Website Clicks
                                                </div>
                                            </div>
                                        </div>

                                        <div className="icz-detailsdatatab icz-detailchartscroll">
                                            <div className="icz-iconwrapper">
                                            <IconClicks className="icz-googlescreenicon" />
                                            </div>
                                            <div className="icz-titlewrapper">
                                                <div className="icz-title">
                                                    <NumberFormatter Number={7} IsNumber={true} />
                                                </div>
                                                <div className="icz-subtitle">
                                                    Leads
                                                </div>
                                            </div>
                                        </div>

                                        <div className="icz-detailsdatatab icz-detailchartscroll">
                                            <div className="icz-iconwrapper">
                                            <IconClicks className="icz-icon" />
                                            </div>
                                            <div className="icz-titlewrapper">
                                                <div className="icz-title icz-highlight">
                                                    <NumberFormatter Number={78} IsNumber={true} />
                                                </div>
                                                <div className="icz-subtitle">
                                                    Total Converstions
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        </div>
                    </Col>
                    <Col className="icz-sectioncardwrapper" sm={12} lg={6}>
                        <div className="icz-sectioncard">
                            <div className="icz-cardheader">
                                <Col className="icz-cardtitle">
                                    State
                                </Col>
                                <Col className="icz-cardfilter">
                                   
                                </Col>
                            </div>
                            <div className="">
                                <LocationBarChart card_id="state-chart" card_class="icz-sectionchart" graph-data={this.state.locationdata} />
                            </div>
                        </div>
                    </Col>
                </div>

                <div className="icz-row">
                    <div className="icz-sectioncardwrapper w-100">
                        <div className="icz-sectioncard">
                            <div className="icz-devicecardbody">
                                <Col className="icz-devicecardtitle">
                                    Device
                                </Col>
                                <div className="icz-devicesection">
                                    <div className="icz-devicelbldata">
                                        <div className="icz-iconwrapper align-item-center"><IconDesktop className="icz-icon" /></div>
                                        <div className="label-txt px-20" lg={12}>Desktop
                                            <p>0</p>
                                        </div>
                                    </div>

                                    <div className="icz-devicelbldata">
                                        <div className="icz-iconwrapper align-item-center"><IconTablet className="icz-icon" /></div>
                                        <div className="label-txt px-20" lg={12}>Tablet
                                            <p>0</p>
                                        </div>
                                    </div>

                                    <div className="icz-devicelbldata">
                                        <div className="icz-iconwrapper align-item-center"><IconMobile className="icz-icon" /></div>
                                        <div className="label-txt px-20" lg={12}>Mobile
                                            <p>0</p>
                                        </div>
                                    </div>

                                    <div className="icz-devicelbldata">
                                        <div className="icz-iconwrapper align-item-center"><IconLaptop className="icz-icon" /></div>
                                        <div className="label-txt px-20" lg={12}>Other
                                            <p>0</p>
                                        </div>
                                    </div>

                                    <div className="icz-devicelbldata">
                                        <div className="icz-iconwrapper align-item-center"><IconAllDevices className="icz-icon" /></div>
                                        <div className="label-txt p-20" lg={12}>Total
                                            <p>0</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="icz-row">
                    <Col className="icz-sectioncardwrapper" sm={12} lg={12}>
                        <div className="icz-sectioncard">
                            <div className="icz-cardheader">
                                <Col className="icz-cardtitle">
                                    Performance
                                </Col>
                                <Col className="icz-cardfilter">
                                    <SourceDropdown />
                                </Col>
                            </div>
                            <div className="icz-dchartsection">
                                <Col className="" sm={12} lg={9}>
                                    <MetricsLineChart card_id="twitter_performance" card_class="icz-sectionchart" graph-data={this.state.metrics} />

                                </Col>
                                <Col className="icz-chartdetailssection" sm={12} lg={3}>
                                    <div className="icz-detailsdatasection icz-detailscroll  w-100">
                                        <div className="icz-detailsdatatab d-flex justify-content-between align-items-end">
                                            <div className="d-flex align-items-center">
                                                <div className="icz-iconwrapper">
                                                    <IconCTR className="icz-icon" />
                                                </div>
                                                <div className="icz-titlewrapper">
                                                    <div className="icz-title">
                                                        39%
                                                    </div>
                                                    <div className="icz-subtitle">
                                                        CTR
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="icz-detailsdatatab d-flex justify-content-between align-items-end">
                                            <div className="d-flex align-items-center">
                                                <div className="icz-iconwrapper">
                                                    <IconCTC className="icz-icon" />
                                                </div>
                                                <div className="icz-titlewrapper">
                                                    <div className="icz-title">
                                                        32%
                                                    </div>
                                                    <div className="icz-subtitle">
                                                        CTR
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="icz-detailsdatatab d-flex justify-content-between align-items-end">
                                            <div className="d-flex align-items-center">
                                                <div className="icz-iconwrapper">
                                                    <IconConversion className="icz-icon" />
                                                </div>
                                                <div className="icz-titlewrapper">
                                                    <div className="icz-title">
                                                        45
                                                    </div>
                                                    <div className="icz-subtitle">
                                                        Conversions
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="icz-detailsdatatab d-flex justify-content-between align-items-end">
                                            <div className="d-flex align-items-center">
                                                <div className="icz-iconwrapper">
                                                    <IconCPL className="icz-icon" />
                                                </div>
                                                <div className="icz-titlewrapper">
                                                    <div className="icz-title">
                                                        <NumberFormatter Number={12700} IsNumber={true} />
                                                    </div>
                                                    <div className="icz-subtitle">
                                                        CPL
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="icz-detailsdatatab d-flex justify-content-between align-items-end">
                                            <div className="d-flex align-items-center">
                                                <div className="icz-iconwrapper">
                                                    <IconImpressions className="icz-icon" />
                                                </div>
                                                <div className="icz-titlewrapper">
                                                    <div className="icz-title">
                                                        <NumberFormatter Number={11987} IsNumber={true} />
                                                    </div>
                                                    <div className="icz-subtitle">
                                                        CPI
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="icz-detailsdatatab d-flex justify-content-between align-items-end">
                                            <div className="d-flex align-items-center">
                                                <div className="icz-iconwrapper">
                                                    <IconCTR className="icz-icon" />
                                                </div>
                                                <div className="icz-titlewrapper">
                                                    <div className="icz-title">
                                                        <NumberFormatter Number={358} IsNumber={true} />
                                                    </div>
                                                    <div className="icz-subtitle">
                                                        Clicks:Conversion
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        </div>
                    </Col>
                </div>


                <div className="icz-row">
                    <div className="icz-sectioncardwrapper w-100">
                        <Col className="icz-sectioncardwrapper" sm={12} lg={12}>
                            <div className="icz-sectioncard">
                                <div className="icz-cardheader">
                                    <Col className="icz-cardtitle">
                                        Summary
                                    </Col>
                                    <Col className="icz-cardfilter">
                                        <MetricsDropdown />
                                    </Col>
                                </div>
                                <div className="">
                                    {/* <SourceDatatable /> */}
                                </div>
                            </div>
                        </Col>
                    </div>
                </div>

            </Wrapper>
        )
    }
}
