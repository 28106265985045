import { useState } from "react";
import { BrowserRouter as Route } from "react-router-dom";

import Wrapper from '../../helpers/wrapper';

import SideMenu, { menuItems } from "./sideMenu";
import "./mainSidebar.scss";

function Mainsidebar() {
  const [inactive, setInactive] = useState(false);
    return (
      <Wrapper>
        <SideMenu
          onCollapse={(inactive) => {
            setInactive(inactive);
          }}
        />

        <div className={`icz_container ${inactive ? "inactive" : ""}`}>
          {menuItems.map((menu, index)  => (
            <Wrapper key={index}>
              {menu.subMenus && menu.subMenus.length > 0
                ? menu.subMenus.map((subMenu, index)  => ( 
                    <Route key={index} path={subMenu.to}></Route>
                  ))
                : null}
            </ Wrapper>
          ))}
        </div>
        </ Wrapper>
    
  );
}

export default Mainsidebar;
