import React, { Component } from 'react';
import { Carousel } from '../../../shared/components/carousel/carousel';
import { CategoryCard } from '../../../shared/components/cards/ricategorycard/ricategorycard';
import DefaultImage from '../../../../assets/images/post.png'

export class StoreCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storehealth_StoreCategory:this.props.storehealth_StoreCategory,
    }
  }

  
  render() {
    const CarouselSettings = {
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 600,
          settings: {
            arrows: false,
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        }
      ]
    }


     const Categories_list = this.state.storehealth_StoreCategory.categories_list;
     const Products_list = this.state.storehealth_StoreCategory.products_list;
     const Brands_list = this.state.storehealth_StoreCategory.brands_list;
     const Colors_list = this.state.storehealth_StoreCategory.color_list;
     const Sizes_list = this.state.storehealth_StoreCategory.size_list;



    return (
      <div className="icz-carouselcontainer">
        <Carousel
          Settings={CarouselSettings}
          class={"icz-cardcontainer"}
          SliderCards={[
            <CategoryCard
              index="1"
              CardClass="icz-rislaescardwrapper"
              CardTitle="Top 5 Categories"
              CardImg={DefaultImage}
              SubTitle={Categories_list[0].busi_sub_dept}
              CardThumbnail={DefaultImage}
              CardText={Categories_list[1].busi_sub_dept}
              CardThumbnail2={DefaultImage}
              CardText2={Categories_list[2].busi_sub_dept}
              CardThumbnail3={DefaultImage}
              CardText3={Categories_list[3].busi_sub_dept}
              CardThumbnail4={DefaultImage}
              CardText4={Categories_list[4].busi_sub_dept}
             />,
             <CategoryCard
              index="2"
              CardClass="icz-rislaescardwrapper"
              CardTitle="Top 5 Products"
              CardImg={DefaultImage}
              SubTitle={Products_list[0].product}
              CardThumbnail={DefaultImage}
              CardText={Products_list[1].product}
              CardThumbnail2={DefaultImage}
              CardText2={Products_list[2].product}
              CardThumbnail3={DefaultImage}
              CardText3={Products_list[3].product}
              CardThumbnail4={DefaultImage}
              CardText4={Products_list[4].product}
             />,
             <CategoryCard
              index="3"
              CardClass="icz-rislaescardwrapper"
              CardTitle="Top 5 Brands"
              CardImg={DefaultImage}
              SubTitle={Brands_list[0].brand_new}
              CardThumbnail={DefaultImage}
              CardText={Brands_list[1].brand_new}
              CardThumbnail2={DefaultImage}
              CardText2={Brands_list[2].brand_new}
              CardThumbnail3={DefaultImage}
              CardText3={Brands_list[3].brand_new}
              CardThumbnail4={DefaultImage}
              CardText4={Brands_list[4].brand_new}
             />,
             <CategoryCard
              index="4"
              CardClass="icz-rislaescardwrapper"
              CardTitle="Top 5 Colors"
              CardImg={DefaultImage}
              SubTitle={Colors_list[0].color_description}
              CardThumbnail={DefaultImage}
              CardText={Colors_list[1].color_description}
              CardThumbnail2={DefaultImage}
              CardText2={Colors_list[2].color_description}
              CardThumbnail3={DefaultImage}
              CardText3={Colors_list[3].color_description}
              CardThumbnail4={DefaultImage}
              CardText4={Colors_list[4].color_description}
             />,
             <CategoryCard
              index="5"
              CardClass="icz-rislaescardwrapper"
              CardTitle="Top 5 Sizes"
              CardImg={DefaultImage}
              SubTitle={Sizes_list[0].size_description}
              CardThumbnail={DefaultImage}
              CardText={Sizes_list[1].size_description}
              CardThumbnail2={DefaultImage}
              CardText2={Sizes_list[2].size_description}
              CardThumbnail3={DefaultImage}
              CardText3={Sizes_list[3].size_description}
              CardThumbnail4={DefaultImage}
              CardText4={Sizes_list[4].size_description}
             />
        ]}>
        </Carousel>

      </div>
    );
  }

}

