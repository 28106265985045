import React, { Component } from 'react'
import Container from 'react-bootstrap/Container';
import { Col } from 'react-bootstrap';


import IconFacebook from '../../../../assets/images/icons/facebookIcon';
import IconTwitter from '../../../../assets/images/icons/twitterIcon'
import IconInstagram from '../../../../assets/images/icons/instagramIcon';
import IconImpressions from '../../../../assets/images/icons/impressionsIcon';
import IconGoogle from '../../../../assets/images/icons/googleIcon'
import {Dropdown} from "../../../shared/components/dropdown/dropdown"

import { NumberFormatter } from "../../../shared/utilities/numberformatter";
import { OverviewDropdown } from '../../../shared/components/dropdown/overviewdropdown'
//import MultiLine_LineChart from '../../../shared/components/charts/MultiLine_LineChart'
// import { OverviewTable } from "./Datatable/OverviewTable";
import MultiLine_LineChart from '../../../shared/components/charts/MultiLine_LineChart';
import './performance.scss';
// import { data } from 'jquery';



// import Newdemo from '../../../shared/components/dropdown/Newdemo';
// import Grandparent from '../../../shared/components/dropdown/Grandparent';
// import { SourceDropdown } from '../../../shared/components/dropdown/sourcedropdown';


export class Overview extends Component {

    constructor(props) {

        super(props);
        this.state = {
            dropdown_options: [{"value": "impression", "label": "Impression"}, {"value": "clicks", "label": "Clicks"}, {"value": "spends", "label": "Spends"}, {"value": "leads", "label": "Leads"}, {"value": "appinstalls", "label": "App Installs"} ],
            
            social: '[{"year":"2016","value1":1,"value2":5,"value3":3,"value4":2,"value5":4},{"year":"2017","value1":3,"value2":6,"value3":4,"value4":7,"value5":5},{"year":"2018","value1":5,"value2":4,"value3":5,"value4":6,"value5":7},{"year":"2019","value1":7,"value2":6,"value3":6,"value4":4,"value5":5},{"year":"2020","value1":8,"value2":7,"value3":5,"value4":6,"value5":7},{"year":"2021","value1":7,"value2":6,"value3":3,"value4":5,"value5":5}]',
           
            is_select_data_loaded: false,
            select_source_data: [],

            is_graph_data_loaded: false,
            campaign_graph_data: [],


        }

        // http://api1.icogz.com/api/performance/select-metrices

        fetch("https://api1.icogz.com/api/performance/select-metrices/")
            .then((res) => res.json())
            .then((data) => {
                this.setState({ select_source_data: data, is_select_data_loaded: true })
            }
            );
        const url = "https://api1.icogz.com/api/performance/campaign-graph_matrix/"
        fetch(url, {
           
        }).then(res => res.json())
            .then((result) => {
                // console.log("====campaign-graph_matrix=======", result)
                let source_list = result['graph_data'];

                let data_list = [];

                let facebook_list = [];
                let insta_list = []
                let twitter_list = []
                let linkedin_list = []
                let google_list = []
                let date_list = []


                source_list.map((info, index) => {
                    try {

                        if (!date_list.includes(source_list[index]['date_to_be_considered'])) {
                            date_list.push(source_list[index]['date_to_be_considered']);
                        }

                        let a = {
                            "date": source_list[index]['date_to_be_considered'],
                            "impression": source_list[index]['impressions'],
                            "click": source_list[index]['clicks'],
                            "spends": source_list[index]['spends_money'],
                            "is_app_install": source_list[index]['is_mobile_app_install'],
                            "conversion": source_list[index]['conversions_money']
                        }

                        if (source_list[index]['source'] == "Twitter") {
                            twitter_list.push(a);
                        } else if (source_list[index]['source'] == "Linkedin") {
                            linkedin_list.push(a);
                        } else if (source_list[index]['source'] == "Facebook") {
                            facebook_list.push(a);
                        } else if (source_list[index]['source'] == "Instagram") {
                            insta_list.push(a);
                        } else if (source_list[index]['source'] == "Google") {
                            google_list.push(a);
                        }
                    } catch {

                    }


                });

                // Prepar data for the graph
                // Iterating Date List
                date_list.map((info, index) => {

                    let facebook_val = 0;
                    let twitter_val = 0;
                    let likedin_val = 0;
                    let insta_val = 0;
                    let google_val = 0;

                    // Facebook
                    try {
                        facebook_val = facebook_list[index]['impression'];
                    } catch {
                        facebook_val = 0;
                    }

                    // Twitter
                    try {
                        twitter_val = twitter_list[index]['impression'];
                    } catch {
                        twitter_val = 0;
                    }

                    // Linkedin
                    try {
                        likedin_val = linkedin_list[index]['impression'];
                    } catch {
                        likedin_val = 0;
                    }

                    // Instagram

                    try {
                        insta_val = insta_list[index]['impression'];
                    } catch {
                        insta_val = 0;
                    }


                    // Google
                    try {
                        google_val = google_list[index]['impression'];
                    } catch {
                        google_val = 0;
                    }

                    let a = {
                        "year": info,
                        "google": google_val,
                        "facebook": facebook_val,
                        "instagram": insta_val,
                        "twitter": twitter_val,
                        "linkedin": likedin_val
                    };

                    data_list.push(a);
                });

                this.setState({ is_graph_data_loaded: true, social: data_list });

            },
                (error) => {
                    //   console.log("Error Result = ", error)
                }
            )
    }




    render() {
        if (!this.state.is_select_data_loaded || !this.state.is_graph_data_loaded) {
            return <div></div>
        }
        // console.log(" ---------- 2(select data) ------- ", this.state.select_source_data);

        let total_click = this.state.select_source_data['graph_list_data'][0]['clicks'] + this.state.select_source_data['graph_list_data'][1]['clicks'] + this.state.select_source_data['graph_list_data'][2]['clicks'] + this.state.select_source_data['graph_list_data'][3]['clicks'] + this.state.select_source_data['graph_list_data'][4]['clicks'];
        let total_impression = this.state.select_source_data['graph_list_data'][0]['impressions'] + this.state.select_source_data['graph_list_data'][1]['impressions'] + this.state.select_source_data['graph_list_data'][2]['impressions'] + this.state.select_source_data['graph_list_data'][3]['impressions'] + this.state.select_source_data['graph_list_data'][4]['impressions'];

        console.log("total_click ", total_click);
        console.log("total_impression ", total_impression);
        let final_ctr = ((total_click / total_impression) * 100).toFixed(2)



        let total_ctr = 0;
        let total_source_value = 0;

        return (
            <Container className="p-0" fluid>
                <div className="icz-row">
                    <Col className="icz-sectioncardwrapper" sm={12} lg={12}>
                        <div className="icz-sectioncard">
                            <div className="icz-cardheader">
                                <Col className="icz-cardtitle">
                                    Performance by Source
                                </Col>
                                <Col className="icz-cardfilter">
                                <Dropdown dropdown_options={this.state.dropdown_options} dropdown_placeholder="Impressions"  parentCallback={this.handleCallback}/>
                                </Col>
                            </div>
                            <div className="icz-dchartsection  icz-leadchartsection">
                                <Col className="" sm={12} lg={9}>
                                <MultiLine_LineChart chart_id="social" chart_class="icz-sectionchart" graph-data={this.state.social} metric_1={"Google"} metric_2={"Facebook"} metric_3={"Instagram"} metric_4={"Twitter"} metric_5={"LinkedIn"}  />
                                </Col>
                                <Col className="icz-chartdetailssection" sm={12} lg={3}>
                                    <div className="icz-detailsdatasection icz-detailscroll w-100">

                                        <div className="icz-detailsdatatab d-flex justify-content-between align-items-end">

                                            <div className="d-flex align-items-center">

                                                <div className="icz-iconwrapper">

                                                    <IconGoogle className="icz-icon" />

                                                </div>

                                                <div className="icz-titlewrapper">
                                                    <div className="icz-title">Google</div>
                                                </div>


                                            </div>


                                            <div className="icz-titlewrapper">

                                                <div className="icz-title">
                                                    <NumberFormatter Number={this.state.select_source_data['graph_list_data'][1]['impressions']} IsNumber={true} />
                                                </div>
                                                <div className="icz-subtitle">
                                                    CTR: {((this.state.select_source_data['graph_list_data'][1]['clicks'] / this.state.select_source_data['graph_list_data'][1]['impressions']) * 100).toFixed(2)}%
                                                </div>



                                            </div>



                                        </div>


                                        <div className="icz-detailsdatatab d-flex justify-content-between align-items-end">
                                            <div className="d-flex align-items-center">
                                                <div className="icz-iconwrapper">
                                                    <IconInstagram className="icz-icon" />

                                                </div>
                                                <div className="icz-titlewrapper">
                                                    <div className="icz-title">
                                                        Instagram
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="icz-titlewrapper">
                                                <div className="icz-title">
                                                    <NumberFormatter Number={this.state.select_source_data['graph_list_data'][2]['impressions']} IsNumber={true} />
                                                </div>
                                                <div className="icz-subtitle">
                                                    CTR: {((this.state.select_source_data['graph_list_data'][2]['clicks'] / this.state.select_source_data['graph_list_data'][2]['impressions']) * 100).toFixed(2)}%
                                                </div>
                                            </div>
                                        </div>


                                        <div className="icz-detailsdatatab d-flex justify-content-between align-items-end">
                                            <div className="d-flex align-items-center">
                                                <div className="icz-iconwrapper">
                                                    <IconFacebook className="icz-icon" />
                                                </div>
                                                <div className="icz-titlewrapper">
                                                    <div className="icz-title">Facebook</div>
                                                </div>
                                            </div>
                                            <div className="icz-titlewrapper">
                                                <div className="icz-title">
                                                    <NumberFormatter Number={this.state.select_source_data['graph_list_data'][0]['impressions']} IsNumber={true} />

                                                </div>
                                                <div className="icz-subtitle">
                                                    CTR: {((this.state.select_source_data['graph_list_data'][0]['clicks'] / this.state.select_source_data['graph_list_data'][0]['impressions']) * 100).toFixed(2)}%
                                                </div>
                                            </div>
                                        </div>

                                        <div className="icz-detailsdatatab d-flex justify-content-between align-items-end icz-overviewdetailscroll">
                                            <div className="d-flex align-items-center">
                                                <div className="icz-iconwrapper">
                                                    <IconTwitter className="icz-icon" />
                                                </div>
                                                <div className="icz-titlewrapper">
                                                    <div className="icz-title">Twitter</div>
                                                </div>
                                            </div>
                                            <div className="icz-titlewrapper">
                                                <div className="icz-title">
                                                    <NumberFormatter Number={this.state.select_source_data['graph_list_data'][4]['impressions']} IsNumber={true} />

                                                </div>
                                                <div className="icz-subtitle">
                                                    CTR: {((this.state.select_source_data['graph_list_data'][4]['clicks'] / this.state.select_source_data['graph_list_data'][4]['impressions']) * 100).toFixed(2)}%
                                                </div>
                                            </div>
                                        </div>



                                        <div className="icz-detailsdatatab d-flex justify-content-between align-items-end">
                                            <div className="d-flex align-items-center">
                                                <div className="icz-iconwrapper">
                                                    <IconGoogle className="icz-icon" />
                                                </div>
                                                <div className="icz-titlewrapper">
                                                    <div className="icz-title">Linkedin</div>
                                                </div>
                                            </div>
                                            <div className="icz-titlewrapper">
                                                <div className="icz-title">
                                                    <NumberFormatter Number={this.state.select_source_data['graph_list_data'][3]['impressions']} IsNumber={true} />

                                                </div>
                                                <div className="icz-subtitle">
                                                    CTR: {((this.state.select_source_data['graph_list_data'][3]['clicks'] / this.state.select_source_data['graph_list_data'][4]['impressions']) * 100).toFixed(2)}%
                                                </div>
                                            </div>
                                        </div>
                                        <div className="icz-detailsdatatab d-flex justify-content-between align-items-end icz-overviewdetailscroll">
                                        <div className="d-flex align-items-center">
                                            <div className="icz-iconwrapper">
                                                <IconTwitter className="icz-icon" />
                                            </div>
                                            <div className="icz-titlewrapper">
                                                <div className="icz-title">
                                                    Linkedin
                                                </div>
                                            </div>
                                        </div>
                                        <div className="icz-titlewrapper">
                                            <div className="icz-title">
                                                <NumberFormatter Number={115465} IsNumber={true} />
                                            </div>
                                            <div className="icz-subtitle">
                                                CTR: 0.3%
                                            </div>
                                        </div>
                                    </div>

                                        <div className="icz-detailsdatatab d-flex justify-content-between align-items-end icz-overviewdetailscroll">
                                            <div className="d-flex align-items-center">
                                                <div className="icz-iconwrapper">
                                                    <IconImpressions className="icz-icon" />
                                                </div>
                                                <div className="icz-titlewrapper">
                                                    <div className="icz-title">
                                                        Total
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="icz-titlewrapper">
                                                <div className="icz-title icz-highlight">
                                                    <NumberFormatter Number={this.state.select_source_data['graph_list_data'][0]['impressions'] + this.state.select_source_data['graph_list_data'][1]['impressions'] + this.state.select_source_data['graph_list_data'][2]['impressions'] + this.state.select_source_data['graph_list_data'][3]['impressions'] + this.state.select_source_data['graph_list_data'][4]['impressions']} IsNumber={true} />
                                                </div>
                                                <div className="icz-subtitle">CTR:{final_ctr}%</div>
                                            </div>
                                        </div>


                                    </div>
                                </Col>
                            </div>
                        </div>
                    </Col>
                </div>
                <div className="icz-row">
                    <div className="icz-sectioncardwrapper w-100">
                        <div className="icz-sectioncard">
                            <div className="icz-cardheader">
                                <Col className="icz-cardtitle">
                                    Summary
                                </Col>
                            </div>
                            <div className="">
                                {/* <OverviewTable /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        )
    }
}

