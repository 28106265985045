import React, { useState } from 'react';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import moment from "moment";

import { addDays, format } from 'date-fns';

import { DateRangePicker, defaultStaticRanges, createStaticRanges } from 'react-date-range';

import IconCalendar from '../../../../assets/images/icons/calendarIcon';

import './datepicker.scss';

function DatePicker(props) {

  // const [startDate, setStartDate] = useState(CURRENT_DATE_RANGE_FROM_DATE);
  // const [endDate, setEndDate] = useState(CURRENT_DATE_RANGE_TO_DATE);

  

  const [show, setShow] = useState(false);
  const [open, setopen] = useState(props.isDatePickerOpen);

  function formatDateDisplay(date, defaultText) {
    if (!date) return defaultText;
    return format(date, "yyyy-MM-dd");
  }

  const [state, setState] = useState({
    selection: {
      startDate: new Date(props.startDate),
      endDate: new Date(props.endDate),
      key: 'selection'
    },
    compare: {
      // startDate: addDays(new Date(), -1),
      // endDate: addDays(new Date(), -1),
      startDate: new Date(props.lastStartDate),
      endDate: new Date(props.lastEndDate),
      key: 'compare'
    }
  });


  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection"
  });

  function onDatesChange() {
    const currentDateRangeFrom = formatDateDisplay(state.selection.startDate);
    const currentDateRangeTo = formatDateDisplay(state.selection.endDate);
    const previousDateRangeFrom = formatDateDisplay(state.compare.startDate);
    const previousDateRangeTo = formatDateDisplay(state.compare.endDate);
    props.parentCallback([currentDateRangeFrom, currentDateRangeTo, previousDateRangeFrom, previousDateRangeTo]);
   
  }

  const onClickClear = () => {
    setSelectedDateRange({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection"
    });
    setShow(false);
  };

  const staticRanges = createStaticRanges([
    // ...defaultStaticRanges,
    {
      label: "Today",
      range: () => ({
        startDate: moment().startOf("day").toDate(),
        endDate: moment().endOf("day").toDate(),
      }),
    },
    {
      label: "Yesterday",
      range: () => ({
        startDate: moment().subtract(1, "days").startOf("day").toDate(),
        endDate: moment().subtract(1, "days").endOf("day").toDate()
      }),
    },
    {
      label: "This Week",
      range: () => ({
        startDate: moment().startOf("week").toDate(),
        endDate: moment().endOf("week").toDate()
      })
    },
    {
      label: "This Month",
      range: () => ({
        startDate: moment().startOf("month").toDate(),
        endDate: moment().endOf("day").toDate()
      })
    },
    {
      label: "Last Week",
      range: () => ({
        startDate: moment().subtract(1, "weeks").startOf("week").toDate(),
        endDate: moment().subtract(1, "weeks").endOf("week").toDate()
      })
    },
    {
      label: "Last 7 Days",
      range: () => ({
        startDate: moment().subtract(7, "days").startOf("day").toDate(),
        endDate: moment().subtract(1, "days").endOf("day").toDate()
      })
    },
    {
      label: "Last 28 Days",
      range: () => ({
        startDate: moment().subtract(28, "days").startOf("day").toDate(),
        endDate: moment().subtract(1, "days").endOf("day").toDate()
      })
    },
    {
      label: "Last 30 Days",
      range: () => ({
        startDate: moment().subtract(30, "days").startOf("day").toDate(),
        endDate: moment().subtract(1, "days").endOf("day").toDate()
      })
    },
    {
      label: "Last 90 Days",
      range: () => ({
        startDate: moment().subtract(90, "days").startOf("day").toDate(),
        endDate: moment().subtract(1, "days").endOf("day").toDate()
      })
    },
    {
      label: "Last 12 Months",
      range: () => ({
        startDate: moment().subtract(12, "months").startOf("month").toDate(),
        endDate: moment().subtract(1, "months").endOf("month").toDate()
      })
    },
    {
      label: "Last Calendar Year",
      range: () => ({
        startDate: moment().subtract(1, "years").startOf("year").toDate(),
        endDate: moment().subtract(1, "years").endOf("year").toDate()
      })
    },
    {
      label: "This Year (Jan - Today)",
      range: () => ({
        startDate: moment().startOf("year").toDate(),
        endDate: moment().endOf("day").toDate()
      })
    }
  ]);

  return (
    <div className="d-flex icz-m-lr-10">
      <div onClick={() => props.setOpen(!props.open)} className="icz-datepickersection">
        <div className="icz-datepickerwrapper">
          <div className="icz-date icz-fromdate">
            {formatDateDisplay(state.selection.startDate)}
          </div>
          <div className="text-secondary-grey">to</div>
          <div className="icz-date icz-todate">
            {state.selection.endDate == null ? formatDateDisplay(state.selection.startDate) : formatDateDisplay(state.selection.endDate)}
          </div>
          <IconCalendar className="icz-datepickericon" />
        </div>
      </div>
      {props.open && (
        <div className='icz-datepickerContainer'>
          <DateRangePicker
            className="icz-daterangepicker"
            editableDateInputs={true}
            onChange={item => setState({ ...state, ...item })}
            direction={'vertical'}
            scroll={{ enabled: true }}
            ranges={[state.selection, state.compare]}
            color="#11a1fd"
            rangeColors={["#11a1fd", "#FF9931"]}
            staticRanges={staticRanges}
          />
          <div className='d-flex justify-content-end'>
            <div className="icz-btnwrapper">
              <button className="icz-themebtn" onClick={() => onDatesChange()}>Apply</button>
            </div>
            <div className="icz-btnwrapper">
              <button onClick={() => props.setOpen(!props.open)} className="icz-deletebtn">Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default DatePicker;
