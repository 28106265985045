import React, { useState, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import DatePicker from '../../shared/components/datepicker/datepicker';
import { CampaignDropdown } from '../../shared/components/dropdown/campaigndropdown';


import IconCalendar from '../../../assets/images/icons/calendarIcon';

import './ri-pageheader.scss';
import Image from 'react-bootstrap/esm/Image';

import StickyLogo from '../../../assets/images/icons/icon.png'
import IconPdf from '../../../assets/images/icons/pdfIcon';
import RiCustomerFilter from './ri-customerfilter';

import Wrapper from '../../helpers/wrapper';


const popover = (
    <Popover id="popover-basic">
        <DatePicker />
    </Popover>
);


const RICustomerHeader = (props) => {

    const [showDatePicker, setshowDatePicker] = useState(false);

    const [scroll, setScroll] = useState(false);

    const [size, setSize] = useState({
        x: window.innerWidth,
        y: window.innerHeight
    });

    const updateSize = () =>
        setSize({
            x: window.innerWidth,
            y: window.innerHeight
        });



    useEffect(() => (window.onresize = updateSize), []);

    useEffect(() => {
        window.addEventListener("scroll", () => {

            setScroll(window.scrollY > 25);
        });

    }, []);

    return (
        <Wrapper>
            {size.x > 500 || scroll ?
                <Container className={scroll ? "icz-section-padding icz-pageheaderwrapper sticky" : "icz-section-padding icz-pageheaderwrapper"} fluid>
                    <div className="icz-pageheadercontainer">
                        <div className="icz-stickylogowrapper">
                            <Image src={StickyLogo} className="icz-stickylogo" />
                        </div>
                        <Col lg={12} className="p-0 icz-headerdetails d-flex justify-content-between">
                            <Col lg={4} md={12} xs={12} className="p-0">
                                <h2 className="icz-ripagetitle">{props.pageTitle} <span className='icz-ripagesubtitle'>{props.pageSubTitle}</span></h2>
                            </Col>

                            <Col lg={8} md={12} xs={12} className="p-0 icz-pagefiltersection">

                                <CampaignDropdown />

                                <OverlayTrigger rootClose trigger='click' placement="bottom" overlay={popover}>
                                    <div onClick={() => setshowDatePicker(!showDatePicker)} className="icz-datepickersection">
                                        <div className="icz-datepickerwrapper">
                                            <div className="icz-date icz-fromdate">
                                                01 Jan 2021
                                            </div>
                                            <div className="text-secondary-grey">to</div>
                                            <div className="icz-date icz-todate">
                                                15 Feb 2021
                                            </div>
                                            <IconCalendar className="icz-datepickericon" />
                                        </div>
                                    </div>
                                </OverlayTrigger>

                              <RiCustomerFilter/>

                                <div className="icz-btnwrapper">
                                    <button className="icz-themebtn">
                                        <IconPdf className="icz-ribtnicon" /> </button>
                                </div>
                            </Col>
                        </Col>
                    </div>
                </Container>
                : ''
            }
        </Wrapper>
    )
}

export default RICustomerHeader;