import IconSmiley from '../../../../../assets/images/icons/smileyIcon';
import IconComments from '../../../../../assets/images/icons/commentsIcon';
import IconShare from '../../../../../assets/images/icons/shareIcon';
import IconClicks from '../../../../../assets/images/icons/clicksIcon';
import IconReach from "../../../../../assets/images/icons/reachIcon";
import IconEngagement from '../../../../../assets/images/icons/engagementIcon';

import VideoImage1 from '../../../../../assets/images/v1.jpg'
import VideoImage2 from '../../../../../assets/images/v2.jpg'
import VideoImage3 from '../../../../../assets/images/v3.jpg'
import VideoImage4 from '../../../../../assets/images/v4.jpg'


export const products = [
  {
    id: "1",
    name: "Post 1",
    image: VideoImage1,
    time: "10:00 AM | 01 Jan 2021",
    minutesicon: <IconSmiley className="icz-icon"/>,
    minutesvalue: "100K",
    minutes: "Minutes viewed",
    viewsicon: <IconComments className="icz-icon"/>,
    views: "Views",
    viewsvalue: "120K",
    likesicon: <IconReach className="icz-icon"/>,
    likes: "Likes",
    likesvalue: "50K",
    dislikesicon: <IconShare className="icz-icon"/>,
    dislikes: "Dislikes",
    dislikesvalue: "80K",
    rateicon: <IconEngagement className="icz-icon"/>,
    rate: "View Rate",
    viewrate: "110",
    completionicon: <IconClicks className="icz-icon"/>,
    completion: "Completion rate ",
    completionvalue: "200",
    link: "View Post"
  },
  {
    id: "2",
    name: "Post 2",
    image: VideoImage2,
    time: "10:00 AM | 01 Jan 2021",
    minutesicon: <IconSmiley className="icz-icon"/>,
    minutesvalue: "100K",
    minutes: "Minutes viewed",
    viewsicon: <IconComments className="icz-icon"/>,
    views: "Views",
    viewsvalue: "120K",
    likesicon: <IconReach className="icz-icon"/>,
    likes: "Likes",
    likesvalue: "50K",
    dislikesicon: <IconShare className="icz-icon"/>,
    dislikes: "Dislikes",
    dislikesvalue: "80K",
    rateicon: <IconEngagement className="icz-icon"/>,
    rate: "View Rate",
    viewrate: "110",
    completionicon: <IconClicks className="icz-icon"/>,
    completion: "Completion rate ",
    completionvalue: "200",
    link: "View Post"
  },
  {
    id: "3",
    name: "Post 3",
    image: VideoImage3,
    time: "10:00 AM | 01 Jan 2021",
    minutesicon: <IconSmiley className="icz-icon"/>,
    minutesvalue: "100K",
    minutes: "Minutes viewed",
    viewsicon: <IconComments className="icz-icon"/>,
    views: "Views",
    viewsvalue: "120K",
    likesicon: <IconReach className="icz-icon"/>,
    likes: "Likes",
    likesvalue: "50K",
    dislikesicon: <IconShare className="icz-icon"/>,
    dislikes: "Dislikes",
    dislikesvalue: "80K",
    rateicon: <IconEngagement className="icz-icon"/>,
    rate: "View Rate",
    viewrate: "110",
    completionicon: <IconClicks className="icz-icon"/>,
    completion: "Completion rate ",
    completionvalue: "200",
    link: "View Post"
  },
  {
    id: "4",
    name: "Post 4",
    image: VideoImage4,
    time: "10:00 AM | 01 Jan 2021",
    minutesicon: <IconSmiley className="icz-icon"/>,
    minutesvalue: "100K",
    minutes: "Minutes viewed",
    viewsicon: <IconComments className="icz-icon"/>,
    views: "Views",
    viewsvalue: "120K",
    likesicon: <IconReach className="icz-icon"/>,
    likes: "Likes",
    likesvalue: "50K",
    dislikesicon: <IconShare className="icz-icon"/>,
    dislikes: "Dislikes",
    dislikesvalue: "80K",
    rateicon: <IconEngagement className="icz-icon"/>,
    rate: "View Rate",
    viewrate: "110",
    completionicon: <IconClicks className="icz-icon"/>,
    completion: "Completion rate ",
    completionvalue: "200",
    link: "View Post"
  }
];
