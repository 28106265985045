const IconEngagement = (props) => {
    return (
        <svg className={props.className} width="54" height="54" viewBox="0 0 54 54" fill="none" stroke="#4d738b" xmlns="http://www.w3.org/2000/svg">
            <g id="Group_507" data-name="Group 507" transform="translate(-189 -499)">
                <g id="Group_451" data-name="Group 451" transform="translate(-710.2 -243.417)">
                    <g id="Icon_feather-user" data-name="Icon feather-user" transform="translate(903.2 747.917)">
                        <path id="Path_260" data-name="Path 260" d="M20.819,28.057V26.2a3.7,3.7,0,0,0-3.7-3.7H9.7A3.7,3.7,0,0,0,6,26.2v1.852" transform="translate(0 -6.886)" fill="none" stroke="#486f88" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        <path id="Path_261" data-name="Path 261" d="M19.409,8.2a3.7,3.7,0,1,1-3.7-3.7A3.7,3.7,0,0,1,19.409,8.2Z" transform="translate(-2.295)" fill="none" stroke="#486f88" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    </g>
                    <g id="Icon_feather-user-2" data-name="Icon feather-user" transform="translate(923 765.917)">
                        <path id="Path_260-2" data-name="Path 260" d="M20.819,28.057V26.2a3.7,3.7,0,0,0-3.7-3.7H9.7A3.7,3.7,0,0,0,6,26.2v1.852" transform="translate(0 -6.886)" fill="none" stroke="#486f88" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        <path id="Path_261-2" data-name="Path 261" d="M19.409,8.2a3.7,3.7,0,1,1-3.7-3.7A3.7,3.7,0,0,1,19.409,8.2Z" transform="translate(-2.295)" fill="none" stroke="#486f88" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    </g>
                    <path id="Path_262" data-name="Path 262" d="M3214.917-884.25h5.917a5.113,5.113,0,0,1,3.823,1.563,4.727,4.727,0,0,1,.76,3.354v4.917" transform="translate(-2286.917 1640.086)" fill="none" stroke="#486f88" strokeLinecap="round" strokeWidth="1.5" />
                    <path id="Path_263" data-name="Path 263" d="M3225.425-874.418h-5.917a5.113,5.113,0,0,1-3.823-1.562,4.727,4.727,0,0,1-.76-3.354v-4.917" transform="translate(-2302.406 1659.086)" fill="none" stroke="#486f88" strokeLinecap="round" strokeWidth="1.5" />
                </g>
            </g>
        </svg>
    )
}

export default IconEngagement;