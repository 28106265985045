import React, { Component } from "react";

import { Link } from "react-router-dom";
import Wrapper from "../../../helpers/wrapper";
import Col from "react-bootstrap/esm/Col";
import Progressbar from "../../../shared/components/progressbar/progressbar";
import { Carousel } from "../../../shared/components/carousel/carousel";
import { ChartCard } from "../../../shared/components/cards/chartcard/chartcard";
import { SummaryCard } from "../../../shared/components/cards/risummarycard/summarycard";
import IconWebsite from "../../../../assets/images/icons/websiteIcon";
import IconDownload from "../../../../assets/images/icons/downloadIcon";
import Linegraph_sparkline from "../../../shared/components/charts/Linegraph_sparkline";
import RiBubbleChart from "../../../shared/components/charts/RiBubbleChart";
import { Breadcrumb, Container, Image } from "react-bootstrap";
import IconPdf from "../../../../assets/images/icons/pdfIcon";
import DatePicker from "../../../shared/components/datepicker/datepicker";
import RiMapFilter from "../ri-mapfilter/RiMapFilter";
import { ProgressBar } from 'react-bootstrap';
import IndiaMap from "../../../shared/components/map/indiamap/indiamap";
import { CSVLink } from "react-csv";
import html2canvas from 'html2canvas';
import ChartExport from "../../../shared/components/export/ChartExport";
import UKMap from  "../../../shared/components/map/ukmap/ukmap";

//import { CampaignDropdown } from '../../../shared/components/dropdown/campaigndropdown';
// import StickyLogo from '../../../assets/images/icons/icon.png'

import StickyLogo from "../../../../assets/images/icons/icon.png";

import { API_URL } from "../../../helpers/constant";

import { Loader } from "../../../shared/components/loader/loader";

import "../../../layout/pageheader/pageheader.scss";
import '../ri.scss';
import { ValueCard } from "../../../shared/components/cards/valueCard/valueCard";


// GLOBAL FILTER
// let fromDate = '2021-04-01';
// let toDate = '2021-04-01';
// let campaign_name = 'B1G1';
// let store_location = 'Metro';
// let store_type = 'New';
// let map_zone = 'East';
// let map_city = 'Jamshedpur';
// let map_store = 8;

export default class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      dropdown_options:
        '[{ "value": "markdown", "label": "Markdown" }, { "value": "nsv", "label": "NSV" }, { "value": "units-sold", "label": "Units Sold" }, { "value": "mix", "label": "Mix%" }, { "value": "nm", "label": "NM%" }, { "value": "asp", "label": "ASP" }, { "value": "sell-thru", "label": "Sell Thru" }]',
      linesparkgraph: [{"date":"2022-01-24","value":5.1999211356466875},{"date":"2022-01-25","value":5.448333333333333},{"date":"2022-01-26","value":5.98400377615776},{"date":"2022-01-27","value":5.239102398761929},{"date":"2022-01-28","value":5.380620723362659},{"date":"2022-01-29","value":5.7249207144049405},{"date":"2022-01-30","value":5.902069716775599}],
    
      selltrend: '[{ "month": "Jan", "value1": 12, "value2": 16, "value3": 13, "value4": 20, "value5": 15, "value6": 10 }, { "month": "Feb", "value1": 20, "value2": 22, "value3": 15, "value4": 24, "value5": 18, "value6": 15 }, { "month": "Mar", "value1": 25, "value2": 26, "value3": 18, "value4": 26, "value5": 22, "value6": 20 }, { "month": "Apr", "value1": 30, "value2": 32, "value3": 22, "value4": 30, "value5": 28, "value6": 26 }, { "month": "May", "value1": 35, "value2": 39, "value3": 25, "value4": 36, "value5": 33, "value6": 30 }, { "month": "June", "value1": 43, "value2": 46, "value3": 33, "value4": 41, "value5": 39, "value6": 37 }, { "month": "Jul", "value1": 47, "value2": 50, "value3": 41, "value4": 46, "value5": 42, "value6": 44 }, { "month": "Aug", "value1": 54, "value2": 56, "value3": 45, "value4": 50, "value5": 48, "value6": 51 }, { "month": "Sep", "value1": 57, "value2": 59, "value3": 51, "value4": 53, "value5": 52, "value6": 55 }, { "month": "Oct", "value1": 61, "value2": 64, "value3": 56, "value4": 60, "value5": 55, "value6": 59 }, { "month": "Nov", "value1": 70, "value2": 66, "value3": 58, "value4": 65, "value5": 61, "value6": 64 }, { "month": "Dec", "value1": 101, "value2": 72, "value3": 64, "value4": 70, "value5": 68, "value6": 72 }]',
      scroll: "",
      promodata: '[{"y":10,"x":14,"value":59,"y2":2,"x2":1,"value2":34,"y3":2,"x3":6,"value3":49},  {"y":5,"x":3,"value":50,"y2":15,"x2":8,"value2":12,"y3":12,"x3":10,"value3":34}, {"y":10,"x":8,"value":19,"y2":4,"x2":6,"value2":35,"y3":11,"x3":9,"value3":40}, {"y":6,"x":5,"value":65,"y2":5,"x2":6,"value2":168,"y3":8,"x3":4,"value3":33}, {"y":15,"x":4,"value":92,"y2":10,"x2":8,"value2":102,"y3":2,"x3":5,"value3":25}, {"y":13,"x":1,"value":8,"y2":2,"x2":2,"value2":41,"y3":4,"x3":6,"value3":28}, {"y":1,"x":6,"value":35,"y2":3,"x2":6,"value2":16,"y3":5,"x3":9,"value3":38}]',

      is_sales_metrices_loaded: false,
      is_customer_metrices_loaded: false,
      is_marketing_metrices_loaded: false,
      is_bubble_chart_loaded: false,
      is_member_metrics_loaded: false,
      is_sales_graph_loaded: false,
      is_customer_graph_loaded: false,
      is_marketing_graph_loaded: false,
      is_member_graph_loaded: false,
      is_getfilter_loaded: false,
      is_India_map_loaded: false,
      
      is_scroll: false,

      static_start_date: "",
      first_start_date: "2022-01-24",
      first_end_date: "2022-01-30",
      second_start_date: "2022-01-17",
      second_end_date: "2022-01-23",
      isDatePickerOpen: false,

      // MAP
      map_zone: '',
      map_state: '',
      map_city: '',
      map_store_code: "",
      map_store_name: '',


      // MAP FILTER
      map_store_category: '',
      map_store_type: '',
      map_store_size: '',

      mapBreadcrumbList: [],
      mapBreadcrumbsNorthPoint: '',
      mapBreadcrumbsSouthPoint: '',
      mapBreadcrumbsCenterPoint: '',

      data: "",
      MapMarker: "",
      North_point: [72.6416015625, 37.71859032558816],
      South_point: [93.1640625, 5.7908968128719565],
      map_level: 0,
      center_point: [0, 0],

      custom_current_basket_size: 5.67,
      custom_last_basket_size: 5.35,
      rangetype:"nsv_ach",
      //map filter
      map_filter_category_list:[
        { name: 'Metro',checked: false},
        { name: 'Non-Metro',checked: false},
      ],
      map_filter_storetype_list:[
        { name: 'New',checked: false},
        { name: 'LTL',checked: false},
        { name: 'Mature',checked: false},
      ],
      map_filter_storesize_list:[],
      export_Header: '',//[{ label: "Name", key: "name" }, { label: "Username", key: "username" }, { label: "Email", key: "email" }, { label: "Phone", key: "phone" }, { label: "Website", key: "website" }],
      export_data: [
        {
            "zone_id": 1,
            "center": "77.16659704, 31.10002545",
            "north_corner": "73.729184, 36.895584",
            "south_corner": "83.045590, 23.924173",
            "zone": "NORTH",
            "nsv": 30571451.0,
            "target_nsv": 43594040.14,
            "nsv_achieved": 70.13,
            "ats": 2977.93,
            "score": 39.0,
            "nsv_mix": 12.47
        },
        {
            "zone_id": 4,
            "center": "79.0193, 18.1124",
            "north_corner": "77.5634765625, 18.437924653474408",
            "south_corner": "79.82048034667969, 11.88146190297441",
            "zone": "APTS",
            "nsv": 35146498.0,
            "target_nsv": 65002442.56,
            "nsv_achieved": 54.07,
            "ats": 3334.27,
            "score": 39.0,
            "nsv_mix": 14.33
        },
        {
            "zone_id": 5,
            "center": "86.41998572, 23.80039349",
            "north_corner": "80.57373046875, 30.486550842588485",
            "south_corner": "92.94433593749999, 22.14670778001263",
            "zone": "EAST",
            "nsv": 42378073.0,
            "target_nsv": 72420065.4,
            "nsv_achieved": 58.52,
            "ats": 2849.52,
            "score": 37.0,
            "nsv_mix": 17.28
        },
        {
            "zone_id": 3,
            "center": "77.5946, 12.9716",
            "north_corner": "73.71826171874999, 15.728813770533966",
            "south_corner": "77.65583038330078, 11.953769241237044",
            "zone": "SOUTH",
            "nsv": 42053827.0,
            "target_nsv": 78553853.26,
            "nsv_achieved": 53.54,
            "ats": 3194.37,
            "score": 34.0,
            "nsv_mix": 17.15
        },
        {
            "zone_id": 2,
            "center": "72.8777, 19.0760",
            "north_corner": "68.66180419921875, 23.792884472869158",
            "south_corner": "80.79757690429686, 19.468534402813017",
            "zone": "WEST",
            "nsv": 95052319.0,
            "target_nsv": 138137016.89,
            "nsv_achieved": 68.81,
            "ats": 2923.7,
            "score": 38.0,
            "nsv_mix": 38.76
        }
    ],
      //'',
      //[{ firstName: "Warren", lastName: "Morrow", email: "sokyt@mailinator.com", age: "36" }, { firstName: "Gwendolyn", lastName: "Galloway", email: "weciz@mailinator.com", age: "76" }, { firstName: "Astra", lastName: "Wyatt", email: "quvyn@mailinator.com", age: "57" }, { firstName: "Jasmine", lastName: "Wong", email: "toxazoc@mailinator.com", age: "42" }, { firstName: "Brooke", lastName: "Mcconnell", email: "vyry@mailinator.com", age: "56" }, { firstName: "Christen", lastName: "Haney", email: "pagevolal@mailinator.com", age: "23" }, { firstName: "Tate", lastName: "Vega", email: "dycubo@mailinator.com", age: "87" }, { firstName: "Amber", lastName: "Brady", email: "vyconixy@mailinator.com", age: "78" }, { firstName: "Philip", lastName: "Whitfield", email: "velyfi@mailinator.com", age: "22" }, { firstName: "Kitra", lastName: "Hammond", email: "fiwiloqu@mailinator.com", age: "35" }, { firstName: "Charity", lastName: "Mathews", email: "fubigonero@mailinator.com", age: "63" } ],
      
    };
    this.csvLinkEl = React.createRef();
    this.printRef = React.createRef();
    
  }

  componentDidMount() {
    this.state.is_India_map_loaded = false;
    this.state.is_marketing_metrices_loaded = false;
    this.state.is_customer_metrices_loaded = false;
    this.state.is_member_metrics_loaded = false;
    this.state.is_sales_metrices_loaded = false;
    this.state.is_sales_graph_loaded = false;
    this.state.is_customer_graph_loaded = false;
    this.state.is_marketing_graph_loaded = false;
    this.state.is_member_graph_loaded = false;
    this.state.is_bubble_chart_loaded = false;
    
    var varCountry = this.getUrlCountryParameter('country');
    if (varCountry != "") {
      localStorage.setItem("country", varCountry);
    } else {
      varCountry = localStorage.getItem('country');
      if (varCountry == null || varCountry == "") {
        varCountry = "IN"
      }
    }
    this.state.country = varCountry;
    this.getMapdetails();
    this.getMarketingMetrices();
    this.getCustomerMetrices();
    this.getMemberMetrics();
    this.getSalesMetrices();
    this.getPromoBubbleChart();
    //this.getMemberlinesparkgraph();
    //this.getMarketinglinesparkgraph();
    this.forceUpdate();

    window.addEventListener("scroll", this.handleScroll);
  }

  isNull(object) {
    for (const [key, value] of Object.entries(object)) {
      if (typeof value === "object" && value !== null) {
        this.isNull(value);
      } else if (!value) {
        object[key] = 0;
      }
    }
    return object;
  }

 getUrlCountryParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if ((sParameterName[0]).toLowerCase() === sParam.toLowerCase()) {
            return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
    return "";
}


  //India map details
  getMapdetails() {
    let result = [];

    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    //map filter
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;
    let country = this.state.country != null ? (this.state.country).toUpperCase() : "IN";

    const url = API_URL + "/api/ri/zones/";

    fetch(url, {
      method: "POST",
      body: "start_date=" + start_date +
        "&end_date=" + end_date +
        "&city_type=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size  +
        "&country=" + country ,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => res.json())
      .then(
        (result1) => {
          let varIndiaMapData = result1.data;
          console.log("indiamap varIndiaMapData :- ",varIndiaMapData)
          //
          // var export_Header_keys = [];
          // if (varIndiaMapData.length > 0) {
          //   Object.keys(varIndiaMapData[0]).forEach(function (key) {
          //     if (export_Header_keys.indexOf(key) == -1) {
          //       var x = '{ label: "' + key + '", key: "' + key + '" }';
          //       export_Header_keys.push(x);
          //     }
          //   });
          // }
          // console.log("indiamap keys :- ",export_Header_keys);
          // get keys as array
          const csvBlobhref =  '';
          // if(varIndiaMapData[0].length!=0){
          // const keys = Object.keys(varIndiaMapData[0]);

          // const commaSeparatedString = [keys.join(","), varIndiaMapData.map(row => keys.map(key => row[key]).join(",")).join("\n")].join("\n")

          // const csvBlob = new Blob([commaSeparatedString]);
          // csvBlobhref=URL.createObjectURL(csvBlob);
          
        //}
        
          this.setState({
            MapMarker: varIndiaMapData,
           // export_href:csvBlobhref,
            is_India_map_loaded: true
          });
        },
        (error) => { }
      );
  }

  getIndiaMapCityDetails() {
    let result = [];

    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let map_zone = this.state.map_zone;
    //map filter
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;

    const url = API_URL + "/api/ri/cities/";

    fetch(url, {
      method: "POST",
      body: "start_date=" + start_date +
        "&end_date=" + end_date +
        "&map_zone=" + map_zone +
        "&city_type=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => res.json())
      .then(
        (result1) => {
          let varIndiaMapData = result1.data;
          this.setState({
            MapMarker: varIndiaMapData,
            is_India_map_loaded: true,
            map_level: 1
          });
        },
        (error) => { }
      );

  }

  getIndiaMapStoresDetails() {
    let result = [];

    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let map_zone = this.state.map_zone;
    let map_city = this.state.map_city;
    //map filter
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;

    const url = API_URL + "/api/ri/stores/";

    fetch(url, {
      method: "POST",
      body: "start_date=" + start_date +
        "&end_date=" + end_date +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&city_type=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => res.json())
      .then(
        (result1) => {
          let varIndiaMapData = result1.data;
          this.setState({
            MapMarker: varIndiaMapData,
            is_India_map_loaded: true,
            map_level: 2
          });
        },
        (error) => { }
      );

  }

  // SALES METRICES
  getSalesMetrices() {

    let result = [];

    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let previous_start_date = this.state.second_start_date;
    let previous_end_date = this.state.second_end_date;

    //map
    let map_zone = this.state.map_zone;
    let map_city = this.state.map_city;
    let storecode = this.state.map_store_code;
    let storename = this.state.map_store_name;
    let state = this.state.map_state;

    //map filter
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;

    const url = API_URL + "/api/ri/ri-summarysales/";

    fetch(url, {
      method: "POST",
      body: "start_date=" + start_date +
        "&end_date=" + end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&storecode=" + storecode +
        "&storename=" + storename +
        "&state=" + state +
        "&city_type=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size
      ,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          let result1 = result;

          let current_net_margin;
          let last_net_margin;
          let current_average_ticket_size;
          let last_average_ticket_size;
          let current_sell_through;
          let last_sell_through;
          let current_average_selling_price;
          let last_average_selling_price;
          let current_markdown;
          let last_markdown;
          let current_recovery;
          //let last_recovery;

          let current_target_net_margin;
          let last_target_net_margin;
          let current_target_ats;
          let last_target_ats;
          let current_target_sell_through;
          let last_target_sell_through;
          let current_target_asp;
          let last_target_asp;
          let current_target_markdown;
          let last_target_markdown;
          let current_target_recovery;
          let current_basket_size;
          let last_basket_size;
          let current_target_basket_size;
          let current_pspfo;
          //let last_pspfo;

          let current_target_nsv_achieved;
          let current_target_net_margin_achieved;
          let current_target_bills_achieved;
          let current_target_ats_achieved;
          let current_target_sell_through_achieved;
          let current_target_units_sold_achieved;
          let current_target_asp_achieved;
          let current_target_markdown_achieved;
          let current_target_rgm_achieved;
          let current_basket_size_achieved;

          let current_nsv = result1["current_ri_sales_list"][0]["nsv"];
          let last_nsv = result1["last_ri_sales_list"][0]["nsv"];

          //let current_net_salesvalue = result1['current_ri_sales_lists'][0]['nsv']

          let current_bills = result1["current_ri_sales_list"][0]["bills"];
          let last_bills = result1["last_ri_sales_list"][0]["bills"];

          let current_units_sold = result1["current_ri_sales_list"][0]["units_sold"];
          let last_units_sold = result1["last_ri_sales_list"][0]["units_sold"];

          let current_rgm = result1["current_ri_sales_list"][0]["rgm"];
          let last_rgm = result1["last_ri_sales_list"][0]["rgm"];

          let current_retailarea = result1["current_ri_sales_list"][0]["retailarea"];

          let last_retailarea = result1["last_ri_sales_list"][0]["retailarea"];

          let current_mbq = result1["current_ri_sales_list"][0]["mbq"];
          //let last_mbq = result1['last_ri_sales_list'][0]['mbq']

          let current_soh = result1["current_ri_sales_list"][0]["soh"];
          let last_soh = result1["current_ri_sales_list"][0]["soh"];

          let current_tax = result1["current_ri_sales_list"][0]["tax"];
          let last_tax = result1["last_ri_sales_list"][0]["tax"];

          let current_gsv = result1["current_ri_sales_list"][0]["gsv"];
          let last_gsv = result1["last_ri_sales_list"][0]["gsv"];

          let current_target_nsv = result1["current_ri_sales_list"][0]["target_nsv"];
          let last_target_nsv = result1["current_ri_sales_list"][0]["target_nsv"];

          let current_target_bills = result1["current_ri_sales_list"][0]["target_bills"];
          let last_target_bills = result1["current_ri_sales_list"][0]["target_bills"];

          let current_target_units_sold = result1["current_ri_sales_list"][0]["target_units_sold"];
          let last_target_units_sold = result1["current_ri_sales_list"][0]["target_units_sold"];

          let current_target_rgm = result1["current_ri_sales_list"][0]["target_rgm"];
          let last_target_rgm = result1["current_ri_sales_list"][0]["target_rgm"];

          let current_target_gsv = result1["current_ri_sales_list"][0]["target_gsv"];
          let last_target_gsv = result1["current_ri_sales_list"][0]["target_gsv"];

          let current_target_tax = result1["current_ri_sales_list"][0]["target_tax"];
          let last_target_tax = result1["current_ri_sales_list"][0]["target_tax"];

          // FOR SPARKLINE
          let sparkline_list = result1["ri_sales_metrices_sparkline_list"];
          let sales_nsv_spark_list = [];
          let sales_net_margin_spark_list = [];
          let sales_bills_spark_list = [];
          let sales_ats_spark_list = [];
          let sales_sell_through_spark_list = [];
          let sales_units_sold_spark_list = [];
          let sales_asp_spark_list = [];
          let sales_markdown_spark_list = [];
          let sales_rgm_spark_list = [];
          let sales_basket_size_list = [];
          //let sales_recovery_spark_list = [];

          sparkline_list.map((info, index) => {
            try {
              let a = {
                date: info["datetime"],
                value: parseInt((info["nsv"] * 1).toFixed(2)),
              };
              sales_nsv_spark_list.push(a);

              let b = {
                date: info["datetime"],
                value: (((info["rgm"] * 1) / (info["nsv"] * 1 - info["tax"] * 1)) * 100).toFixed(2),
              };
              sales_net_margin_spark_list.push(b);

              let c = {
                date: info["datetime"],
                value: info["bills"],
              };
              sales_bills_spark_list.push(c);

              let d = {
                date: info["datetime"],
                value: ((info["nsv"] * 1) / info["bills"]).toFixed(2),
              };
              sales_ats_spark_list.push(d);

              let e = {
                date: info["datetime"],
                value: ((info["units_sold"] / info["soh"]) * 100).toFixed(2),
              };
              sales_sell_through_spark_list.push(e);

              let f = {
                date: info["datetime"],
                value: info["units_sold"],
              };
              sales_units_sold_spark_list.push(f);

              let g = {
                date: info["datetime"],
                value: ((info["nsv"] * 1) / info["units_sold"]).toFixed(2),
              };
              sales_asp_spark_list.push(g);

              let h = {
                date: info["datetime"],
                value: (((info["gsv"] * 1 - info["nsv"] * 1) / (info["gsv"] * 1)) * 100).toFixed(2),
              };
              sales_markdown_spark_list.push(h);

              let i = {
                date: info["datetime"],
                value: info["rgm"] * 1,
              };
              sales_rgm_spark_list.push(i);

              // let j = {
              //     "date" : info['date'],
              //     "value":info[]

              // }
              // sales_recovery_spark_list.push(j);
              let k = {
                date: info["datetime"],
                value: info["units_sold"] / info["bills"],
              };
              sales_basket_size_list.push(k);
            } catch (e) { }
          });

          current_net_margin = ((current_rgm / (current_nsv - current_tax)) * 100).toFixed(2);

          last_net_margin = ((last_rgm / (last_nsv - last_tax)) * 100).toFixed(2);

          current_average_ticket_size = (current_nsv / current_bills).toFixed(2);


          last_average_ticket_size = parseFloat(last_nsv / last_bills).toFixed(2);

          current_sell_through = ((current_units_sold / current_soh) * 100).toFixed(2);
          last_sell_through = ((last_units_sold / last_soh) * 100).toFixed(2);

          current_average_selling_price = current_nsv / current_units_sold;
          last_average_selling_price = last_nsv / last_units_sold;

          current_markdown = (((current_gsv - current_nsv) / current_gsv) * 100).toFixed(2);
          last_markdown = (((last_gsv - last_nsv) / last_gsv) * 100).toFixed(2);

          current_recovery = ((current_nsv / last_nsv) * 100).toFixed(2);

          current_basket_size = current_units_sold / current_bills;
          last_basket_size = last_units_sold / last_bills;

          current_pspfo = (current_nsv / current_retailarea).toFixed(2);

          current_target_basket_size = current_target_units_sold / current_target_bills;

          current_target_net_margin = ((current_target_rgm / (current_target_nsv - current_target_tax)) * 100).toFixed(2);


          current_target_ats = (current_target_nsv / current_target_bills).toFixed(2);


          current_target_sell_through = ((current_target_units_sold / current_soh) * 100).toFixed(2);

          current_target_asp = current_target_nsv / current_target_units_sold;

          current_target_markdown = (((current_target_gsv - current_target_nsv) / current_target_gsv) * 100).toFixed(2);

          current_target_rgm_achieved = ((current_rgm / current_target_rgm) * 100).toFixed(2);

          current_target_nsv_achieved = ((current_nsv / current_target_nsv) * 100).toFixed(2);


          current_target_net_margin_achieved = ((current_net_margin / current_target_net_margin) * 100).toFixed(2);


          current_target_bills_achieved = ((current_bills / current_target_bills) * 100).toFixed(2);

          current_target_ats_achieved = ((current_average_ticket_size / current_target_ats) * 100).toFixed(2);

          current_target_sell_through_achieved = ((current_sell_through / current_target_sell_through) * 100).toFixed(2);

          current_target_units_sold_achieved = ((current_units_sold / current_target_units_sold) * 100).toFixed(2);

          current_target_asp_achieved = ((current_average_selling_price / current_target_asp) * 100).toFixed(2);

          current_target_markdown_achieved = ((current_markdown / current_target_markdown) * 100).toFixed(2);

          current_basket_size_achieved = ((current_basket_size / current_target_basket_size) * 100).toFixed(2);

          if (
            current_sell_through == "NaN" ||
            current_sell_through == "InfinityG"
          ) {
            current_sell_through = 0;
          }

          // if (current_target_nsv_achieved > 100) {
          //   current_target_nsv_achieved = 100;
          // }

          if (current_target_net_margin_achieved > 100) {
            current_target_net_margin_achieved = 100;
          }

          if (current_target_bills_achieved > 100) {
            current_target_bills_achieved = 100;
          }

          // if(current_target_ats_achieved>100){
          //     current_target_ats_achieved=100
          // }

          if (current_target_sell_through_achieved > 100) {
            current_target_sell_through_achieved = 100;
          }

          if (current_target_units_sold_achieved > 100) {
            current_target_units_sold_achieved = 100;
          }

          if (current_target_asp_achieved > 100) {
            current_target_asp_achieved = 100;
          }

          // if(current_target_markdown_achieved > 100){
          //     current_target_markdown_achieved =100
          // }

          // if(current_basket_size_achieved > 100){
          //     current_basket_size_achieved =100
          // }

          this.setState({

            current_nsv: current_nsv,
            last_nsv: last_nsv,
            current_gsv: current_gsv,
            last_gsv: last_gsv,
            // current_tax_sales:current_tax_sales,
            //last_tax_sales:last_tax_sales,
            current_rgm: current_rgm,
            last_rgm: last_rgm,
            // current_target_nsv_sales:current_target_nsv_sales,
            // last_target_nsv_sales:last_target_nsv_sales,
            // current_target_rgm_sales:current_target_rgm_sales,
            // last_target_rgm_sales:last_target_rgm_sales,
            // current_target_gsv_sales:current_target_gsv_sales,
            // last_target_gsv_sales:last_target_gsv_sales,
            //current_target_tax_sales:current_target_tax_sales,
            // last_target_tax_sales:last_target_tax_sales,

            //current_net_salesvalue:current_net_salesvalue,
            //last_net_salesvalue:last_net_salesvalue,
            current_bills: current_bills,
            last_bills: last_bills,
            current_units_sold: current_units_sold,
            last_units_sold: last_units_sold,
            //current_retail_gross_margin:current_retail_gross_margin,
            //last_retail_gross_margin:last_retail_gross_margin,
            current_soh: current_soh,
            last_soh: last_soh,
            current_tax: current_tax,
            last_tax: last_tax,
            // current_gross_sales_value:current_gross_sales_value,
            //last_gross_sales_value:last_gross_sales_value,
            current_net_margin: current_net_margin,
            last_net_margin: last_net_margin,
            current_average_ticket_size: current_average_ticket_size,
            last_average_ticket_size: last_average_ticket_size,
            current_sell_through: current_sell_through,
            last_sell_through: last_sell_through,
            current_average_selling_price: current_average_selling_price,
            last_average_selling_price: last_average_selling_price,
            current_markdown: current_markdown,
            last_markdown: last_markdown,
            current_recovery: current_recovery,
            //last_recovery :last_recovery
            current_mbq: current_mbq,
            current_basket_size: current_basket_size,
            last_basket_size: last_basket_size,
            current_pspfo: current_pspfo,
            //last_pspfo:last_pspfo,
            current_retailarea: current_retailarea,
            last_retailarea: last_retailarea,

            current_target_nsv: current_target_nsv,
            last_target_nsv: last_target_nsv,
            current_target_bills: current_target_bills,
            last_target_bills: last_target_bills,
            current_target_units_sold: current_target_units_sold,
            last_target_units_sold: last_target_units_sold,
            current_target_rgm: current_target_rgm,
            last_target_rgm: last_target_rgm,
            current_target_gsv: current_target_gsv,
            last_target_gsv: last_target_gsv,
            current_target_tax: current_target_tax,
            last_target_tax: last_target_tax,
            current_target_net_margin: current_target_net_margin,
            current_target_ats: current_target_ats,
            current_target_sell_through: current_target_sell_through,
            current_target_asp: current_target_asp,
            current_target_markdown: current_target_markdown,
            current_target_basket_size: current_target_basket_size,
            current_target_nsv_achieved: current_target_nsv_achieved,
            current_target_net_margin_achieved: current_target_net_margin_achieved,
            current_target_bills_achieved: current_target_bills_achieved,
            current_target_ats_achieved: current_target_ats_achieved,
            current_target_sell_through_achieved: current_target_sell_through_achieved,
            current_target_units_sold_achieved: current_target_units_sold_achieved,
            current_target_asp_achieved: current_target_asp_achieved,
            current_target_markdown_achieved: current_target_markdown_achieved,
            current_target_rgm_achieved: current_target_rgm_achieved,
            current_basket_size_achieved: current_basket_size_achieved,

            sales_nsv_spark_graph: sales_nsv_spark_list,
            sales_net_margin_spark_graph: sales_net_margin_spark_list,
            sales_bills_spark_graph: sales_bills_spark_list,
            sales_ats_spark_graph: sales_ats_spark_list,
            sales_sell_through_spark_graph: sales_sell_through_spark_list,
            sales_units_sold_spark_graph: sales_units_sold_spark_list,
            sales_asp_spark_graph: sales_asp_spark_list,
            sales_markdown_spark_graph: sales_markdown_spark_list,
            sales_rgm_spark_graph: sales_rgm_spark_list,
            sales_basket_size_graph: sales_basket_size_list,

            is_sales_metrices_loaded: true,
            is_sales_graph_loaded: true,

          });
        },
        (error) => { }
      );
  }


  // CUSTOMER METRICES
  getCustomerMetrices() {
    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let previous_start_date = this.state.second_start_date;
    let previous_end_date = this.state.second_end_date;

    //map
    let map_zone = this.state.map_zone;
    let map_city = this.state.map_city;
    let storecode = this.state.map_store_code;
    let storename = this.state.map_store_name;
    let state = this.state.map_state;

    //map filter
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;

    const url = API_URL + "/api/ri/ri-summarycustomer/";

    fetch(url, {
      method: "POST",
      body: "start_date=" + start_date +
        "&end_date=" + end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&storecode=" + storecode +
        "&storename=" + storename +
        "&state=" + state +
        "&city_type=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          let result1 = result;

          let current_conversion_rate;
          let last_conversion_rate;

          let current_repeating_rate;
          let last_repeating_rate;
          let current_customer_frequency;
          let last_customer_frequency;
          let current_target_customer_number;
          let current_target_returning_rate;

          let current_target_conversion_rate;
          let last_target_conversion_rate;
          let current_target_repeating_rate;

          let current_target_customer_frequency;
          //let last_target_customer_frquency;
          let current_basket_size;
          let last_basket_size;

          let current_footfalls_achieved;
          let current_conversion_rate_achieved;
          let current_customer_number_achieved;
          let current_repeating_rate_achieved;
          let current_customer_frequency_achieved;

          let current_footfalls = result1["current_ri_customer_list"][0]["footfall"];
          let last_footfalls = result1["last_ri_customer_list"][0]["footfall"];

          let current_bills = result1["current_ri_customer_list"][0]["unique_bills_final"];
          let last_bills = result1["last_ri_customer_list"][0]["unique_bills_final"];

          let current_customer_number = result1["current_ri_customer_list"][0]["unique_customer"];
          let last_customer_number = result1["last_ri_customer_list"][0]["unique_customer"];

          let current_target_footfalls = result1["current_ri_customer_list"][0]["target_footfall"];
          let last_target_footfalls = result1["last_ri_customer_list"][0]["target_footfall"];

          let current_target_bills = result1["current_ri_customer_list"][0]["target_bills"];
          let last_target_bills = result1["last_ri_customer_list"][0]["target_bills"];

          let current_units_sold = result1["current_ri_customer_list"][0]["units_sold"];
          let last_units_sold = result1["last_ri_customer_list"][0]["units_sold"];

          current_conversion_rate = parseFloat(((current_bills / current_footfalls) * 100).toFixed(2));
          last_conversion_rate = (last_bills / last_footfalls) * 100;

          current_basket_size = (current_units_sold / current_bills).toFixed(2);
          last_basket_size = (last_units_sold / last_bills).toFixed(2);

          current_repeating_rate = parseFloat((((current_bills - current_customer_number) / current_bills) * 100).toFixed(2));
          last_repeating_rate = parseFloat((((last_bills - last_customer_number) / last_bills) * 100).toFixed(2));

          current_customer_frequency = parseFloat((current_bills / current_customer_number).toFixed(2));

          if (
            current_customer_frequency == "NaN" ||
            current_customer_frequency == "InfinityG"
          ) {
            current_customer_frequency = 0;
          }

          last_customer_frequency = parseFloat(
            (last_bills / last_customer_number).toFixed(2)
          );
          if (
            last_customer_frequency == "NaN" ||
            last_customer_frequency == "InfinityG"
          ) {
            last_customer_frequency = 0;
          }

          current_target_customer_number = parseFloat(((80 * current_target_bills) / 100).toFixed(2));


          //current_target_repeating_rate = parseFloat(((20 * current_target_bills) / 100).toFixed(2));
          current_target_repeating_rate = 20;

          current_target_conversion_rate = ((current_target_bills / current_target_footfalls) * 100).toFixed(2);
          last_target_conversion_rate = (last_target_bills / last_target_footfalls) * 100;

          current_target_customer_frequency = 2;

          current_footfalls_achieved = ((current_footfalls / current_target_footfalls) * 100).toFixed(2);

          current_conversion_rate_achieved = ((current_conversion_rate / current_target_conversion_rate) * 100).toFixed(2);

          current_customer_number_achieved = ((current_customer_number / current_target_customer_number) * 100).toFixed(2);

          current_repeating_rate_achieved = parseFloat(((current_repeating_rate / current_target_repeating_rate) * 100).toFixed(2));

          current_customer_frequency_achieved = ((current_customer_frequency / current_target_customer_frequency) * 100).toFixed(2);

          // if (current_footfalls_achieved > 100) {
          //   current_footfalls_achieved = 100;
          // }

          // if (current_conversion_rate_achieved > 100) {
          //   current_conversion_rate_achieved = 100;
          // }

          // if (current_customer_number_achieved > 100) {
          //   current_customer_number_achieved = 100;
          // }
          // if (current_repeating_rate_achieved > 100) {
          //   current_repeating_rate_achieved = 100;
          // }
          // if (current_customer_frequency_achieved > 100) {
          //   current_customer_frequency_achieved = 100;
          // }

          // CUSTOMER SPARKLINE
          let customer_sparkline_list = result1["ri_customer_metrices_sparkline_list"];

          let customer_footfalls_spark_list = [];
          let customer_conversion_rate_spark_list = [];
          let customer_number_spark_list = [];
          let customer_returning_rate_spark_list = [];
          let customer_frequency_spark_list = [];
          let customer_basket_size_list = [];

          customer_sparkline_list.map((info, index) => {
            try {
              let a = {
                date: info["datetime"],
                value: info["footfall"],
              };
              customer_footfalls_spark_list.push(a);

              let b = {
                date: info["datetime"],
                value: ((info["unique_bills_final"] / info["footfall"]) * 100).toFixed(2),
              };
              customer_conversion_rate_spark_list.push(b);

              let c = {
                date: info["datetime"],
                value: info["unique_customer"],
              };
              customer_number_spark_list.push(c);

              let d = {
                date: info["datetime"],
                value: ((info["unique_customer"] / info["unique_bills_final"]) * 100).toFixed(2),
              };
              customer_returning_rate_spark_list.push(d);

              let e = {
                date: info["datetime"],
                value: (
                  info["unique_bills_final"] / info["unique_customer"]
                ).toFixed(2),
              };
              customer_frequency_spark_list.push(e);

              let f = {
                date: info["datetime"],
                value: (info["units_sold"] / info["unique_bills_final"]).toFixed(2),
              };
              customer_basket_size_list.push(f);
            } catch (e) { }
          });

          this.setState({
            current_footfalls: current_footfalls,
            last_footfalls: last_footfalls,
            current_bills: current_bills,
            last_bills: last_bills,
            current_customer_number: current_customer_number,
            last_customer_number: last_customer_number,
            //current_total_customer:current_total_customer,
            //last_total_customer:last_total_customer,
            //current_total_bills:current_total_bills,
            //last_total_bills:last_total_bills,
            current_units_sold: current_units_sold,
            last_units_sold: last_units_sold,
            current_basket_size: current_basket_size,
            last_basket_size: last_basket_size,

            current_conversion_rate: current_conversion_rate,
            last_conversion_rate: last_conversion_rate,
            current_repeating_rate: current_repeating_rate,
            last_repeating_rate: last_repeating_rate,
            current_customer_frequency: current_customer_frequency,
            last_customer_frequency: last_customer_frequency,

            current_target_footfalls: current_target_footfalls,
            last_target_footfalls: last_target_footfalls,
            current_target_bills: current_target_bills,
            last_target_bills: last_target_bills,
            current_target_customer_number: current_target_customer_number,
            //last_target_customer_number:last_target_customer_number,

            current_target_repeating_rate: current_target_repeating_rate,
            current_target_conversion_rate: current_target_conversion_rate,
            last_target_conversion_rate: last_target_conversion_rate,
            current_target_customer_frequency: current_target_customer_frequency,

            current_footfalls_achieved: current_footfalls_achieved,
            current_conversion_rate_achieved: current_conversion_rate_achieved,
            current_customer_number_achieved: current_customer_number_achieved,
            current_repeating_rate_achieved: current_repeating_rate_achieved,
            current_customer_frequency_achieved: current_customer_frequency_achieved,

            // FOR SPARKLINE
            customer_footfalls_spark_graph: customer_footfalls_spark_list,
            customer_conversion_rate_spark_graph: customer_conversion_rate_spark_list,
            customer_number_spark_graph: customer_number_spark_list,
            customer_returning_rate_spark_graph: customer_returning_rate_spark_list,
            customer_frequency_spark_graph: customer_frequency_spark_list,
            customer_basket_size_graph: customer_basket_size_list,


            is_customer_graph_loaded: true,
            is_customer_metrices_loaded: true,

          });
        },
        (error) => { }
      );
  }

  // MARKETING METRICES
  getMarketingMetrices() {
    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let previous_start_date = this.state.second_start_date;
    let previous_end_date = this.state.second_end_date;


    //map
    let map_zone = this.state.map_zone;
    let map_city = this.state.map_city;
    let storecode = this.state.map_store_code;
    let storename = this.state.map_store_name;
    let state = this.state.map_state;

    //map filter
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;

    const url = API_URL + "/api/ri/ri-summarymarketing/";
    fetch(url, {
      method: "POST",
      body: "start_date=" + start_date +
        "&end_date=" + end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&storecode=" + storecode +
        "&storename=" + storename +
        "&state=" + state +
        "&city_type=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,

      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          let result1 = result;

          let current_average_cost_per_footfall;
          let last_average_cost_per_footfall;
          let current_average_cost_per_acquition;
          let last_average_cost_per_acquition;
          let current_return_on_investment;
          let last_return_on_investment;

          let current_target_avg_cpf;
          let last_target_avg_cpf;
          let current_target_avg_cpa;
          let last_target_avg_cpa;
          let current_target_roi;
          let last_target_roi;
          //let current_basket_size; units_sold to be fetched from API and binding is left
          //let last_basket_size; units_sold to be fetched from API and binding is left

          let current_total_spends_achieved;
          let current_campaign_number_achieved;
          let current_avg_cpf_achieved;
          let current_avg_cpa_achieved;
          let current_roi_achieved;

          let current_total_spends =
            result1["current_ri_marketing_list"][0]["total_spends"];

          //let current_total_spends = result1['current_ri_marketing_list'][0]['total_spends']
          let last_total_spends =
            result1["last_ri_marketing_list"][0]["total_spends"];

          let current_campaign_number =
            result1["current_ri_marketing_list"][0]["campaign_number"];
          let last_campaign_number =
            result1["last_ri_marketing_list"][0]["campaign_number"];

          let current_bills = result1["current_ri_marketing_list"][0]["bills"];
          let last_bills = result1["last_ri_marketing_list"][0]["bills"];

          let current_net_salesvalue =
            result1["current_ri_marketing_list"][0]["nsv"];
          let last_net_salesvalue = result1["last_ri_marketing_list"][0]["nsv"];

          let current_footfalls =
            result1["current_ri_marketing_list"][0]["footfalls"];
          let last_footfalls =
            result1["last_ri_marketing_list"][0]["footfalls"];

          let current_target_total_spends =
            result1["current_ri_marketing_list"][0]["target_total_spends"];
          let last_target_total_spends =
            result1["last_ri_marketing_list"][0]["target_total_spends"];

          let current_target_campaign_number =
            result1["current_ri_marketing_list"][0]["target_campaign_number"];
          let last_target_campaign_number =
            result1["last_ri_marketing_list"][0]["target_campaign_number"];

          let current_target_footfalls =
            result1["current_ri_marketing_list"][0]["target_footfalls"];
          let last_target_footfalls =
            result1["last_ri_marketing_list"][0]["target_footfalls"];

          let current_target_nsv =
            result1["current_ri_marketing_list"][0]["target_nsv"];

          let last_target_nsv =
            result1["last_ri_marketing_list"][0]["target_nsv"];

          let current_target_bills =
            result1["current_ri_marketing_list"][0]["target_bills"];
          let last_target_bills =
            result1["last_ri_marketing_list"][0]["target_bills"];

          current_average_cost_per_footfall =
            current_total_spends / current_footfalls;
          last_average_cost_per_footfall = last_total_spends / last_footfalls;
          // last_average_cost_per_footfall = Infinity
          // ? "0"
          // : (last_average_cost_per_footfall = NaN
          //   ? "0"
          //   : last_average_cost_per_footfall);

          current_average_cost_per_acquition =
            current_total_spends / current_bills;
          last_average_cost_per_acquition = last_total_spends / last_bills;
          // last_average_cost_per_acquition = Infinity
          //   ? "0"
          //   : (last_average_cost_per_acquition = NaN
          //     ? "0"
          //     : last_average_cost_per_acquition);

          current_return_on_investment =
            (current_total_spends / current_net_salesvalue) * 100;
          last_return_on_investment =
            (last_total_spends / last_net_salesvalue) * 100;
          // last_return_on_investment = Infinity
          //   ? "0"
          //   : (last_return_on_investment = NaN
          //     ? "0"
          //     : last_return_on_investment);

          current_target_avg_cpf =
            current_target_total_spends / current_target_footfalls;
          // current_target_avg_cpf = Infinity
          //   ? "0"
          //   : (current_target_avg_cpf = NaN ? "0" : current_target_avg_cpf);

          last_target_avg_cpf =
            last_target_total_spends / last_target_footfalls;
          // last_target_avg_cpf = Infinity
          //   ? "0"
          //   : (last_target_avg_cpf = NaN ? "0" : last_target_avg_cpf);

          current_target_avg_cpa =
            current_target_total_spends / current_target_bills;
          // current_target_avg_cpa = Infinity
          //   ? "0"
          //   : (current_target_avg_cpa = NaN ? "0" : current_target_avg_cpa);

          last_target_avg_cpa = last_target_total_spends / last_target_bills;
          // last_target_avg_cpa = Infinity
          //   ? "0"
          //   : (last_target_avg_cpa = NaN ? "0" : last_target_avg_cpa);

          current_target_roi = (
            (current_target_total_spends / current_target_nsv) *
            100
          ).toFixed(2);
          // current_target_roi = Infinity
          //   ? "0"
          //   : (current_target_roi = NaN ? "0" : current_target_roi);

          last_target_roi = (
            (last_target_total_spends / last_target_nsv) *
            100
          ).toFixed(2);
          // last_target_roi = Infinity
          //   ? "0"
          //   : (last_target_roi = NaN ? "0" : last_target_roi);

          current_total_spends_achieved = (
            (current_total_spends / current_target_total_spends) *
            100
          ).toFixed(2);
          current_campaign_number_achieved = (
            (current_campaign_number / current_target_campaign_number) *
            100
          ).toFixed(2);
          current_avg_cpf_achieved = (
            (current_average_cost_per_footfall / current_target_avg_cpf) *
            100
          ).toFixed(2);
          current_avg_cpa_achieved = (
            (current_average_cost_per_acquition / current_target_avg_cpa) *
            100
          ).toFixed(2);
          current_roi_achieved = (
            (current_return_on_investment / current_target_roi) *
            100
          ).toFixed(2);

          if (current_total_spends_achieved > 100) {
            current_total_spends_achieved = 100;
          }

          if (current_campaign_number_achieved > 100) {
            current_campaign_number_achieved = 100;
          }
          if (current_avg_cpf_achieved > 100) {
            current_avg_cpf_achieved = 100;
          }

          if (current_avg_cpa_achieved > 100) {
            current_avg_cpa_achieved = 100;
          }

          if (current_roi_achieved > 100) {
            current_roi_achieved = 100;
          }

          // SPARKLINE CODES

          let sparkline_list = result1["ri_marketing_metrices_sparkline_list"];

          let marketing_total_spends_spark_list = [];
          let marketing_campaign_number_spark_list = [];
          let marketing_avg_cpf_spark_list = [];
          let marketing_avg_cpa_spark_list = [];
          let marketing_roi_spark_list = [];

          sparkline_list.map((info, index) => {

            try {
              let a = {
                date: info["datetime"],
                value: info["total_spends"],
              };
              marketing_total_spends_spark_list.push(a);

              let b = {
                date: info["datetime"],
                value: info["campaign_number"],
              };
              marketing_campaign_number_spark_list.push(b);

              let c = {
                date: info["datetime"],
                value: (info["total_spends"] / info["footfalls"]).toFixed(2),
              };
              marketing_avg_cpf_spark_list.push(c);

              let d = {
                date: info["datetime"],
                value: (info["total_spends"] / info["bills"]).toFixed(2),
              };
              marketing_avg_cpa_spark_list.push(d);

              let e = {
                date: info["datetime"],
                value: ((info["total_spends"] / info["nsv"]) * 100).toFixed(2),
              };
              marketing_roi_spark_list.push(e);
            } catch (e) { }
          });







          this.setState({
            is_marketing_metrices_loaded: true,
            current_total_spends: current_total_spends,
            last_total_spends: last_total_spends,
            current_campaign_number: current_campaign_number,
            last_campaign_number: last_campaign_number,
            current_average_cost_per_footfall:
              current_average_cost_per_footfall,
            last_average_cost_per_footfall: last_average_cost_per_footfall,
            current_average_cost_per_acquition:
              current_average_cost_per_acquition,
            last_average_cost_per_acquition: last_average_cost_per_acquition,
            current_return_on_investment: current_return_on_investment,
            last_return_on_investment: last_return_on_investment,

            current_target_total_spends: current_target_total_spends,
            last_target_total_spends: last_target_total_spends,
            current_target_footfalls: current_target_footfalls,
            last_target_footfalls: last_target_footfalls,
            current_target_nsv: current_target_nsv,
            last_target_nsv: last_target_nsv,
            current_target_bills: current_target_bills,
            last_target_bills: last_target_bills,
            current_target_avg_cpf: current_target_avg_cpf,
            last_target_avg_cpf: last_target_avg_cpf,
            current_target_avg_cpa: current_target_avg_cpa,
            last_target_avg_cpa: last_target_avg_cpa,
            current_target_roi: current_target_roi,
            last_target_roi: last_target_roi,
            current_total_spends_achieved: current_total_spends_achieved,
            current_campaign_number_achieved: current_campaign_number_achieved,
            current_avg_cpf_achieved: current_avg_cpf_achieved,
            current_avg_cpa_achieved: current_avg_cpa_achieved,
            current_roi_achieved: current_roi_achieved,
            current_target_campaign_number: current_target_campaign_number,
            last_target_campaign_number: last_target_campaign_number,

            //SPARKLINE SET STATE
            is_marketing_graph_loaded: true,
            marketing_total_spends_spark_graph: marketing_total_spends_spark_list,
            marketing_campaign_number_spark_graph: marketing_campaign_number_spark_list,
            marketing_avg_cpf_spark_graph: marketing_avg_cpf_spark_list,
            marketing_avg_cpa_spark_graph: marketing_avg_cpa_spark_list,
            marketing_roi_spark_graph: marketing_roi_spark_list,
          });
        },
        (error) => { }
      );
  }

  //SPARK LINE FOR MARKETING
  // getMarketinglinesparkgraph() {
  //   let start_date = this.state.first_start_date;
  //   let end_date = this.state.first_end_date;
  //   let previous_start_date = this.state.second_start_date;
  //   let previous_end_date = this.state.second_end_date;

  //   //map
  //   let map_zone = this.state.map_zone;
  //   let map_city = this.state.map_city;
  //   let storecode = this.state.storecode;
  //   let storename = this.state.storename;
  //   let state = this.state.state;

  //   //map filter
  //   let category = this.state.category;
  //   let store_type = this.state.store_type;
  //   let store_size = this.state.store_size;

  //   const url = API_URL + "/api/ri/ri-summarymarketing/";

  //   fetch(url, {
  //     method: "POST",
  //     //body: JSON.stringify(data),
  //     body: "start_date=" + start_date +
  //       "&end_date=" + end_date +
  //       "&last_start_date=" + previous_start_date +
  //       "&last_end_date=" + previous_end_date +
  //       "&map_zone=" + map_zone +
  //       "&map_city=" + map_city +
  //       "&storecode=" + storecode +
  //       "&storename=" + storename +
  //       "&state=" + state +
  //       "&city_type=" + category +
  //       "&store_type=" + store_type +
  //       "&store_size=" + store_size,


  //     // body: JSON.stringify('start_date='+start_date+'&end_date='+end_date +'&store_type='+store_type + '&map_zone='+map_zone +'&map_city='+map_city+'&storecode='+storecode),
  //     headers: {
  //       // 'Accept': 'application/json',
  //       // 'Content-Type': 'application/json'
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       let result1 = result;

  //       let sparkline_list = result1["ri_marketing_metrices_sparkline_list"];

  //       let marketing_total_spends_spark_list = [];
  //       let marketing_campaign_number_spark_list = [];
  //       let marketing_avg_cpf_spark_list = [];
  //       let marketing_avg_cpa_spark_list = [];
  //       let marketing_roi_spark_list = [];

  //       sparkline_list.map((info, index) => {

  //         try {
  //           let a = {
  //             date: info["datetime"],
  //             value: info["total_spends"],
  //           };
  //           marketing_total_spends_spark_list.push(a);

  //           let b = {
  //             date: info["datetime"],
  //             value: info["campaign_number"],
  //           };
  //           marketing_campaign_number_spark_list.push(b);

  //           let c = {
  //             date: info["datetime"],
  //             value: (info["total_spends"] / info["footfalls"]).toFixed(2),
  //           };
  //           marketing_avg_cpf_spark_list.push(c);

  //           let d = {
  //             date: info["datetime"],
  //             value: (info["total_spends"] / info["bills"]).toFixed(2),
  //           };
  //           marketing_avg_cpa_spark_list.push(d);

  //           let e = {
  //             date: info["datetime"],
  //             value: ((info["total_spends"] / info["nsv"]) * 100).toFixed(2),
  //           };
  //           marketing_roi_spark_list.push(e);
  //         } catch (e) { }
  //       });

  //       this.setState({
  //         is_marketing_graph_loaded: true,
  //         marketing_total_spends_spark_graph: marketing_total_spends_spark_list,
  //         marketing_campaign_number_spark_graph:
  //         marketing_campaign_number_spark_list,
  //         marketing_avg_cpf_spark_graph: marketing_avg_cpf_spark_list,
  //         marketing_avg_cpa_spark_graph: marketing_avg_cpa_spark_list,
  //         marketing_roi_spark_graph: marketing_roi_spark_list,
  //       });
  //     });
  // }

  // BE THE FIRST DRIVE METRICES (MEMBER)
  getMemberMetrics() {

    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let previous_start_date = this.state.second_start_date;
    let previous_end_date = this.state.second_end_date;

    //map
    let map_zone = this.state.map_zone;
    let map_city = this.state.map_city;
    let storecode = this.state.map_store_code;
    let storename = this.state.map_store_name;
    let state = this.state.map_state;

    //map filter
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;

    const url = API_URL + "/api/ri/ri-summarymember/";
    fetch(url, {
      method: "POST",
      body: "start_date=" + start_date +
        "&end_date=" + end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&storecode=" + storecode +
        "&storename=" + storename +
        "&state=" + state +
        "&city_type=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,

      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          let result1 = result;

          let current_member_average_ticket_size;
          let last_member_average_ticket_size;
          let current_member_sale_vp;
          let last_member_sale_vp;
          let current_response;
          let last_response;
          //let current_basket_size ---> Binding and data from API is left
          //let last_basket_size ---> Binding and data from API is left
          let current_target_member_ats;
          let last_target_member_ats;
          let current_target_member_sale_vp;
          let last_target_member_sale_vp;
          let current_target_response;
          let current_member_nsv_achieved;
          let current_member_bills_achieved;
          let current_member_ats_achieved;
          let current_member_sale_vp_achieved;
          let current_member_response_achieved;

          let current_member_nsv =
            result1["current_ri_member_list"][0]["member_nsv"];
          let last_member_nsv = result1["last_ri_member_list"][0]["member_nsv"];

          let current_member_bills =
            result1["current_ri_member_list"][0]["member_bills"];
          let last_member_bills =
            result1["last_ri_member_list"][0]["member_bills"];

          let current_total_nsv =
            result1["current_ri_member_list"][0]["total_nsv"];
          let last_total_nsv = result1["last_ri_member_list"][0]["total_nsv"];

          let current_member_responding =
            result1["current_ri_member_list"][0]["responding_member"];
          let last_member_responding =
            result1["last_ri_member_list"][0]["responding_member"];

          let current_total_btfmember =
            result1["current_ri_member_list"][0]["total_btfmember"];
          let last_total_btfmember =
            result1["last_ri_member_list"][0]["total_btfmember"];

          let current_target_member_bill =
            result1["current_ri_member_list"][0]["target_member_bills"];
          let last_target_member_bill =
            result1["last_ri_member_list"][0]["target_member_bills"];

          let current_target_member_nsv =
            result1["current_ri_member_list"][0]["target_member_nsv"];
          let last_target_member_nsv =
            result1["last_ri_member_list"][0]["target_member_nsv"];

          let current_target_member_responding =
            result1["current_ri_member_list"][0]["target_member_responding"];
          let last_target_member_responding =
            result1["last_ri_member_list"][0]["target_member_responding"];

          let current_target_total_btfmember =
            result1["current_ri_member_list"][0]["target_total_btfmember"];
          let last_target_total_btfmember =
            result1["last_ri_member_list"][0]["target_total_btfmember"];

          let current_target_total_nsv =
            result1["current_ri_member_list"][0]["target_total_nsv"];
          let last_target_total_nsv =
            result1["last_ri_member_list"][0]["target_total_nsv"];

          current_member_average_ticket_size =
            (current_member_nsv / current_member_bills).toFixed(2);

          // current_member_average_ticket_size = Infinity
          //   ? "0"
          //   : (current_member_average_ticket_size = NaN
          //     ? "0"
          //     : current_member_average_ticket_size);

          last_member_average_ticket_size = (last_member_nsv / last_member_bills).toFixed(2);
          // last_member_average_ticket_size = Infinity
          //   ? "0"
          //   : (last_member_average_ticket_size = NaN
          //     ? "0"
          //     : last_member_average_ticket_size);
          current_member_sale_vp =
            ((current_member_nsv / current_total_nsv) * 100).toFixed(2);
          // current_member_sale_vp = Infinity
          //   ? "0"
          //   : (current_member_sale_vp = NaN ? "0" : current_member_sale_vp);

          last_member_sale_vp = ((last_member_nsv / last_total_nsv) * 100).toFixed(2);
          // last_member_sale_vp = Infinity
          //   ? "0"
          //   : (last_member_sale_vp = NaN ? "0" : last_member_sale_vp);

          current_response =
            (current_member_responding / current_total_btfmember) * 100;
          // current_response = Infinity
          //   ? "0"
          //   : (current_response = NaN ? "0" : current_response);

          last_response = (last_member_responding / last_total_btfmember) * 100;
          // last_response = Infinity
          //   ? "0"
          //   : (last_response = NaN ? "0" : last_response);

          console.log('456', current_member_responding, current_total_btfmember, last_member_responding, last_total_btfmember)

          current_target_member_ats =
            current_target_member_nsv / current_target_member_bill;
          current_target_member_ats = Infinity
            ? "0"
            : (current_target_member_ats = NaN
              ? "0"
              : current_target_member_ats);

          last_target_member_ats =
            last_target_member_nsv / last_target_member_bill;
          last_target_member_ats = Infinity
            ? "0"
            : (last_target_member_ats = NaN ? "0" : last_target_member_ats);

          current_target_member_sale_vp = (
            (current_target_member_nsv / current_target_total_nsv) *
            100
          ).toFixed(2);
          last_target_member_sale_vp = (
            (last_target_member_nsv / last_target_total_nsv) *
            100
          ).toFixed(2);

          current_target_response = (
            (current_target_member_responding /
              current_target_total_btfmember) *
            100
          ).toFixed(2);

          current_member_nsv_achieved = (
            (current_member_nsv / current_target_member_nsv) *
            100
          ).toFixed(2);
          current_member_bills_achieved = (
            (current_member_bills / current_target_member_bill) *
            100
          ).toFixed(2);
          current_member_ats_achieved = (
            (current_member_average_ticket_size / current_target_member_ats) *
            100
          ).toFixed(2);
          current_member_sale_vp_achieved = (
            (current_member_sale_vp / current_target_member_sale_vp) *
            100
          ).toFixed(2);
          current_member_response_achieved = (
            (current_response / current_target_response) *
            100
          ).toFixed(2);

          if (current_member_sale_vp_achieved > 100) {
            current_member_sale_vp_achieved = 100;
          }

          if (current_member_nsv_achieved > 100) {
            current_member_nsv_achieved = 100;
          }

          if (current_member_bills_achieved > 100) {
            current_member_bills_achieved = 100;
          }

          if (current_member_ats_achieved > 100) {
            current_member_ats_achieved = 100;
          }
          if (current_member_response_achieved > 100) {
            current_member_response_achieved = 100;
          }

          // SPARK LINE GRAPH CODES

          let sparkline_list = result1["ri_member_metrices_sparkline_list"];

          let member_nsv_spark_list = [];
          let member_bills_spark_list = [];
          let member_ats_spark_list = [];
          let member_sale_vp_spark_list = [];
          let member_response_spark_list = [];

          sparkline_list.map((info, index) => {

            try {
              let a = {
                date: info["datetime"],
                value: info["member_nsv"],
              };
              member_nsv_spark_list.push(a);

              let b = {
                date: info["datetime"],
                value: info["member_bills"],
              };
              member_bills_spark_list.push(b);

              let c = {
                date: info["datetime"],
                value: (info["member_nsv"] / info["member_bills"]).toFixed(2),
              };
              member_ats_spark_list.push(c);

              let d = {
                date: info["datetime"],
                value: ((info["member_nsv"] / info["total_nsv"]) * 100).toFixed(
                  2
                ),
              };
              member_sale_vp_spark_list.push(d);


              let e = {
                date: info["datetime"],
                value: ((info["responding_member"] / info["total_btfmember"]) * 100).toFixed(2),
              };
              member_response_spark_list.push(e);

              console.log('789', info["responding_member"], info["total_btfmember"])

              //   let f = {
              //     date: info["datetime"],
              //     value: (
              //       (info["responding_member"] / info["total_btfmember"]) *
              //       100
              //     ).toFixed(2),
              // };
              // member_response_spark_list.push(f);
            } catch (e) { }
          });














          this.setState({
            is_member_metrics_loaded: true,
            current_member_nsv: current_member_nsv,
            last_member_nsv: last_member_nsv,
            current_member_bills: current_member_bills,
            last_member_bills: last_member_bills,
            current_total_nsv: current_total_nsv,
            last_total_nsv: last_total_nsv,
            current_member_responding: current_member_responding,
            last_member_responding: last_member_responding,
            current_total_btfmember: current_total_btfmember,
            last_total_btfmember: last_total_btfmember,
            current_target_member_bill: current_target_member_bill,
            last_target_member_bill: last_target_member_bill,
            current_target_member_nsv: current_target_member_nsv,
            last_target_member_nsv: last_target_member_nsv,
            current_target_member_responding: current_target_member_responding,
            last_target_member_responding: last_target_member_responding,
            current_target_total_btfmember: current_target_total_btfmember,
            last_target_total_btfmember: last_target_total_btfmember,
            current_target_total_nsv: current_target_total_nsv,
            last_target_total_nsv: last_target_total_nsv,

            current_member_average_ticket_size:
              current_member_average_ticket_size,
            last_member_average_ticket_size: last_member_average_ticket_size,
            current_member_sale_vp: current_member_sale_vp,
            last_member_sale_vp: last_member_sale_vp,
            current_response: current_response,
            last_response: last_response,
            current_target_member_ats: current_target_member_ats,
            last_target_member_ats: last_target_member_ats,
            current_target_member_sale_vp: current_target_member_sale_vp,
            current_target_response: current_target_response,

            current_member_nsv_achieved: current_member_nsv_achieved,
            current_member_bills_achieved: current_member_bills_achieved,
            current_member_ats_achieved: current_member_ats_achieved,
            current_member_sale_vp_achieved: current_member_sale_vp_achieved,
            current_member_response_achieved: current_member_response_achieved,

            //SPARK LINE SET STATE
            is_member_graph_loaded: true,
            member_nsv_spark_graph: member_nsv_spark_list,
            member_bills_spark_graph: member_bills_spark_list,
            member_ats_spark_graph: member_ats_spark_list,
            member_sale_vp_spark_graph: member_sale_vp_spark_list,
            member_response_spark_graph: member_response_spark_list,
          });
        },
        (error) => { }
      );
  }

  //SPARK LINE FOR MEMBER
  // getMemberlinesparkgraph() {
  //   let start_date = this.state.first_start_date;
  //   let end_date = this.state.first_end_date;
  //   let previous_start_date = this.state.second_start_date;
  //   let previous_end_date = this.state.second_end_date;

  //   //map
  //   let map_zone = this.state.map_zone;
  //   let map_city = this.state.map_city;
  //   let storecode = this.state.storecode;
  //   let storename = this.state.storename;
  //   let state = this.state.state;

  //   //map filter
  //   let category = this.state.category;
  //   let store_type = this.state.store_type;
  //   let store_size = this.state.store_size;

  //   const url = API_URL + "/api/ri/ri-summarymember/";

  //   fetch(url, {
  //     method: "POST",
  //     body: "start_date=" + start_date +
  //       "&end_date=" + end_date +
  //       "&last_start_date=" + previous_start_date +
  //       "&last_end_date=" + previous_end_date +
  //       "&last_start_date=" + previous_start_date +
  //       "&last_end_date=" + previous_end_date +
  //       "&map_zone=" + map_zone +
  //       "&map_city=" + map_city +
  //       "&storecode=" + storecode +
  //       "&storename=" + storename +
  //       "&state=" + state +
  //       "&city_type=" + category +
  //       "&store_type=" + store_type +
  //       "&store_size=" + store_size,
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       let result1 = result;

  //       let sparkline_list = result1["ri_member_metrices_sparkline_list"];

  //       let member_nsv_spark_list = [];
  //       let member_bills_spark_list = [];
  //       let member_ats_spark_list = [];
  //       let member_sale_vp_spark_list = [];
  //       let member_response_spark_list = [];

  //       sparkline_list.map((info, index) => {

  //         try {
  //           let a = {
  //             date: info["datetime"],
  //             value: info["member_nsv"],
  //           };
  //           member_nsv_spark_list.push(a);

  //           let b = {
  //             date: info["datetime"],
  //             value: info["member_bills"],
  //           };
  //           member_bills_spark_list.push(b);

  //           let c = {
  //             date: info["datetime"],
  //             value: (info["member_nsv"] / info["member_bills"]).toFixed(2),
  //           };
  //           member_ats_spark_list.push(c);

  //           let d = {
  //             date: info["datetime"],
  //             value: ((info["member_nsv"] / info["total_nsv"]) * 100).toFixed(
  //               2
  //             ),
  //           };
  //           member_sale_vp_spark_list.push(d);

  //           let e = {
  //             date: info["datetime"],
  //             value: (
  //               (info["responding_member"] / info["total_btfmember"]) *
  //               100
  //             ).toFixed(2),
  //           };
  //           member_response_spark_list.push(e);
  //         } catch (e) { }
  //       });

  //       this.setState({
  //         is_member_graph_loaded: true,
  //         member_nsv_spark_graph: member_nsv_spark_list,
  //         member_bills_spark_graph: member_bills_spark_list,
  //         member_ats_spark_graph: member_ats_spark_list,
  //         member_sale_vp_spark_graph: member_sale_vp_spark_list,
  //         member_response_spark_graph: member_response_spark_list,
  //       });
  //     });
  // }

  // PROMO BUBBLE CHART
  getPromoBubbleChart() {
    let start_date = this.state.first_start_date;
    let end_date = this.state.first_end_date;
    let previous_start_date = this.state.second_start_date;
    let previous_end_date = this.state.second_end_date;

    //map
    let map_zone = this.state.map_zone;
    let map_city = this.state.map_city;
    let storecode = this.state.map_store_code;
    let storename = this.state.map_store_name;
    let state = this.state.map_state;

    //map filter
    let category = this.state.map_store_category;
    let store_type = this.state.map_store_type;
    let store_size = this.state.map_store_size;

    const url = API_URL + "/api/ri/ri-summarybubble/";

    fetch(url, {
      method: "POST",
      body: "start_date=" + start_date +
        "&end_date=" + end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +
        "&last_start_date=" + previous_start_date +
        "&last_end_date=" + previous_end_date +
        "&map_zone=" + map_zone +
        "&map_city=" + map_city +
        "&storecode=" + storecode +
        "&storename=" + storename +
        "&state=" + state +
        "&city_type=" + category +
        "&store_type=" + store_type +
        "&store_size=" + store_size,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let promo_bubble_chart_list = result["ri_promo_bubble_chart_list"];

        let ri_bubble_chart_list = [];
        //let cost_per_click_spark_list = [];

        promo_bubble_chart_list.map((info, index) => {


          try {
            let a = {
              y: info["total_spends"],
              x: info["nsv"],
              value: (info["nsv"] / info["bills"]).toFixed(2),
            };
            ri_bubble_chart_list.push(a);

          } catch (e) { }
        });

        this.setState({
          is_bubble_chart_loaded: true,
          ri_bubble_chart_graph: ri_bubble_chart_list,
        });
      });
  }

  handleCallback = (childData) => {

    this.state.first_start_date = childData[0];
    this.state.first_end_date = childData[1];
    this.state.second_start_date = childData[2];
    this.state.second_end_date = childData[3];

    this.state.is_India_map_loaded = false;
    this.state.is_marketing_metrices_loaded = false;
    this.state.is_customer_metrices_loaded = false;
    this.state.is_member_metrics_loaded = false;
    this.state.is_sales_metrices_loaded = false;
    this.state.is_sales_graph_loaded = false;
    this.state.is_customer_graph_loaded = false;
    this.state.is_marketing_graph_loaded = false;
    this.state.is_member_graph_loaded = false;
    this.state.is_bubble_chart_loaded = false;

    this.getMapdetails();
    this.getMarketingMetrices();
    this.getCustomerMetrices();
    this.getMemberMetrics();
    this.getSalesMetrices();
    this.getPromoBubbleChart();
    //this.getMemberlinesparkgraph();
    // this.getMarketinglinesparkgraph();
    this.forceUpdate();
    this.handleCallback2();
  };

  handleCallback2 = () => {
    this.setState({ isDatePickerOpen: !this.state.isDatePickerOpen });
  };

  handleScroll = () => {
    if (window.pageYOffset > 1) {
      if (!this.state.is_scroll) {
        this.setState({ is_scroll: true });
      }
    } else {
      if (this.state.is_scroll) {
        this.setState({ is_scroll: false });
      }
    }
  };

  handleMapCallback = (childData) => {
    this.state.storecode = childData;

    // this.state.is_marketing_metrices_loaded = false;
    this.state.is_customer_metrices_loaded = false;
    // this.state.is_member_metrics_loaded = false;
    this.state.is_sales_metrices_loaded = false;
    // this.state.is_sales_graph_loaded = false;
    // this.state.is_customer_graph_loaded = false;
    // this.state.is_marketing_graph_loaded = false;
    // this.state.is_member_graph_loaded = false;
    // this.state.is_bubble_chart_loaded = false;
    // this.state.is_India_map_loaded =  false;


    // this.getMarketingMetrices();
    this.getCustomerMetrices();
    // this.getMemberMetrics();
    this.getSalesMetrices();
    // this.getPromoBubbleChart();
    // this.getMemberlinesparkgraph();
    // this.getMarketinglinesparkgraph();
    this.forceUpdate();

  };

  handleRiMapFilterCallback = (childData) => {
   
    let storeCategory = [];
    let storeType = [];
    let storeSize = [];

    // Object.keys(childData[0]).forEach(key => {
    //   if (childData[0][key] == true) {
    //     storeCategory.push(key);
    //   }
    // });
    // Object.keys(childData[1]).forEach(key => {
    //   if (childData[1][key] == true) {
    //     storeType.push(key);
    //   }
    // });
    // // Object.keys(childData[2]).forEach(key => {
    // //   if (childData[2][key] == true) {
    // //     storeSize.push(key);
    // //   }
    // // });
    storeCategory.push(childData[0]);
    storeType.push(childData[1]);
    storeSize.push(childData[2]);

    this.state.map_store_category = storeCategory;
    this.state.map_store_type = storeType;
    this.state.map_store_size = storeSize;

    //0 zone level
    //get city
    if (this.state.map_level == 0) {
      this.state.is_India_map_loaded = false;
      this.getMapdetails();
    }

    //1 city level
    else if (this.state.map_level == 1) {
      this.state.is_India_map_loaded = false;
      this.getIndiaMapCityDetails();
    }

    //2 store level
    else if (this.state.map_level == 2) {
      this.state.is_India_map_loaded = false;
      this.getIndiaMapStoresDetails();
    }

    //3 store code level data 
    else if (this.state.map_level == 3) {
    }

    this.state.is_customer_metrices_loaded = false;
    this.state.is_sales_metrices_loaded = false;
    this.state.is_sales_graph_loaded = false;
    this.state.is_customer_graph_loaded = false;

    ////dummy data for now
    // this.state.is_member_metrics_loaded = false;
    // this.state.is_member_graph_loaded = false;
    // this.state.is_marketing_metrices_loaded = false;
    // this.state.is_marketing_graph_loaded = false;
    // this.state.is_bubble_chart_loaded = false;

    this.getSalesMetrices();
    this.getCustomerMetrices();
    //dummy data for now
    // this.getMemberMetrics();
    // this.getMarketingMetrices();
    // this.getPromoBubbleChart();

    this.state.map_filter_storesize_list=childData[3];
    this.forceUpdate();

  };

  handleRISalesSummaryIndiaMapCallBack = (childData) => {

    this.state.North_point = childData[0];
    this.state.South_point = childData[1];
    this.state.center_point = childData[2];
    this.state.map_zone = childData[3];
    this.state.map_city = childData[4];
    this.state.map_store_code = childData[5];
    this.state.map_store_name = childData[6];


    //0 zone level
    //get city
    if (this.state.map_level == 0) {
      this.state.is_India_map_loaded = false;
      this.getIndiaMapCityDetails();
      this.state.mapBreadcrumbList = ['All ZONES', this.state.map_zone];
    }

    //1 city level
    else if (this.state.map_level == 1) {
      this.state.is_India_map_loaded = false;
      this.getIndiaMapStoresDetails();
      this.state.mapBreadcrumbList = ['All ZONES', this.state.map_zone, this.state.map_city];
    }

    //2 store level
    else if (this.state.map_level == 2) {
      this.state.mapBreadcrumbList = ['All ZONES', this.state.map_zone, this.state.map_city, this.state.map_store_name];
      // this.getIndiaMapStoresDetails();
    }
    //3 store code level data 
    else if (this.state.map_level == 3) {
      this.state.mapBreadcrumbList = ['All ZONES', this.state.map_zone, this.state.map_city, this.state.map_store_name];
      // this.getIndiaMapStoresDetails();
    }

    this.state.mapBreadcrumbsCenterPoint = this.state.center_point;
    this.state.mapBreadcrumbsNorthPoint = this.state.North_point;
    this.state.mapBreadcrumbsSouthPoint = this.state.South_point;


    this.state.is_customer_metrices_loaded = false;
    this.state.is_sales_metrices_loaded = false;
    this.state.is_sales_graph_loaded = false;
    this.state.is_customer_graph_loaded = false;

    ////dummy data for now
    // this.state.is_member_metrics_loaded = false;
    // this.state.is_member_graph_loaded = false;
    // this.state.is_marketing_metrices_loaded = false;
    // this.state.is_marketing_graph_loaded = false;
    // this.state.is_bubble_chart_loaded = false;

    this.getSalesMetrices();
    this.getCustomerMetrices();
    //dummy data for now
    // this.getMemberMetrics();
    // this.getMarketingMetrices();
    // this.getPromoBubbleChart();

    this.forceUpdate();
  };

  mapBreadcrumbs = (evt, index) => {
    this.state.mapBreadcrumbList.splice(index);

    // level 0
    // all zone
    if (index == 1) {
      this.state.map_zone = '';
      this.state.map_city = '';
      this.state.map_store_code = '';
      this.state.map_store_name = '';
      this.state.map_store_category = '';
      this.state.map_store_type = '';
      this.state.map_store_size = '';
      this.state.map_level = 0;
      this.state.center_point = [0, 0];
      this.state.North_point = [72.6416015625, 37.71859032558816];
      this.state.South_point = [93.1640625, 5.7908968128719565];
      this.state.is_India_map_loaded = false;

      this.state.is_customer_metrices_loaded = false;
      this.state.is_sales_metrices_loaded = false;
      this.state.is_sales_graph_loaded = false;
      this.state.is_customer_graph_loaded = false;

      ////dummy data for now
      // this.state.is_member_metrics_loaded = false;
      // this.state.is_member_graph_loaded = false;
      // this.state.is_marketing_metrices_loaded = false;
      // this.state.is_marketing_graph_loaded = false;
      // this.state.is_bubble_chart_loaded = false;

      this.getMapdetails();
      this.getSalesMetrices();
      this.getCustomerMetrices();
      //dummy data for now
      // this.getMemberMetrics();
      // this.getMarketingMetrices();
      // this.getPromoBubbleChart();

      this.forceUpdate();
    }
    // level 1
    //city level
    if (index == 2) {
      // this.state.map_zone = '';
      this.state.map_city = '';
      this.state.map_store_code = '';
      this.state.map_store_name = '';
      this.state.map_level = 0;
      this.state.center_point = [0, 0];
      this.state.North_point = [72.6416015625, 37.71859032558816];
      this.state.South_point = [93.1640625, 5.7908968128719565];
      this.state.is_India_map_loaded = false;

      this.state.is_customer_metrices_loaded = false;
      this.state.is_sales_metrices_loaded = false;
      this.state.is_sales_graph_loaded = false;
      this.state.is_customer_graph_loaded = false;

      ////dummy data for now
      // this.state.is_member_metrics_loaded = false;
      // this.state.is_member_graph_loaded = false;
      // this.state.is_marketing_metrices_loaded = false;
      // this.state.is_marketing_graph_loaded = false;
      // this.state.is_bubble_chart_loaded = false;

      this.getSalesMetrices();
      this.getCustomerMetrices();
      this.getIndiaMapCityDetails();
      //dummy data for now
      // this.getMemberMetrics();
      // this.getMarketingMetrices();
      // this.getPromoBubbleChart();

      this.forceUpdate();
    }
    // level 3
    //Store level
    if (index == 3) {
      // this.state.map_zone = '';
      //this.state.map_city = '';
      this.state.map_store_code = '';
      this.state.map_store_name = '';
      this.state.map_level = 0;
      this.state.center_point = [0, 0];
      this.state.North_point = [72.6416015625, 37.71859032558816];
      this.state.South_point = [93.1640625, 5.7908968128719565];
      this.state.is_India_map_loaded = false;

      this.state.is_customer_metrices_loaded = false;
      this.state.is_sales_metrices_loaded = false;
      this.state.is_sales_graph_loaded = false;
      this.state.is_customer_graph_loaded = false;

      ////dummy data for now
      // this.state.is_member_metrics_loaded = false;
      // this.state.is_member_graph_loaded = false;
      // this.state.is_marketing_metrices_loaded = false;
      // this.state.is_marketing_graph_loaded = false;
      // this.state.is_bubble_chart_loaded = false;

      this.getSalesMetrices();
      this.getCustomerMetrices();
      this.getIndiaMapStoresDetails();
      //dummy data for now
      // this.getMemberMetrics();
      // this.getMarketingMetrices();
      // this.getPromoBubbleChart();

      this.forceUpdate();
    }




  }

  onClickRiExport = () => {
    window.print('height=2000,width=2000')
  }

  downloadReport = async () => {
    const data =this.state.export_data;
    console.log("getMapdetails: ",data);
    this.setState({ data: data }, () => {
      setTimeout(() => {
        this.csvLinkEl.current.link.click();
      });
    });
  };
  

  render() {

   // console.log('render console', this.state.member_response_spark_graph)

    const CarouselSettings = {
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            arrows: false,
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
      ],
    };
    const now = 40;
    const progress1 = 65; const progress2 = 25; const progress3 = 10;

    return (
      <Container className="p-0" fluid>
        <div className="d-flex flex-column">
          <Col lg={3} md={3} sm={12}>
            <div className="icz-rimapsection">
              {this.state.is_India_map_loaded == true ?
                <Wrapper>
                  <Col lg={12} className="icz-rimapsectionheader">
                    <Wrapper>
                      <Wrapper>
                        <Col lg={4} md={4} sm={12} className="icz-rimaptitle">
                        {this.state.country!=null ? (this.state.country.toUpperCase() =="UK"?"UK":"India"):"India"}
                        </Col>
                        <Col lg={8} md={8} sm={12} className="icz-rimapfilter">
                          <RiMapFilter 
                           parentCallback={this.handleRiMapFilterCallback}
                           map_filter_category_list={this.state.map_filter_category_list}
                           map_filter_storetype_list={this.state.map_filter_storetype_list}
                           map_filter_storesize_list={this.state.map_filter_storesize_list}  />
                          <div className="icz-ribtnwrapper">
                            <button className="icz-rithemebtn" onClick={this.downloadReport}>
                              <IconDownload className="icz-riicon" /> </button>
                              {/* {this.state.is_India_map_loaded == true ?
                              <a href={this.state.export_href} download="test.csv"> Download</a>
                            : ''} */}
                          </div>
                        </Col>
                      </Wrapper>
                      <div className="breadcrumb-no-padding">
                        <Breadcrumb>
                          {this.state.mapBreadcrumbList.map((item, index) => (
                            <Breadcrumb.Item onClick={() => { this.mapBreadcrumbs(item, index + 1) }} key={index}>{item}</Breadcrumb.Item>
                          ))}
                        </Breadcrumb>
                      </div>
                    </Wrapper>
                  </Col>

                  <Col lg={12} className="icz-rimapcontainer">
                    {/* <IndiaMapnew data={this.state.data} parentCallback={this.handleMapCallback} /> */}

                    {/* {this.state.is_India_map_loaded == true ?
                      <IndiaMap data={this.state.MapMarker}
                        parentCallback={this.handleRISalesSummaryIndiaMapCallBack}
                        North_point={this.state.North_point}
                        South_point={this.state.South_point}
                        Center_point={this.state.center_point}
                        map_level={this.state.map_level}
                        rangetype={this.state.rangetype}
                        ref={this.printRef}
                      />
                      : <Loader />} */}
                      {this.state.is_India_map_loaded == true ?
                      <>
                      {(() => {
                        switch (this.state.country) {
                            case "india": return <IndiaMap data={this.state.MapMarker}
                            parentCallback={this.handleRISalesSummaryIndiaMapCallBack}
                            North_point={this.state.North_point}
                            South_point={this.state.South_point}
                            Center_point={this.state.center_point}
                            map_level={this.state.map_level}
                            rangetype={this.state.rangetype}
                            ref={this.printRef}
                          />;
                            case "uk": return  <UKMap
                            data={this.state.MapMarker}
                            parentCallback={this.handleRISalesSummaryIndiaMapCallBack}
                            North_point={this.state.North_point}
                            South_point={this.state.South_point}
                            Center_point={this.state.center_point}
                            map_level={this.state.map_level}
                            rangetype={this.state.rangetype}
                            ref={this.printRef}
                            ></UKMap>;
                            default: return <IndiaMap data={this.state.MapMarker}
                            parentCallback={this.handleRISalesSummaryIndiaMapCallBack}
                            North_point={this.state.North_point}
                            South_point={this.state.South_point}
                            Center_point={this.state.center_point}
                            map_level={this.state.map_level}
                            rangetype={this.state.rangetype}
                            ref={this.printRef}
                          />;
                        }
                    })()}
                    </>
                     : <Loader />} 
                  </Col>

                  <div className="icz-rimapfooter">
                    <Col lg={12}>
                      <ul className="icz-rilist-inline">
                        <li><div className="icz-rilist-bullet1" /> North</li>
                        <li><div className="icz-rilist-bullet2" /> West</li>
                        <li><div className="icz-rilist-bullet3" /> South</li>
                        <li><div className="icz-rilist-bullet4" /> East</li>
                        <li><div className="icz-rilist-bullet5" /> APTS</li>
                      </ul>
                    </Col>

                    <Col lg={12} className="icz-rimapprogressbar">
                      <h4 className="icz-riprogresstitle">NSV Achievement Percentage</h4>
                      <ProgressBar>
                        <ProgressBar className="range1" variant="range1" now={progress1} key={1} label="0-69" />
                        <ProgressBar className="range2" variant="range2" now={progress2} key={2} label="70-94" />
                        <ProgressBar className="range3" variant="range3" now={progress3} key={3} label="95+" />
                      </ProgressBar>
                    </Col>
                  </div>
                </Wrapper>
                : <Loader />}
            </div>
          </Col>
          <Col lg={{ span: 9, offset: 3 }} md={{ span: 9, offset: 3 }} sm={12} className="icz-ridatacontainer">
            <Container className={this.state.is_scroll ? "icz-section-padding icz-pageheaderwrapper sticky" : "icz-section-padding icz-pageheaderwrapper"} fluid>
              <div className="icz-pageheadercontainer">
                <div className="icz-stickylogowrapper">
                  <Image src={StickyLogo} className="icz-stickylogo" />
                </div>
                <div className="p-0 icz-headerdetails d-flex justify-content-between">
                  <Col lg={4} xs={12} md={12} className="p-0">
                    <h2 className="icz-pagetitle">Summary</h2>
                  </Col>

                  <Col lg={8} xs={12} md={12} className="p-0 icz-pagefiltersection">
                    {/* <CampaignDropdown ismulti={true} placeholder={'Choose Campaign'} options={this.state.campaignDropdownOptions}/> */}

                    <DatePicker
                      parentCallback={this.handleCallback}
                      open={this.state.isDatePickerOpen}
                      startDate={this.state.first_start_date}
                      endDate={this.state.first_end_date}
                      lastStartDate={this.state.second_start_date}
                      lastEndDate={this.state.second_end_date}
                      setOpen={this.handleCallback2}
                    />

                    <div className="icz-btnwrapper">
                      <button className="icz-themebtn" onClick={() => this.onClickRiExport()}>
                        <IconPdf className="icz-ribtnicon" />{" "}
                      </button>
                    </div>
                  </Col>
                </div>
              </div>
            </Container>

            <div className="">
              <Col lg={12} className="icz-ricardheader">
                <Col lg={6} className="icz-ricardtitle">
                  Sales
                </Col>
                <Col lg={6} className="icz-ricardfilter">
                  <Link to="/ri/salessummary" className="icz-link">
                    View More
                  </Link>
                </Col>
              </Col>
              <div className="icz-carouselcontainer">
                {this.state.is_sales_metrices_loaded == true && this.state.is_sales_graph_loaded == true ?
                  <Carousel
                    Settings={CarouselSettings}
                    class={"icz-cardcontainer"}
                    SliderCards={[
                      <SummaryCard
                        index="1"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="NSV"
                        progressbar={
                          <Progressbar
                            targetHover={this.state.current_target_nsv}
                            progress={this.state.current_target_nsv_achieved}
                            isnumber={'money'}
                          />
                        }
                        RowOneCurrentValue={parseFloat(this.state.current_nsv).toFixed(2)}
                        IsStandard={true}
                        RowOnePreviousValue={parseFloat(this.state.last_nsv).toFixed(2)}
                        RowOneTitle="overall"
                        IsNumber={'money'}
                        chart={
                          <Linegraph_sparkline
                            card_id="icz-nsv"
                            card_class="icz-cardchart"
                            graph-data={this.state.sales_nsv_spark_graph}
                            isnumber={'money'}
                          />
                        }
                      />,
                      <SummaryCard
                        index="2"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="Net Margin"
                        progressbar={
                          <Progressbar
                            targetHover={this.state.current_target_net_margin}
                            progress={this.state.current_target_net_margin_achieved}
                            isnumber={'percentage'}
                          />
                        }
                        RowOneCurrentValue={
                          parseFloat(this.state.current_net_margin).toFixed(2)
                        }
                        RowOnePreviousValue={
                          parseFloat(this.state.last_net_margin).toFixed(2)
                        }
                        RowOneTitle="overall"
                        IsNumber={'percentage'}
                        chart={
                          <Linegraph_sparkline
                            card_id="icz-netmargin"
                            card_class="icz-cardchart"
                            graph-data={this.state.sales_net_margin_spark_graph}
                            isnumber={'decimal_percentage'}
                          />
                        }
                      />,
                      <SummaryCard
                        index="3"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="Bills"
                        progressbar={
                          <Progressbar
                            targetHover={this.state.current_target_bills}
                            progress={this.state.current_target_bills_achieved}
                            isnumber={'number'}
                          />
                        }
                        RowOneCurrentValue={this.state.current_bills}
                        RowOnePreviousValue={this.state.last_bills}
                        RowOneTitle="overall"
                        IsNumber={'number'}
                        chart={
                          <Linegraph_sparkline
                            card_id="icz-bills"
                            card_class="icz-cardchart"
                            graph-data={this.state.sales_bills_spark_graph}
                            isnumber={'number'}
                          />
                        }
                      />,
                      <SummaryCard
                        index="4"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="ATS"
                        progressbar={
                          <Progressbar
                            targetHover={this.state.current_target_ats}
                            progress={this.state.current_target_ats_achieved}
                            isnumber={'money'}
                          />
                        }
                        RowOneCurrentValue={parseFloat(
                          this.state.current_average_ticket_size
                        ).toFixed(2)}
                        RowOnePreviousValue={parseFloat(
                          this.state.last_average_ticket_size
                        ).toFixed(2)}
                        RowOneTitle="overall"
                        IsNumber={'money'}
                        chart={
                          <Linegraph_sparkline
                            card_id="icz-ats"
                            card_class="icz-cardchart"
                            graph-data={this.state.sales_ats_spark_graph}
                            isnumber={'money'}
                          />
                        }
                      />,
                      <SummaryCard
                        index="5"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="Sell Through%"
                        progressbar={
                          <Progressbar
                            targetHover={this.state.current_target_sell_through}
                            progress={this.state.current_target_sell_through_achieved}
                            isnumber={'percentage'}
                          />
                        }
                        RowOneCurrentValue={this.state.current_target_sell_through}
                        RowOnePreviousValue={
                          parseFloat(this.state.last_sell_through).toFixed(2)
                        }
                        RowOneTitle="overall"
                        IsNumber={'percentage'}
                        chart={
                          <Linegraph_sparkline
                            card_id="icz-sell"
                            card_class="icz-cardchart"
                            graph-data={this.state.sales_sell_through_spark_graph}
                            isnumber={'percentage'}
                          />
                        }
                      />,
                      <SummaryCard
                        index="6"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="Units Sold"
                        progressbar={
                          <Progressbar
                            targetHover={this.state.current_target_units_sold}
                            progress={this.state.current_target_units_sold_achieved}
                            isnumber={'number'}
                          />
                        }
                        RowOneCurrentValue={parseFloat(
                          this.state.current_units_sold
                        ).toFixed(2)}
                        RowOnePreviousValue={parseFloat(
                          this.state.last_units_sold
                        ).toFixed(2)}
                        RowOneTitle="overall"
                        IsNumber={'number'}
                        chart={
                          <Linegraph_sparkline
                            card_id="icz-units"
                            card_class="icz-cardchart"
                            graph-data={this.state.sales_units_sold_spark_graph}
                            isnumber={'number'}
                          />
                        }
                      />,
                      <SummaryCard
                        index="7"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="ASP"
                        progressbar={
                          <Progressbar
                            targetHover={parseFloat(this.state.current_target_asp).toFixed(2)}
                            progress={parseFloat(this.state.current_target_asp_achieved).toFixed(2)}
                            isnumber={'money'}
                          />
                        }
                        RowOneCurrentValue={parseFloat(this.state.current_average_selling_price).toFixed(2)}
                        RowOnePreviousValue={parseFloat(
                          this.state.last_average_selling_price
                        ).toFixed(2)}
                        RowOneTitle="overall"
                        IsNumber={'money'}
                        chart={
                          <Linegraph_sparkline
                            card_id="icz-asp"
                            card_class="icz-cardchart"
                            graph-data={this.state.sales_asp_spark_graph}
                            isnumber={'money'}
                          />
                        }
                      />,
                      <SummaryCard
                        index="8"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="Markdown%"
                        progressbar={
                          <Progressbar
                            targetHover={this.state.current_target_markdown}
                            progress={this.state.current_target_markdown_achieved}
                            isnumber={'percentage'}
                          />
                        }
                        RowOneCurrentValue={
                          parseFloat(this.state.current_markdown).toFixed(2)
                        }
                        RowOnePreviousValue={
                          parseFloat(this.state.last_markdown).toFixed(2)
                        }
                        RowOneTitle="overall"
                        IsNumber={'percentage'}
                        chart={
                          <Linegraph_sparkline
                            card_id="icz-markdown"
                            card_class="icz-cardchart"
                            graph-data={this.state.sales_markdown_spark_graph}
                            isnumber={'decimal_percentage'}
                          />
                        }
                      />,
                      <SummaryCard
                        index="9"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="RGM"
                        progressbar={
                          <Progressbar
                            targetHover={this.state.current_target_rgm}
                            progress={this.state.current_target_rgm_achieved}
                            isnumber={'money'}
                          />
                        }
                        RowOneCurrentValue={parseFloat(
                          this.state.current_rgm
                        ).toFixed(2)}
                        RowOnePreviousValue={parseFloat(
                          this.state.last_rgm
                        ).toFixed(2)}
                        RowOneTitle="overall"
                        IsNumber={'money'}
                        chart={
                          <Linegraph_sparkline
                            card_id="icz-rgm"
                            card_class="icz-cardchart"
                            graph-data={this.state.sales_rgm_spark_graph}
                            isnumber={'money'}
                          />
                        }
                      />,
                      <SummaryCard
                        index="10"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="Basket Size"
                        progressbar={
                          <Progressbar
                            targetHover={parseFloat(this.state.current_target_basket_size).toFixed(2)}
                            progress={parseFloat(this.state.current_basket_size_achieved).toFixed(2)}
                            isnumber={'number'}
                          />
                        }
                        RowOneCurrentValue={parseFloat(
                          this.state.current_basket_size
                        ).toFixed(2)}
                        RowOnePreviousValue={parseFloat(
                          this.state.last_basket_size
                        ).toFixed(2)}
                        RowOneTitle="overall"
                        IsNumber={'number'}
                        chart={
                          <Linegraph_sparkline
                            card_id="sales_basket_size"
                            card_class="icz-cardchart"
                            graph-data={this.state.sales_basket_size_graph}
                            isnumber={'number'}
                          />
                        }
                      />,
                      <ValueCard
                        index="11"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="Recovery%"
                        RowOneCurrentValue={parseFloat(this.state.current_recovery).toFixed(2)}
                        IsNumber={'percentage'}
                      />,
                      <ValueCard
                        index="12"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="PSFPD"
                        RowOneCurrentValue={parseFloat(this.state.current_pspfo).toFixed(2)}
                        RowOneCurrentValueUnit={'Sales Per sq.ft.'}
                        IsNumber={'number'}
                      />,
                      <ValueCard
                        index="13"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="MBQ%"
                        RowOneCurrentValue={this.state.current_mbq}
                        IsNumber={'number'}
                      />,
                    ]}
                  ></Carousel>
                  : <Loader />}
              </div>
            </div>

            <div className="">
              <Col lg={12} className="icz-ricardheader">
                <Col lg={6} className="icz-ricardtitle">
                  Customer
                </Col>
                <Col lg={6} className="icz-ricardfilter">
                  <Link to="/ri/customer" className="icz-link">
                    View More
                  </Link>
                </Col>
              </Col>
              <div className="icz-carouselcontainer">
                {this.state.is_customer_metrices_loaded == true && this.state.is_customer_graph_loaded == true ?
                  <Carousel
                    Settings={CarouselSettings}
                    class={"icz-cardcontainer"}
                    SliderCards={[
                      <SummaryCard
                        index="1"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="Footfalls"
                        progressbar={
                          <Progressbar
                            targetHover={this.state.current_target_footfalls}
                            progress={this.state.current_footfalls_achieved}
                            isnumber={'number'}
                          />
                        }
                        RowOneCurrentValue={this.state.current_footfalls}
                        RowOnePreviousValue={this.state.last_footfalls}
                        RowOneTitle="overall"
                        IsNumber={'number'}
                        chart={
                          <Linegraph_sparkline
                            card_id="icz-footfalls"
                            card_class="icz-cardchart"
                            graph-data={this.state.customer_footfalls_spark_graph}
                            isnumber={'number'}
                          />
                        }
                      />,
                      <SummaryCard
                        index="2"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="Conversion Rate"
                        progressbar={
                          <Progressbar
                            targetHover={this.state.current_target_conversion_rate}
                            progress={this.state.current_conversion_rate_achieved}
                            isnumber={'percentage'}
                          />
                        }
                        RowOneCurrentValue={
                          parseFloat(this.state.current_conversion_rate).toFixed(2)
                        }
                        RowOnePreviousValue={
                          parseFloat(this.state.last_conversion_rate).toFixed(2)
                        }
                        RowOneTitle="overall"
                        IsNumber={'percentage'}
                        chart={
                          <Linegraph_sparkline
                            card_id="icz-convrate"
                            card_class="icz-cardchart"
                            graph-data={
                              this.state.customer_conversion_rate_spark_graph
                            }
                            isnumber={'decimal_percentage'}
                          />
                        }
                      />,
                      <SummaryCard
                        index="3"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="No. of Customers"
                        progressbar={
                          <Progressbar
                            targetHover={this.state.current_target_customer_number}
                            progress={this.state.current_customer_number_achieved}
                            isnumber={'number'}
                          />
                        }
                        RowOneCurrentValue={this.state.current_customer_number}
                        RowOnePreviousValue={this.state.last_customer_number}
                        RowOneTitle="overall"
                        IsNumber={'number'}
                        chart={
                          <Linegraph_sparkline
                            card_id="icz-customerno"
                            card_class="icz-cardchart"
                            graph-data={this.state.customer_number_spark_graph}
                            isnumber={'number'}
                          />
                        }
                      />,
                      <SummaryCard
                        index="4"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="Repeat Customers"
                        progressbar={
                          <Progressbar
                            targetHover={this.state.current_target_repeating_rate}
                            progress={this.state.current_repeating_rate_achieved}
                            isnumber={'percentage'}
                          />
                        }
                        RowOneCurrentValue={parseFloat(this.state.current_repeating_rate).toFixed(2)}
                        RowOnePreviousValue={parseFloat(this.state.last_repeating_rate).toFixed(2)}
                        RowOneTitle="overall"
                        IsNumber={'percentage'}
                        chart={
                          <Linegraph_sparkline
                            card_id="icz-rtrate"
                            card_class="icz-cardchart"
                            graph-data={
                              this.state.customer_returning_rate_spark_graph
                            }
                            isnumber={'decimal_percentage'}
                          />
                        }
                      />,
                      <SummaryCard
                        index="5"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="Customer Frequency"
                        progressbar={
                          <Progressbar
                            targetHover={this.state.current_target_customer_frequency}
                            progress={this.state.current_customer_frequency_achieved}
                            isnumber={'number'}
                          />
                        }
                        RowOneCurrentValue={parseFloat(
                          this.state.current_customer_frequency
                        ).toFixed(2)}
                        RowOnePreviousValue={parseFloat(
                          this.state.last_customer_frequency
                        ).toFixed(2)}
                        RowOneTitle="overall"
                        IsNumber={'number'}
                        chart={
                          <Linegraph_sparkline
                            card_id="icz-customer"
                            card_class="icz-cardchart"
                            graph-data={this.state.customer_frequency_spark_graph}
                            isnumber={'number'}
                          />
                        }
                      />,
                      <SummaryCard
                        index="6"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="Basket Size"
                        progressbar={
                          <Progressbar
                            targetHover={parseFloat(this.state.current_target_basket_size).toFixed(2)}
                            progress={parseFloat(this.state.current_basket_size_achieved).toFixed(2)}
                            isnumber={'number'}
                          />
                        }
                        RowOneCurrentValue={parseFloat(
                          this.state.current_basket_size
                        ).toFixed(2)}
                        RowOnePreviousValue={parseFloat(
                          this.state.last_basket_size
                        ).toFixed(2)}
                        RowOneTitle="overall"
                        IsNumber={'number'}
                        chart={
                          <Linegraph_sparkline
                            card_id="customer-basket-size"
                            card_class="icz-cardchart"
                            graph-data={this.state.linesparkgraph}
                            isnumber={'number'}
                          />
                        }
                      />,
                    ]}
                  ></Carousel>
                  :
                  <Loader />}
              </div>
            </div>

            <div className="">
              <Col lg={12} className="icz-ricardheader">
                <Col lg={6} className="icz-ricardtitle">
                  Marketing
                </Col>
                <Col lg={6} className="icz-ricardfilter">
                  <Link to="/ri/sales" className="icz-link">
                    View More
                  </Link>
                </Col>
              </Col>
              <div className="icz-carouselcontainer">
                {this.state.is_marketing_metrices_loaded == true ?
                  <Carousel
                    Settings={CarouselSettings}
                    class={"icz-cardcontainer"}
                    SliderCards={[
                      <SummaryCard
                        index="1"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="Total Spends"
                        progressbar={
                          <Progressbar
                            targetHover={this.state.current_total_spends_achieved}
                            progress={this.state.current_total_spends_achieved}
                            isnumber={'money'}
                          />
                        }
                        RowOneCurrentValue={this.state.current_total_spends}
                        RowOnePreviousValue={this.state.last_total_spends}
                        RowOneTitle="overall"
                        IsNumber={'money'}
                        chart={
                          <Linegraph_sparkline
                            card_id="icz-spends"
                            card_class="icz-cardchart"
                            graph-data={
                              this.state.marketing_total_spends_spark_graph
                            }
                            isnumber={'money'}
                          />
                        }
                      />,
                      <SummaryCard
                        index="2"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="No. of Campaigns"
                        progressbar={
                          <Progressbar
                            targetHover={this.state.current_campaign_number_achieved}
                            progress={this.state.current_campaign_number_achieved}
                            isnumber={'number'}
                          />
                        }
                        RowOneCurrentValue={this.state.current_campaign_number}
                        RowOnePreviousValue={this.state.last_campaign_number}
                        RowOneTitle="overall"
                        IsNumber={'number'}
                        chart={
                          <Linegraph_sparkline
                            card_id="icz-campaigns"
                            card_class="icz-cardchart"
                            graph-data={
                              this.state.marketing_campaign_number_spark_graph
                            }
                            isnumber={'number'}
                          />
                        }
                      />,
                      <SummaryCard
                        index="3"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="Avg CPF"
                        progressbar={
                          <Progressbar
                            targetHover={0}
                            progress={this.state.current_avg_cpf_achieved}
                            isnumber={'money'}
                          />
                        }
                        RowOneCurrentValue={parseFloat(
                          this.state.current_average_cost_per_footfall
                        ).toFixed(2)}
                        RowOnePreviousValue={parseFloat(
                          this.state.last_average_cost_per_footfall
                        ).toFixed(2)}
                        RowOneTitle="overall"
                        IsNumber={'money'}
                        chart={
                          <Linegraph_sparkline
                            card_id="icz-avgcpf"
                            card_class="icz-cardchart"
                            graph-data={this.state.marketing_avg_cpf_spark_graph}
                            isnumber={'money'}
                          />
                        }
                      />,
                      <SummaryCard
                        index="4"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="Avg CPA"
                        progressbar={
                          <Progressbar
                            targetHover={0}
                            progress={this.state.current_avg_cpa_achieved}
                            isnumber={'money'}
                          />
                        }
                        RowOneCurrentValue={parseFloat(
                          this.state.current_average_cost_per_acquition
                        ).toFixed(2)}
                        RowOnePreviousValue={parseFloat(
                          this.state.last_average_cost_per_acquition
                        ).toFixed(2)}
                        RowOneTitle="overall"
                        IsNumber={'money'}
                        chart={
                          <Linegraph_sparkline
                            card_id="icz-avgcpa"
                            card_class="icz-cardchart"
                            graph-data={this.state.marketing_avg_cpa_spark_graph}
                            isnumber={'money'}
                          />
                        }
                      />,
                      <SummaryCard
                        index="5"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="ROI%"
                        progressbar={
                          <Progressbar
                            targetHover={0}
                            progress={this.state.current_roi_achieved}
                            isnumber={'percentage'}
                          />
                        }
                        RowOneCurrentValue={parseFloat(
                          this.state.current_return_on_investment
                        ).toFixed(2)}
                        RowOnePreviousValue={parseFloat(
                          this.state.last_return_on_investment
                        ).toFixed(2)}
                        RowOneTitle="overall"
                        IsNumber={'percentage'}
                        chart={
                          <Linegraph_sparkline
                            card_id="icz-roi"
                            card_class="icz-cardchart"
                            graph-data={this.state.linesparkgraph}
                            isnumber={'percentage'}
                          />
                        }
                      />,
                      <SummaryCard
                        index="10"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="Basket Size"
                        progressbar={
                          <Progressbar
                            targetHover={parseFloat(this.state.current_target_basket_size).toFixed(2)}
                            progress={parseFloat(this.state.current_basket_size_achieved).toFixed(2)}
                            isnumber={'number'}
                          />
                        }
                        RowOneCurrentValue={parseFloat(this.state.custom_current_basket_size).toFixed(2)}
                        RowOnePreviousValue={parseFloat(this.state.custom_last_basket_size).toFixed(2)}
                        RowOneTitle="overall"
                        IsNumber={'number'}
                        chart={
                          <Linegraph_sparkline
                            card_id="marketing-basket-size"
                            card_class="icz-cardchart"
                            graph-data={this.state.linesparkgraph}
                            isnumber={'number'}
                          />
                        }
                      />,
                    ]}
                  ></Carousel>
                  : <Loader />}
              </div>
            </div>

            <div className="icz-row">
              <div className="icz-sectioncardwrapper w-100">
                <div className="icz-sectioncard">
                  <Col sm={12} className="icz-cardheader">
                    <Col lg={10} className="icz-cardtitle">
                      Promo Bubble Chart
                    </Col>
                    <Col lg={2} className="icz-cardfilter">
                      <div className="icz-cardbtnwrapper">
                        <ChartExport ChartId='tweets' exportName="Promo Bubble Chart"
                        export_href={this.state.export_href} downloadName="Promo-Bubble-Chart.csv"
                        />
                      </div>
                    </Col>
                  </Col>
                  {this.state.is_bubble_chart_loaded === true ?
                    <>
                      <div className="">
                        <RiBubbleChart
                          card_id="tweets"
                          card_class="icz-sectionbigchart"
                          graph-data={this.state.ri_bubble_chart_graph}
                          Xtitle={"NSV"}
                          Ytitle={"Spends"}
                          metric_1={"B1G1"}
                          metric_2={"B1G2"}
                          metric_3={"B1G3"}
                          isnumber={'money'}
                        />
                      </div>
                      <Col lg={12} className="icz-ridatabullet">
                        <ul className="icz-ridatalist-inline">
                          <li>Size = ATS</li>
                          <li>
                            <div className="icz-ridatalist-bullet5" /> High
                            Conversion Rate%
                          </li>
                          <li>
                            <div className="icz-ridatalist-bullet2" /> Medium
                            Conversion Rate%
                          </li>
                          <li>
                            <div className="icz-ridatalist-bullet6" /> Low
                            Conversion Rate%
                          </li>
                        </ul>
                      </Col>
                    </>
                    : <Loader />}
                </div>
              </div>
            </div>

            <div className="">
              <Col lg={12} className="icz-ricardheader">
                <Col lg={6} className="icz-ricardtitle">
                  #BeTheFirstDrive
                </Col>
                <Col lg={6} className="icz-ricardfilter">
                  <Link to="/ri/sales" className="icz-link">
                    View More
                  </Link>
                </Col>
              </Col>
              <div className="icz-carouselcontainer">
                {this.state.is_member_metrics_loaded == true ?
                  <Carousel
                    Settings={CarouselSettings}
                    class={"icz-cardcontainer"}
                    SliderCards={[
                      <SummaryCard
                        index="1"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="Members Sale"
                        progressbar={
                          <Progressbar
                            targetHover={0}
                            progress={this.state.current_member_nsv_achieved}
                            isnumber={'money'}
                          />
                        }
                        RowOneCurrentValue={this.state.current_member_nsv}
                        RowOnePreviousValue={this.state.last_member_nsv}
                        RowOneTitle="overall"
                        IsNumber={'money'}
                        chart={
                          <Linegraph_sparkline
                            card_id="icz-membrsale"
                            card_class="icz-cardchart"
                            graph-data={this.state.member_nsv_spark_graph}
                            isnumber={'money'}
                          />
                        }
                      />,
                      <SummaryCard
                        index="2"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="Members Bills"
                        progressbar={
                          <Progressbar
                            targetHover={0}
                            progress={this.state.current_member_bills_achieved}
                            isnumber={'number'}
                          />
                        }
                        RowOneCurrentValue={this.state.current_member_bills}
                        RowOnePreviousValue={this.state.last_member_bills}
                        RowOneTitle="overall"
                        IsNumber={'number'}
                        chart={
                          <Linegraph_sparkline
                            card_id="icz-membrbills"
                            card_class="icz-cardchart"
                            graph-data={this.state.member_bills_spark_graph}
                            isnumber={'number'}
                          />
                        }
                      />,
                      <SummaryCard
                        index="3"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="Members ATS"
                        progressbar={
                          <Progressbar
                            targetHover={0}
                            progress={this.state.current_member_ats_achieved}
                            isnumber={'money'}
                          />
                        }
                        RowOneCurrentValue={parseFloat(
                          this.state.current_member_average_ticket_size
                        ).toFixed(2)}
                        RowOnePreviousValue={parseFloat(
                          this.state.last_member_average_ticket_size
                        ).toFixed(2)}
                        RowOneTitle="overall"
                        IsNumber={'money'}
                        chart={
                          <Linegraph_sparkline
                            card_id="icz-membrats"
                            card_class="icz-cardchart"
                            graph-data={this.state.member_ats_spark_graph}
                            isnumber={'money'}
                          />
                        }
                      />,
                      <SummaryCard
                        index="4"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="Members Sale VP%"
                        progressbar={
                          <Progressbar
                            targetHover={0}
                            progress={this.state.current_member_sale_vp_achieved}
                            isnumber={'percentage'}
                          />
                        }
                        RowOneCurrentValue={
                          parseFloat(this.state.current_member_sale_vp).toFixed(2)
                        }
                        RowOnePreviousValue={
                          parseFloat(this.state.last_member_sale_vp).toFixed(2)
                        }
                        RowOneTitle="overall"
                        IsNumber={'percentage'}
                        chart={
                          <Linegraph_sparkline
                            card_id="icz-membrsalevp"
                            card_class="icz-cardchart"
                            graph-data={this.state.member_sale_vp_spark_graph}
                            isnumber={'decimal_percentage'}
                          />
                        }
                      />,
                      <SummaryCard
                        index="5"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="Response%"
                        progressbar={
                          <Progressbar
                            targetHover={0}
                            progress={this.state.current_member_response_achieved}
                            isnumber={'percentage'}
                          />
                        }
                        RowOneCurrentValue={
                          parseFloat(this.state.current_response).toFixed(2)
                        }
                        RowOnePreviousValue={
                          parseFloat(this.state.last_response).toFixed(2)
                        }
                        RowOneTitle="overall"
                        IsNumber={'percentage'}
                        chart={
                          <Linegraph_sparkline
                            card_id="icz-response"
                            card_class="icz-cardchart"
                            graph-data={this.state.member_response_spark_graph}
                            isnumber={'decimal_percentage'}
                          />
                        }
                      />,
                      <SummaryCard
                        index="10"
                        CardClass="icz-cardwrapper"
                        CardIcon={<IconWebsite className="icz-icon" />}
                        CardTitle="Basket Size"
                        progressbar={
                          <Progressbar
                            targetHover={parseFloat(this.state.current_target_basket_size).toFixed(2)}
                            progress={parseFloat(this.state.current_basket_size_achieved).toFixed(2)}
                            isnumber={'number'}
                          />
                        }
                        RowOneCurrentValue={parseFloat(this.state.custom_current_basket_size).toFixed(2)}
                        RowOnePreviousValue={parseFloat(this.state.custom_last_basket_size).toFixed(2)}
                        RowOneTitle="overall"
                        IsNumber={'number'}
                        chart={
                          <Linegraph_sparkline
                            card_id="first-drive-basket-size"
                            card_class="icz-cardchart"
                            graph-data={this.state.linesparkgraph}
                            isnumber={'number'}
                          />
                        }
                      />,
                    ]}
                  ></Carousel>
                  : <Loader />}
              </div>
            </div>
          </Col>
        </div>
      </Container>
    );
  }
}
