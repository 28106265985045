const IconUserGroup = (props) => {
    return (

        <svg className={props.className} width="54" height="54" viewBox="0 0 54 54" fill="none" stroke="#486F88" xmlns="http://www.w3.org/2000/svg">
            <path d="M34.024 40.582V37.57C34.0241 36.7811 33.8688 35.9999 33.567 35.2711C33.2652 34.5422 32.8227 33.8799 32.2649 33.3221C31.707 32.7643 31.0448 32.3218 30.3159 32.02C29.5871 31.7181 28.8059 31.5629 28.017 31.563H16.007C15.2181 31.5629 14.4369 31.7181 13.7081 32.02C12.9792 32.3218 12.3169 32.7643 11.7591 33.3221C11.2013 33.8799 10.7588 34.5422 10.457 35.2711C10.1552 35.9999 9.99987 36.7811 10 37.57V40.582" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M28.021 19.551C28.021 20.7391 27.6687 21.9005 27.0086 22.8883C26.3486 23.8762 25.4104 24.6461 24.3128 25.1008C23.2151 25.5554 22.0073 25.6744 20.8421 25.4426C19.6768 25.2108 18.6065 24.6387 17.7664 23.7986C16.9263 22.9585 16.3542 21.8882 16.1224 20.7229C15.8906 19.5577 16.0096 18.3499 16.4642 17.2522C16.9189 16.1546 17.6888 15.2164 18.6767 14.5564C19.6645 13.8963 20.8259 13.544 22.014 13.544V13.544C22.8029 13.5439 23.5841 13.6992 24.3129 14.001C25.0418 14.3028 25.704 14.7453 26.2619 15.3031C26.8197 15.861 27.2622 16.5232 27.564 17.2521C27.8658 17.9809 28.0211 18.7621 28.021 19.551V19.551Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M43.036 40.577V37.569C43.0324 36.24 42.5881 34.9497 41.7727 33.9002C40.9573 32.8506 39.8169 32.1011 38.53 31.769" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M32.526 13.739C33.8168 14.0711 34.9605 14.823 35.7771 15.8764C36.5938 16.9297 37.037 18.2247 37.037 19.5575C37.037 20.8903 36.5938 22.1853 35.7771 23.2387C34.9605 24.292 33.8168 25.0439 32.526 25.376" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>


    )
}

export default IconUserGroup;