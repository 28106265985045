import React, { Component } from "react"
import Container from 'react-bootstrap/Container';
import { VideoCard } from "../../shared/components/cards/videocard/videocard";
import { Carousel } from '../../shared/components/carousel/carousel';
import PageHeader from '../../layout/pageheader/pageheader';
import { CardTabNav } from '../../shared/components/tabs/tabcardnav';
import { Tab } from '../../shared/components/tabs/tab';

import Overview from "./overview"
import VideoFacebook from "./video-facebook/video-facebook";

import FacebookIcon from '../../../assets/images/social/facebook.png'
import InstagramIcon from '../../../assets/images/social/instagram.png'
import LinkedinIcon from '../../../assets/images/social/linkedin.png'
import TwiiterIcon from '../../../assets/images/social/twitter.png'
import YouTubeIcon from '../../../assets/images/social/youtube.png'
import IconFacebookwhite from '../../../assets/images/icons/facebookwhiteIcon';
import IconInstagramwhite from "../../../assets/images/icons/instagramwhiteIcon";
import IconTwitterwhite from "../../../assets/images/icons/twitterwhiteIcon";
import IconLinkedinwhite from "../../../assets/images/icons/linkedinwhiteIcon";
import IconYouTubewhite from "../../../assets/images/icons/youtubeIcon";

import VideoImage1 from '../../../assets/images/v1.jpg'
import VideoImage2 from '../../../assets/images/v2.jpg'
import VideoImage3 from '../../../assets/images/v3.jpg'
import VideoImage4 from '../../../assets/images/v4.jpg'
import VideoImage5 from '../../../assets/images/v5.jpg'

export class Video extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: 'Overview',
            search: '',
            BadgeData: ['DiwaliOffer', 'Offer', '365DaysDiscount', 'MensFashion'],
            trendtopic: '',
            is_data_loaded: false,
            data_list: [],
        }

    }

    setSelected = (tab) => {
        this.setState({ selected: tab });
    }

    setSearch = (search) => {
        console.log('search', search);
        if (!search) return this.state.BadgeData;
        console.log('trendtopic', this.state.trendtopic);

        return this.state.BadgeData.filter((topic) => {
            return (
                this.state.trendtopic = topic.toLowerCase().includes(search.toLowerCase())
            ),
                console.log('trendtopic 2', this.state.trendtopic)
        });
    };

    trendingTopics = () => {
        if (!this.state.search) return this.state.BadgeData;

        return this.state.BadgeData.filter((topic) => {
            return (
                topic.toLowerCase().includes(this.state.search.toLowerCase())
            );
        });
    };

    render() {
        const CarouselSettings = {
            slidesToShow: 5,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                }
            ]
        }
        return (
            <Container className="p-0" fluid>
                <PageHeader pageTitle="Video" />

                <div className="icz-carouselcontainer">
                    <Carousel
                        Settings={CarouselSettings}
                        class={"icz-cardcontainer"}
                        SliderCards={[

                            <VideoCard
                                index="1"
                                CardClass="icz-vcardwrapper"
                                CardImg={VideoImage1}
                                CardIcon={<IconFacebookwhite className="icz-icon" />}
                                RowOneCurrentValue="256000"
                                CardSubTitle="Views"
                                CardView="1:00"
                            />,
                            <VideoCard
                                index="1"
                                CardClass="icz-vcardwrapper"
                                CardImg={VideoImage2}
                                CardIcon={<IconInstagramwhite className="icz-icon" />}
                                RowOneCurrentValue="256000"
                                CardSubTitle="Views"
                                CardView="1:00"
                            />,
                            <VideoCard
                                index="1"
                                CardClass="icz-vcardwrapper"
                                CardImg={VideoImage3}
                                CardIcon={<IconTwitterwhite className="icz-icon" />}
                                RowOneCurrentValue="256000"
                                CardSubTitle="Views"
                                CardView="1:00"
                            />,
                            <VideoCard
                                index="1"
                                CardClass="icz-vcardwrapper"
                                CardImg={VideoImage4}
                                CardIcon={<IconLinkedinwhite className="icz-icon" />}
                                RowOneCurrentValue="256000"
                                CardSubTitle="Views"
                                CardView="1:00"
                            />,
                            <VideoCard
                                index="1"
                                CardClass="icz-vcardwrapper"
                                CardImg={VideoImage5}
                                CardIcon={<IconYouTubewhite className="icz-icon" />}
                                RowOneCurrentValue="256000"
                                CardSubTitle="Views"
                                CardView="1:00"
                            />
                        ]}>

                    </Carousel>
                </div>

                <div className="d-flex flex-column">
                    <CardTabNav tabs={['Facebook', 'Instagram', 'Twitter', 'LinkedIn', 'YouTube']}
                        icon={[FacebookIcon, InstagramIcon, TwiiterIcon, LinkedinIcon, YouTubeIcon]}
                        RowOneCurrentValue={['31321321', '879874', '656562', '898989', '589565']}
                        RowOnePreviousValue={['7987', '8922', '7897654', '23113213', '798541']}
                        RowOneTitle={['Paid', 'Paid', 'Paid', 'Paid', 'Paid']}
                        RowTwoCurrentValue={['789789', '78788', '21564', '8976', '8265']}
                        RowTwoPreviousValue={['23234', '456456', '7878', '567576', '256879']}
                        RowTwoTitle={['Organic', 'Organic', 'Organic', 'Organic', 'Organic']}
                        selected={this.state.selected}
                        setSelected={this.setSelected}
                    >

                        <Tab isSelected={this.state.selected === 'Overview'}>
                            <Overview />
                        </Tab>
                        <Tab isSelected={this.state.selected === 'Facebook'}>
                            <VideoFacebook />
                        </Tab>
                        <Tab isSelected={this.state.selected === 'Instagram'}>
                        </Tab>
                        <Tab isSelected={this.state.selected === 'Twitter'}>
                        </Tab>
                        <Tab isSelected={this.state.selected === 'LinkedIn'}>
                        </Tab>
                        <Tab isSelected={this.state.selected === 'YouTube'}>
                        </Tab>
                    </CardTabNav>
                </div>

            </Container>
        )
    }
}

