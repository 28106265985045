import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
am4core.addLicense("CH258896422");

export default class RiSalesPieChart extends Component {
    componentDidMount() {
        let info = this.props
        let card_id = info['card_id'];
        let data = info['graph-data'];
        let section1_color = info['section1_color'];
        let section2_color = info['section2_color'];
        let section3_color = info['section3_color'];
        let section4_color = info['section4_color'];
        let section5_color = info['section5_color'];

        console.log("cardname", card_id);
        console.log("data", data);

        let chart = am4core.create(card_id, am4charts.PieChart);

        // Add data
        chart.data = data

        // Add and configure Series
        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "name";
       

        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;
        pieSeries.ticks.template.disabled = true;

        // remove label
        pieSeries.alignLabels = false;
        pieSeries.labels.template.text = "{value.percent.formatNumber('#.0')}%";
        pieSeries.labels.template.radius = am4core.percent(-40);
        pieSeries.labels.template.fill = am4core.color("white");
        pieSeries.labels.template.fontSize = 11;

        pieSeries.alignLabels = false;
        pieSeries.labels.template.propertyFields.disabled = "disabled";
        pieSeries.ticks.template.propertyFields.disabled = "disabled";
        pieSeries.tooltip.getFillFromObject = false;
        pieSeries.tooltip.background.fill = am4core.color("#213345");
        pieSeries.tooltip.background.stroke = am4core.color("#213345");
        pieSeries.tooltip.label.fill = am4core.color("#ffffff");
        pieSeries.tooltip.label.fontSize = 12;


        // Remove padding
        chart.paddingBottom = 0;
        chart.paddingTop = 0;
        chart.paddingLeft = 0;
        chart.paddingRight = 0;

        pieSeries.colors.list = [
            am4core.color(section1_color),
            am4core.color(section2_color),
            am4core.color(section3_color),
            am4core.color(section4_color),
            am4core.color(section4_color),
            am4core.color(section5_color)
        ];

      }

    render() {
        let card_class = this.props.card_class;
        let card_id = this.props.card_id;

        return (
            <div>
                <div id={card_id} className={card_class}></div>
            </div>
        )
    }
}
