import React, { useState, useEffect, useMemo } from 'react';
import Table from 'react-bootstrap/Table'
import Wrapper from "../../../helpers/wrapper"
import TableHeader from '../datatable/TableHeader'
import TablePagination from "../datatable/TablePagination"
import IconDownload from '../../../../assets/images/icons/downloadIcon'
//import TableSearch from "./TableSearch"
import Col from 'react-bootstrap/Col';
import '../datatable/dataTable.scss';

const RiDataTable = (props) => {



    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [sorting, setSorting] = useState({ field: "", order: "" })

    const [search, setSearch] = useState("");

    const ITEMS_PER_PAGE = props.TableSize;

    const headers = props.Headers;
    const tableData = props.TableData;

    const headerCount = headers.length;

    const commentsData = useMemo(() => {
        let computedComments = tableData;

        if (search) {
            computedComments = computedComments.filter(
                comment =>
                    comment.title1.toString().includes(search.toLowerCase()) ||
                    comment.title2.toLowerCase().includes(search.toLowerCase()) ||
                    comment.title3.toLowerCase().includes(search.toLowerCase()) ||
                    comment.title4.toLowerCase().includes(search.toLowerCase()) ||
                    comment.title5.toLowerCase().includes(search.toLowerCase()) ||
                    comment.title6.toLowerCase().includes(search.toLowerCase()) ||
                    comment.title7.toLowerCase().includes(search.toLowerCase()) ||
                    comment.title8.toLowerCase().includes(search.toLowerCase()) ||
                    comment.title9.toLowerCase().includes(search.toLowerCase()) ||
                    comment.title10.toLowerCase().includes(search.toLowerCase()) ||
                    comment.title11.toLowerCase().includes(search.toLowerCase())
            )
        }
        setTotalItems(computedComments.length);

        // SORTING
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field]))
        }

        // CURRENT PAGE SLICE
        return computedComments.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [tableData, currentPage, search, sorting]);

    return (
        <Wrapper>
            <div className="icz-cardbody">
            <div className="icz-tableWrapper">
                <div className="w-100 icz-tablesectionheader">
                    <Col md={6} className="justify-content-start align-items-center">
                        <h2 className="icz-cardtitle">{props.tableTitle}</h2>
                    </Col>
                    <Col md={4} className="justify-content-end">
                        {/* <TableSearch
                            onSearch={
                                (value) => {
                                    setSearch(value);
                                    setCurrentPage(1)
                                }
                            }
                        /> */}
                    </Col>
                    <Col md={2} className="justify-content-end">
                        <div className="icz-cardbtnwrapper text-right">
                            <button className="icz-cardthemebtn">
                                <IconDownload className="icz-cardicon" /> </button>
                        </div>
                    </Col>
                </div>
                <Table className="icz-table table-striped table-responsive overflox-x">
                    <TableHeader
                        headers={headers}
                        onSorting={(field, order) =>
                            setSorting({ field, order })}
                    />
                    {totalItems !== 0 ?
                        <tbody className="icz-tableBody">
                            {commentsData.map(comment => (
                                <tr key={comment.id} className="icz-tr">
                                <td className="icz-td" scrop="row">{comment.title1}</td>
                                <td className="icz-td">{comment.title2}</td>
                                <td className="icz-td">{comment.title3}</td>
                                <td className="icz-td">{comment.title4}</td>
                                <td className="icz-td">{comment.title5}</td>
                                <td className="icz-td">{comment.title6}</td>
                                <td className="icz-td">{comment.title7}</td>
                                <td className="icz-td">{comment.title8}</td>
                                <td className="icz-td">{comment.title9}</td>
                                <td className="icz-td">{comment.title10}</td>
                                <td className="icz-td">{comment.title11}</td>
                            </tr>))}
                        </tbody>
                        :
                        <tbody className="icz-tableBody">
                            <td colSpan={headerCount} className="p-10 text-center">
                                <h2 className="icz-cardtitle">No Data Available</h2>
                            </td>
                        </tbody>
                    }
                </Table>
                <div className="w-100 d-flex flex-wrap">
                    <Col md={6} className="d-flex justify-content-start align-items-center">
                        {totalItems !== 0 ? <div className="">Page {currentPage} of {totalItems / ITEMS_PER_PAGE}</div> : ""}
                    </Col>
                    <Col md={6} className="d-flex justify-content-end">
                        {totalItems !== 0 ?
                            <TablePagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                                initialPage
                            />
                            : ""}
                    </Col>
                </div>
            </div>
            </div>
        </Wrapper>
    )
}

export default RiDataTable;
