import React, { useState, useEffect, useMemo } from "react"
import DataTable from '../../shared/components/datatable/DataTable'
import Wrapper from "../../helpers/wrapper";
import UseLoader from "../../shared/components/loader/useLoader"
import PieOfPieChart from "../../shared/components/charts/PieOfPieChart";
import {PAGESIZE} from "../../core/constants/common";
import { fitAngleToRange } from "@amcharts/amcharts4/.internal/core/utils/Math";

export const Dashboard = () => {

    const [comments, setComments] = useState([]);
    const [loader, showLoader, hideLoader] = UseLoader();

    const chartData= [{ "country": "Lithuania", "litres": 500, "subData": [{ "name": "A", "value": 200 }, { "name": "B", "value": 150 }, { "name": "C", "value": 100 }, { "name": "D", "value": 50 }] }, { "country": "Czech Republic", "litres": 300, "subData": [{ "name": "A", "value": 150 }, { "name": "B", "value": 100 }, { "name": "C", "value": 50 }] }, { "country": "Ireland", "litres": 200, "subData": [{ "name": "A", "value": 110 }, { "name": "B", "value": 60 }, { "name": "C", "value": 30 }] }, { "country": "Germany", "litres": 150, "subData": [{ "name": "A", "value": 80 }, { "name": "B", "value": 40 }, { "name": "C", "value": 30 }] }, { "country": "Australia", "litres": 140, "subData": [{ "name": "A", "value": 90 }, { "name": "B", "value": 40 }, { "name": "C", "value": 10 }] }, { "country": "Austria", "litres": 120, "subData": [{ "name": "A", "value": 60 }, { "name": "B", "value": 30 }, { "name": "C", "value": 30 }] }];

   

    useEffect(() => {
        const getData = () => {
            showLoader();

            fetch("https://jsonplaceholder.typicode.com/comments")
                .then(response => response.json())
                .then(json => {
                    hideLoader();
                    setComments(json);
                    console.log(json);
                });
        };
        getData();
    }, []);

    const headers = [
        { name: "No", field: "id", sortable: false },
        { name: "Name", field: "name", sortable: true },
        { name: "Email", field: "email", sortable: true },
        { name: "Comment", field: "body", sortable: true }
    ]

    return (
        <Wrapper>
            <h1>Dashboard RUNNING</h1>
           <DataTable Headers={headers} TableSize={PAGESIZE} TableData={comments}/> 
            
            <br />
            <br />
            <br />
            {/* <PieOfPieChart chart_id={"pieofpiechart"} chart_class={"icz-sectionchart"} graph-data={chartData}/> */}
        </Wrapper>
    )
}


// 