import React, { Component } from 'react';
import Select from 'react-select';
import axios from 'axios'

export class LocationDropdown extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dropdownData: [{ "id": 1, "name": "Andhra Pradesh" }, { "id": 2, "name": "Bihar" }, { "id": 3, "name": "Chhattisgarh"}, { "id": 4, "name": "Delhi" },  { "id": 5, "name": "Gujarat" }, { "id": 6, "name": "Haryana" }, { "id": 7, "name": "Jharkhand" }, { "id": 8, "name": "Karnataka" }
            , { "id": 9, "name": "Kerala" }, { "id": 10, "name": "Maharashtra" }, { "id": 11, "name": "Madhya Pradesh"}, { "id": 12, "name": "Manipur"}, { "id": 13, "name": "Odisha"}, { "id": 14, "name": "Punjab"}, { "id": 15, "name": "Rajasthan"}, { "id": 16, "name": "Telangana"}, { "id": 17, "name": "Tamil Nadu"}, { "id": 18, "name": "Uttar Pradesh"}, { "id": 19, "name": "West Bengal"}],
            selectOptions: [],
            id: "",
            name: ''
        }
    }

    getOptions() {
        // const res = await axios.get('https://jsonplaceholder.typicode.com/users')
        const data = this.state.dropdownData;

        const options = data.map(d => ({
            "value": d.id,
            "label": d.name

        }))

        this.setState({ selectOptions: options })

    }

    handleChange(e) {
        console.log(e)
        this.setState({ id: e.value, name: e.label })
    }

    componentDidMount() {
        this.getOptions()
    }


    render() {
        console.log(this.state.selectOptions)
        return (
            <div>
                <Select options={this.state.selectOptions} onChange={this.handleChange.bind(this)}  />
                
            </div>
        )
    }
}