import React, { Component } from 'react';
import GrowthChange from './growthchange';  

import GrowthIcon from '../../../../assets/images/icons/growthIcon';
import DeGrowthIcon from '../../../../assets/images/icons/degrowthIcon';
import NeutralIcon from '../../../../assets/images/icons/neutralIcon';

export default class PercentMetricChange extends Component {
    render() {
        
        let currentVal = this.props.CurrentValue == null ? 0 : this.props.CurrentValue ;
        let previousVal = this.props.PreviousValue == null ? 0 : this.props.PreviousValue ;

        let previous = parseFloat(previousVal);
        let current = parseFloat(currentVal);
        let standard = this.props.IsStandard;

        let result = ((current - previous) / previous) * 100;

        if (isNaN(result)) result = 0;

        let icon = <GrowthIcon className={"icz-icon"} />;
        let classstatus = 'positive';

        if (standard === true) {
            if (result > 0) {
                icon = <GrowthIcon className={"icz-icon"} />;
                classstatus = 'positive';
            } else if (result === 0) {
                icon = <NeutralIcon className={"icz-icon"} />;
                classstatus = 'neutral';
            } else {
                icon = <DeGrowthIcon className={"icz-icon"} />;
                classstatus = 'negative';
            }

        } else {
            if (result < 0) {
                icon = <DeGrowthIcon className={"icz-icon"} />;
                classstatus = 'negative';
            } else if (result === 0) {
                icon = <NeutralIcon className={"icz-icon"} />;
                classstatus = 'neutral';
            } else {
                icon = <GrowthIcon className={"icz-icon"} />;
                classstatus = 'positive';
            }
        };

        return (
            // console.log('results', result),
            <GrowthChange Icon={icon} Result={Math.abs(result).toFixed(2)} Class={classstatus} />
        )
    }
}
