import React, { Component } from "react"

export class Reviews extends Component {
    render() {
        return (
            <h1>Reviews RUNNING</h1>
        )
    }
}

