const IconViews = (props) => {
    return (

        <svg className={props.className} width="54" height="54" viewBox="0 0 54 54" fill="none" stroke="#486F88" xmlns="http://www.w3.org/2000/svg">
            <path d="M42 10.75H12C11.3096 10.75 10.75 11.3096 10.75 12V42C10.75 42.6904 11.3096 43.25 12 43.25H42C42.6904 43.25 43.25 42.6904 43.25 42V12C43.25 11.3096 42.6904 10.75 42 10.75Z" strokeWidth="1.5" />
            <path d="M11.448 19.862H42.552" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M15.678 17.295C16.571 17.295 17.295 16.571 17.295 15.678C17.295 14.7849 16.571 14.061 15.678 14.061C14.7849 14.061 14.061 14.7849 14.061 15.678C14.061 16.571 14.7849 17.295 15.678 17.295Z" fill="#486F88" />
            <path d="M19.46 17.295C20.3531 17.295 21.077 16.571 21.077 15.678C21.077 14.7849 20.3531 14.061 19.46 14.061C18.567 14.061 17.843 14.7849 17.843 15.678C17.843 16.571 18.567 17.295 19.46 17.295Z" fill="#486F88" />
            <path d="M23.467 17.295C24.36 17.295 25.084 16.571 25.084 15.678C25.084 14.7849 24.36 14.061 23.467 14.061C22.5739 14.061 21.85 14.7849 21.85 15.678C21.85 16.571 22.5739 17.295 23.467 17.295Z" fill="#486F88" />
            <path d="M19.041 31.924C19.041 31.924 22.067 27.005 27.361 27.005C32.655 27.005 35.681 31.924 35.681 31.924C35.681 31.924 32.655 36.843 27.361 36.843C22.067 36.843 19.041 31.924 19.041 31.924Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M29.63 31.924C29.63 32.3728 29.497 32.8115 29.2476 33.1846C28.9983 33.5578 28.644 33.8486 28.2294 34.0203C27.8147 34.192 27.3585 34.237 26.9184 34.1494C26.4782 34.0619 26.0739 33.8458 25.7566 33.5285C25.4393 33.2111 25.2232 32.8068 25.1356 32.3667C25.0481 31.9265 25.093 31.4703 25.2648 31.0557C25.4365 30.6411 25.7273 30.2867 26.1005 30.0374C26.4736 29.7881 26.9123 29.655 27.361 29.655C27.9628 29.655 28.5399 29.8941 28.9655 30.3196C29.391 30.7451 29.63 31.3223 29.63 31.924V31.924Z" fill="#486F88" />
        </svg>

    )
}

export default IconViews;