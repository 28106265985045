const IconCTC = (props) => {
    return (
        <svg className={props.className} width="54" height="54" viewBox="0 0 54 54" fill="none" stroke="#486F88" xmlns="http://www.w3.org/2000/svg">
            <path d="M41.035 19H12.005L23.617 32.731V42.224L29.423 45.124V32.724L41.035 19Z" stroke="#486F88" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17 14C18.6569 14 20 12.6569 20 11C20 9.34315 18.6569 8 17 8C15.3431 8 14 9.34315 14 11C14 12.6569 15.3431 14 17 14Z" fill="white"/>
<path d="M17 13.25C18.2426 13.25 19.25 12.2426 19.25 11C19.25 9.75736 18.2426 8.75 17 8.75C15.7574 8.75 14.75 9.75736 14.75 11C14.75 12.2426 15.7574 13.25 17 13.25Z" stroke="#486F88" strokeWidth="1.5"/>
<path d="M37 14C38.6569 14 40 12.6569 40 11C40 9.34315 38.6569 8 37 8C35.3431 8 34 9.34315 34 11C34 12.6569 35.3431 14 37 14Z" fill="white"/>
<path d="M37 13.25C38.2426 13.25 39.25 12.2426 39.25 11C39.25 9.75736 38.2426 8.75 37 8.75C35.7574 8.75 34.75 9.75736 34.75 11C34.75 12.2426 35.7574 13.25 37 13.25Z" stroke="#486F88" strokeWidth="1.5"/>
<path d="M27 17C28.6569 17 30 15.6569 30 14C30 12.3431 28.6569 11 27 11C25.3431 11 24 12.3431 24 14C24 15.6569 25.3431 17 27 17Z" fill="white"/>
<path d="M27 16.25C28.2426 16.25 29.25 15.2426 29.25 14C29.25 12.7574 28.2426 11.75 27 11.75C25.7574 11.75 24.75 12.7574 24.75 14C24.75 15.2426 25.7574 16.25 27 16.25Z" stroke="#486F88" strokeWidth="1.5"/>
        </svg>
    )
}

export default IconCTC;