import React, { Component } from 'react'
import Col from "react-bootstrap/esm/Col";

import Wrapper from "../../../helpers/wrapper";

import { TwitterPostOverall } from "./twitterPostOverall";
import { TwitterPostOrganic } from "./twitterPostOrganic";
import { TwitterPostPaid } from "./twitterPostPaid";
import {Dropdown} from "../../../shared/components/dropdown/dropdown"

export class TwitterPost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdown_options: [{ "value": "all", "label": "All" }, { "value": "tweets", "label": "Tweets" }, { "value": "poll", "label": "Poll" }, { "value": "video", "label": "Video" }, { "value": "media", "label": "Media" } ],
            postsubnav: 'Overall',
            search: '',
            setSearch: '',

        }

    }


    render() {
        const updateState = (navtab) => {
            this.setState({
                postsubnav: navtab
            })

        }

        // TOGGLE TABS SECTION
        const subnav = ["Overall", "Organic", "Paid"];
        console.log('this.state.navtab', this.state.postsubnav);

        return (
            <Wrapper>
                <div className="icz-row">
                    <div className="icz-sectiontabcontainer">
                      <Col lg={12} className="d-flex">
                          <Col lg={10} className="">
                          <ul className="btn-group icz-tabgroup" role="group">
                            {subnav.map(navtab => (
                                <li
                                    type="button"
                                    key={navtab}
                                    className={this.state.postsubnav == navtab ? "btn icz-tabbtn active": "btn icz-tabbtn" }
                                    onClick={() => updateState(navtab)}>
                                    {navtab}
                                </li>
                            ))}
                        </ul>
                          </Col>
                            <Col lg={2}>
                            <Dropdown dropdown_options={this.state.dropdown_options} dropdown_placeholder="All"  parentCallback={this.handleCallback}/>  
                            </Col>
                      
                      </Col>
                    </div>
                    <Col xs={12} className="overflox-x-hidden">
                        {this.state.postsubnav == "Overall" && (
                            <TwitterPostOverall />
                        )}
                        {this.state.postsubnav == "Organic" && (
                            <TwitterPostOrganic />
                        )}
                        {this.state.postsubnav == "Paid" && (
                            <TwitterPostPaid />
                        )}
                    </Col>
                </div>
            </Wrapper>
        )
    }
}
