import React, { Component } from "react"

export class Hashtags extends Component {
    render() {
        return (
            <h1>HASHTAGS RUNNING</h1>
        )
    }
}

