const IconLikesGrowth = (props) => {
    return (

        <svg className={props.className} width="54" height="54" viewBox="0 0 54 54" fill="none" stroke="#486F88" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.417 43.667H13.367C12.9248 43.667 12.487 43.5799 12.0785 43.4107C11.67 43.2415 11.2988 42.9935 10.9862 42.6808C10.6735 42.3682 10.4255 41.997 10.2563 41.5885C10.0871 41.18 10 40.7422 10 40.3V28.517C10 27.624 10.3547 26.7676 10.9862 26.1362C11.6176 25.5047 12.474 25.15 13.367 25.15H18.417M30.2 21.783V15.05C30.2 13.7107 29.6679 12.4262 28.7209 11.4791C27.7738 10.5321 26.4893 10 25.15 10L18.417 25.15V43.667H37.4C38.2134 43.677 39.003 43.3921 39.6227 42.8651C40.2423 42.338 40.6503 41.6045 40.771 40.8L43.094 25.65C43.1672 25.1674 43.1347 24.6747 42.9986 24.206C42.8625 23.7373 42.6261 23.3037 42.3059 22.9354C41.9856 22.5671 41.5891 22.2728 41.1438 22.073C40.6985 21.8731 40.2151 21.7725 39.727 21.778L30.2 21.783Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M29.906 37.186V29.358" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25.992 33.272L29.906 29.358L33.82 33.272" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>


    )
}

export default IconLikesGrowth;