const IconLike = (props) => {
    return (

        <svg className={props.className} width="54" height="54" viewBox="0 0 54 54" fill="none" stroke="#486F88" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.84 42.666H15.29C14.4856 42.666 13.7142 42.3465 13.1454 41.7777C12.5766 41.2089 12.257 40.4374 12.257 39.633V29.016C12.257 28.2116 12.5766 27.4402 13.1454 26.8714C13.7142 26.3026 14.4856 25.983 15.29 25.983H19.84M30.457 22.95V16.883C30.457 15.6763 29.9776 14.519 29.1244 13.6657C28.2711 12.8124 27.1138 12.333 25.907 12.333L19.84 25.983V42.666H36.948C37.6795 42.6743 38.3893 42.4179 38.9466 41.9442C39.504 41.4704 39.8713 40.8112 39.981 40.088L42.075 26.433C42.141 25.9983 42.1117 25.5545 41.9891 25.1322C41.8665 24.71 41.6536 24.3194 41.3651 23.9876C41.0766 23.6559 40.7194 23.3908 40.3183 23.2107C39.9171 23.0307 39.4817 22.94 39.042 22.945L30.457 22.95Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>


    )
}

export default IconLike;