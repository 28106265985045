import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.addLicense("CH258896422");

export default class DatedbasedBubbleChart extends Component {
    componentDidMount() {
        let info = this.props

        let card_id = info['card_id'];
        let title1 = info['metric_1']
        let title2 = info['metric_2']

        console.log("cardname", card_id);


        let chart = am4core.create(card_id, am4charts.XYChart);

        // Add data
        chart.data = [{
            "date": "2015-01-01",
            "ay": 6.5,
            "aValue": 15,

        }, {
            "date": "2015-01-02",
            "ay": 12.3,
            "aValue": 8,

        }, {
            "date": "2015-01-03",
            "ay": 12.3,
            "aValue": 16,
        }, {
            "date": "2015-01-04",
            "ay": 2.8,
            "aValue": 9,
        }, {
            "date": "2015-01-05",
            "ay": 3.5,
            "aValue": 5,
        }, {
            "date": "2015-01-06",
            "ay": 5.1,
            "aValue": 10,
        }, {
            "date": "2015-01-07",
            "ay": 6.7,
            "aValue": 3,
        }, {
            "date": "2015-01-08",
            "ay": 8,
            "aValue": 5,
        }, {
            "date": "2015-01-09",
            "ay": 8.9,
            "aValue": 8,
        }, {
            "date": "2015-01-10",
            "ay": 9.7,
            "aValue": 15,
        }, {
            "date": "2015-01-11",
            "ay": 10.4,
            "aValue": 1,
        }, {
            "date": "2015-01-12",
            "ay": 1.7,
            "aValue": 12,
        }];
        chart.fontFamily = "Ubuntu";//TO CHANGE FONT SIZE OF CHART LABELS
        chart.fontSize = 12;//TO CHANGE FONT SIZE OF CHART LABELS
        // Create axes
        let xAxis = chart.xAxes.push(new am4charts.DateAxis());
        xAxis.dataFields.category = "category";
        xAxis.renderer.grid.template.location = 0;
        xAxis.title.text = title1; 

        let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        xAxis.renderer.grid.template.disabled = true; //vline
        yAxis.renderer.grid.template.disabled = true; //hline

        xAxis.cursorTooltipEnabled = false;
        yAxis.cursorTooltipEnabled = false;
        yAxis.title.text = title2;
        // Create series
        let series1 = chart.series.push(new am4charts.LineSeries());
        series1.dataFields.valueY = "ay";
        series1.dataFields.dateX = "date";
        series1.dataFields.value = "aValue";
        series1.strokeOpacity = 0;
        series1.cursorTooltipEnabled = false;
       

        let bullet1 = series1.bullets.push(new am4charts.CircleBullet());
        bullet1.tooltipText = "{date} \n Transactions:{valueY}";
        series1.tooltip.getFillFromObject = false;
        series1.tooltip.background.fill = am4core.color("#213345");
        series1.tooltip.background.stroke = am4core.color("#213345");
        series1.tooltip.background.cornerRadius = 5;
        series1.tooltip.label.minWidth = 150;
        series1.tooltip.label.padding(10, 10, 10, 10);
        series1.tooltip.label.fontSize = 12;
        // bullet1.tooltip.position = "pointer";
        series1.heatRules.push({
            target: bullet1.circle,
            min: 10,
            max: 20,
            property: "radius"
        });


        // Add scrollbars
        // chart.scrollbarX = new am4core.Scrollbar();
        // chart.scrollbarY = new am4core.Scrollbar();

        // Add cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = "zoomXY";

    }
    render() {
        let card_class = this.props.card_class;
        let card_id = this.props.card_id;
        return (
            <div>
                <div id={card_id} className={card_class}></div>
            </div>
        )
    }
}
