import React, { Component } from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);
am4core.addLicense("CH258896422");

export default class ColumnLineChart extends Component {
    componentDidMount() {
        let info = this.props
        let title1 = info['metric_1']
        let title2 = info['metric_2']
        let chart_id = info['chart_id'];
        let data = info['graph-data'];

        let chart = am4core.create(chart_id, am4charts.XYChart);
        chart.fontFamily = "Ubuntu"; //TO CHANGE FONT SIZE OF CHART LABELS
        chart.fontSize = 12; //TO CHANGE FONT SIZE OF CHART LABELS

        chart.data = data;

        chart.colors.list = [
            am4core.color("#11A1FD"),
            am4core.color("#FF9931"),
        ];

        /* Create axes */
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "year";
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.line.strokeOpacity = 0.2; // for reducing the x axis opacity 
        categoryAxis.renderer.line.stroke = am4core.color("#707070"); // for changing the x axis color
        categoryAxis.renderer.labels.template.fill = "#757575"; //TO CHANGE COLOR OF X AXIS LABELS
        categoryAxis.tooltip.disabled = true; // for disabling to the x axis tooltip
        categoryAxis.startLocation = 0.2;
        categoryAxis.endLocation = 0.8;
        
        
        let valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis1.tooltip.disabled = true; 
        valueAxis1.min = 0;
        valueAxis1.renderer.line.strokeOpacity = 0.2; // for reducing the Y axis opacity 
        valueAxis1.renderer.line.stroke = am4core.color("#707070"); // for changing the Y axis color
        valueAxis1.renderer.labels.template.fill = "#757575"; //TO CHANGE COLOR OF Y AXIS LABELS
        valueAxis1.renderer.line.strokeOpacity = 0.2; // for reducing the Y axis opacity 

        let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis2.renderer.opposite = true;
        valueAxis2.syncWithAxis = valueAxis1;
        valueAxis2.tooltip.disabled = true; 
        valueAxis2.renderer.line.strokeOpacity = 0.2; // for reducing the Y axis opacity 
        valueAxis2.renderer.line.stroke = am4core.color("#707070"); // for changing the Y axis color
        valueAxis2.renderer.labels.template.fill = "#757575"; //TO CHANGE COLOR OF Y AXIS LABELS
        valueAxis2.renderer.line.strokeOpacity = 0.2; // for reducing the Y axis opacity 

        /* Create series */
        let columnSeries = chart.series.push(new am4charts.ColumnSeries());
        columnSeries.name = title1;
        columnSeries.dataFields.valueY = "count";
        columnSeries.dataFields.categoryX = "year";
        columnSeries.yAxis = valueAxis1;
        columnSeries.columns.template.tooltipText = "{name} in {categoryX}: {valueY}"
        columnSeries.tooltip.label.textAlign = "middle";
        columnSeries.columns.template.width = 5;
        columnSeries.columns.template.column.cornerRadiusTopLeft = 6;
        columnSeries.columns.template.column.cornerRadiusTopRight = 6;
        columnSeries.strokeWidth = .6;
        columnSeries.strokeOpacity = 0;
        columnSeries.tooltip.getFillFromObject = false;
        columnSeries.tooltip.background.fill = am4core.color("#213345");
        columnSeries.tooltip.background.cornerRadius = 5;
        columnSeries.tooltip.label.padding(10, 10, 10, 10);
        columnSeries.tooltip.label.fontSize = 12;
        columnSeries.tooltip.pointerOrientation = "vertical";
        columnSeries.tooltip.label.minWidth = 150;
        var shadow = columnSeries.tooltip.background.filters.getIndex(0);
        shadow.dx = 3;
        shadow.dy = 10;
        shadow.blur = 10;
        shadow.color = am4core.color("#14567829");

        let lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.name = title2;
        lineSeries.dataFields.valueY = "type";
        lineSeries.dataFields.categoryX = "year";
        lineSeries.yAxis = valueAxis2;
        lineSeries.strokeWidth = 2;
        lineSeries.propertyFields.strokeDasharray = "lineDash";
        lineSeries.tooltip.label.textAlign = "middle";
        lineSeries.tensionX = 0.8;
        lineSeries.tooltip.getFillFromObject = false;
        lineSeries.tooltip.background.fill = am4core.color("#213345");
        lineSeries.tooltip.background.cornerRadius = 5;
        lineSeries.tooltip.label.padding(10, 10, 10, 10);
        lineSeries.tooltip.label.fontSize = 12;
        lineSeries.tooltip.pointerOrientation = "vertical";
        lineSeries.tooltip.label.minWidth = 150;
        var shadow = lineSeries.tooltip.background.filters.getIndex(0);
        shadow.dx = 3;
        shadow.dy = 10;
        shadow.blur = 10;
        shadow.color = am4core.color("#14567829");

        let bullet = lineSeries.bullets.push(new am4charts.Bullet());
        bullet.fill = am4core.color("#FF9931"); // tooltips grab fill from parent by default
        bullet.tooltipText = "{name} in {categoryX}: {valueY}"
        let circle = bullet.createChild(am4core.Circle);
        circle.radius = 4;
        circle.fill = am4core.color("#fff");
        circle.strokeWidth = 2;

        // Add chart cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = "zoomY";

        // Add legend
        chart.legend = new am4charts.Legend();
        chart.legend.position = "bottom";
        chart.legend.useDefaultMarker = true;
        chart.legend.labels.template.text = "{name}";
        chart.legend.labels.template.fill = "#213345";
        chart.legend.fill = "rgba(112, 112, 112, 0.1)";
        chart.legend.opacity = 1;
        var markerTemplate = chart.legend.markers.template;
        let marker = chart.legend.markers.template.children.getIndex(0);
        marker.cornerRadius(12, 12, 12, 12);
        markerTemplate.width = 13;
        markerTemplate.height = 13;

        chart.padding(10, 0, 0, 0);
    }

    render() {
        let chart_class = this.props.chart_class;
        let chart_id = this.props.chart_id;

        return (
            <div id={chart_id} className={chart_class}></div>
        )
    }
}
