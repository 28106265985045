import React, { Component } from 'react';
import Wrapper from '../../../helpers/wrapper';
import '../../../modules/social/social.scss';
import { RiTabCard } from '../cards/ritabcard/ritabcard';

export class RiTabCardNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.selected
        }
    }

    render() {
        return (
            <Wrapper>
                <div className="icz-carouselcontainer d-flex flex-row">
                    {
                        this.props.tabs.map((tab, index) => {
                            const active = (tab === this.props.selected ? " active" : '');
                            return (
                                <RiTabCard
                                    IsNumber={false}
                                    CardClass={
                                        // this.props.isDisabled[index] + 
                                        ` icz-ritabcardwrapper` + active}
                                    key={tab}
                                    CardTitle={tab}
                                    CardScore={this.props.CardScore[index]}
                                    Chart={this.props.Chart[index]}
                                    ChartBottom={this.props.ChartBottom[index]}
                                    DataMetrics={this.props.DataMetrics[index]}
                                    onClick={() => this.props.setSelected(tab)} />
                            )
                        })
                    }
                </div>

                {this.props.children}

            </Wrapper>
        );
    }
}
