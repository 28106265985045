import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
am4core.addLicense("CH258896422");

export default class RiSalesVBarChart extends Component {
    componentDidMount() {
        let info = this.props
        let title1 = info['metric_1']
        let titleX = info['Xtitle']
        let titleY = info['Ytitle']

        // let card_name = info['name'];
        let chart_id = info['chart_id'];
        let data = info['graph-data'];
        let bar_color = info['bar_color'];


        let chart = am4core.create(chart_id, am4charts.XYChart);
        chart.fontFamily = "Ubuntu";//TO CHANGE FONT SIZE OF CHART LABELS
        chart.fontSize = 12;//TO CHANGE FONT SIZE OF CHART LABELS
        chart.data = data;

        chart.colors.list = [
            am4core.color(bar_color)
        ];

        chart.data = data;

        // Create axes
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.title.text = titleX;
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.baseGrid.disabled = true;
        categoryAxis.renderer.grid.template.strokeWidth = 0;
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.minGridDistance = 1;
        categoryAxis.renderer.inside = false;
        categoryAxis.renderer.labels.template.fill = am4core.color("#757575");
        categoryAxis.renderer.labels.template.fontSize = 12;
        categoryAxis.renderer.cellStartLocation = 0.3;
        categoryAxis.renderer.cellEndLocation = 0.5;
        categoryAxis.tooltip.disabled = true; // for disabling to the y axis tooltip

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.title.text = titleY;
        valueAxis.renderer.labels.template.fill = am4core.color("#757575");
        valueAxis.renderer.line.strokeOpacity = 0.2; // for reducing the Y axis opacity 
        valueAxis.renderer.line.stroke = am4core.color("#707070"); // for changing the Y axis color
        valueAxis.renderer.labels.template.fontSize = 12;
        valueAxis.tooltip.disabled = true; // for disabling to the y axis tooltip
        valueAxis.renderer.grid.template.location = 0;
        valueAxis.renderer.minGridDistance = 30;


        // Create series
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "value";
        series.dataFields.categoryX = "category";
        series.dataFields.customtooltiptext = "customtooltiptext";
        series.name = title1;
        series.columns.template.tooltipText = "{customtooltiptext}: [bold]{valueY}[/]";
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color("#213345");
        series.tooltip.background.stroke = am4core.color("#213345");
        series.tooltip.background.cornerRadius = 5;
        series.tooltip.label.fontSize = 12;
        series.tooltip.position = "pointer";
        var shadow = series.tooltip.background.filters.getIndex(0);
        shadow.dx = 3;
        shadow.dy = 10;
        shadow.blur = 10;
        shadow.color = am4core.color("#14567829");
        

        let columnTemplate = series.columns.template;
        columnTemplate.width = 5;
        columnTemplate.column.cornerRadiusTopLeft = 20;
        columnTemplate.column.cornerRadiusTopRight = 20;
        columnTemplate.strokeWidth = .6;
        columnTemplate.strokeOpacity = 0;

        // Add chart cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = "zoomY";

        // LEGEND
        chart.legend = new am4charts.Legend();
        chart.legend.useDefaultMarker = true;
        chart.legend.labels.template.text = "{name}";
        chart.legend.labels.template.fill = "#213345";
        chart.legend.fill = "rgba(112, 112, 112, 0.1)";
        chart.legend.opacity = 1;
        var markerTemplate = chart.legend.markers.template;
        let marker = chart.legend.markers.template.children.getIndex(0);
        marker.cornerRadius(12, 12, 12, 12);
        markerTemplate.width = 13;
        markerTemplate.height = 13;

        // Remove padding
        chart.paddingBottom = 10;
        chart.paddingTop = 10;
        chart.paddingLeft = 0;
        chart.paddingRight = 0;

    }

    render() {
        let chart_class = this.props.chart_class;
        let chart_id = this.props.chart_id;

        return (
            <div id={chart_id} className={chart_class}></div>
            // <div id="chartdiv"></div>
        )
    }
}
