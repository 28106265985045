const IconLaptop = (props) => {
    return (
        <svg className={props.className} xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#486f88" width="54" height="54" viewBox="0 0 54 54">
            <g id="Group_532" data-name="Group 532" transform="translate(-294 -714)">

                <g id="Group_457" data-name="Group 457" transform="translate(1.4 0.4)">
                    <g id="Group_456" data-name="Group 456" transform="translate(301 726)">
                        <g id="Path_279" data-name="Path 279" transform="translate(3.6 1.6)">
                            <path d="M2.657,0H28.343A2.627,2.627,0,0,1,31,2.6V20.761H0V2.6A2.627,2.627,0,0,1,2.657,0Z"/>
                            <path d="M 2.657150268554688 1.499996185302734 C 2.019100189208984 1.499996185302734 1.5 1.991266250610352 1.5 2.595127105712891 L 1.5 19.26105690002441 L 29.50006103515625 19.26105690002441 L 29.50006103515625 2.595127105712891 C 29.50006103515625 1.991266250610352 28.98096084594727 1.499996185302734 28.34291076660156 1.499996185302734 L 2.657150268554688 1.499996185302734 M 2.657150268554688 -3.814697265625e-06 L 28.34291076660156 -3.814697265625e-06 C 29.81040954589844 -3.814697265625e-06 31.00006103515625 1.161876678466797 31.00006103515625 2.595127105712891 L 31.00006103515625 20.76105690002441 L 0 20.76105690002441 L 0 2.595127105712891 C 0 1.161876678466797 1.189640045166016 -3.814697265625e-06 2.657150268554688 -3.814697265625e-06 Z" />
                        </g>
                        <g id="Rectangle_217" data-name="Rectangle 217" transform="translate(-0.4 20.976)" strokeWidth="1.5">
                            <path d="M0,0H39a0,0,0,0,1,0,0V4a3,3,0,0,1-3,3H3A3,3,0,0,1,0,4V0A0,0,0,0,1,0,0Z" stroke="none" />
                            <path d="M1.5.75h36a.75.75,0,0,1,.75.75V4A2.25,2.25,0,0,1,36,6.25H3A2.25,2.25,0,0,1,.75,4V1.5A.75.75,0,0,1,1.5.75Z" fill="none" />
                        </g>
                    </g>
                    <line id="Line_106" data-name="Line 106" x2="6.522" transform="translate(317.305 750.69)" strokeLinecap="round" strokeWidth="1.5" />
                </g>
            </g>
        </svg>




    )
}

export default IconLaptop;