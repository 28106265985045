// export const API_URL = 'http://127.0.0.1:8000';
export const API_URL = 'https://api2.icogz.com';

export const LOADERTEXT = "Adding Intelligence To Your Data!";

export const PAGEGROWTH = [{"id": 1, "value": "Likes"}, {"id": 2, "value": "Followers"}];

export const CURRENT_DATE_RANGE_FROM_DATE = '2021-10-01';
export const CURRENT_DATE_RANGE_TO_DATE = '2021-12-31';



export const CountryOrigin = "IN";







