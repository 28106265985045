const IconConversion = (props) => {
    return (
        <svg className={props.className} width="54" height="54" viewBox="0 0 54 54" fill="none" stroke="#486F88" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.38 25.559C24.801 25.559 24.258 25.559 23.715 25.559C23.5161 25.5667 23.3223 25.4951 23.1762 25.3599C23.0301 25.2246 22.9437 25.0369 22.936 24.838C22.9283 24.6391 22.9999 24.4453 23.1352 24.2992C23.2704 24.1531 23.4581 24.0667 23.657 24.059C24.8463 24.059 26.0357 24.059 27.225 24.059C27.4112 24.0638 27.5885 24.1398 27.7203 24.2713C27.8522 24.4028 27.9287 24.5798 27.934 24.766C27.938 25.9554 27.938 27.1447 27.934 28.334C27.9241 28.5252 27.8412 28.7053 27.7024 28.8371C27.5635 28.9689 27.3794 29.0424 27.188 29.0424C26.9966 29.0424 26.8124 28.9689 26.6736 28.8371C26.5348 28.7053 26.4519 28.5252 26.442 28.334C26.437 27.815 26.442 27.295 26.442 26.776C26.4445 26.7253 26.4324 26.675 26.407 26.631C26.369 26.677 26.335 26.731 26.293 26.768L21.829 31.232C21.7571 31.3306 21.6629 31.4108 21.5541 31.466C21.4453 31.5213 21.325 31.5501 21.203 31.5501C21.081 31.5501 20.9607 31.5213 20.8519 31.466C20.7431 31.4108 20.6489 31.3306 20.577 31.232C20.077 30.732 19.569 30.232 19.07 29.72C18.986 29.634 18.942 29.631 18.854 29.72C17.5167 31.0607 16.1773 32.401 14.836 33.741C14.7391 33.8605 14.6094 33.9491 14.4628 33.9956C14.3161 34.0422 14.1591 34.0448 14.011 34.003C13.8936 33.969 13.7864 33.9067 13.6988 33.8215C13.6112 33.7363 13.5458 33.6309 13.5086 33.5145C13.4713 33.3981 13.4633 33.2743 13.4852 33.1541C13.507 33.0339 13.5581 32.9208 13.634 32.825C13.6795 32.7689 13.7282 32.7155 13.78 32.665L18.343 28.103C18.4148 28.006 18.5083 27.9272 18.6161 27.8729C18.7238 27.8186 18.8428 27.7903 18.9635 27.7903C19.0842 27.7903 19.2032 27.8186 19.3109 27.8729C19.4187 27.9272 19.5122 28.006 19.584 28.103C20.0947 28.6137 20.6047 29.1244 21.114 29.635C21.172 29.694 21.207 29.726 21.284 29.648C22.637 28.295 23.993 26.94 25.38 25.559Z" fill="#486F88" />
            <path d="M23.156 33.781V43L29.437 46.375V33.656L41.093 20H11.999L17.249 26.344" stroke="#486F88" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.937 14C17.5939 14 18.937 12.6569 18.937 11C18.937 9.34315 17.5939 8 15.937 8C14.2802 8 12.937 9.34315 12.937 11C12.937 12.6569 14.2802 14 15.937 14Z" fill="white" />
            <path d="M15.937 13.25C17.1797 13.25 18.187 12.2426 18.187 11C18.187 9.75736 17.1797 8.75 15.937 8.75C14.6944 8.75 13.687 9.75736 13.687 11C13.687 12.2426 14.6944 13.25 15.937 13.25Z" stroke="#486F88" strokeWidth="1.5" />
            <path d="M35.937 14C37.5939 14 38.937 12.6569 38.937 11C38.937 9.34315 37.5939 8 35.937 8C34.2802 8 32.937 9.34315 32.937 11C32.937 12.6569 34.2802 14 35.937 14Z" fill="white" />
            <path d="M35.937 13.25C37.1797 13.25 38.187 12.2426 38.187 11C38.187 9.75736 37.1797 8.75 35.937 8.75C34.6944 8.75 33.687 9.75736 33.687 11C33.687 12.2426 34.6944 13.25 35.937 13.25Z" stroke="#486F88" strokeWidth="1.5" />
            <path d="M25.937 17C27.5939 17 28.937 15.6569 28.937 14C28.937 12.3431 27.5939 11 25.937 11C24.2802 11 22.937 12.3431 22.937 14C22.937 15.6569 24.2802 17 25.937 17Z" fill="white" />
            <path d="M25.937 16.25C27.1797 16.25 28.187 15.2426 28.187 14C28.187 12.7574 27.1797 11.75 25.937 11.75C24.6944 11.75 23.687 12.7574 23.687 14C23.687 15.2426 24.6944 16.25 25.937 16.25Z" stroke="#486F88" strokeWidth="1.5" />
        </svg>
    )
}

export default IconConversion;