import React, { Component } from 'react';
import { Col, Container } from 'react-bootstrap';
import "../../ecom/ecom.scss";
import Piechart from '../../../shared/components/charts/Piechart';
import Linegraph_sparkline from '../../../shared/components/charts/Linegraph_sparkline';
import { ChartCard } from '../../../shared/components/cards/chartcard/chartcard'
import { Carousel } from '../../../shared/components/carousel/carousel';
import PageHeader from '../../../layout/pageheader/pageheader';
import '../../../shared/components/cards/card.scss';
import Wrapper from '../../../helpers/wrapper';
import IconWebsite from '../.././../../assets/images/icons/websiteIcon';
import { Link } from 'react-router-dom'
import { DailydataDropdown } from '../../../shared/components/dropdown/dailydatadropdown';
import IconFacebook from '../../../../assets/images/icons/facebookIcon';
import IconTwitter from '../../../../assets/images/icons/twitterIcon'
import IconInstagram from '../../../../assets/images/icons/instagramIcon';
import IconImpressions from '../../../../assets/images/icons/impressionsIcon';
import IconGoogle from '../../../../assets/images/icons/googleIcon'
import DataTable from '../../../shared/components/datatable/DataTable';

import { NumberFormatter } from "../../../shared/utilities/numberformatter";
import MultiLine_LineChart from '../../../shared/components/charts/MultiLine_LineChart';
import Sunbrust_chart from '../../../shared/components/charts/Sunbrust_chart';
import { LocationBarChart } from '../../../shared/components/charts/LocationBarChart';
import { PieChart } from '@amcharts/amcharts4/charts';

const API_URL = 'http://127.0.0.1:8000';

export default class Paid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // dropdown_options: [{ "value": "impression", "label": "Impression" }, { "value": "clicks", "label": "Clicks" }, { "value": "spends", "label": "Spends" },],
            datatabledropdown_options: [{ "value": "Facebook", "label": "Facebook" }, { "value": "Instagram", "label": "Instagram" }, { "value": "Twitter", "label": "Twitter" }, { "value": "Linkedin", "label": "Linkedin" }, { "value": "Youtube", "label": "Youtube" }, { "value": "Instagram", "Instagram": "User type" }],
            trafficdropdown_options: [{ "value": "traffic_channels", "label": "Traffic Channels" }, { "value": "Category", "label": "Category" }],
            trafficdropdown_value:"traffic_channels",
            sunbrustdropdown_options:[{"value": "Sessions", "label": "Sessions" },{"value":"Transactions","label":"Transactions"}],
            sunbrustdropdown_value:"Sessions",
            sunburstChartData: [{"name":"First","children":[{"name":"A1","value":100},{"name":"A2","value":60}]},{"name":"Second","children":[{"name":"B1","value":135},{"name":"B2","value":98}]}],
            paidsocial: [{ facebook: 3035, google: 2680, instagram: 158, linkedin: 3216, twitter: 78, year: "2021-03-01" }, { facebook: 1035, google: 2680, instagram: 2158, linkedin: 2216, twitter: 2078, year: "2021-03-02" }, { facebook: 1035, google: 1680, instagram: 1158, linkedin: 1216, twitter: 1078, year: "2021-03-03" }, { facebook: 8035, google: 8680, instagram: 5158, linkedin: 5216, twitter: 7078, year: "2021-03-04" }],
            trafficpievalue: [{ "name": "Social", "value": 30 }, { "name": "Direct", "value": 30 }, { "name": "Website", "value": 50 }, { "name": "Email", "value": 40 }, { "name": "Organic", "value": 60 }],
            linesparkgraph: [{ "date": "2012-07-27", "value": 13 }, { "date": "2012-07-28", "value": 10 }, { "date": "2012-07-29", "value": 15 }, { "date": "2012-07-30", "value": 15 }, { "date": "2012-07-31", "value": 15 }, { "date": "2012-08-01", "value": 15 }, { "date": "2012-08-02", "value": 15 }, { "date": "2012-08-03", "value": 19 }, { "date": "2012-08-04", "value": 16 }, { "date": "2012-08-05", "value": 15 }, { "date": "2012-08-06", "value": 19 }, { "date": "2012-08-07", "value": 17 }, { "date": "2012-08-08", "value": 18 }],
            locationdata: [{ "name": "Top-10", "points": 100 }, { "name": "10-20", "points": 200 }, { "name": "20-30", "points": 110 }, { "name": "30-40", "points": 100 }, { "name": "40-50", "points": 100 }, { "name": "50-100", "points": 100 }],
            scroll: '',
            visibility: false,
            setShow: '',
            isActive: false,
            show: false,

            source_dropdown_options: [{ "value": "impression", "label": "Impression" }, { "value": "click", "label": "Clicks" }, { "value": "spends", "label": "Spends" } ],
            source_metrices_dropdown_value: "impression",
            icon_class: {
                "Google": <IconGoogle className="icz-icon" />,
                "Facebook": <IconFacebook className="icz-icon" />,
                "Instagram": <IconInstagram className="icz-icon" />,
                "Linkedin": <IconInstagram className="icz-icon" />,
                "Twitter": <IconTwitter className="icz-icon" />
            },


            source_bar_dropdown_options: [{ "value": "sessions", "label": "Sessions" }, { "value": "transactions", "label": "Transactions" } ],
            source_bar_dropdown_value: "sessions",
            

            action_short_code: {
                "impression": "CTR",
                "click": "CPC",
                "spends": "Conversions"
            },
            total_source: 0,
            final_calc: 0,

            source_column_name: {
                "impression": "impressions",
                "click": "clicks",
                "spends": "spends"
                
            },

            //piedata: [{ "name": "Top-10", "points": 100 }, { "name": "10-20", "points": 200 }, { "name": "20-30", "points": 110 }, { "name": "30-40", "points": 100 }, { "name": "40-50", "points": 100 }, { "name": "50-100", "points": 100 }],

            sunbrustchart: [{"name": "Mobile", "value": 100 }, {"name" : "Desktop", "value" : 200}, {"name" : "Tablet", "value" :170}],
            


           // sunbrustchart: [{ "name": "Mobile", "children": [{ "name": "Android", "value": 100 }, { "name": "IOS", "value": 60 }] }, { "name": "Desktop", "children": [{ "name": "Android", "value": 100 }, { "name": "IOS", "value": 60 }] }, { "name": "Tablet", "children": [{ "name": "Android", "value": 100 }, { "name": "IOS", "value": 160 }] }],
            events: [{ name: "Channel Grouping", field: "Channel Grouping", sortable: "false" }, { name: "Event Category", field: "Event Category", sortable: "false" }, { name: "Event Action", filed: "Event Action", sort: true }, { name: "Event Label", filed: "Event Label", sort: true }, { name: "Sessions", filed: "Sessions", sort: true }, { name: "Total Events", filed: "Total Events", sort: true }, { name: "Unique Events", filed: "Unique Events", sort: true }, { name: "Events / Session with Event", filed: "Events / Session with Event", sort: true }, { name: "Transactions", filed: "Transactions", sort: true }, { name: "Revenue", filed: "Revenue", sort: true }, { name: "Average Order Value", filed: "Average Order Value", sort: true }],
            search: [{ name: "Channel Grouping", field: "Channel Grouping", sortable: "false" }, { name: "Event Category", field: "Event Category", sortable: "false" }, { name: "Event Action", filed: "Event Action", sort: true }, { name: "Event Label", filed: "Event Label", sort: true }, { name: "Sessions", filed: "Sessions", sort: true }, { name: "Total Events", filed: "Total Events", sort: true }, { name: "Unique Events", filed: "Unique Events", sort: true }, { name: "Events / Session with Event", filed: "Events / Session with Event", sort: true }, { name: "Transactions", filed: "Transactions", sort: true }, { name: "Revenue", filed: "Revenue", sort: true }, { name: "Average Order Value", filed: "Average Order Value", sort: true }],
            landingpage: [{ name: "Impressions", field: "Impressions", sortable: "false" }, { name: "Clicks/Sessions", field: "Clicks/Sessions", sortable: "false" }, { name: "Top Landing", filed: "Top Landing", sort: true }, { name: "Bounce Rate", filed: "Bounce Rates", sort: true }, { name: "Conversion Rate", filed: "Conversion Rate", sort: true }, { name: "No of Orders", filed: "No of Orders", sort: true }, { name: "Sales", filed: "Sales", sort: true }, { name: "NSU", filed: "NSU", sort: true }, { name: "ATS", filed: "ATS", sort: true }, { name: "ROI", filed: "ROI", sort: true }],
            comments: '',
            table_header: [
                { 'name': 'Impressions	', 'field': "impressions", 'sortable': false },
                { 'name': 'Clicks/Sessions	', 'field': "sessions", 'sortable': false },
                { 'name': 'Top Landing	', 'field': "landing", 'sortable': false },
                { 'name': 'Bounce Rate', 'field': "bouncerate", 'sortable': false },
                { 'name': 'Conversion Rate', 'field': "conversionrate", 'sortable': false },
                { 'name': 'No Of Orders', 'field': "noorders", 'sortable': false },
                { 'name': 'Sales', 'field': "sales", 'sortable': false },
                { 'name': 'NSU', 'field': "nsu", 'sortable': false },
                { 'name': 'ATS', 'field': "ats", 'sortable': false },
                { 'name': 'ROI', 'field': "roi", 'sortable': false },
            ],
            table_data: [
                { impressions: "ClientName_Chennai_Display", sessions: '3,70,238', landing: '1,753', bouncerate: '0.47', conversionrate: '9153', noorders: '3', sales: '0.17', nsu: '3051', ats: '55', roi: '30' },
                { impressions: "ClientName_Hyderabad_Display", sessions: '3,70,238', landing: '1,753', bouncerate: '0.47', conversionrate: '9153', noorders: '3', sales: '0.17', nsu: '3051', ats: '55', roi: '30' },
                { impressions: "ClientName_Pune_Search", sessions: '3,70,238', landing: '1,753', bouncerate: '0.47', conversionrate: '9153', noorders: '3', sales: '0.17', nsu: '3051', ats: '55', roi: '30' },
                { impressions: "ClientName_Pune_Display", sessions: '3,70,238', landing: '1,753', bouncerate: '0.47', conversionrate: '9153', noorders: '3', sales: '0.17', nsu: '3051', ats: '55', roi: '30' },
                { impressions: "ClientName_Hyderabad_Search", sessions: '3,70,238', landing: '1,753', bouncerate: '0.47', conversionrate: '9153', noorders: '3', sales: '0.17', nsu: '3051', ats: '55', roi: '30' },
            ],
            // is_table_data_loaded: false,


            //Multiline Graph
            // is_graph_data_loaded: false,
            // campaign_graph_data: [],

            // source_dropdown_options: [{ "value": "impression", "label": "Impression" },{ "value": "click", "label": "Clicks" }, { "value": "spends", "label": "Spends" }],
            // source_metrices_dropdown_value: "impression",

            // source_column_name: {
            //     "impression": "impressions",
            //     "click": "clicks",
            //     "spends": "spends",
            // },


            is_select_data_loaded: false,
            multiline_source_data: [],

            //sourceName ="Facebook",

            is_overall_pie_chart_loaded:false,
            is_paid_trans_metrices_loaded:false,
            is_paid_spends_metrics_loaded:false,
            is_trans_graph_loaded: false,
            is_spends_graph_loaded:false,
            is_roi_graph_loaded:false,
            is_multi_graph_loaded:false,
            campaign_graph_data: [],
            //is_multi_graph_info_loaded:false,
            // is_sessions_bar_graph_loaded:false,
            // is_trans_bar_graph_loaded:false,
            is_bar_graph_loaded:false,
            is_sunburst_pie_graph_loaded:false,
            //is_sessions_sunburst_pie_loaded:false,
            //is_trans_sunburst_pie_loaded:false,

        }
    }
    componentDidMount() {
    //     this.getMultilineGraphData();
        
    // }

    // getMultilineGraphData() {

    //     console.log("source_metrices_dropdown_value ", this.state.source_metrices_dropdown_value)

    //     // const data = {
    //     //     'start_date': '2021-10-01',
    //     //     'end_date': '2021-12-31',

    //     // }
    //     const url = "http://127.0.0.1:8000/api/ecom/traffic-innerPaidMultiLine/"
    //     fetch(url, {
    //         method:'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //     }).then(res => res.json())
    //         .then((result) => {
    //             // console.log("====campaign-graph_matrix=======", result)
    //             let source_list = result['multiline_graph_list'];
        this.getOverallPiechart();
        this.getTransPaidMetrices();
        this.getSpendsPaidMetrices();
        this.getTranslinesparkgraph();
        this.getSpendslinesparkgraph();
        this.getROIlinesparkgraph();
        this.getMultilineGraph();
        // this.getSessionBarGraph();
        // this.getTransBarGraph();
        this.getBarGraph();
       // this.getMultilineGraphInfo();
        //this.getSelectData();
        this.getSunburstPieChart();
        //this.getTransPieChart();
        


    }

// PAID OVERALL PIE CHART
    getOverallPiechart() {

        const data = {
            'start_date': '2021-04-01',
            'end_date': '2021-06-31',
            //'social_social_platform': 'FACEBOOK'
        }
        const url = API_URL + "/api/ecom/traffic-innerPaidSource/"
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => res.json()).then((result) => {
            let overall_pie_list = result['source_traffic_paid'];
            let overall_pie_graph_list=[];
            // {    "name": "Social","value": 30}
            console.log("getPiechart========================", overall_pie_list)
            overall_pie_list.map((info, index) => {
                console.log("index================",index)
                let a = {
                    "name": info['source'],
                    "value": info['sessions'],
                }
                overall_pie_graph_list.push(a);
            })
            this.setState({
                is_overall_pie_chart_loaded: true,
                trafficpievalue: overall_pie_graph_list,
            
            });
        })
    }





// PAID INNER SECTION PAID-TRANS
    getTransPaidMetrices(){
        const data = {
            'start_date': '2021-03-01',
            'end_date': '2021-03-30',
        
        }
        const url = API_URL + "/api/ecom/traffic-innerPaidTrans/"
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        }

        )
            .then(res => res.json())
            .then((result) => {


               
                let current_conversion_rate;
                let last_conversion_rate;
                let current_revenue_per_session;
                let last_revenue_per_session;
                let current_cost_per_transaction;
                let last_cost_per_transaction;


                let current_transaction_revenue = result['current_traffic_inner_paid'][0]['transaction_revenue']
                //console.log("🚀 ~ file: Paid.js ~ line 151 ~ Paid ~ .then ~ current_transaction_revenue", current_transaction_revenue)
                let last_transaction_revenue = result['last_traffic_inner_paid'][0]['transaction_revenue']
                

                let current_transactions = result['current_traffic_inner_paid'][0]['transactions']
                let last_transactions = result['last_traffic_inner_paid'][0]['transactions']
                
                let current_sessions = result['current_traffic_inner_paid'][0]['sessions']
                let last_sessions = result['last_traffic_inner_paid'][0]['sessions']
                

                current_conversion_rate = (current_transactions / current_sessions) *100;
                last_conversion_rate = (last_transactions / last_sessions) *100;

                current_revenue_per_session = (current_transaction_revenue / current_sessions); 
                last_revenue_per_session = (last_transaction_revenue / last_sessions) ; 

                current_cost_per_transaction = (current_transaction_revenue/ current_transactions);
                last_cost_per_transaction =(last_transaction_revenue/last_transactions);

                //console.log("🚀 ~ file: Traffic.js ~ line 73 ~ Traffic ~ .then ~ result", result['last_traffic_paid'][0]['sessions']);
                //console.log("🚀 ~ file: Traffic.js ~ line 73 ~ Traffic ~ .then ~ result", result)
                //console.log("🚀 ~ file: Traffic.js ~ line 72 ~ Traffic ~ .then ~ result", result['current_traffic_paid'])
                // let sparkline_list = result['traffic_metrices_sparkline_list'];
                // console.log("🚀 ~ file: Traffic.js ~ line 77 ~ Traffic ~ .then ~ sparkline_list", sparkline_list)
                // let paid_traffic_list = [];
                // let paid_impressions_list = [];
            
            
                // sparkline_list.map((info, index) => {
                //     try{
                //         let a = {
                //             "date":info['date'],
                //             "value": info['sessions']
                //         }
                //         paid_traffic_list.push(a);
                //         let b ={
                //             "date":info['date'],
                //             "value": info['impressions']
                //         }
                //         paid_impressions_list.push(b);

                //     }
                //     catch (e) {

                //     }

                // })
                // console.log("🚀 ~ file: Traffic.js ~ line 79 ~ Traffic ~ .then ~ paid_traffic_list", paid_traffic_list)
                // console.log("🚀 ~ file: Traffic.js ~ line 81 ~ Traffic ~ .then ~ paid_impressions_list", paid_impressions_list)
            
                this.setState({
                    is_paid_trans_metrices_loaded:true,
                    current_transaction_revenue:current_transaction_revenue,
                    last_transaction_revenue:last_transaction_revenue,
                    current_transactions:current_transactions,
                    last_transactions: last_transactions,
                    current_sessions: current_sessions,
                    last_sessions:last_sessions,
                    current_conversion_rate:current_conversion_rate,
                    last_conversion_rate:last_conversion_rate,
                    current_revenue_per_session:current_revenue_per_session,
                    last_revenue_per_session:last_revenue_per_session,
                    current_cost_per_transaction:current_cost_per_transaction,
                    last_cost_per_transaction:last_cost_per_transaction,
                    

                //     paid_traffic_graph:paid_traffic_list,
                //     paid_impressions_graph:paid_impressions_list

                });
            
            },
                (error) => {
                }
            )
      
    
    }


// getsourcevariabledata(){
//     console.log(att)
// }
// PAID INNER SECTION PAID-SPENDS
    getSpendsPaidMetrices(){
        const data = {
            'start_date': '2021-03-01',
            'end_date': '2021-03-30',
        
        }
        const url = API_URL + "/api/ecom/traffic-innerPaidSpends/"
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        }

        )
            .then(res => res.json())
            .then((result) => {


                let current_cost_per_click;
                let last_cost_per_click;
                

                let current_spends = result['current_traffic_inner_paid'][0]['spends']
                let last_spends = result['last_traffic_inner_paid'][0]['spends']

                let current_clicks = result['current_traffic_inner_paid'][0]['clicks']
                let last_clicks = result['last_traffic_inner_paid'][0]['clicks']
                
                
                

                current_cost_per_click = (current_spends / current_clicks) ;
                last_cost_per_click = (last_spends / last_clicks);

                

                //console.log("🚀 ~ file: Traffic.js ~ line 73 ~ Traffic ~ .then ~ result", result['last_traffic_paid'][0]['sessions']);
                //console.log("🚀 ~ file: Traffic.js ~ line 73 ~ Traffic ~ .then ~ result", result)
                //console.log("🚀 ~ file: Traffic.js ~ line 72 ~ Traffic ~ .then ~ result", result['current_traffic_paid'])
                // let sparkline_list = result['traffic_metrices_sparkline_list'];
                // console.log("🚀 ~ file: Traffic.js ~ line 77 ~ Traffic ~ .then ~ sparkline_list", sparkline_list)
                // let paid_traffic_list = [];
                // let paid_impressions_list = [];
            
            
                // sparkline_list.map((info, index) => {
                //     try{
                //         let a = {
                //             "date":info['date'],
                //             "value": info['sessions']
                //         }
                //         paid_traffic_list.push(a);
                //         let b ={
                //             "date":info['date'],
                //             "value": info['impressions']
                //         }
                //         paid_impressions_list.push(b);

                //     }
                //     catch (e) {

                //     }

                // })
                // console.log("🚀 ~ file: Traffic.js ~ line 79 ~ Traffic ~ .then ~ paid_traffic_list", paid_traffic_list)
                // console.log("🚀 ~ file: Traffic.js ~ line 81 ~ Traffic ~ .then ~ paid_impressions_list", paid_impressions_list)
            
                this.setState({
                    is_paid_spends_metrics_loaded:true,
                    current_spends:current_spends,
                    last_spends:last_spends,
                    current_clicks:current_clicks,
                    last_clicks:last_clicks,
                    current_cost_per_click: current_cost_per_click,
                    last_cost_per_click:last_cost_per_click,
                   
                    

       

                });
            
            },
                (error) => {
                }
            )
      
    
    }

//SPARK LINE FOR TRANS
    getTranslinesparkgraph() {
        const data = {
            'start_date': '2021-04-01',
            'end_date': '2021-04-11',
            
        }
        const url = API_URL + "/api/ecom/traffic-innerPaidTrans/"
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => res.json()).then((result) => {

            //console.log("2 getlinesparkgraph=======================", result)
            let sparkline_list = result['traffic_inner_metrices_sparkline_list']
            //console.log("🚀 ~ file: ecom.js ~ line 167 ~ Ecom ~ getlinesparkgraph ~ sparkline_list", sparkline_list)

            let transaction_revenue_spark_list = [];
            let transactions_spark_list = [];
            let conversion_rate_list = [];
            let revenue_per_session_list = [];
            let cost_per_transaction_list = [];

            sparkline_list.map((info, index) => {
                //console.log("🚀 ~ file: Paid.js ~ line 355 ~ Paid ~ sparkline_list.map ~ index", index)
                
                try {
                    let a = {
                        "date": info['date'],
                        "value": info['transaction_revenue']
                    }
                    transaction_revenue_spark_list.push(a);

                    
                    let b = {
                        "date" : info['date'],
                        "value":info['transactions'] 

                    }
                    transactions_spark_list.push(b);

                    let c = {
                        "date": info['date'], 
                        "value":((info['transactions'] / info['sessions'])*100).toFixed(2)

                    }
                    conversion_rate_list.push(c);

                    let d = {
                        "date": info['date'], 
                        "value":((info['transaction_revenue'] / info['sessions'])*100).toFixed(2)

                    }
                    revenue_per_session_list.push(d);

                    let e = {
                        "date": info['date'], 
                        "value":((info['transaction_revenue'] / info['transactions'])*100).toFixed(2)

                    }
                    cost_per_transaction_list.push(d);


                } catch (e) {

                }



            })


            this.setState({
                is_trans_graph_loaded: true,
                transaction_revenue_spark_graph:transaction_revenue_spark_list,
                transactions_spark_graph:transactions_spark_list,
                conversion_rate_graph:conversion_rate_list,
                revenue_per_session_graph:revenue_per_session_list,
                cost_per_transaction_graph:cost_per_transaction_list,

            });
        })

    }

//SPARK LINE FOR SPENDS
    getSpendslinesparkgraph() {
        const data = {
            'start_date': '2021-04-01',
            'end_date': '2021-04-11',
            
        }
        const url = API_URL + "/api/ecom/traffic-innerPaidSpends/"
       
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => res.json()).then((result) => {

            //console.log("2 getlinesparkgraph=======================", result['ecom_metrices_sparkline_list'])
            let sparkline_list = result['traffic_inner_metrices_sparkline_list']
            //console.log("🚀 ~ file: ecom.js ~ line 167 ~ Ecom ~ getlinesparkgraph ~ sparkline_list", sparkline_list)

            let spends_spark_list = [];
            let cost_per_click_spark_list = [];

            sparkline_list.map((info, index) => {
                //console.log("🚀 ~ file: Paid.js ~ line 441 ~ Paid ~ sparkline_list.map ~ index", index)
                
                try {
                    let a = {
                        "date": info['date_to_be_considered'],
                        "value": info['spends']
                    }
                    spends_spark_list.push(a);

                    
                    let b = {
                        "date": info['date_to_be_considered'], 
                        "value":((info['spends'] / info['clicks'])).toFixed(2)

                    }
                    cost_per_click_spark_list.push(b);
                    console.log("cost_per_click_spark_graph--------",cost_per_click_spark_list)
                    


                } catch (e) {

                }



            })


            this.setState({
                is_spends_graph_loaded: true,
                spends_spark_graph:spends_spark_list,
                cost_per_click_spark_graph:cost_per_click_spark_list,
                


            });
        })

    }


//SPARK LINE FOR SPENDS
    getROIlinesparkgraph() {
        const data = {
            'start_date': '2021-04-01',
            'end_date': '2021-04-11',
            
        }
        const url = API_URL + "/api/ecom/traffic-innerPaidROI/"
       
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => res.json()).then((result) => {

            //console.log("2 getlinesparkgraph=======================", result['ecom_metrices_sparkline_list'])
            let sparkline_list = result['paid_roi_metrices_sparkline_list']
            //console.log("🚀 ~ file: ecom.js ~ line 167 ~ Ecom ~ getlinesparkgraph ~ sparkline_list", sparkline_list)

            let roi_spark_list = [];
            //let cost_per_click_spark_list = [];

            sparkline_list.map((info, index) => {
                //console.log("🚀 ~ file: Paid.js ~ line 441 ~ Paid ~ sparkline_list.map ~ index", index)
                
                try {
                    let a = {
                        "date": info['date'],
                        "value": info['roi']
                    }
                    roi_spark_list.push(a);

                    
                  
                    console.log("ROI_spark_graph--------",roi_spark_list)
                    


                } catch (e) {

                }



            })


            this.setState({
                is_roi_graph_loaded: true,
                roi_spark_graph:roi_spark_list,
            });
        })

    }



// MULTI-LINE GRAPH PERFORMANCE
    getMultilineGraph() {

        //console.log("source_metrices_dropdown_value ", this.state.source_metrices_dropdown_value)

        const data = {
            'start_date': '2021-04-01',
            'end_date': '2021-04-30',

        }
        const url = API_URL + "/api/ecom/traffic-innerPaidMultiLine/"
        fetch(url, {
            method: 'POST',
           // body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        }

        )
            .then(res => res.json())
            .then((result) => {
                // console.log("====campaign-graph_matrix=======", result)
                let source_list = result['multiline_graph_list'];
                //console.log("🚀 ~ file: Paid.js ~ line 507 ~ Paid ~ .then ~ source_list", source_list)
                

                let data_list = [];

                let facebook_list = [];
                let insta_list = []
                let twitter_list = []
                let linkedin_list = []
                let google_list = []
                let date_list = []


                source_list.map((info, index) => {
                    //console.log("🚀 ~ file: Paid.js ~ line 519 ~ Paid ~ source_list.map ~ index", index)
                    //console.log("source_list.map(info,index)---------------------",info)
                    try {

                        if (!date_list.includes(source_list[index]['date_to_be_considered'])) {
                            date_list.push(source_list[index]['date_to_be_considered']);
                        }

                        let a = {
                            "date": source_list[index]['date_to_be_considered'],
                            "impression": source_list[index]['impressions'],
                            "click": source_list[index]['clicks'],
                            "spends": source_list[index]['spends'],
                            
                        }

                        if (source_list[index]['source'] == "Twitter") {
                            twitter_list.push(a);
                        } else if (source_list[index]['source'] == "Linkedin") {
                            linkedin_list.push(a);
                        } else if (source_list[index]['source'] == "Facebook") {
                            facebook_list.push(a);
                        } else if (source_list[index]['source'] == "Instagram") {
                            insta_list.push(a);
                        } else if (source_list[index]['source'] == "Google") {
                            google_list.push(a);
                        }
                    } catch {

                    }


                });

                // Prepar data for the graph
                // Iterating Date List
                date_list.map((info, index) => {
                    //console.log("🚀 ~ file: Paid.js ~ line 556 ~ Paid ~ date_list.map ~ info", info)

                    let facebook_val = 0;
                    let twitter_val = 0;
                    let likedin_val = 0;
                    let insta_val = 0;
                    let google_val = 0;

                    // Facebook
                    try {
                        facebook_val = facebook_list[index][this.state.source_metrices_dropdown_value];
                    } catch {
                        facebook_val = 0;
                    }

                    // Twitter
                    try {
                        twitter_val = twitter_list[index][this.state.source_metrices_dropdown_value];
                    } catch {
                        twitter_val = 0;
                    }

                    // Linkedin
                    try {
                        likedin_val = linkedin_list[index][this.state.source_metrices_dropdown_value];
                    } catch {
                        likedin_val = 0;
                    }

                    // Instagram

                    try {
                        insta_val = insta_list[index][this.state.source_metrices_dropdown_value];
                    } catch {
                        insta_val = 0;
                    }


                    // Google
                    try {
                        google_val = google_list[index][this.state.source_metrices_dropdown_value];
                    } catch {
                        google_val = 0;
                    }

                    let a = {
                        "year": info,
                        "google": google_val,
                        "facebook": facebook_val,
                        "instagram": insta_val,
                        "twitter": twitter_val,
                        "linkedin": likedin_val
                    };

                    data_list.push(a);
                });

                // this.setState({ is_graph_data_loaded: true, paidsocial: data_list });
                this.setState({ is_multi_graph_loaded:true, paidsocial: data_list });

            },
                (error) => {
                    //   console.log("Error Result = ", error)
                }
            )
    }

    // phandleCallback = (childData) => {
    //     console.log("Child Data ============================================ ", childData);

    //     this.state.source_metrices_dropdown_value = childData
    //     this.setState({ is_graph_data_loaded: false });
    //     this.getMultilineGraphData();
    // }

    // handleCallback = (childData) => {
    //     this.setState({ daily_metrics_name: childData, is_daily_data_loaded: false });
    // }
    // handleperformanceCallback = (childData) => {
    //     this.setState({ daily_metrics_name: childData, is_daily_data_loaded: false });
    // }
// BAR CHART SESSION

    // getSessionBarGraph() {
    //     const data = {
    //         'start_date': '2021-04-01',
    //         'end_date': '2021-04-30',
            
    //     }
    //     const url = API_URL + "/api/ecom/traffic-innerSessionBarGraph/"
    //     fetch(url, {
    //         method: 'POST',
    //         body: JSON.stringify(data),
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //     }).then(res => res.json()).then((result) => {

    //         let source_list = result['bar_graph_session_paid']
    //         //console.log("🚀 ~ file: ecom.js ~ line 167 ~ Ecom ~ getlinesparkgraph ~ source_list", source_list)
    //         let data_list = [];

    //         source_list.map((info, index) => {
    //             //console.log("🚀 ~ file: Paid.js ~ line 355 ~ Paid ~ source_list.map ~ index", index)

    //             let a = {
    //                 "name": info['browser'],
    //                 "points": info['sessions']
    //             }
    //             data_list.push(a);
    //         })
    //         //console.log("Data_LIST --------------",data_list)
    //         this.setState({ is_sessions_bar_graph_loaded:true, locationdata: data_list });
            
    //     })

    // } 

    //  BAR CHART TRANS

    // getTransBarGraph() {
    //     const data = {
    //         'start_date': '2021-04-01',
    //         'end_date': '2021-04-30',
            
    //     }
    //     const url = API_URL + "/api/ecom/traffic-innerTransBarGraph/"
    //     fetch(url, {
    //         method: 'POST',
    //         body: JSON.stringify(data),
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //     }).then(res => res.json()).then((result) => {

    //         let source_list = result['bar_graph_trans_paid']
    //         //console.log("🚀 ~ file: ecom.js ~ line 167 ~ Ecom ~ getlinesparkgraph ~ source_list", source_list)
    //         let data_list = [];

    //         source_list.map((info, index) => {
    //             //console.log("🚀 ~ file: Paid.js ~ line 355 ~ Paid ~ source_list.map ~ index", index)

    //             let a = {
    //                 "name": info['browser'],
    //                 "points": info['transactions']
    //             }
    //             data_list.push(a);
    //         })
    //         console.log("Trans data_LIST --------------",data_list)
    //         this.setState({ is_trans_bar_graph_loaded:true, locationdata: data_list });
            
    //     })

    // } 

    // getBarGraph() {
    //     if (this.state.source_bar_dropdown_options == 'sessions'){
    //         this.getSessionBarGraph();
    //     }
    //     else{
    //         this.getSessionBarGraph();
    //     }

    // }
//FINAL BAR CHART
    getBarGraph() {
        const data = {
            'start_date': '2021-04-01',
            'end_date': '2021-04-30',
            
        }
        let flag = this.state.source_bar_dropdown_value
        //console.log("FLAG --------------",flag == 'transactions')

        let url = ""
        if (flag == 'transactions'){
            url = API_URL + "/api/ecom/traffic-innerTransBarGraph/" 
        }
        else{
            url = API_URL + "/api/ecom/traffic-innerSessionBarGraph/"
        }
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => res.json()).then((result) => {

            let source_list = []   
            if (flag == 'transactions'){
                source_list = result['bar_graph_trans_paid'] 
            }   
            else{
                source_list = result['bar_graph_session_paid']
            }    

            //console.log("🚀 ~ file: ecom.js ~ line 167 ~ Ecom ~ getlinesparkgraph ~ source_list", source_list)
            let data_list = [];

            source_list.map((info, index) => {
                //console.log("🚀 ~ file: Paid.js ~ line 355 ~ Paid ~ source_list.map ~ index", index)
                
                let pp = 0
                if (flag == 'transactions'){
                    pp = info['transactions']  
                }
                else{
                    pp = info['sessions']
                }
                let a = {
                    "name": info['browser'],
                    "points": pp
                }
                data_list.push(a);
            })
            //console.log("Data_LIST --------------",data_list)
            this.setState({ is_bar_graph_loaded:true, locationdata: data_list });
            
        })

    }     


//SESSIONS PIE CHART
    getSunburstPieChart() {

        const data = {
            'start_date': '2021-04-01',
            'end_date': '2021-04-30',
        }
        let flag = this.state.sunbrustdropdown_value
        console.log("FLAG --------------",flag == 'Transactions')

        let url = ""
        if (flag == 'Transactions'){
            url = API_URL + "/api/ecom/traffic-innerPaidTransPieChart/" 
        }
        else{
            url = API_URL + "/api/ecom/traffic-innerPaidSessionsPieChart/"
        }

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => res.json()).then((result) => {
            let sunburst_pie_list =[]
            if(flag == 'Transactions'){
                sunburst_pie_list = result['pie_sunburst_trans_paid']
            } 
            else {
                sunburst_pie_list = result['pie_sunburst_sessions_paid']
            }
            
            let sunburst_pie_graph_list=[];
            //console.log("getPiechart========================", result)
            sunburst_pie_list.map((info, index) => {

                let ps = 0
                if (flag == 'Transactions'){
                    ps = info['transactions']  
                }
                else{
                    ps = info['sessions']
                }
                let a = {
                    "name": info['device'],
                    "value": ps
                }
                sunburst_pie_graph_list.push(a);
            })
            console.log("Pie_LIST --------------",sunburst_pie_graph_list)            
            this.setState({
                is_sunburst_pie_graph_loaded: true,
                sunbrustchart: sunburst_pie_graph_list,
            });
        })
    }


// // MULTILINE GRAPH INFO (DROPDOWN)
//     getMultilineGraphInfo() {
//         const data = {
//             'start_date': '2021-10-01',
//             'end_date': '2021-12-31',
//         }
//         const url = API_URL + "/api/ecom/traffic-innerPaidMultiLineGraphInfo/"

//         fetch(url, {
//             method: 'POST',
//             body: JSON.stringify(data),
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//         }) .then((res) => res.json())
//         .then((result) => {
//                 console.log("data sss== ", result);
//                 let data_list = result['multiline_graph_info_list']
//                 console.log("data_list-------------",data_list)
//                 let total = 0;
//                 let total_calc = 0;

//                 let total_impression = 0
//                 let total_click = 0
//                 let total_spends = 0
//                 let total_conversions = 0
                

//                 data_list.map((item, index) => {
//                     console.log("Hello ------------------------", item)

                    
//                     total_impression += item.impressions;
//                     total_click += item.clicks;
//                     total_spends += item.spends;
//                     total_conversions +=item.conversions;
                   
//                     if (this.state.source_metrices_dropdown_value == 'impression') {
//                         total += item.impressions;
//                         console.log("impression block exicute");
//                     } else if (this.state.source_metrices_dropdown_value == 'clicks') {
//                         total += item.clicks;
//                         console.log("clicks block exicute");
//                     } else if (this.state.source_metrices_dropdown_value == 'spends') {
//                         total += item.spends;
//                         console.log("spends block exicute")
//                     }
//                 })

//                 console.log("Total ================ ", total)
//                 console.log("total_impression = ", total_impression)
//                 console.log("total_click = ", total_click)
//                 console.log("total_spends = ", total_spends)
//                 console.log("total conversions ",total_conversions)
                

//                 let final_calc = 0;

//                 if (this.state.source_metrices_dropdown_value == 'impression') {
//                     final_calc = ((total_click / total_impression) * 100).toFixed(2) + "%";
//                     console.log("-------------------",final_calc)
//                 } else if (this.state.source_metrices_dropdown_value == 'clicks') {
//                     final_calc = "₹: " + (total_spends / total_click).toFixed(2);
//                 } else if (this.state.source_metrices_dropdown_value == 'spends') {
//                     final_calc = (total_conversions).toFixed(2);
//                 }

//                 console.log("=======================list multiline source data===========", final_calc)
//                 this.setState({
//                     multiline_source_data: final_calc,
//                     //is_select_data_loaded: true,
//                     is_multi_graph_loaded:true,
//                     total_source: total,
//                     final_calc: final_calc
//                 })
//                 console.log("===============list select data==========", this.state.multiline_source_data)
                


//             });

//     }

    //Testing
    // getSelectData() {
    //     const data = {
    //         'start_date': '2021-10-01',
    //         'end_date': '2021-12-31',
    //     }
    //         const url = API_URL + "/api/ecom/traffic-innerPaidMultiLineGraphInfo/"

    //     fetch(url , {
    //                 method: 'POST',
    //                 body: JSON.stringify(data),
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                 },
    //             }).then((res) => res.json())
    //         .then((data) => {

    //             console.log("data sss== ", data);
    //             let data_list = data['multiline_graph_info_list']
    //             console.log("🚀 ~ file: Paid.js ~ line 719 ~ Paid ~ .then ~ data_list", data_list)
                
    //             let total = 0;
    //             let total_calc = 0;

    //             let total_impression = 0
    //             let total_click = 0
    //             let total_spends = 0
                

    //             data_list.map((item, index) => {

    //                 total_impression += item.impressions;
    //                 total_click += item.clicks;
    //                 total_spends += item.spends;
                    

    //                 if (this.state.source_metrices_dropdown_value == 'impression') {
    //                     total += item.impressions;
    //                     console.log("impression block exicute");
    //                 } else if (this.state.source_metrices_dropdown_value == 'clicks') {
    //                     total += item.clicks;
    //                     console.log("clicks block exicute");
                   
    //                 } else if (this.state.source_metrices_dropdown_value == "spends") {
    //                     total += item.spends_money;
    //                     console.log("spends block exicute")
                   
                  
    //                 }
    //             })

    //             console.log("Total = ", total)
    //             console.log("total_impression = ", total_impression)
    //             console.log("total_click = ", total_click)
    //             console.log("total_spends = ", total_spends)
                

    //             let final_calc = 0;

    //             if (this.state.source_metrices_dropdown_value == 'impression') {
    //                 final_calc = ((total_click / total_impression) * 100).toFixed(2) + "%";

    //             } else if (this.state.source_metrices_dropdown_value == 'clicks') {
    //                 final_calc = "₹: " + (total_spends / total_click).toFixed(2);
               
    //             } else if (this.state.source_metrices_dropdown_value == "spends") {
    //                 final_calc = total_spends;
            
    //             }


    //             this.setState({
    //                 select_source_data: data,
    //                 is_select_data_loaded: true,
    //                 total_source: total,
    //                 final_calc: final_calc
    //             })
    //             console.log("===============list select data==========", this.state.select_source_data)


    //         });

    // }



// calculatePaidMetrices = (action, info) => {

//     let result = 0;
//     if (action == "CTR") {
//         return result = ((info.clicks / info.impressions) * 100).toFixed(2) + "%";
//     } else if (action == "CPC") {
//         return result = "₹: " + (info.spends / info.clicks).toFixed(2);

//     } else if (action == "Conversions") {
//         return result = info.conversions;
//     }

// }



//SESSIONS PIE CHART
    // getSessionsPieChart() {

    //     const data = {
    //         'start_date': '2021-10-01',
    //         'end_date': '2021-12-31',
            
    //     }
    //     const url = API_URL + "/api/ecom/traffic-innerPaidSessionsPieChart/"
    //     fetch(url, {
    //         method: 'POST',
    //         body: JSON.stringify(data),
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //     }).then(res => res.json()).then((result) => {
    //         let pie_list = result['pie_chart_traffic_inner_paid'];
    //         let pie_graph_sessions_list=[];
    //         // {    "name": "Social","value": 30}
    //         console.log("getPiechart========================", result)
    //         pie_list.map((info, index) => {
    //             console.log("-------------------",info)


    //             let a = {
    //                 "name": info['device'],
    //                 "value": info['sessions'],
    //             }
    //             pie_graph_sessions_list.push(a);
    //         })
    //         this.setState({
    //             is_sessions_sunburst_pie_loaded:true,
    //             sunburst_pie: pie_graph_sessions_list,

    //         });
    //     })
    // }



//TRANS PIE CHART
    // getTransPieChart() {

    //     const data = {
    //         'start_date': '2021-10-01',
    //         'end_date': '2021-12-31',
            
    //     }
    //     const url = API_URL + "/api/ecom/traffic-innerPaidTransPieChart/"
    //     fetch(url, {
    //         method: 'POST',
    //         body: JSON.stringify(data),
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //     }).then(res => res.json()).then((result) => {
    //         let pie_list = result['pie_chart_traffic_inner_paid'];
    //         let pie_graph_trans_list=[];
    //         // {    "name": "Social","value": 30}
    //         console.log("getPiechart========================", result)
    //         pie_list.map((info, index) => {
    //             console.log("-------------------",info)


    //             let a = {
    //                 "name": info['device'],
    //                 "value": info['transactions'],
    //             }
    //             pie_graph_trans_list.push(a);
    //         })
    //         this.setState({
    //             is_trans_sunburst_pie_loaded:true,
    //             sunbrustdropdown_options: pie_graph_trans_list,

    //         });
    //     })
    // }






// getData = () => {

    //     fetch("https://jsonplaceholder.typicode.com/comments")
    //         .then(response => response.json())
    //         .then(json => {
    //             // this.state.hideLoader();
    //             // this.setState.comments(json);
    //             console.log("==================getdatapaid=========================", json);
    //             this.setState({ is_table_data_loaded: true, table_data: json });
    //         });
    // }

    phandleCallback = (childData) => {
        //console.log("Child Data ============================================ ", childData);

        this.state.source_metrices_dropdown_value = childData
        this.setState({ is_multi_graph_loaded: false });
       // this.setState({is_multi_graph_info_loaded:false});

        this.getMultilineGraph();
        //this.getMultilineGraphInfo();
    }

    barhandleCallback = (childData) => {
        //console.log("Child Data ============================================ ", childData);

        this.state.source_bar_dropdown_value = childData
        this.setState({ is_bar_graph_loaded: false });
       // this.setState({is_multi_graph_info_loaded:false});

        this.getBarGraph();
        //this.getMultilineGraphInfo();
    }


    handletrafficCallback =(childData) => {
        this.state.trafficdropdown_value =childData
        this.state({is_overall_pie_chart_loaded:false});
        this.getOverallPiechart();
        console.log("Traffic Overall Pie Chart ============",this.state.trafficpievalue)

    }



    handleperformanceCallback = (childData) => {
        this.setState({ daily_metrics_name: childData, is_daily_data_loaded: false });

    }
    // handlesunbrustCallback = (childData) => {
    //     console.log("Child DATA ==================================",childData);
    //     this.state.sunbrustdropdown_value = childData
    //     this.state({is_sunburst_pie_graph_loaded: false });
    //     this.getSunburstPieChart();
    //     console.log("SunBurst-Chart data ==================================",this.state.sunbrustchart);        
      
    // }

    handlesunbrustCallback = (childData) => {
        console.log("*******************************",childData);
        this.state.sunbrustdropdown_value = childData
        this.state({is_sunburst_pie_graph_loaded:false});
        this.getSunburstPieChart();

    }

    handleCallback = (childData) => {
        // console.log("Child Data ============================================ ", childData);

        this.state.source_metrices_dropdown_value = childData
        // this.setState({ is_multi_graph_loaded: false });

        // this.getSelectData();
        // this.getMultilineGraph();
    }

    eventShow = () => {
        this.setState({
            isActive: true
        });
        this.scrollDiv.current.scrollIntoView({ behavior: "smooth" });
    }
    eventHide = () => {
        this.setState({
            isActive: false
        })
    }
    searchShow = () => {
        this.setState({
            show: true
        });
        this.scrollSearch.current.scrollIntoView({ behavior: "smooth" });
    }

    searchHide = () => {
        this.setState({
            show: false
        })
    }

    render() {
        if(!this.state.is_overall_pie_chart_loaded||
            !this.state.is_paid_trans_metrices_loaded || 
            !this.state.is_paid_spends_metrics_loaded||
            !this.state.is_trans_graph_loaded ||
            !this.state.is_spends_graph_loaded||
            !this.state.is_roi_graph_loaded||
            !this.state.is_multi_graph_loaded ||
            // !this.state.is_multi_graph_info_loaded||
            !this.state.is_sunburst_pie_graph_loaded||
            // !this.state.is_sessions_bar_graph_loaded||
            // !this.state.is_trans_bar_graph_loaded ||
            !this.state.is_bar_graph_loaded            
            //!this.state_select_data_loaded
            )
            {
            return "<div></div>"
        }

        console.log("Trans data_LIST --------------",this.state.locationdata)
        console.log("Sunbrust Pie chart data -----------------------",this.state.sunbrustchart)

        const CarouselSettings = {
            arrows: false,
            dots: false,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [

                {
                    breakpoint: 1202,
                    settings: {
                        arrows: false,
                        slidesToShow: 4,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,

                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,

                    }
                }
            ]
        }
        if(!this.state.is_graph_data_loaded) {
            return'Loading....';
        }
        return (
            <Wrapper>
                <Container className="p-0" fluid>
                    <PageHeader pageTitle="Paid Traffic" />
                    <div className="d-flex">
                        <Col className="icz-leftsection" sm={12} lg={3} xl={3}>
                            <div className="icz-card-wrap">
                                <Col className="icz-sectioncardwrapper ps-0">
                                    <div className="icz-leftsectioncard">
                                        <div className="d-flex">
                                            <Col sm={12} className="icz-cardheader icz-leftcardheader">
                                                <Col className="icz-cardtitle">
                                                    <div className="icz-title-wrap p-0">
                                                        <div className="text-left">
                                                            <h5 className="icz-cardtitle text-start mb-0 text-">Traffic By Channel</h5>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className="icz-pagefilter">
                                                    <div className="icz-page-dropdown justify-content-end p-0">
                                                        <DailydataDropdown dropdown_options={this.state.trafficdropdown_options} dropdown_placeholder="Traffic" parentCallback={this.handletrafficCallback} />
                                                    </div>
                                                </Col>

                                            </Col>
                                        </div>
                                        <div className="w-100">
                                            <Piechart card_id="icz-traffic-piechart" card_class="icz-leftsectionchart" graph-data={this.state.trafficpievalue} />
                                        </div>
                                    </div>
                                </Col>

                            </div>

                        </Col>
                        <Col className="icz-rightsection" sm={12} lg={9} xl={9}>
                            <div className="icz-title-wrap d-flex">
                                <Col sm={12}>
                                    <div className="icz-row">
                                        <Col><h5 className="icz-card-title align-items-start">Paid</h5></Col>
                                        <Col className="align-items-end text-end"><Link to='/ecom/customer' className='icz-link'>View More</Link></Col>
                                    </div>
                                </Col>
                            </div>
                            <div className="icz-carouselcontainer">

                                <Carousel
                                    Settings={CarouselSettings}
                                    class={"icz-cardcontainer"}
                                    SliderCards={[
                                        <ChartCard
                                            index="1"
                                            CardClass="icz-cardwrapper"
                                            CardIcon={<IconWebsite className="icz-icon" />}
                                            CardTitle="Spends"
                                            RowOneCurrentValue= {this.state.current_spends.toFixed(2)}
                                            RowOnePreviousValue={this.state.last_spends.toFixed(2)}
                                            RowOneTitle="sales"
                                            IsNumber={true}
                                            chart={<Linegraph_sparkline card_id="icz-sales" card_class="icz-cardchart" graph-data={this.state.spends_spark_graph} />}
                                        />,

                                        <ChartCard
                                            index="2"
                                            CardClass="icz-cardwrapper"
                                            CardIcon={<IconWebsite className="icz-icon" />}
                                            CardTitle="Revenue"
                                            RowOneCurrentValue={this.state.current_transaction_revenue}
                                            RowOnePreviousValue={this.state.last_transaction_revenue}
                                            RowOneTitle="sales"
                                            IsNumber={true}
                                            chart={<Linegraph_sparkline card_id="icz-total-orders" card_class="icz-cardchart" graph-data={this.state.transaction_revenue_spark_graph} />}
                                        />,
                                        <ChartCard
                                            index="3"
                                            CardClass="icz-cardwrapper"
                                            CardIcon={<IconWebsite className="icz-icon" />}
                                            CardTitle="Transactions"
                                            RowOneCurrentValue={parseFloat(this.state.current_transactions)}
                                            RowOnePreviousValue= {parseFloat(this.state.last_transactions)}
                                            RowOneTitle="sales"
                                            IsNumber={true}
                                            chart={<Linegraph_sparkline card_id="icz-avgorder-value" card_class="icz-cardchart" graph-data={this.state.transactions_spark_graph} />}
                                        />,
                                        <ChartCard
                                            index="4"
                                            CardClass="icz-cardwrapper"
                                            CardIcon={<IconWebsite className="icz-icon" />}
                                            CardTitle="Return On Investment"
                                            RowOneCurrentValue={parseFloat(this.state.current_transaction_revenue/this.state.current_spends).toFixed(2)}
                                            RowOnePreviousValue={parseFloat(this.state.last_transaction_revenue/this.state.last_spends).toFixed(2)}
                                            
                                            RowOneTitle="sales"
                                            IsNumber={true}
                                            chart={<Linegraph_sparkline card_id="icz-cancellations" card_class="icz-cardchart" graph-data={this.state.roi_spark_list} />}
                                        />,


                                    ]}>

                                </Carousel>


                            </div>

                            <div className="icz-carouselcontainer">

                                <Carousel
                                    Settings={CarouselSettings}
                                    class={"icz-cardcontainer"}
                                    SliderCards={[
                                        <ChartCard
                                            index="1"
                                            CardClass="icz-cardwrapper"
                                            CardIcon={<IconWebsite className="icz-icon" />}
                                            CardTitle="Conversion Rate"
                                            RowOneCurrentValue={parseFloat(this.state.current_conversion_rate).toFixed(2)}
                                            RowOnePreviousValue={parseFloat(this.state.last_conversion_rate).toFixed(2)}
                                            RowOneTitle="Traffic"
                                            IsNumber={true}
                                            chart={<Linegraph_sparkline card_id="icz-session" card_class="icz-cardchart" graph-data={this.state.conversion_rate_graph} />}
                                        />,

                                        <ChartCard
                                            index="2"
                                            CardClass="icz-cardwrapper"
                                            CardIcon={<IconWebsite className="icz-icon" />}
                                            CardTitle="Cost per Click"
                                            RowOneCurrentValue={parseFloat(this.state.current_cost_per_click).toFixed(2)}
                                            RowOnePreviousValue={parseFloat(this.state.last_cost_per_click).toFixed(2)}
                                            RowOneTitle="Traffic"
                                            IsNumber={true}
                                            chart={<Linegraph_sparkline card_id="icz-user" card_class="icz-cardchart" graph-data={this.state.cost_per_click_spark_graph} />}
                                        />,
                                        <ChartCard
                                            index="3"
                                            CardClass="icz-cardwrapper"
                                            CardIcon={<IconWebsite className="icz-icon" />}
                                            CardTitle="Revenue per Session"
                                            RowOneCurrentValue={parseFloat(this.state.current_revenue_per_session).toFixed(2)}
                                            RowOnePreviousValue={parseFloat(this.state.last_revenue_per_session).toFixed(2)}
                                            RowOneTitle="Traffic"
                                            IsNumber={true}
                                            chart={<Linegraph_sparkline card_id="icz-new-users" card_class="icz-cardchart" graph-data={this.state.revenue_per_session_graph} />}
                                        />,
                                        <ChartCard
                                            index="4"
                                            CardClass="icz-cardwrapper"
                                            CardIcon={<IconWebsite className="icz-icon" />}
                                            CardTitle="Cost per transaction"
                                            RowOneCurrentValue={parseFloat(this.state.current_cost_per_transaction).toFixed(2)}
                                            RowOnePreviousValue={parseFloat(this.state.last_cost_per_transaction).toFixed(2)}
                                            RowOneTitle="Traffic"
                                            IsNumber={true}
                                            chart={<Linegraph_sparkline card_id="icz-mobile-user" card_class="icz-cardchart" graph-data={this.state.cost_per_transaction_graph} />}
                                        />,

                                    ]}>
                                </Carousel>
                            </div>
                            <div className="icz-row">

                                <Col className="icz-sectioncardwrapper" sm={12} lg={12}>
                                    <div className="icz-sectioncard">
                                        <div className="icz-cardheader">
                                            <Col className="icz-cardtitle">
                                                Performance
                                            </Col>
                                            <Col className="icz-cardfilter">
                                                <DailydataDropdown dropdown_options={this.state.source_dropdown_options} dropdown_placeholder="Impressions" parentCallback={this.phandleCallback} />
                                            </Col>
                                        </div>


                                        <div className="icz-dchartsection">
                                            {console.log(this.state.multiline_source_data)}
                                        {this.state.is_multi_graph_loaded == true ?
                                            <Col className="" sm={12} lg={8}>

                                                <MultiLine_LineChart chart_id="paid_social" chart_class="icz-sectionchart" graph-data={this.state.paidsocial} metric_1={"Google"} metric_2={"Facebook"} metric_3={"Twitter"} metric_4={"Linkedin"} metric_5={"Instagram"} value1={"facebook"} value2={"google"} value3={"instagram"} value4={"linkedin"} value5={"twitter"} />
                                            
                                                {/* <MultiLine_LineChart chart_id="icz-sourcegraph" chart_class="icz-sectionchart" graph-data={this.state.paidsocial} /> */}
                                            </Col>
                                            : ""}
                                            <Col className="icz-chartdetailssection" sm={12} lg={4}>
                                                <div className="icz-detailsdatasection icz-detailscroll w-100">
                                                    <div className="icz-detailsdatatab d-flex justify-content-between align-items-end">
                                                        <div className="d-flex align-items-center">
                                                            <div className="icz-iconwrapper">
                                                            {/* {this.state.icon_class[info.source]}
                                                            {console.log(this.state.icon_class[info.source])} */}
                                                            </div>
                                                            <div className="icz-titlewrapper">
                                                                <div className="icz-title">
                                                                {/* {info.source} */}
                                                                Google
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="icz-titlewrapper">
                                                            <div className="icz-title">
                                                                <NumberFormatter Number= {0} IsNumber={true} />
                                                                {/* {console.log("info[this.state.source_column_name[this.state.source_metrices_dropdown_value]]-----------",info[this.state.source_column_name[this.state.source_metrices_dropdown_value]] )} */}
                                                            </div>
                                                            <div className="icz-subtitle">
                                                                {/* {this.state.action_short_code[this.state.source_metrices_dropdown_value]}: {this.calculatePaidMetrices(this.state.action_short_code[this.state.source_metrices_dropdown_value], info)} */}
                                                                {/* {console.log("{this.state.action_short_code[this.state.source_metrices_dropdown_value]}============",{this.state.action_short_code[this.state.source_metrices_dropdown_value]})} */}
                                                                {/* // {console.log("{this.calculatePaidMetrices(this.state.action_short_code[this.state.source_metrices_dropdown_value], info)}================", {this.calculatePaidMetrices(this.state.action_short_code[this.state.source_metrices_dropdown_value], info)})} */}
                                                                        1
                                                            </div>
                                                    </div>
                                                </div>
                                                

                                                    <div className="icz-detailsdatatab d-flex justify-content-between align-items-end">
                                                        <div className="d-flex align-items-center">
                                                            <div className="icz-iconwrapper">
                                                                <IconImpressions className="icz-icon" />
                                                            </div>
                                                            <div className="icz-titlewrapper">
                                                                <div className="icz-title">
                                                                    Total
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="icz-titlewrapper">
                                                            <div className="icz-title">
                                                                <NumberFormatter Number={0} IsNumber={true} />
                                                            </div>
                                                            <div className="icz-subtitle">
                                                                2
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </Col>
                                        </div>
                                    </div>
                                </Col>
                            </div>



                            <div className="icz-row">

                                <Col className="icz-sectioncardwrapper" sm={12} lg={6}>
                                    <div className="icz-sectioncard">
                                        <div className="icz-cardheader">
                                            <Col className="icz-cardtitle">

                                            </Col>
                                            <Col className="icz-cardfilter">
                                                <DailydataDropdown dropdown_options={this.state.sunbrustdropdown_options} dropdown_placeholder="Sessions" parentCallback={this.handlesunbrustCallback} />
                                            </Col>
                                        </div>
                                        <div className="">
                                            {/* <Sunbrust_chart card_id="icz-sunbrustchart" card_class="icz-sectionchart" graph-data={this.state.sunbrustchart} /> */}
                                            <Sunbrust_chart card_id="iczsunbrustchart2" card_class="icz-sectionchart" graph-data={this.state.sunbrustchart} />
                                        </div>
                                    </div>
                                </Col>


                                <Col className="icz-sectioncardwrapper" sm={12} lg={6}>
                                    <div className="icz-sectioncard">
                                        <div className="icz-cardheader">
                                            <Col className="icz-cardtitle">

                                            </Col>
                                            <Col className="icz-cardfilter">
                                                <DailydataDropdown dropdown_options={this.state.source_bar_dropdown_options} dropdown_placeholder="Sessions" parentCallback={this.barhandleCallback} />
                                            </Col>
                                        </div>
                                        <div className="">
                                            <LocationBarChart card_id="icz-bar" card_class="icz-sectionchart" graph-data={this.state.locationdata} />
                                        </div>
                                    </div>
                                </Col>
                            </div>
                            <div className="icz-row">
                                <Col className="icz-sectioncardwrapper" sm={12} lg={12}>
                                    <div className="icz-sectioncard">

                                        <div className="icz-cardheader">
                                            <Col className="icz-cardtitle">

                                            </Col>
                                            <Col className="icz-cardfilter">
                                                <DailydataDropdown dropdown_options={this.state.datatabledropdown_options} dropdown_placeholder="Facebook" parentCallback={this.handleperformanceCallback} />
                                            </Col>
                                        </div>
                                        <div className="icz-table">
                                            <DataTable Headers={this.state.table_header} TableData={this.state.table_data} TableSize={5} />
                                        </div>
                                    </div>

                                </Col>
                            </div>
                        </Col>
                    </div>
                </Container>
            </Wrapper>
        )
    }
}

