import React, { useState, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import DatePicker from '../../shared/components/datepicker/datepicker';
import { CampaignDropdown } from '../../shared/components/dropdown/campaigndropdown';


import IconCalendar from '../../../assets/images/icons/calendarIcon';

import './ri-pageheader.scss';
import Image from 'react-bootstrap/esm/Image';

import StickyLogo from '../../../assets/images/icons/icon.png'
import IconPdf from '../../../assets/images/icons/pdfIcon';
import RiFilter from './ri-filter';
import moment from 'moment';


const popover = (
    <Popover id="popover-basic">
        <DatePicker />
    </Popover>
);



const RIPageHeader = (props) => {

    const [scroll, setScroll] = useState(false);
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState({});

    const onChange = (ranges) => {
        // ranges ...
        if (
            moment(ranges.startDate).format("MM-DD-YYYY") !==
            moment(ranges.endDate).format("MM-DD-YYYY")
        ) {
            console.log("Success", ranges);
            setDate(ranges);
            setOpen(false);
        }
    };


    useEffect(() => {
        window.addEventListener("scroll", () => {

            setScroll(window.scrollY > 75);
        });

    }, []);

    return (
        <Container className={scroll ? "icz-section-padding icz-pageheaderwrapper sticky" : "icz-section-padding icz-pageheaderwrapper"} fluid>
            <div className="icz-pageheadercontainer">
                <div className="icz-stickylogowrapper">
                    <Image src={StickyLogo} className="icz-stickylogo" />
                </div>
                <div className="p-0 icz-headerdetails d-flex justify-content-between">
                    <Col lg={4} xs={12} md={12} className="p-0">
                        <h2 className="icz-ripagetitle">{props.pageTitle} <span className='icz-ripagesubtitle'>{props.pageSubTitle}</span></h2>
                     </Col>

                    <Col lg={8} xs={12} md={12} className="p-0 icz-pagefiltersection">

                        <CampaignDropdown />

                        <DatePicker  onChange={onChange} open={open} setOpen={setOpen} data={date} />

                        <RiFilter />

                        <div className="icz-btnwrapper">
                            <button className="icz-themebtn">
                                <IconPdf className="icz-ribtnicon" /> </button>
                        </div>
                    </Col>
                </div>
            </div>

        </Container>
    )
}

export default RIPageHeader;
