import React, { Component } from "react"

import { Switch, Route } from 'react-router-dom';

import { Dashboard } from '../modules/dashboard/dashboard';

import { Aryabot } from '../modules/aryabot/aryabot';

import { Comparison } from '../modules/comparison/comparison';
import ComparisonSocial from '../modules/comparison/comparison-social/comparison-social';
import ComparisonTopicModelling from '../modules/comparison/comparison-topic-modelling/comparison-topic-modelling';
import ComparisonWebsite from '../modules/comparison/comparison-website/comparison-website';

import { CRM } from '../modules/crm/crm';


import { Ecom } from '../modules/ecom/ecom';
import { Customer } from '../modules/ecom/customer/Customer';
import Engagement from '../modules/ecom/Engagement/Engagement';
import { Traffic } from '../modules/ecom/Traffic/Traffic';
import { Hashtags } from '../modules/hashtags/hashtags';

import { PerformanceOverview } from '../modules/performance/performance-overview/performance-overview';
import PerformanceCampaign from '../modules/performance/performance-campaign/performance-campaign';
import PerformanceDevice from '../modules/performance/performance-device/performance-device';
import PerformanceLocation from '../modules/performance/performance-location/performance-location';


import { Reviews } from '../modules/reviews/reviews';

import { Social } from '../modules/social/social';
import SocialFacebook from '../modules/social/social-facebook/socialFacebook';
import SocialInstagram from '../modules/social/social-instagram/socialInstagram';
import SocialLinkedin from '../modules/social/social-linkedin/social-linkedin';
import SocialTwitter from '../modules/social/social-twitter/social-twitter';

import { Video } from '../modules/video/video';
import VideoFacebook from '../modules/video/video-facebook/video-facebook';
import VideoYoutube from '../modules/video/video-youtube/video-youtube';
import { Website } from '../modules/website/website';

import { RI } from '../modules/ri/ri';
import BusinessOverview from '../modules/ri/ri-businessoverview/RiBusinessOverview'
import RISellThrough from "../modules/ri/ri-sellthrough/RiSellThrough";
import SellThroughOverview from '../modules/ri/ri-sellthrough/RiSellThroughOverview';
import SalesSummaryOverview from "../modules/ri/ri-salessummary/RiSalesSummaryOverview";
import Summary from "../modules/ri/ri-summary/RiSummary";
import RISalesSummary from "../modules/ri/ri-salessummary/RiSalesSummary"
import RIStoreHealth from "../modules/ri/ri-storehealth/RiStoreHealth"
import RICustomer from "../modules/ri/ri-customer/RiCustomer";


import { Earned } from "../modules/ecom/Earned/Earned";
import Paid from "../modules/ecom/Paid/Paid";
import { Sales } from "../modules/ecom/Sales/Sales";
import { RealTime } from "../modules/ecom/RealTime/RealTime";
import StoreHealthOverview from "../modules/ri/ri-storehealth/RiStoreHealthOverview";



export class RouteUrls extends Component {

    render() {

        return (
            <Switch>

                {/* Dashboard */}
                <Route path="/dashboard" exact>
                    <Dashboard />
                </Route>

                {/* Aryabot */}
                <Route path="/aryabot" exact>
                    <Aryabot />
                </Route>

                {/* Comparison */}
                <Route path="/comparison" exact>
                    <Comparison />
                </Route>
                <Route path="/comparison/social" exact>
                    <ComparisonSocial />
                </Route>
                <Route path="/comparison/topic-modelling" exact>
                    <ComparisonTopicModelling />
                </Route>
                <Route path="/comparison/website" exact>
                    <ComparisonWebsite />
                </Route>

                {/* CRM */}
                <Route path="/crm" exact>
                    <CRM />
                </Route>

                {/* Ecommerce */}
                <Route path="/ecom" exact>
                    <Ecom />
                </Route>
                <Route path="/ecom/customer" exact>
                    <Customer />
                </Route>
                <Route path="/ecom/engagement" exact>
                    <Engagement />
                </Route>
                <Route path="/ecom/traffic" exact>
                    <Traffic />
                </Route>
                <Route path="/ecom/earned" exact>
                    <Earned />
                </Route>
                <Route path="/ecom/paid" exact>
                    <Paid />
                </Route>
                <Route path="/ecom/sales" exact>
                    <Sales />
                </Route>
                <Route path="/ecom/realtime" exact>
                    <RealTime />
                </Route>

                {/* Hashtag */}
                <Route path="/hashtags" exact>
                    <Hashtags />
                </Route>

                {/* Performance */}
                <Route path="/performance" exact>
                    <PerformanceOverview />
                </Route>
                <Route path="/performance/overview" exact>
                    <PerformanceOverview />
                </Route>
                <Route path="/performance/campaign" exact>
                    <PerformanceCampaign />
                </Route>
                <Route path="/performance/device" exact>
                    <PerformanceDevice />
                </Route>
                <Route path="/performance/location" exact>
                    <PerformanceLocation />
                </Route>


                {/* Reviews */}
                <Route path="/reviews" exact>
                    <Reviews />
                </Route>

                {/* Retail Intelligence */}
                {/* 
                <Route path="/" exact>
                    <RI />
                </Route> */}
                <Route path="/" exact>
                    <Summary />
                </Route>  <Route path="/?country=in" exact>
                    <Summary />
                </Route>
                
                <Route path="/?country=uk" exact>
                    <Summary />
                </Route>
                {/*                 
                <Route path="/ri/summary" exact>
                    <Summary/>
                </Route> */}
                <Route path="/ri/businessoverview" exact>
                    <BusinessOverview />
                </Route>
                <Route path="/ri/sellthrough" exact>
                    <SellThroughOverview />
                </Route>
                <Route path="/ri/salessummary" exact>
                    <SalesSummaryOverview />
                </Route>
                <Route path="/ri/storehealth" exact>
                    <StoreHealthOverview />
                </Route>
                {/* <Route path="/ri/customer" exact>
                    <RICustomer/>
                </Route> */}


                {/* Social */}
                <Route path="/react/social" exact>
                    <Social />
                </Route>
                <Route path="/social" exact>
                    <Social />
                </Route>
                <Route path="/social/facebook" exact>
                    <SocialFacebook />
                </Route>
                <Route path="/social/instagram" exact>
                    <SocialInstagram />
                </Route>
                <Route path="/social/linkedin" exact>
                    <SocialLinkedin />
                </Route>
                <Route path="/social/twitter" exact>
                    <SocialTwitter />
                </Route>

                {/* Video */}
                <Route path="/video" exact>
                    <Video />
                </Route>
                <Route path="/video/facebook" exact>
                    <VideoFacebook />
                </Route>
                <Route path="/video/youtube" exact>
                    <VideoYoutube />
                </Route>

                {/* Website */}
                <Route path="/website" exact>
                    <Website />
                </Route>

            </Switch>
        )
    }
}