import React, { Component } from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);
am4core.addLicense("CH258896422");

export default class MultiLineMultiAxesChart extends Component {
  componentDidMount() {

    console.log("this is props ", this.props)

    let info = this.props
    let title1 = info['metric_1']
    let title2 = info['metric_2']
    let title3 = info['metric_3']
    let per="%";
    if(title3=="ASP"){
      per="";
    }
    


    let chart_id = info['chart_id'];
    let data = info['graph-data'];

    console.log("cardname"); //Avgsession_barchart
    console.log("data", data);

    // Create chart instance
    let chart = am4core.create(chart_id, am4charts.XYChart);
    chart.exporting.menu = new am4core.ExportMenu();
    chart.data = data
    chart.fontSize = 12;

    // FOR FORMATTING LARGE NUMBERS
    if (this.props.isnumber == 'money') {
      chart.numberFormatter.numberFormat = "#.##a";
      chart.numberFormatter.bigNumberPrefixes = [
        { "number": 1e+3, "suffix": "K" },
        { "number": 1e+5, "suffix": "L" },
        { "number": 1e+7, "suffix": "Cr" }
      ];
    } else if(this.props.isnumber == 'number') {
      chart.numberFormatter.numberFormat = "#.##a";
      chart.numberFormatter.bigNumberPrefixes = [
        { "number": 1e+3, "suffix": "K" },
        { "number": 1e+6, "suffix": "M" },
        { "number": 1e+9, "suffix": "G" }
      ];
    }

    var colorSet = new am4core.ColorSet();
    colorSet.list = ["#11A1FD", "#FF9931", "#07C180", "#5A75F9"].map(function (color) {
      return new am4core.color(color);
    });
    chart.colors = colorSet;

    // Create category axis
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.line.strokeOpacity = 0.2; // for reducing the x axis opacity 
    categoryAxis.renderer.line.stroke = am4core.color("#707070"); // for changing the x axis color
    categoryAxis.renderer.labels.template.fill = "#757575"; //TO CHANGE COLOR OF X AXIS LABELS
    categoryAxis.renderer.minGridDistance = 0;
    categoryAxis.dataFields.category = "month";
    categoryAxis.renderer.labels.template.rotation = 0;
    categoryAxis.renderer.opposite = false;
    categoryAxis.tooltip.disabled = true;
    categoryAxis.startLocation = 0.5;
    categoryAxis.endLocation = 0;


    // Create value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis.renderer.minGridDistance = 20;
    valueAxis.renderer.line.strokeOpacity = 0; // for reducing the x axis opacity 
    valueAxis.renderer.line.stroke = am4core.color("#707070"); // for changing the Y axis color
    valueAxis.renderer.grid.template.strokeWidth = 0; //disable horizontal lines
    valueAxis.renderer.labels.template.fill = "#757575"; //TO CHANGE COLOR OF Y AXIS LABELS
    valueAxis.renderer.line.strokeOpacity = 0.2; // for reducing the Y axis opacity 
    valueAxis.tooltip.disabled = true; // for disabling to the y axis tooltip
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return text;
    });

    // Create value axis
    let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis.renderer.minGridDistance = 10;
    valueAxis2.renderer.opposite = true;
    valueAxis2.renderer.line.strokeOpacity = 0; // for reducing the x axis opacity 
    valueAxis2.renderer.line.stroke = am4core.color("#707070"); // for changing the Y axis color
    valueAxis2.renderer.grid.template.strokeWidth = 0; //disable horizontal lines
    valueAxis2.renderer.labels.template.fill = "#757575"; //TO CHANGE COLOR OF Y AXIS LABELS
    valueAxis2.renderer.line.strokeOpacity = 0.2; // for reducing the Y axis opacity 
    valueAxis2.tooltip.disabled = true; // for disabling to the y axis tooltip
    valueAxis2.renderer.labels.template.adapter.add("text", function (text) {
      return text ;
    });

    // Create value axis
    let valueAxis3 = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis.renderer.minGridDistance = 10;
    valueAxis3.renderer.opposite = true;
    valueAxis3.renderer.line.strokeOpacity = 0; // for reducing the x axis opacity 
    valueAxis3.renderer.line.stroke = am4core.color("#707070"); // for changing the Y axis color
    valueAxis3.renderer.grid.template.strokeWidth = 0; //disable horizontal lines
    valueAxis3.renderer.labels.template.fill = "#757575"; //TO CHANGE COLOR OF Y AXIS LABELS
    valueAxis3.renderer.line.strokeOpacity = 0.2; // for reducing the Y axis opacity 
    valueAxis3.tooltip.disabled = true; // for disabling to the y axis tooltip
    valueAxis3.renderer.labels.template.adapter.add("text", function (text) {
      return text + per;
    });



    // Create series
    let series1 = chart.series.push(new am4charts.LineSeries());
    series1.dataFields.valueY = "value1";
    series1.dataFields.categoryX = "month";
    series1.yAxis = valueAxis;
    series1.name = title1;
    series1.fillOpacity = 0.1;
    series1.tensionX = 0.8;
    series1.tooltipText = "{name}: {valueY}";
    series1.tooltip.getFillFromObject = false;
    series1.tooltip.background.fill = am4core.color("#213345");
    series1.tooltip.background.stroke = am4core.color("#213345");
    series1.tooltip.background.cornerRadius = 5;
    series1.tooltip.label.fontSize = 12;
    series1.tooltip.position = "pointer";

    var shadow = series1.tooltip.background.filters.getIndex(0);
    shadow.dx = 3;
    shadow.dy = 10;
    shadow.blur = 10;
    shadow.color = am4core.color("#14567829");
    series1.legendSettings.valueText = "{valueY}";

    let series2 = chart.series.push(new am4charts.LineSeries());
    series2.dataFields.valueY = "value2";
    series2.dataFields.categoryX = "month";
    series2.yAxis = valueAxis2;
    series2.name = title2;
    series2.tooltipText = "{name}: {valueY}";
    series2.tooltip.getFillFromObject = false;
    series2.tooltip.background.fill = am4core.color("#213345");
    series2.tooltip.background.stroke = am4core.color("#213345");
    series2.tooltip.background.cornerRadius = 5;
    series2.tooltip.label.fontSize = 12;
    series2.tooltip.position = "pointer";


    series2.fillOpacity = 0.1;
    series2.tensionX = 0.8;
    var shadow = series2.tooltip.background.filters.getIndex(0);
    shadow.dx = 3;
    shadow.dy = 10;
    shadow.blur = 10;
    shadow.color = am4core.color("#14567829");
    series2.legendSettings.valueText = "{valueY}";

    let series3 = chart.series.push(new am4charts.LineSeries());
    series3.dataFields.valueY = "value3";
    series3.dataFields.tooltiptext = "value3";
    series3.dataFields.categoryX = "month";
    series3.yAxis = valueAxis3;
    series3.name = title3;
    series3.tooltipText = "{name}: {tooltiptext}";
    series3.tooltip.getFillFromObject = false;
    series3.tooltip.background.fill = am4core.color("#213345");
    series3.tooltip.background.stroke = am4core.color("#213345");
    series3.tooltip.background.cornerRadius = 5;
    series3.tooltip.label.fontSize = 12;
    series3.tooltip.position = "pointer";

    series3.fillOpacity = 0.1;
    series3.tensionX = 0.8;
    var shadow = series3.tooltip.background.filters.getIndex(0);
    shadow.dx = 3;
    shadow.dy = 10;
    shadow.blur = 10;
    shadow.color = am4core.color("#14567829");
    series3.legendSettings.valueText = "{valueY}";


    let fillModifier = new am4core.LinearGradientModifier();
    fillModifier.opacities = [1, 1, 0];
    fillModifier.offsets = [0, 0.5, 1];
    fillModifier.gradient.rotation = 90;
    series1.segments.template.fillModifier = fillModifier;
    series2.segments.template.fillModifier = fillModifier;
    series3.segments.template.fillModifier = fillModifier;

    valueAxis.renderer.line.strokeOpacity = 1;
    valueAxis.renderer.line.strokeWidth = 2;
    valueAxis.renderer.line.stroke = series1.stroke;
    valueAxis.renderer.labels.template.fill = series1.stroke;

    valueAxis2.renderer.line.strokeOpacity = 1;
    valueAxis2.renderer.line.strokeWidth = 2;
    valueAxis2.renderer.line.stroke = series2.stroke;
    valueAxis2.renderer.labels.template.fill = series2.stroke;

    valueAxis3.renderer.line.strokeOpacity = 1;
    valueAxis3.renderer.line.strokeWidth = 2;
    valueAxis3.renderer.line.stroke = series3.stroke;
    valueAxis3.renderer.labels.template.fill = series3.stroke;


    // Add chart cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomY";

    // add legend
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    let marker = chart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(12, 12, 12, 12);

    let markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 15;
    markerTemplate.height = 15;


    chart.cursor = new am4charts.XYCursor();
    chart.padding(10, 0, 0, 0);
  }


  render() {
    let chart_class = this.props.chart_class;
    let chart_id = this.props.chart_id;

    return (
      <div>
        <div id={chart_id} className={chart_class}></div>
      </div>
    )
  }
}
