import React, { Component } from 'react';
import Wrapper from "../../../helpers/wrapper";
import Col from "react-bootstrap/esm/Col";
import {Sparklines, SparklinesLine, SparklinesCurve} from 'react-sparklines';
import { Dropdown } from "../../../shared/components/dropdown/dropdown"
import IconDownload from '../../../../assets/images/icons/downloadIcon'
import RiStoreBubbleChart from '../../../shared/components/charts/RiStoreBubbleChart';
import RiDataTable from '../../../shared/components/datatable/RiDataTable';

export class SellthroughExceptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown_options: [{ "value": "soh", "label": "SOH" }, { "value": "nsv", "label": "NSV" }, { "value": "sale", "label": "Sale%" }, { "value": "units", "label": "Units" }, { "value": "asp", "label": "ASP" }, { "value": "wks", "label": "Wks on Floor" }, { "value": "fwd", "label": "Fwd Week Cover" }],
      performancetrend: [{ "year": "2016", "value1": 1, "value2": 5, "value3": 3 }, { "year": "2017", "value1": 3, "value2": 6, "value3": 4 }, { "year": "2018", "value1": 5, "value2": 4, "value3": 5 }, { "year": "2019", "value1": 7, "value2": 6, "value3": 6 }, { "year": "2020", "value1": 8, "value2": 7, "value3": 5 }, { "year": "2021", "value1": 7, "value2": 6, "value3": 3 }],
      storedata: '[{"name":"Store1","y":470,"x":10,"value":20,"y2":550,"x2":18,"value2":20,"y3":1350,"x3":8,"value3":24},{"name":"Store2","y":675,"x":20,"value":20,"y2":810,"x2":14,"value2":23,"y3":320,"x3":23,"value3":20},{"name":"Store3","y":590,"x":28,"value":20,"y2":1800,"x2":35,"value2":25,"y3":960,"x3":41,"value3":22},{"name":"Store4","y":676,"x":51,"value":25,"y2":245,"x2":76,"value2":28,"y3":654,"x3":88,"value3":24},{"name":"Store5","y":2305,"x":86,"value":22,"y2":710,"x2":92,"value2":22,"y3":676,"x3":93,"value3":24},{"name":"Store6","y":853,"x":99,"value":22,"y2":702,"x2":75,"value2":21,"y3":564,"x3":60,"value3":24},{"name":"Store7","y":3001,"x":36,"value":20,"y2":1725,"x2":43,"value2":24,"y3":644,"x3":76,"value3":20}]',
      headers: [{ name: "Category Name", field: "title1", sortable: true }, { name: "Inward", field: "title2", sortable: true }, { name: "SOH", field: "title3", sortable: true }, { name: "Units Sold", field: "title4", sortable: true }, { name: "Sell Thru%", field: "title5", sortable: true }, { name: "Wks on Floor", field: "title6", sortable: true }, { name: "Fwd Wk CVR", field: "title7", sortable: true }, { name: "ROS%", field: "title8", sortable: true }, { name: "Markdown", field: "title9", sortable: true }, { name: "Comment", field: "title10", sortable: true }, { name: "Trend", field: "title11", sortable: true }],
      tableDatatop: [{"title1":"Malad (1234)","title2":"10","title3":"100k","title4":"10k","title5":"10%","title6":"4", "title7":"8","title8":"0.5%","title9":"50%","title10":"Inward","title11":<Sparklines data={[5, 10, 5, 20, 8]}><SparklinesCurve style={{ strokeWidth: 4, stroke: "#11a1fd", fill: "none" }}/></Sparklines>}, {"title1":"City Gold (2345)","title2":"10","title3":"100k","title4":"10k","title5":"10%","title6":"4", "title7":"8","title8":"0.5%","title9":"50%","title10":"Inward","title11": <Sparklines data={[5, 10, 5, 20, 8]}><SparklinesCurve style={{ strokeWidth: 4, stroke: "#11a1fd", fill: "none" }}/></Sparklines>}],
      tableDatabottom: [{"title1":"Andheri (5634)","title2":"10","title3":"200k","title4":"1k","title5":"0.5%","title6":"10", "title7":"50","title8":"2.5%","title9":"40%","title10":"Liquidation","title11":<Sparklines data={[5, 10, 5, 20, 8]}><SparklinesCurve style={{ strokeWidth: 4, stroke: "#11a1fd", fill: "none" }}/></Sparklines>}, {"title1":"Goa (4955)","title2":"10","title3":"200k","title4":"1k","title5":"0.5%","title6":"10", "title7":"50","title8":"2.5%","title9":"60%","title10":"Transfer","title11": <Sparklines data={[5, 10, 5, 20, 8]}><SparklinesCurve style={{ strokeWidth: 4, stroke: "#11a1fd", fill: "none" }}/></Sparklines>}]
      }
  }
  render() {
    return (
      <Wrapper>
        <div className="icz-row">
          <div className="icz-sectioncardwrapper w-100">
            <div className="icz-sectioncard">
              <Col sm={12} className="icz-cardheader">
                <Col className="icz-cardtitle">
                  Store Deep-dive
                </Col>
              </Col>
              <div className="">
                <RiStoreBubbleChart chart_id="store-data" chart_class="icz-sectionchart" Ytitle={"SOH"} Xtitle={"Units Sold"} graph-data={this.state.storedata} />
              </div>
              <div className="">
                                <Col lg={12} className="d-flex">
                                    <Col lg={6} className="icz-ridatabullet">
                                        <ul className="icz-ridatalist-inline">
                                            <li><div className="icz-ridatalist-bullet5" /> High NSV%</li>
                                            <li><div className="icz-ridatalist-bullet2" /> Med NSV%</li>
                                            <li><div className="icz-ridatalist-bullet6" /> Low NSV%</li>
                                        </ul>
                                    </Col>
                                    <Col lg={6} className="icz-ridatabullet">
                                        <ul className="icz-ridatalist-inline">
                                            <li><div className="icz-ridatalist-bullet7" /> New Store</li>
                                            <li><div className="icz-ridatalist-bullet8" /> Mature Store</li>
                                            <li><div className="icz-ridatalist-bullet9" /> LTL Store</li>
                                        </ul>
                                    </Col>

                                </Col>
                            </div>
            </div>
          </div>
        </div>

        <div className="icz-row">
          <div className="icz-sectioncardwrapper w-100">
            <div className="icz-sectioncard">
              <div className="icz-cardbody">
                <RiDataTable tableTitle="Top" Headers={this.state.headers} TableSize={2} TableData={this.state.tableDatatop} className="MainDiv icz-table" />
              </div>
            </div>
          </div>
        </div>

        <div className="icz-row">
          <div className="icz-sectioncardwrapper w-100">
            <div className="icz-sectioncard">
              <div className="icz-cardbody">
                <RiDataTable tableTitle="Bottom" Headers={this.state.headers} TableSize={2} TableData={this.state.tableDatabottom} className="MainDiv icz-table" />
              </div>
            </div>
          </div>
        </div>

      </Wrapper>
    );
  }
}
