import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import IconDownArrow from "../../../assets/images/icons/downArrow";
import Wrapper from "../../helpers/wrapper";

const MenuItem = (props) => {
  const { name, subMenus, iconClassName, to, parentCallBack } = props;
  const [expand, setExpand] = useState(false);


  return (

    <Wrapper>
      {subMenus && subMenus.length > 0 ?
        <Wrapper>
          <li onClick={props.onClick} className="icz-sidebarlist">
            <div onClick={() => { setExpand((e) => !e) }} className={`icz-sidebarlink`} >
              {iconClassName}
              <span>{name}{subMenus.length > 0 ? <IconDownArrow className='icz_dropdownIcon' /> : ''}</span>
            </div>
            <ul className={`icz-submenu ${expand ? "active" : ""}`} >
              {subMenus.map((menu, index) => (
                <li key={index} onClick={parentCallBack} className="icz-sidesubmenu">
                  <NavLink to={menu.to} className="icz-sidesublink" >{menu.name}</NavLink>
                </li>
              ))}
            </ul>
          </li>
        </Wrapper>
        :
        <li className="icz-sidebarlist">
          <Link exact to={to} onClick={() => { setExpand((e) => e) }} className={`icz-sidebarlink`} >
            {iconClassName}
            <span>{name}{subMenus.length > 0 ? <IconDownArrow className='icz_dropdownIcon' /> : ''}</span>
          </Link>
        </li>
      }
    </Wrapper>

  );
};

export default MenuItem;