import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);
am4core.addLicense("CH258896422");

export default class MultipartGaugeChart extends Component {
  componentDidMount() {
    console.log("MultipartGaugeChart", this.props)

    let info = this.props

    let chart_id = info['chart_id'];
    // let data = info['graph-data'];

    console.log("cardname", this.props['hand3Value'], typeof this.props['hand3Value']); //Avgsession_barchart
    // console.log("data", data);

    // Create chart instance
    let chart = am4core.create(chart_id, am4charts.GaugeChart);
    chart.exporting.menu = new am4core.ExportMenu();
    chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect

    // Set number format
    // chart.numberFormatter.numberFormat = "#.#'%'";

    chart.startAngle = 0;
    chart.endAngle = 360;

    // chart.data = data
    chart.fontSize = 11;

    function createAxis(min, max, start, end, color) {
      let axis = chart.xAxes.push(new am4charts.ValueAxis());
      axis.min = min;
      axis.max = max;
      axis.strictMinMax = true;
      axis.renderer.useChartAngles = false;
      axis.renderer.startAngle = start;
      axis.renderer.endAngle = end;
      axis.renderer.minGridDistance = 100;

      axis.renderer.line.strokeOpacity = 1;
      axis.renderer.line.strokeWidth = 6;
      axis.renderer.line.stroke = am4core.color(color);

      axis.renderer.ticks.template.disabled = false;
      axis.renderer.ticks.template.stroke = am4core.color(color);
      axis.renderer.ticks.template.strokeOpacity = 1;
      axis.renderer.grid.template.disabled = true;
      axis.renderer.ticks.template.length = 10;

      return axis;
    }

    function createHand(axis) {
      let hand = chart.hands.push(new am4charts.ClockHand());
      hand.fill = axis.renderer.line.stroke;
      hand.stroke = axis.renderer.line.stroke;
      hand.axis = axis;
      hand.pin.disabled = true;
      hand.startWidth = 10;
      hand.endWidth = 0;
      hand.radius = am4core.percent(90);
      hand.innerRadius = am4core.percent(70);
      hand.value = 0;
      return hand;
    }

    let axis1 = createAxis(0, 5, -85, -5, "#5a75f9");
    let axis2 = createAxis(0, 5, 5, 85, "#ff9931");
    let axis3 = createAxis(0, 5, 95, 175, "#07c180");
    let axis4 = createAxis(0, 5, 185, 265, "#11a1fd");

    let hand1 = createHand(axis1);
    let hand2 = createHand(axis2);
    let hand3 = createHand(axis3);
    let hand4 = createHand(axis4);

    hand1.value = this.props.hand1Value;
    hand2.value = this.props.hand2Value;
    hand3.value = this.props.hand3Value;
    hand4.value = this.props.hand4Value;


    // Padding
    chart.paddingTop = 5;
    chart.paddingLeft = 10;
    chart.paddingRight = 10;
    chart.paddingBottom = 5;

    let tootltiphand1 = hand1.value? hand1.value  + '%' : 'n/a';
    let tootltiphand2 = hand2.value? hand2.value  + '%' : 'n/a';
    let tootltiphand3 = hand3.value? hand3.value  + '%' : 'n/a';
    let tootltiphand4 = hand4.value? hand4.value  + '%' : 'n/a';
    var tooltipHTML =
      `
      <div class="w-100 d-flex flex-column justify-space-between" style="width: 100%">
        <div class="text-left d-flex flex-column justify-start" style="width: 150px; padding:10px">
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
              <span style="font-size: 13px; color: #fff; font-weight: 400">`+ tootltiphand1 +  ` </span>
              <span style="font-size: 12px; color: #7D9EB5; font-weight: 300">Overall</span>
            </div>
            <div class="d-flex flex-column">
              <span style="font-size: 13px; color: #fff; font-weight: 400">`+ tootltiphand2 + `</span>
              <span style="font-size: 12px; color: #7D9EB5; font-weight: 300">Zone</span>
            </div>
          </div>
          <hr style="margin: 4px 0"/>
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
              <span style="font-size: 13px; color: #fff; font-weight: 400">`+ tootltiphand3 + ` </span>
              <span style="font-size: 12px; color: #7D9EB5; font-weight: 300">City</span>
            </div>
            <div class="d-flex flex-column">
              <span style="font-size: 13px; color: #fff; font-weight: 400">`+ tootltiphand4 + ` </span>
              <span style="font-size: 12px; color: #7D9EB5; font-weight: 300">Store</span>
            </div>
          </div>
        </div>
      </div>  
      `;

    chart.tooltip.getFillFromObject = false;
    chart.tooltip.background.fill = am4core.color("#213345");
    chart.tooltip.background.stroke = am4core.color("#213345");
    chart.tooltip.background.cornerRadius = 5;
    chart.tooltip.label.minWidth = 150;
    chart.tooltip.label.padding(0, 0, 0, 0);
    chart.tooltip.label.fontSize = 12;
    chart.tooltip.position = "pointer";
    chart.tooltip.pointerOrientation = "vertical";
    chart.tooltipHTML = tooltipHTML;
    var shadow = chart.tooltip.background.filters.getIndex(0);
    shadow.dx = 3;
    shadow.dy = 10;
    shadow.blur = 10;
    shadow.color = am4core.color("#14567829");

  }

  render() {
    let chart_class = this.props.chart_class;
    let chart_id = this.props.chart_id;

    return (
      <div>
        <div id={chart_id} className={chart_class}></div>
      </div>
    )
  }
}
