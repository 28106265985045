const IconAllDevices = (props) => {
    return (
        <svg className={props.className} width="54" height="54" viewBox="0 0 54 54" fill="none" stroke="#486F88" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.212 13C12.1092 13 11.212 13.8972 11.212 15V34C11.212 35.1028 12.1092 36 13.212 36H37.212C38.3148 36 39.212 35.1028 39.212 34V15C39.212 13.8972 38.3148 13 37.212 13H13.212ZM13.212 12H37.212C38.8689 12 40.212 13.3431 40.212 15V34C40.212 35.6569 38.8689 37 37.212 37H13.212C11.5551 37 10.212 35.6569 10.212 34V15C10.212 13.3431 11.5551 12 13.212 12Z" fill="#486F88" />
            <path d="M18.212 39.5H32.212C32.6098 39.5 32.9914 39.658 33.2727 39.9393C33.554 40.2206 33.712 40.6022 33.712 41C33.712 41.1326 33.6593 41.2598 33.5656 41.3536C33.4718 41.4473 33.3446 41.5 33.212 41.5H17.212C17.0794 41.5 16.9522 41.4473 16.8585 41.3536C16.7647 41.2598 16.712 41.1326 16.712 41C16.712 40.6022 16.87 40.2206 17.1513 39.9393C17.4326 39.658 17.8142 39.5 18.212 39.5V39.5Z" stroke="#486F88" />
            <path d="M10.712 30.03H39.712" stroke="#486F88" />
            <path d="M25.212 34.5C26.0404 34.5 26.712 33.8284 26.712 33C26.712 32.1716 26.0404 31.5 25.212 31.5C24.3836 31.5 23.712 32.1716 23.712 33C23.712 33.8284 24.3836 34.5 25.212 34.5Z" stroke="#486F88" />
            <path d="M20.712 36.5L19.584 39.555" stroke="#486F88" />
            <path d="M29.712 36.5L30.84 39.555" stroke="#486F88" />
            <path d="M6.977 32.232H11.861C12.1201 32.232 12.3686 32.3349 12.5518 32.5182C12.7351 32.7014 12.838 32.9499 12.838 33.209V41.023C12.838 41.1513 12.8127 41.2783 12.7636 41.3969C12.7145 41.5154 12.6426 41.6231 12.5518 41.7138C12.4611 41.8046 12.3534 41.8765 12.2349 41.9256C12.1163 41.9747 11.9893 42 11.861 42H6.977C6.8487 42 6.72165 41.9747 6.60312 41.9256C6.48458 41.8765 6.37688 41.8046 6.28616 41.7138C6.19543 41.6231 6.12347 41.5154 6.07437 41.3969C6.02527 41.2783 6 41.1513 6 41.023V33.209C6 32.9499 6.10293 32.7014 6.28616 32.5182C6.46938 32.3349 6.71788 32.232 6.977 32.232Z" fill="#F8F8F8" stroke="#486F88" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M47.212 25H39.212C38.1074 25 37.212 25.8954 37.212 27V40C37.212 41.1046 38.1074 42 39.212 42H47.212C48.3166 42 49.212 41.1046 49.212 40V27C49.212 25.8954 48.3166 25 47.212 25Z" fill="#F8F8F8" />
            <path d="M47.212 25.5H39.212C38.3836 25.5 37.712 26.1716 37.712 27V40C37.712 40.8284 38.3836 41.5 39.212 41.5H47.212C48.0404 41.5 48.712 40.8284 48.712 40V27C48.712 26.1716 48.0404 25.5 47.212 25.5Z" stroke="#486F88" />
            <path d="M37.712 27.993H48.982" stroke="#486F88" />
            <path d="M37.712 38.097H48.982" stroke="#486F88" />
        </svg>
    )
}

export default IconAllDevices;