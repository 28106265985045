import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);

export default class SankeyChart extends Component {
    componentDidMount() {

        let info = this.props
        let chart_id = info['chart_id'];
        // let data = info['graph-data'];
        let chart = am4core.create(chart_id, am4charts.SankeyDiagram);
        chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

        chart.data = [
          { from: "Channel", to: "Source", value: 1, id:"A0-0" },
          { from: "Channel", to: "Platform", value: 1, id:"A1-0" },
          { from: "Channel", to: "Source", value: 1, id:"A2-0" },
          
          { from: "User Type", to: "Source", value: 1, id:"B0-0" },
          { from: "User Type", to: "Channel", value: 1, id:"B1-0" },
          { from: "User Type", to: "Platform", value: 1, id:"B2-0" },  
          
          { from: "Platform", to: "Source", value: 1, id:"C0-0" },
          { from: "Platform", to: "Channel", value: 1, id:"C1-0" },
          { from: "Platform", to: "Source", value: 1, id:"C2-0" },    
        
          { from: "Source", to: "Channel", value: 1, id:"D0-0" },
          { from: "Source", to: "Source", value: 1, id:"D1-0" },
          { from: "Source", to: "Platform", value: 1, id:"D2-0" },      
          { from: "Source", to: "Channel", value: 1, id:"A0-1" },        
        
        ];

        let hoverState = chart.links.template.states.create("hover");
        hoverState.properties.fillOpacity = 0.6;
        
        chart.dataFields.fromName = "from";
        chart.dataFields.toName = "to";
        chart.dataFields.value = "value";
        chart.colors.list = [
          am4core.color("#07c180"),
          am4core.color("#07cf89"),
          am4core.color("#08e296"),
          am4core.color("#09f6a3"),
          am4core.color("#1df7ab"),
          am4core.color("#30f8b2"),
          am4core.color("#44f8b9"),
          am4core.color("#adbafc"),
          
      ];
        chart.links.template.propertyFields.id = "id";
        chart.links.template.colorMode = "solid";
        chart.links.template.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
        chart.links.template.fillOpacity = 0.1;
        chart.links.template.tooltipText = "";
        
        // highlight all links with the same id beginning
        chart.links.template.events.on("over", function(event){
          let link = event.target;
          let id = link.id.split("-")[0];
        
          chart.links.each(function(link){
            if(link.id.indexOf(id) != -1){
              link.isHover = true;
            }
          })
        })
        
        chart.links.template.events.on("out", function(event){  
          chart.links.each(function(link){
            link.isHover = false;
          })
        })
        chart.paddingRight = 90;
        
        // make nodes draggable
        var nodeTemplate = chart.nodes.template;
        nodeTemplate.inert = true;
        nodeTemplate.readerTitle = "Drag me!";
        nodeTemplate.showSystemTooltip = true;
        nodeTemplate.width = 30;
        
        // make nodes draggable
        var nodeTemplate = chart.nodes.template;
        nodeTemplate.readerTitle = "Click to show/hide or drag to rearrange";
        nodeTemplate.showSystemTooltip = true;
        nodeTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer
    }

    render() {

        let chart_class = this.props.chart_class;
        let chart_id = this.props.chart_id;

        return (
            <div>
                <div id={chart_id} className={chart_class}></div>
            </div>
        )
    }
}
