import React, { useState } from "react";
import '../comparecard.scss'
import ProductCard from "./ProductCard";
import { Grid, Item, Table, Label } from "semantic-ui-react";
import Col from 'react-bootstrap/esm/Col';
import { Dropdown } from "../../../../shared/components/dropdown/dropdown"

const ProductComparison = ({ products }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [CheckedItems, setCheckedItems] = useState([]);
  const [disable, setDisable] = React.useState(true);
  const [isCompare, setCompare] = React.useState(false);


  const layout_dropdown_options = [{ "value": "layout", "label": "Layout" }, { "value": "a", "label": "A" }, { "value": "b", "label": "B" }];
  const label_dropdown_options = [{ "value": "boosted", "label": "Boosted" }, { "value": "a", "label": "A" }, { "value": "b", "label": "B" }];

  const addToCompare = (item, event) => {
    if (event.target.checked == true) {
      setCheckedItems((CheckedItems) => [...CheckedItems, item]);
      if (CheckedItems.length >= 1) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    }
    else {
      const filteredItems = CheckedItems.filter(
        (product) => product.id !== item.id
      );
      setCheckedItems((CheckedItems) => filteredItems);
      if (CheckedItems.length > 1) {
        setDisable(true);
      } else {
        setDisable(false);

      }
      setCompare(false);
    }
  };

  const MainCompare = () => {
    console.log("CheckedItems ===", CheckedItems)
    console.log("selected Item === ", selectedItems)
    setSelectedItems(CheckedItems);

    // setCompare(true);
  };


  const removeFromCompare = (item) => {
    // console.log("----------", item)
    // const filteredItems = selectedItems.filter(
    //   (product) => product.id !== item.id
    // );
    // setSelectedItems((selectedItems) => filteredItems);


    let newItems = [];
    selectedItems.map((info, index) => {
      if (info.id != item.id) {
        newItems.push(info);
      }
    });

    console.log("New Items ===== ", newItems);
    // const filteredItems = selectedItems.filter(
    //   (product) => product.id !== item.id
    // );
    setSelectedItems((selectedItems) => newItems);
    setCheckedItems((CheckedItems) => newItems);


  };

  const handleCallback = (childData) => {
    // this.setState({daily_metrics_name: childData, is_daily_data_loaded: false });
    // this.getPageDailyData();
    // alert('hi');
  }



  console.log('dropdown_options-------------------------------', layout_dropdown_options);
  console.log('dropdown_options-------------------------------', label_dropdown_options);

  return (
    <div>
      <Col lg={12} className="icz-compare-navcontainer">
       <Col lg={4} className="icz-comparetitle">Top Post</Col>
       <Col lg={8}>
       <ul className="icz-list-inline">
          <li><button onClick={() => MainCompare()} disabled={disable} className="btn btn-primary"> Compare </button></li>
          <li><Dropdown dropdown_options={layout_dropdown_options} dropdown_placeholder="Layout" parentCallback={handleCallback} /></li>
          <li><Dropdown dropdown_options={label_dropdown_options} dropdown_placeholder="Boosted" parentCallback={handleCallback} /></li>
        </ul>
       </Col>
      </Col>

      <Col className="overflox-x">
        <Grid column={selectedItems.length}>
          <Item.Group className="icz-compare">
            {products.map((product) => (
              <ProductCard
                key={product.id}
                product={product}
                selected={selectedItems}
                addToCompare={addToCompare}
                removeFromCompare={removeFromCompare}
              />
            ))}
          </Item.Group>
        </Grid>
      </Col>

      {selectedItems.length > 0 && (
     <div className="icz-comparison-result">
         <Table definition className="table-striped table-responsive overflox-x compare-table">
           <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              {selectedItems.map((el) => (
                <Table.HeaderCell key={el.id}>{el.name}</Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Label>

                </Label>
              </Table.Cell>
              {selectedItems.map((el) => (
                <Table.Cell><img src={el.image} className="icz-smallimg" /></Table.Cell>
              ))}
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Label>
                  Reactions
                </Label>
              </Table.Cell>
              {selectedItems.map((el) => (
                <Table.Cell key={el.id}>{el. minutesvalue}</Table.Cell>
              ))}
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Label>
                  Comments
                </Label>
              </Table.Cell>
              {selectedItems.map((el) => (
                <Table.Cell key={el.id}>{el.viewsvalue}</Table.Cell>
              ))}
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Label>
                  Reach
                </Label>
              </Table.Cell>
              {selectedItems.map((el) => (
                <Table.Cell key={el.id}>{el.likesvalue}</Table.Cell>
              ))}
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Label>
                  Shares
                </Label>
              </Table.Cell>
              {selectedItems.map((el) => (
                <Table.Cell key={el.id}>{el.dislikesvalue}</Table.Cell>
              ))}
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Label>
                  Rate
                </Label>
              </Table.Cell>
              {selectedItems.map((el) => (
                <Table.Cell key={el.id}>{el.viewrate}</Table.Cell>
              ))}
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Label>
                  Clicks
                </Label>
              </Table.Cell>
              {selectedItems.map((el) => (
                <Table.Cell key={el.id}>{el.completionvalue}</Table.Cell>
              ))}
            </Table.Row>
            
          </Table.Body>
        </Table>
     </div>
      )
      }


    </div >
  );

};

export default ProductComparison;
