import React, { Component } from 'react'
import Wrapper from "../../../helpers/wrapper";
import RICustomerHeader from '../../../layout/ri-pageheader/ri-customerheader';
import { Tab } from "../../../shared/components/tabs/tab";
import { TabNav } from "../../../shared/components/tabs/tabnav";
import IconUserGroup from "../../../../assets/images/icons/usergroupIcon";
import IconWebsite from '../../../../assets/images/icons/websiteIcon';
import {CustomerStudy} from './RiCustomerStudy';
import {CustomerTracker} from './RiCustomerTracker';

export default class CustomerOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
          selectedSubNav: 'Customer Study',
        }
    
        console.log('ri tab')
      }
    
      setSelectedSubNav = (tab) => {
        this.setState({ selectedSubNav: tab });
      }

  render() {
    return (
        <Wrapper>
        <RICustomerHeader pageTitle="Customer" />
        <div className="icz-datacontainer">
          <div className="icz-sectiontabscontainer">
            <div className="icz-tabscontrols">
              <TabNav tabs={['Customer Study', 'Customer Tracker']} icon={[<IconUserGroup className="icz-tabicon" />, <IconWebsite className="icz-tabicon" />]} selected={this.state.selectedSubNav} setSelected={this.setSelectedSubNav}>
                <Tab isSelected={this.state.selectedSubNav === 'Customer Study'}>
                 <CustomerStudy/>
                </Tab>
                <Tab isSelected={this.state.selectedSubNav === 'Customer Tracker'}>
                 <CustomerTracker/>
                </Tab>
               </TabNav>
            </div>
          </div>
        </div>
        </Wrapper>
    )
  }
}
