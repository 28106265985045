import React, { Component } from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
am4core.addLicense("CH258896422");


export default class SolidGaugeChart extends Component {
    componentDidMount() {
        let info = this.props;

        let chart_id = info['chart_id'];
        let data = info['graph-data'];

       // console.log('444', data)

        var chart = am4core.create(chart_id, am4charts.RadarChart);
        chart.exporting.menu = new am4core.ExportMenu();

        chart.data = data;
        chart.fontFamily = "Ubuntu";//TO CHANGE FONT SIZE OF CHART LABELS
        chart.fontSize = 11;//TO CHANGE FONT SIZE OF CHART LABELS
        chart.exporting.menu = new am4core.ExportMenu(); // TO EXPORT OF CHART

        chart.colors.list = [
            am4core.color("#5A75F9"),
            am4core.color("#FF9931"),
            am4core.color("#07C180"),
            am4core.color("#11A1FD")
        ];

        // Make chart not full circle
        chart.startAngle = -90;
        chart.endAngle = 180;
        chart.innerRadius = am4core.percent(30);

        // Create axes
        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.labels.template.fill = "#757575"; //TO CHANGE COLOR OF X AXIS LABELS
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.grid.template.strokeOpacity = 0;
        categoryAxis.renderer.labels.template.horizontalCenter = "right";
        categoryAxis.renderer.labels.template.disabled = false;
        categoryAxis.renderer.labels.template.adapter.add("fill", function (fill, target) {
            return (target.dataItem.index >= 0) ? chart.colors.getIndex(target.dataItem.index) : fill;
        });
        categoryAxis.renderer.minGridDistance = 10;
        categoryAxis.tooltip.disabled = true;

        let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.labels.template.fill = "#757575"; //TO CHANGE COLOR OF Y AXIS LABELS
        valueAxis.renderer.labels.template.disabled = true;
        valueAxis.renderer.grid.template.strokeOpacity = 0;
        valueAxis.min = 0;
        valueAxis.max = 100;
        valueAxis.strictMinMax = true;
        valueAxis.tooltip.disabled = true;

        // Create series
        let series1 = chart.series.push(new am4charts.RadarColumnSeries());
        series1.dataFields.valueX = "full";
        series1.dataFields.categoryY = "category";
        series1.clustered = false;
        series1.columns.template.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
        series1.columns.template.fillOpacity = 0.08;
        series1.columns.template.cornerRadiusTopLeft = 20;
        series1.columns.template.width = 5;
        series1.columns.template.strokeWidth = 0;
        series1.columns.template.radarColumn.cornerRadius = 20;


        let series2 = chart.series.push(new am4charts.RadarColumnSeries());
        series2.dataFields.valueX = "value";
        series2.dataFields.categoryY = "category";
        series2.clustered = false;
        series2.columns.template.strokeWidth = 0;
        series2.columns.template.width = 5;
        series2.columns.template.radarColumn.cornerRadius = 20;

        var shadow = series2.tooltip.background.filters.getIndex(0);
        shadow.dx = 3;
        shadow.dy = 10;
        shadow.blur = 10;
        shadow.color = am4core.color("#14567829");


        series2.columns.template.adapter.add("fill", function (fill, target) {
            return chart.colors.getIndex(target.dataItem.index);
        });

        // Add cursor
        chart.cursor = new am4charts.RadarCursor();

        //Padding
        chart.paddingTop = -10;
        chart.paddingLeft = 0;
        chart.paddingRight = 0;
        chart.paddingBottom = 0;

        var tooltipHTML =
        `
            <div class="w-100 d-flex flex-column justify-space-between" style="width: 100%">
                <div class="text-left d-flex flex-column justify-start" style="width: 150px; padding:10px">
                <div class="d-flex justify-content-between">
                    <div class="d-flex flex-column">
                    <span style="font-size: 13px; color: #fff; font-weight: 400">`+ data[0]['value'] + `</span>
                    <span style="font-size: 12px; color: #7D9EB5; font-weight: 300">Overall</span>
                    </div>
                    <div class="d-flex flex-column">
                    <span style="font-size: 13px; color: #fff; font-weight: 400">`+ data[1]['value'] + `</span>
                    <span style="font-size: 12px; color: #7D9EB5; font-weight: 300">Zone</span>
                    </div>
                </div>
                <hr style="margin: 4px 0"/>
                <div class="d-flex justify-content-between">
                    <div class="d-flex flex-column">
                    <span style="font-size: 13px; color: #fff; font-weight: 400">`+ data[2]['value'] + `</span>
                    <span style="font-size: 12px; color: #7D9EB5; font-weight: 300">City</span>
                    </div>
                    <div class="d-flex flex-column">
                    <span style="font-size: 13px; color: #fff; font-weight: 400">`+ data[3]['value'] + `</span>
                    <span style="font-size: 12px; color: #7D9EB5; font-weight: 300">Store</span>
                    </div>
                </div>
                </div>
            </div>  
      `;

        chart.tooltip.getFillFromObject = false;
        chart.tooltip.background.fill = am4core.color("#213345");
        chart.tooltip.background.stroke = am4core.color("#213345");
        chart.tooltip.background.cornerRadius = 5;
        chart.tooltip.label.minWidth = 150;
        chart.tooltip.label.padding(0, 0, 0, 0);
        chart.tooltip.label.fontSize = 12;
        chart.tooltip.position = "pointer";
        chart.tooltip.pointerOrientation = "vertical";
        chart.tooltipHTML = tooltipHTML;
        var shadow = chart.tooltip.background.filters.getIndex(0);
        shadow.dx = 3;
        shadow.dy = 10;
        shadow.blur = 10;
        shadow.color = am4core.color("#14567829");

    }

    render() {
        let chart_class = this.props.chart_class;
        let chart_id = this.props.chart_id;

        return (
            <div id={chart_id} className={chart_class}></div>
        )
    }
}
