import IconSmiley from '../../../../../assets/images/icons/smileyIcon';
import IconComments from '../../../../../assets/images/icons/commentsIcon';
import IconShare from '../../../../../assets/images/icons/shareIcon';
import IconClicks from '../../../../../assets/images/icons/clicksIcon';
import IconReach from "../../../../../assets/images/icons/reachIcon";
import IconCRM from "../../../../../assets/images/icons/crmIcon"
import IconEngagement from '../../../../../assets/images/icons/engagementIcon';
import Post1 from '../../../../../assets/images/post.png';

export const products = [
  {
    id: "1",
    name: "Post 1",
    image: Post1,
    time: "10:00 AM | 01 Jan 2021",
    reactionicon: <IconSmiley className="icz-icon"/>,
    reactionvalue: "100K",
    reactions: "Reactions",
    commentsicon: <IconComments className="icz-icon"/>,
    comments: "Comments",
    commentsvalue: "120K",
    reachicon: <IconReach className="icz-icon"/>,
    reach: "Organic Reach",
    reachvalue: "50K",
    sharesicon: <IconShare className="icz-icon"/>,
    shares: "Shares",
    sharesvalue: "80K",
    rateicon: <IconEngagement className="icz-icon"/>,
    rate: "Engagement Rate",
    engagementrate: "110",
    clicksicon: <IconClicks className="icz-icon"/>,
    clicks: "Link Clicks",
    clickesvalue: "200",
    posticon: <IconCRM className="icz-icon"/>,
    post: "Post Clicks",
    postvalue: "135",
    link: "View Post"
  },
  {
    id: "2",
    name: "Post 2",
    image: Post1,
    time: "10:00 AM | 01 Jan 2021",
    reactionicon: <IconSmiley className="icz-icon"/>,
    reactionvalue: "100K",
    reactions: "Reactions",
    commentsicon: <IconComments className="icz-icon"/>,
    comments: "Comments",
    commentsvalue: "120K",
    reachicon: <IconReach className="icz-icon"/>,
    reach: "Organic Reach",
    reachvalue: "50K",
    sharesicon: <IconShare className="icz-icon"/>,
    shares: "Shares",
    sharesvalue: "80K",
    rateicon: <IconEngagement className="icz-icon"/>,
    rate: "Engagement Rate",
    engagementrate: "110",
    clicksicon: <IconClicks className="icz-icon"/>,
    clicks: "Link Clicks",
    clickesvalue: "200",
    posticon: <IconCRM className="icz-icon"/>,
    post: "Post Clicks",
    postvalue: "135",
    link: "View Post"
  },
  {
    id: "3",
    name: "Post 3",
    image: Post1,
    time: "10:00 AM | 01 Jan 2021",
    reactionicon: <IconSmiley className="icz-icon"/>,
    reactionvalue: "100K",
    reactions: "Reactions",
    commentsicon: <IconComments className="icz-icon"/>,
    comments: "Comments",
    commentsvalue: "120K",
    reachicon: <IconReach className="icz-icon"/>,
    reach: "Organic Reach",
    reachvalue: "50K",
    sharesicon: <IconShare className="icz-icon"/>,
    shares: "Shares",
    sharesvalue: "80K",
    rateicon: <IconEngagement className="icz-icon"/>,
    rate: "Engagement Rate",
    engagementrate: "110",
    clicksicon: <IconClicks className="icz-icon"/>,
    clicks: "Link Clicks",
    clickesvalue: "200",
    posticon: <IconCRM className="icz-icon"/>,
    post: "Post Clicks",
    postvalue: "135",
    link: "View Post"
  },
  {
    id: "4",
    name: "Post 4",
    image: Post1,
    time: "10:00 AM | 01 Jan 2021",
    reactionicon: <IconSmiley className="icz-icon"/>,
    reactionvalue: "100K",
    reactions: "Reactions",
    commentsicon: <IconComments className="icz-icon"/>,
    comments: "Comments",
    commentsvalue: "120K",
    reachicon: <IconReach className="icz-icon"/>,
    reach: "Organic Reach",
    reachvalue: "50K",
    sharesicon: <IconShare className="icz-icon"/>,
    shares: "Shares",
    sharesvalue: "80K",
    rateicon: <IconEngagement className="icz-icon"/>,
    rate: "Engagement Rate",
    engagementrate: "110",
    clicksicon: <IconClicks className="icz-icon"/>,
    clicks: "Link Clicks",
    clickesvalue: "200",
    posticon: <IconCRM className="icz-icon"/>,
    post: "Post Clicks",
    postvalue: "135",
    link: "View Post"
  }
];
