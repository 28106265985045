import React, { Component } from 'react';
import { Col, Container } from 'react-bootstrap';
import "../ecom/ecom.scss";
import Converstion_funnel from '../../shared/components/charts/Converstion_funnel';
import Piechart from '../../shared/components/charts/Piechart';
// import SummaryDropdown from './Summary/SummaryDropdown';
import CategoryPiechart from '../../shared/components/charts/CategoryPiechart';
import { useState } from "react";
// import Dropdown from './Summary//Dropdown';
// import BarChart from '../../shared/components/charts/BarChart';
import Linegraph_sparkline from '../../shared/components/charts/Linegraph_sparkline';
import { ChartCard } from '../../shared/components/cards/chartcard/chartcard'
import { BarChart } from '../../shared/components/charts/BarChart';
import { Carousel } from '../../shared/components/carousel/carousel';
import PageHeader from '../../layout/pageheader/pageheader';
import '../../shared/components/cards/card.scss'
import IconWebsite from '../../../assets/images/icons/websiteIcon';
import { DailydataDropdown } from '../../shared/components/dropdown/dailydatadropdown';

import { Link } from 'react-router-dom'

// import { API_BASE_URL } from '../../../helpers/constant';



const API_URL = 'http://127.0.0.1:8000';


const sortObject = obj => {
    const arr = Object.keys(obj).map(el => {
        return obj[el];
    });
    arr.sort((a, b) => {
        return a - b;
    });
    return arr;
};

export class Ecom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trafficpievalue: '[{"name": "Social","value": 30},{"name": "Direct","value": 30},{ "name": "Website","value": 50},{ "name": "Email","value": 40},{ "name": "Organic","value": 60}]',
            bargraphsession: '[{"name": "Jan", "points": 100}, {"name": "Feb", "points": 200},{"name": "march", "points": 110},{"name": "April", "points": 100},{"name": "May", "points": 100},{"name": "June", "points": 100},{"name": "July", "points": 100},{"name": "Aug", "points": 100},{"name": "Sep", "points": 130},{"name": "Oct", "points": 140},{"name": "Nov", "points": 200},{"name": "Dec", "points": 180}]',
            linesparkgraph: '[{"date":"2021-05-01","value":590887},{"date":"2021-05-02","value":563594},{"date":"2021-05-03","value":265531},{"date":"2021-05-04","value":197982},{"date":"2021-05-05","value":362524},{"date":"2021-05-06","value":339153},{"date":"2021-05-07","value":353475},{"date":"2021-05-08","value":406614},{"date":"2021-05-09","value":410095},{"date":"2021-05-10","value":103192},{"date":"2021-05-11","value":97085},{"date":"2021-05-12","value":142234},{"date":"2021-05-13","value":319419},{"date":"2021-05-14","value":258862},{"date":"2021-05-15","value":279252},{"date":"2021-05-16","value":200358},{"date":"2021-05-17","value":65448},{"date":"2021-05-18","value":71861},{"date":"2021-05-19","value":109825},{"date":"2021-05-20","value":203801},{"date":"2021-05-21","value":75118},{"date":"2021-05-22","value":69015},{"date":"2021-05-23","value":51354},{"date":"2021-05-24","value":44399},{"date":"2021-05-25","value":62825},{"date":"2021-05-26","value":154277},{"date":"2021-05-27","value":292812},{"date":"2021-05-28","value":81882},{"date":"2021-05-29","value":39089},{"date":"2021-05-30","value":43483}]',
            linesparkgraph: [{"date": "2012-07-27","value": 13}, {"date": "2012-07-28","value": 10},{"date": "2012-07-29","value": 15},{"date": "2012-07-30","value": 15},{"date": "2012-07-31","value": 15},{"date": "2012-08-01","value": 15},{"date": "2012-08-02","value": 15},{"date": "2012-08-03","value": 19},{"date": "2012-08-04","value": 16},{"date": "2012-08-05","value": 15},{"date": "2012-08-06","value": 19},{"date": "2012-08-07","value": 17},{"date": "2012-08-08","value": 18}],
            scroll: '',
            funnelgraph: '[{ "name": "Sessions with Category Page Views", "value": 600},{ "name": "Sessions with Product detail page views", "value": 300},{ "name": "Sessions with Add to Cart","value": 200},{"name": "Sessions with visit to Checkout Page","value": 180},{"name": "Sessions with Visit to Address Page", "value": 50} ,{ "name": "Sessions with Visit to Payment Page","value": 20},{"name": "Sessions with Purchase","value": 10}]',
            is_metrices_loaded: false,
            metrices_data: [],
            is_funnel_loaded: false,
            funnel_graph_list: [],
            is_graph_loaded: false,
            is_pie_loaded:false,
            channel_dropdown_option: [{ "value": "channel", "label": "Traffic Channels" }, { "value": "product", "label": "Product" }],
            is_channel_data_loaded: false,
            dropdown_channel_name: "channel",
            pie_click_data:"",
        }
    }
    componentDidMount() {
        console.log("--------- 1 ------------");
        this.loadSummaryData();
        // this.getChannelData()
        // this.getallMetrices();
        // this.getFunnel();
        // this.getlinesparkgraph();
        // this.getPiechart();

    }

    loadSummaryData = () => {
        if (this.state.dropdown_channel_name == "channel") {
            this.getChannelData();
        }  else {
            this.getProductData();
        }
    }

    getChannelData = () => {
        this.getPiechart();
        this.getallMetrices();
        this.getFunnel();
        this.getlinesparkgraph();
        
    }

    getProductData = () => {
        console.log("Product called ");
        // TODO for Product
        // this.getallMetrices();
        // this.getFunnel();
        // this.getlinesparkgraph();
        // this.getPiechart();
    }



    getallMetrices() {
        const data = {
            'start_date': '2021-10-01',
            'end_date': '2021-12-31',
            'social_social_platform': 'FACEBOOK',
            'pie_click_data': this.state.pie_click_data,
        }
        const url = API_URL + "/api/ecom/ecom-metrices/"
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => res.json()).then((result) => {
            console.log("2 getallMetrices=======================", result)
            let current_avg_order_value;
            let last_avg_order_value;
            let current_conversion_rate;
            let last_conversion_rate;
            let current_avg_session_duration;
            let last_avg_session_duration;
            let current_avg_Page_Sessions;
            let last_avg_Page_Sessions;
            let current_sessions_with_search_percentage;
            let last_sessions_with_search_percentage;
            let current_bounce_rate;
            let last_bounce_rate;


            let current_sales = result['current_ecom_metrices_list'][0]['sales']
            let current_total_order = result['current_ecom_metrices_list'][0]['total_order']
            let current_cancellations = result['current_ecom_metrices_list'][0]['cancellations']
            let current_sessions = result['current_ecom_metrices_list'][0]['sessions']
            let current_session_duration_in_seconds = result['current_ecom_metrices_list'][0]['session_duration_in_seconds']
            let current_page_views = result['current_ecom_metrices_list'][0]['page_views']
            let current_search_session = result['current_ecom_metrices_list'][0]['search_session']
            let current_bounce = result['current_ecom_metrices_list'][0]['bounce']
            let current_users = result['current_ecom_metrices_list'][0]['users']
            let current_new_user = result['current_ecom_metrices_list'][0]['new_user']


            let last_sales = result['last_ecom_metrices_list'][0]['sales']
            let last_total_order = result['last_ecom_metrices_list'][0]['total_order']
            let last_cancellations = result['last_ecom_metrices_list'][0]['cancellations']
            let last_sessions = result['last_ecom_metrices_list'][0]['sessions']
            let last_session_duration_in_seconds = result['last_ecom_metrices_list'][0]['session_duration_in_seconds']
            let last_page_views = result['last_ecom_metrices_list'][0]['page_views']
            let last_search_session = result['current_ecom_metrices_list'][0]['search_session']
            let last_bounce = result['current_ecom_metrices_list'][0]['bounce']
            let last_users = result['current_ecom_metrices_list'][0]['users']
            let last_new_user = result['current_ecom_metrices_list'][0]['new_user']



            current_avg_order_value = (current_sales / current_total_order);
            last_avg_order_value = (last_sales / last_total_order);
            current_conversion_rate = (current_total_order / current_sessions) * 100;
            last_conversion_rate = (last_total_order / last_sessions) * 100;
            current_avg_session_duration = (current_session_duration_in_seconds / current_sessions);
            last_avg_session_duration = (last_session_duration_in_seconds / last_sessions);
            current_avg_Page_Sessions = (current_page_views / current_sessions);
            last_avg_Page_Sessions = (last_page_views / last_sessions);
            current_sessions_with_search_percentage = (current_search_session / current_sessions) * 100;
            last_sessions_with_search_percentage = (last_search_session / last_sessions) * 100;
            current_bounce_rate = (current_bounce / current_sessions) * 100;
            last_bounce_rate = (last_bounce / last_sessions) * 100;




            this.setState({
                is_metrices_loaded: true,
                metrices_data: result,
                current_avg_order_value: current_avg_order_value,
                last_avg_order_value: last_avg_order_value,
                current_conversion_rate: current_conversion_rate,
                last_conversion_rate: last_conversion_rate,
                current_cancellations: current_cancellations,
                last_cancellations: last_cancellations,
                current_avg_session_duration: current_avg_session_duration,
                last_avg_session_duration: last_avg_session_duration,
                current_avg_Page_Sessions: current_avg_Page_Sessions,
                last_avg_Page_Sessions: last_avg_Page_Sessions,
                current_sessions_with_search_percentage: current_sessions_with_search_percentage,
                last_sessions_with_search_percentage: last_sessions_with_search_percentage,
                current_bounce_rate: current_bounce_rate,
                last_bounce_rate: last_bounce_rate,
                current_sessions: current_sessions,
                last_sessions: last_sessions,
                current_users: current_users,
                last_users: last_users,
                current_new_user: current_new_user,
                last_new_user: last_new_user,           








            });
        })

    }

    getlinesparkgraph() {
        const data = {
            'start_date': '2021-10-01',
            'end_date': '2021-12-31',
            'social_social_platform': 'FACEBOOK'
        }
        const url = API_URL + "/api/ecom/ecom-metrices/"
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => res.json()).then((result) => {

            // console.log("2 getlinesparkgraph=======================", result['ecom_metrices_sparkline_list'])
            let sparkline_list = result['ecom_metrices_sparkline_list']
            console.log("🚀 ~ file: ecom.js ~ line 167 ~ Ecom ~ getlinesparkgraph ~ sparkline_list", sparkline_list)

            let sales_spark_list = [];
            let total_order_list = [];
            let cancellations_list = [];
            let avg_order_value_list = [];
            let conversion_list = [];
            let avg_session_duration_list = [];
            let avg_Page_Sessions_list = [];
            let sessions_with_search_percentage_list = [];
            let bounce_rate_list = [];
            let sessions_list = [];
            let users_list = [];
            let new_user_list = [];


            sparkline_list.map((info, index) => {
                try {
                    let a = {
                        "date": info['date'],
                        "value": info['sales']
                    }
                    sales_spark_list.push(a);

                    let b = {
                        "date": info['date'],
                        "value": info['total_order']

                    }
                    total_order_list.push(b);

                    let c = {
                        "date": info['date'],
                        "value": info['cancellations']

                    }
                    cancellations_list.push(c);

                    let d = {
                        "date": info['date'],
                        "value": (info['sales'] / info['total_order']).toFixed()

                    }
                    avg_order_value_list.push(d);

                    let e = {
                        "date": info['date'],
                        "value": (info['total_order'] / info['sessions']) * 100

                    }
                    conversion_list.push(e);

                    let f = {
                        "name": info['date'],
                        "points": (info['session_duration_in_seconds'] / info['sessions']).toFixed()
                    }
                    avg_session_duration_list.push(f);

                    let g = {
                        "name": info['date'],
                        "points": (info['page_views'] / info['sessions']).toFixed()
                    }
                    avg_Page_Sessions_list.push(g);

                    let h = {
                        "name": info['date'],
                        "points": ((info['search_session'] / info['sessions']) * 100).toFixed(2)
                    }
                    sessions_with_search_percentage_list.push(h);

                    let i = {
                        "name": info['date'],
                        "points": ((info['bounce'] / info['sessions']) * 100).toFixed(2)
                    }
                    bounce_rate_list.push(i);

                    let j = {
                        "date": info['date'],
                        "value": info['sessions']

                    }
                    sessions_list.push(j);

                    let k = {
                        "date": info['date'],
                        "value": info['users']

                    }
                    users_list.push(k);
                    let l = {
                        "date": info['date'],
                        "value": info['new_user']

                    }
                    new_user_list.push(l);








                } catch (e) {

                }



            })


            this.setState({
                is_graph_loaded: true,
                sales_spark_graph: sales_spark_list,
                total_order_graph: total_order_list,
                cancellations_graph: cancellations_list,
                avg_order_value_graph: avg_order_value_list,
                conversion_graph: conversion_list,
                avg_session_duration_graph: avg_session_duration_list,
                avg_Page_Sessions_graph: avg_Page_Sessions_list,
                sessions_with_search_percentage_graph: sessions_with_search_percentage_list,
                bounce_rate_graph: bounce_rate_list,
                sessions_graph: sessions_list,
                users_graph: users_list,
                new_user_graph: new_user_list,



            });
        })

    }

    getFunnel() {
        const data = {
            'start_date': '2021-10-01',
            'end_date': '2021-12-31',
            'social_social_platform': 'FACEBOOK'
        }
        const url = API_URL + "/api/ecom/ecom-funnel/"
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => res.json()).then((result) => {
            let funnel_list = result['funnel_list']
            const shopping_stage = ["ALL_VISITS", "ADD_TO_CART", "CHECKOUT_WITH_CART_ADDITION", "CHECKOUT_WITHOUT_CART_ADDITION", "TRANSACTION", "TRANSACTION_WITHOUT_CHECKOUT"]
            let funnel_graph_list = []
            funnel_list.map((info, index) => {


                if (shopping_stage.includes(info['shopping_stage'])) {
                    console.log("yes")
                    let a = {
                        "name": info['shopping_stage'],
                        "value": info['sessions']

                    }
                    funnel_graph_list.push(a);

                }

            });
            funnel_graph_list.sort(function (a, b) {
                return b.value - a.value;
            });


            // console.log("descending", homes)
            console.log("funnel_graph_list================", funnel_graph_list)

            this.setState({
                is_funnel_loaded: true,
                funnelgraph: funnel_graph_list,


            });
        })

    }

    getPiechart() {

        const data = {
            'start_date': '2021-10-01',
            'end_date': '2021-12-31',
            'social_social_platform': 'FACEBOOK'
        }
        const url = API_URL + "/api/ecom/ecom-pie/"
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => res.json()).then((result) => {
            let pie_list = result['pie_list'];
            let pie_graph_list=[];
            // {    "name": "Social","value": 30}
            console.log("getPiechart========================", result)
            pie_list.map((info, index) => {

                let a = {
                    "name": info['channel_name'],
                    "value": info['total_sales'],
                }
                pie_graph_list.push(a);
            })
            this.setState({
                is_pie_loaded: true,
                trafficpievalue: pie_graph_list,


            });



        })


    }


    // const dropdownoptions2 = [
    //     { key: 'key-1', text: 'Organic' },
    //     { key: 'key-2', text: 'Website' },
    //     { key: 'key-3', text: 'Email' },
    //     { key: 'key-4', text: 'Social' },
    //     { key: 'key-5', text: 'Direct' }
    // ]

    // const dropdownoptions = [
    //     { key: 'key-1', text: 'Home' },
    //     { key: 'key-2', text: 'Mobiles' },
    //     { key: 'key-3', text: 'Electronics' },
    //     { key: 'key-4', text: 'Health' },
    //     { key: 'key-5', text: 'Books' }
    // ]

    // useEffect(() => {
    //     window.addEventListener("scroll", () => {
    //         setScroll(window.scrollY > 60);

    //     });

    // });
    // const [toggleState, setToggleState] = useState(1);
    // const toggleTab = (index) => {
    //     console.log(index);
    //     setToggleState(index);
    // }
    // const [scroll, setScroll] = useState(false);

    handleChannelCallback = (childData) => {
        console.log("--------- 3 ------------");
        console.log("childData ", childData);
        
        this.state.dropdown_channel_name = childData;     
        this.setState({is_channel_data_loaded: false, is_metrices_loaded:false });
        this.loadSummaryData();
    } 

    handlePieclick = (childData) =>{
        console.log("handlePieclick",childData)
        this.state.pie_click_data = childData;     
        this.setState({is_channel_data_loaded: false, is_metrices_loaded:false });
        this.loadSummaryData();
        // this.setState({name: childData})
    }



    render() {
        if (!this.state.is_metrices_loaded || !this.state.is_funnel_loaded || !this.state.is_graph_loaded || !this.state.is_pie_loaded) {
            return "<div></div>";
        }
        console.log("=================== this.state=======================", this.state)


        const CarouselSettings = {
            arrows: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        arrows: true,
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        arrows: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,

                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        arrows: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,

                    }
                }
            ]
        }

        
      


        return (
            // <h1>ECOM RUNNING</h1>
            <Container className="p-0" fluid>
                <PageHeader pageTitle="Summary" />
                <div className="">
                    <Container fluid className="p-0">

                        <div className="d-flex">
                            <Col className="icz-leftsection" sm={12} lg={3} xl={3}>
                                <div className="icz-card-wrap">
                                    <Col className="icz-sectioncardwrapper ps-0">
                                        <div className="icz-leftsectioncard">
                                            <div className="d-flex">
                                                <Col sm={12} className="icz-cardheader">
                                                    <Col className="icz-cardtitle">
                                                        <div className="icz-title-wrap p-0">
                                                            <div className="text-left">
                                                                <h5 className="icz-cardtitle text-start mb-0">Sales By</h5>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col className="icz-pagefilter">
                                                        <div className="icz-page-dropdown justify-content-end p-0">
                                                        <DailydataDropdown dropdown_options={this.state.channel_dropdown_option} dropdown_placeholder="Traffic Channels"  parentCallback={this.handleChannelCallback} />
                                                           
                                                            {/* <DailyDropdown /> */}
                                                        </div>
                                                    </Col>
                                                </Col>
                                            </div>
                                            <div className="w-100">
                                                <Piechart card_id="icz-traffic-piechart" card_class="icz-leftsectionchart" graph-data={this.state.trafficpievalue} parentCallback = {this.handlePieclick}  />
                                            </div>
                                            {/* card_class={scroll ? "chart_h500 icz-cardchart" : "chart_h300 icz-cardchart"} ? */}

                                        </div>
                                    </Col>

                                </div>

                            </Col>
                            <Col className="icz-rightsection" sm={12} lg={9} xl={9}>
                                <div className="icz-title-wrap d-flex">
                                    <Col sm={12}>
                                        <div className="icz-row">
                                            <Col><h5 className="icz-card-title align-items-start">Sales</h5></Col>
                                            <Col className="align-items-end text-end"><Link to='/ecom/customer'><button className="icz-btn">View More</button></Link></Col>
                                        </div>
                                    </Col>
                                </div>
                                <div className="icz-carouselcontainer">
                                    <Carousel
                                        Settings={CarouselSettings}
                                        class={"icz-cardcontainer"}
                                        SliderCards={[
                                            <ChartCard
                                                index="1"
                                                CardClass="icz-cardwrapper"
                                                CardIcon={<IconWebsite className="icz-icon" />}
                                                CardTitle="Sales"
                                                RowOneCurrentValue={this.state.metrices_data['current_ecom_metrices_list'][0]['sales']}
                                                RowOnePreviousValue={this.state.metrices_data['last_ecom_metrices_list'][0]['sales']}
                                                RowOneTitle="sales"
                                                IsNumber={true}
                                                chart={<Linegraph_sparkline card_id="icz-sales" card_class="icz-cardchart" graph-data={this.state.sales_spark_graph} />}
                                            />,

                                            <ChartCard
                                                index="2"
                                                CardClass="icz-cardwrapper"
                                                CardIcon={<IconWebsite className="icz-icon" />}
                                                CardTitle="Total Orders"
                                                RowOneCurrentValue={this.state.metrices_data['current_ecom_metrices_list'][0]['total_order']}
                                                RowOnePreviousValue={this.state.metrices_data['last_ecom_metrices_list'][0]['total_order']}
                                                RowOneTitle="sales"
                                                IsNumber={true}
                                                chart={<Linegraph_sparkline card_id="icz-total-orders" card_class="icz-cardchart" graph-data={this.state.total_order_graph} />}
                                            />,
                                            <ChartCard
                                                index="3"
                                                CardClass="icz-cardwrapper"
                                                CardIcon={<IconWebsite className="icz-icon" />}
                                                CardTitle="Avg Order Value"
                                                RowOneCurrentValue={this.state.current_avg_order_value.toFixed(2)}
                                                RowOnePreviousValue={this.state.last_avg_order_value.toFixed(2)}
                                                RowOneTitle="sales"
                                                IsNumber={true}
                                                chart={<Linegraph_sparkline card_id="icz-avgorder-value" card_class="icz-cardchart" graph-data={this.state.avg_order_value_graph} />}
                                            />,
                                            <ChartCard
                                                index="4"
                                                CardClass="icz-cardwrapper"
                                                CardIcon={<IconWebsite className="icz-icon" />}
                                                CardTitle="Cancellations"
                                                RowOneCurrentValue={this.state.metrices_data['current_ecom_metrices_list'][0]['cancellations']}
                                                RowOnePreviousValue={this.state.metrices_data['last_ecom_metrices_list'][0]['cancellations']}
                                                RowOneTitle="sales"
                                                IsNumber={true}
                                                chart={<Linegraph_sparkline card_id="icz-cancellations" card_class="icz-cardchart" graph-data={this.state.cancellations_graph} />}
                                            />,
                                            <ChartCard
                                                index="5"
                                                CardClass="icz-cardwrapper"
                                                CardIcon={<IconWebsite className="icz-icon" />}
                                                CardTitle="Converstion Rate"
                                                RowOneCurrentValue={this.state.current_conversion_rate.toFixed(2)}
                                                RowOnePreviousValue={this.state.last_conversion_rate.toFixed(2)}
                                                RowOneTitle="Traffic"
                                                IsNumber={true}
                                                chart={<Linegraph_sparkline card_id="icz-converstion-rate" card_class="icz-cardchart" graph-data={this.state.conversion_graph} />}
                                            />,

                                        ]}>

                                    </Carousel>


                                </div>
                                <div className="icz-title-wrap d-flex">
                                    <Col sm={12}>
                                        <div className="icz-row">
                                            <Col><h5 className="icz-card-title align-items-start">Enagagement</h5></Col>
                                            <Col className="align-items-end text-end"><button className="icz-btn">View More</button></Col>
                                        </div>
                                    </Col>
                                </div>
                                <div className="icz-carouselcontainer">
                                    <Carousel
                                        Settings={CarouselSettings}
                                        class={"icz-cardcontainer"}
                                        SliderCards={[
                                            <ChartCard
                                                index="1"
                                                CardClass="icz-cardwrapper"
                                                CardIcon={<IconWebsite className="icz-icon" />}
                                                CardTitle="Avg Sessions Duration"
                                                RowOneCurrentValue={this.state.current_avg_session_duration.toFixed(2)}
                                                RowOnePreviousValue={this.state.last_avg_session_duration.toFixed(2)}
                                                RowOneTitle="enagagement"
                                                IsNumber={true}
                                                chart={<BarChart card_id="icz-avg-session-duration" card_class="icz-cardchart" graph-data={this.state.avg_session_duration_graph} />}
                                            />,

                                            <ChartCard
                                                index="2"
                                                CardClass="icz-cardwrapper"
                                                CardIcon={<IconWebsite className="icz-icon" />}
                                                CardTitle="Avg Page Sessions"
                                                RowOneCurrentValue={this.state.current_avg_Page_Sessions.toFixed(2)}
                                                RowOnePreviousValue={this.state.last_avg_Page_Sessions.toFixed(2)}
                                                RowOneTitle="enagagement"
                                                IsNumber={true}
                                                chart={<BarChart card_id="icz-avgpage-session" card_class="icz-cardchart" graph-data={this.state.avg_Page_Sessions_graph} />}
                                            />,
                                            <ChartCard
                                                index="3"
                                                CardClass="icz-cardwrapper"
                                                CardIcon={<IconWebsite className="icz-icon" />}
                                                CardTitle="Add To Card Rate"
                                                RowOneCurrentValue="2800000"
                                                RowOnePreviousValue="80000"
                                                RowOneTitle="enagagement"
                                                IsNumber={true}
                                                chart={<BarChart card_id="Add To Card Rate" card_class="icz-cardchart" graph-data={this.state.bargraphsession} />}
                                            />,
                                            <ChartCard
                                                index="4"
                                                CardClass="icz-cardwrapper"
                                                CardIcon={<IconWebsite className="icz-icon" />}
                                                CardTitle=" %Session With Search"
                                                RowOneCurrentValue={this.state.current_sessions_with_search_percentage.toFixed(2)}
                                                RowOnePreviousValue={this.state.last_sessions_with_search_percentage.toFixed(2)}
                                                RowOneTitle="enagagement"
                                                IsNumber={true}
                                                chart={<BarChart card_id="icz-session-search" card_class="icz-cardchart" graph-data={this.state.sessions_with_search_percentage_graph} />}
                                            />,

                                            <ChartCard
                                                index="5"
                                                CardClass="icz-cardwrapper"
                                                CardIcon={<IconWebsite className="icz-icon" />}
                                                CardTitle="Bounce RAte"
                                                RowOneCurrentValue={this.state.current_bounce_rate.toFixed(2)}
                                                RowOnePreviousValue={this.state.last_bounce_rate.toFixed(2)}
                                                RowOneTitle="enagagement"
                                                IsNumber={true}
                                                chart={<BarChart card_id="icz-bounce-rate" card_class="icz-cardchart" graph-data={this.state.bounce_rate_graph} />}
                                            />,

                                        ]}>

                                    </Carousel>

                                </div>

                                <div className="icz-title-wrap d-flex">
                                    <Col sm={12}>
                                        <div className="icz-row">
                                            <Col ><h5 className="icz-card-title align-items-start">Traffic</h5></Col>
                                            <Col className="align-items-end text-end"><button className="icz-btn">View More</button></Col>
                                        </div>
                                    </Col>
                                </div>

                                <div className="icz-carouselcontainer">

                                    <Carousel
                                        Settings={CarouselSettings}
                                        class={"icz-cardcontainer"}
                                        SliderCards={[
                                            <ChartCard
                                                index="1"
                                                CardClass="icz-cardwrapper"
                                                CardIcon={<IconWebsite className="icz-icon" />}
                                                CardTitle="Sessions"
                                                RowOneCurrentValue={this.state.current_sessions}
                                                RowOnePreviousValue={this.state.last_sessions}
                                                RowOneTitle="Traffic"
                                                IsNumber={true}
                                                chart={<Linegraph_sparkline card_id="icz-session" card_class="icz-cardchart" graph-data={this.state.sessions_graph} />}
                                            />,

                                            <ChartCard
                                                index="2"
                                                CardClass="icz-cardwrapper"
                                                CardIcon={<IconWebsite className="icz-icon" />}
                                                CardTitle="Users"
                                                RowOneCurrentValue={this.state.current_users}
                                                RowOnePreviousValue={this.state.last_users}
                                                RowOneTitle="Traffic"
                                                IsNumber={true}
                                                chart={<Linegraph_sparkline card_id="icz-user" card_class="icz-cardchart" graph-data={this.state.users_graph} />}
                                            />,
                                            <ChartCard
                                                index="3"
                                                CardClass="icz-cardwrapper"
                                                CardIcon={<IconWebsite className="icz-icon" />}
                                                CardTitle="New Users"
                                                RowOneCurrentValue={this.state.current_new_user}
                                                RowOnePreviousValue={this.state.last_new_user}
                                                RowOneTitle="Traffic"
                                                IsNumber={true}
                                                chart={<Linegraph_sparkline card_id="icz-new-users" card_class="icz-cardchart" graph-data={this.state.new_user_graph} />}
                                            />,
                                            <ChartCard
                                                index="4"
                                                CardClass="icz-cardwrapper"
                                                CardIcon={<IconWebsite className="icz-icon" />}
                                                CardTitle="Mobile Users"
                                                RowOneCurrentValue="80"
                                                RowOnePreviousValue="80"
                                                RowOneTitle="Traffic"
                                                IsNumber={true}
                                                chart={<Linegraph_sparkline card_id="icz-mobile-user" card_class="icz-cardchart" graph-data={this.state.linesparkgraph} />}
                                            />,
                                            <ChartCard
                                                index="5"
                                                CardClass="icz-cardwrapper"
                                                CardIcon={<IconWebsite className="icz-icon" />}
                                                CardTitle="Top Landing"
                                                RowOneCurrentValue="80"
                                                RowOnePreviousValue="80"
                                                RowOneTitle="Traffic"
                                                IsNumber={true}
                                                chart={<Linegraph_sparkline card_id="icz-top-landing" card_class="icz-cardchart" graph-data={this.state.linesparkgraph} />}
                                            />,
                                        ]}>
                                    </Carousel>
                                </div>

                                <div className="icz-row">
                                    <Col className="icz-sectioncardwrapper pt-3" sm={12} lg={12}>
                                        <div className="icz-sectioncard">
                                            <div className="icz-cardheader">
                                                <Col className="icz-cardtitle">
                                                    Converstion Funnel
                                                </Col>
                                            </div>
                                            <div className="">
                                                <Converstion_funnel card_id="converstion_funnel" card_class="icz-sectionchart" graph-data={this.state.funnelgraph} />
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                            </Col>

                        </div>

                    </Container>
                </div>
            </Container>
        )
    }
}
