import React, { Component } from "react"

export class Website extends Component {
    render() {
        return (
            <h1>WEBSITE RUNNING</h1>
        )
    }
}

