import React, { useState } from "react";
import IconSortDown from "../../../../assets/images/icons/sortDownIcon";
import IconSortUp from "../../../../assets/images/icons/sortUpIcon";
import IconSortUpActive from "../../../../assets/images/icons/sortUpIconActive"
import IconSortDownActive from "../../../../assets/images/icons/sortDownIconActive"

const TableHeader = ({ headers, onSorting }) => {

    const [sortingField, setSortingField] = useState("");
    const [sortingOrder, setSortingOrder] = useState("asc");

    const onSortingChange = (field) => {
        const order =
            field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

        setSortingField(field);
        setSortingOrder(order);
        onSorting(field, order);
    }

    return (
        <thead className="icz-tableHeader">
            <tr className="icz-tableHeaderTr">
                {headers.map(({ name, field, sortable }) => (
                    <th className="icz-tableTh icz-tableHeaderTh" key={name}
                        onClick={() => sortable ? onSortingChange(field) : null}
                    ><div className="d-flex flex-row">
                            {name}
                            
                            {sortingField && sortingField === field ?
                            <div className="icz-sortSection">
                                <IconSortUp className="icz-tableSortIcon icz-mb-5" />
                                <IconSortDown className="icz-tableSortIcon" />
                            </div> : ""}

                            <div className="icz-sortSection">
                                {sortingField && sortingField === field && (
                                    sortingOrder === "asc" ? <IconSortUpActive className="icz-tableSortIcon icz-mb-5" /> : <IconSortUp className="icz-tableSortIcon icz-mb-5" />
                                )}
                                {sortingField && sortingField === field && (
                                    sortingOrder === "desc" ? <IconSortDownActive className="icz-tableSortIcon" /> : <IconSortDown className="icz-tableSortIcon" />
                                )}
                            </div>
                        </div>
                    </th>
                ))}
            </tr>
        </thead>
    )
}

export default TableHeader;