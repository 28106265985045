import React, { Component } from "react"

export class Comparison extends Component {
    render() {
        return (
            <h1>COMPARISON RUNNING</h1>
        )
    }
}

