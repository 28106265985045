import React, { useState, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';

import moment from "moment";



import DatePicker from '../../shared/components/datepicker/datepicker';
import { CampaignDropdown } from '../../shared/components/dropdown/campaigndropdown';

import './pageheader.scss';
import Image from 'react-bootstrap/esm/Image';

import StickyLogo from '../../../assets/images/icons/icon.png'
import IconPdf from '../../../assets/images/icons/pdfIcon';


// const popover = (
//     <Popover id="popover-basic">
//         <DatePicker />
//     </Popover>
// );
// const name="reactdemo"

const PageHeader = (props) => {
    const [scroll, setScroll] = useState(false);

    const [open, setOpen] = useState(false);
    const [date, setDate] = useState({});

    const onChange = (ranges) => {
        // ranges ...
        if (
            moment(ranges.startDate).format("MM-DD-YYYY") !==
            moment(ranges.endDate).format("MM-DD-YYYY")
        ) {
            console.log("Success", ranges);
            setDate(ranges);
            setOpen(false);
        }
    };

    const onRangeFocusChange = (ranges) => {
        console.log('onRangeFocusChange Called');
        setDate(ranges);
        setOpen(false);
    };

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 20);
        });
    }, []);

    return (
        <Container>
            <Container className={scroll ? "icz-section-padding icz-pageheaderwrapper sticky" : "icz-section-padding icz-pageheaderwrapper"} fluid>
                <div className="icz-pageheadercontainer">
                    <div className="icz-stickylogowrapper">
                        <Image src={StickyLogo} className="icz-stickylogo" />
                    </div>
                    <div className="p-0 icz-headerdetails d-flex justify-content-between">

                        <Col className="p-0">
                            <h2 className="icz-pagetitle">{props.pageTitle}</h2>
                        </Col>

                        <Col xs={12} md={10} className="p-0 icz-pagefiltersection ">
                            {props.isCampaignDropdownEnabled === true ? "<CampaignDropdown />" : ""}
                            <DatePicker onChange={onChange} open={open} setOpen={setOpen} data={date} />
                            <div className="icz-btnwrapper">
                                <button className="icz-themebtn">
                                    <IconPdf className="icz-btnicon" /> Export</button>
                            </div>

                        </Col>
                    </div>
                </div>

            </Container>
        </Container>

    )
}

export default PageHeader;
