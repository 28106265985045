import React, { Component } from 'react'
import Col from "react-bootstrap/esm/Col";
import Wrapper from "../../../../helpers/wrapper";
import IconFemale from "../../../../../assets/images/icons/femaleIcon";
import IconMale from "../../../../../assets/images/icons/maleIcon";
import HorizontalBarChart from "../../../../shared/components/charts/HorizontalBarChart";

import IconDesktop from '../../../../../assets/images/icons/desktopIcon'
import IconMobile from '../../../../../assets/images/icons/mobileIcon';
import IconTablet from '../../../../../assets/images/icons/tabletIcon';
import IconLaptop from '../../../../../assets/images/icons/laptopIcon';
import IconAllDevices from '../../../../../assets/images/icons/alldevicesIcon';
import IconCTR from '../../../../../assets/images/icons/ctrIcon';
import IconCTC from '../../../../../assets/images/icons/ctcIcon'
import IconConversion from '../../../../../assets/images/icons/conversionIcon';
import IconCPL from '../../../../../assets/images/icons/cplIcon'
import IconImpressions from '../../../../../assets/images/icons/impressionsIcon';
import { NumberFormatter } from "../../../../shared/utilities/numberformatter";
import { MetricsLineChart } from '../../../../shared/components/charts/MetricsLineChart';
import { SourceDropdown } from "../../../../shared/components/dropdown/sourcedropdown";
import { MetricsDropdown } from '../../../../shared/components/dropdown/metricsdropdown';
import { FacebookDatatable } from '../Datatable/FacebookDatatable';
import IconClicks from '../../../../../assets/images/icons/clicksIcon';
import { LocationDropdown } from "../../../../shared/components/dropdown/locationdropdown";
import { LocationBarChart } from '../../../../shared/components/charts/LocationBarChart';
import { DonutChart } from '../../../../shared/components/charts/DonutChart';

export class Facebook extends Component {
    constructor(props) {
        super(props);
        this.state = {
            leadspiedata: [{"name":"Google Search","value":39},{"name":"Google Display","value":32},{"name":"Remarketing","value":7}],
            locationdata: [{"name": "Assam", "points": 100}, {"name": "Bihar", "points": 200},{"name": "Delhi", "points": 110},{"name": "Gujarat", "points": 100},{"name": "Haryana", "points": 100},{"name": "Karnataka", "points": 100},{"name": "Maharashtra", "points": 100},{"name": "Punjab", "points": 100},{"name": "Rajasthan", "points": 130},{"name": "Telangana", "points": 140}],
            maleDemoData: [{"age":"18-24","research":"40"},{"age":"25-36","research":"70"},{"age":"37-50","research":"25"},{"age":"50+","research":"15"}],
            femaleDemoData: [{"age":"18-24","research":"75"},{"age":"25-36","research":"60"},{"age":"37-50","research":"25"},{"age":"50+","research":"15"}],
            facebookmetrics: '[{"date":"2016","value":18},{"date":"2017","value":23},{"date":"2018","value":25},{"date":"2019","value":31},{"date":"2020","value":29},{"date":"2021","value":33}]',
            sourceDropdownData: '[{ "id": 1, "name": "Impression" }, { "id": 2, "name": "Clicks" }, { "id": 3, "name": "Spends" }, { "id": 4, "name": "Leads" },  { "id": 5, "name": "App Installs" },  { "id": 6, "name": "Sales" }]',
            metrics: [{"date":"2016","value":13},{"date":"2017","value":23},{"date":"2018","value":25},{"date":"2019","value":31},{"date":"2020","value":29},{"date":"2021","value":33}],
        }
    }
    render() {
        return (
            <div>
                <Wrapper>
                    <div className="icz-row">
                        <Col className="icz-sectioncardwrapper" sm={12} lg={6}>
                            <div className="icz-sectioncard">
                                <div className="icz-cardheader">
                                    <Col className="icz-cardtitle">
                                        Conversions
                                    </Col>
                                </div>

                                <div className="icz-dchartsection icz-leadchartsection">
                                    <Col className="" sm={12} lg={8}>
                                        <DonutChart card_id="leads-chart" card_class="icz-sectionchart" center_title="Facebook" graph-data={this.state.leadspiedata} />
                                    </Col>
                                    <Col sm={12} lg={4} className="icz-chartdetailssection icz-chartscroll">
                                        <div className="icz-detailsdatasection  w-100">
                                            <div className="icz-detailsdatatab icz-detailchartscroll">
                                                <div className="icz-iconwrapper">
                                                    <IconClicks className="icz-icon" />
                                                </div>
                                                <div className="icz-titlewrapper">
                                                    <div className="icz-title">
                                                        <NumberFormatter Number={39} IsNumber={true} />
                                                    </div>
                                                    <div className="icz-subtitle">
                                                        Google Search
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="icz-detailsdatatab icz-detailchartscroll">
                                                <div className="icz-iconwrapper">
                                                    <IconClicks className="icz-icon" />
                                                </div>
                                                <div className="icz-titlewrapper">
                                                    <div className="icz-title">
                                                        <NumberFormatter Number={32} IsNumber={true} />
                                                    </div>
                                                    <div className="icz-subtitle">
                                                        Google Display
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="icz-detailsdatatab icz-detailchartscroll">
                                                <div className="icz-iconwrapper">
                                                    <IconClicks className="icz-googlescreenicon" />
                                                </div>
                                                <div className="icz-titlewrapper">
                                                    <div className="icz-title">
                                                        <NumberFormatter Number={7} IsNumber={true} />
                                                    </div>
                                                    <div className="icz-subtitle">
                                                        Remarketing
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="icz-detailsdatatab icz-detailchartscroll">
                                                <div className="icz-iconwrapper">
                                                    <IconClicks className="icz-icon" />
                                                </div>
                                                <div className="icz-titlewrapper">
                                                    <div className="icz-title icz-highlight">
                                                        <NumberFormatter Number={78} IsNumber={true} />
                                                    </div>
                                                    <div className="icz-subtitle icz-highlight">
                                                        Total Leads
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </Col>
                                </div>

                            </div>
                        </Col>
                        <Col className="icz-sectioncardwrapper" sm={12} lg={6}>
                            <div className="icz-sectioncard">
                                <div className="icz-cardheader">
                                    <Col className="icz-cardtitle">
                                        Location
                                    </Col>
                                    <Col className="icz-cardfilter">
                                        <LocationDropdown />
                                    </Col>
                                </div>
                                <div className="">
                                    <LocationBarChart card_id="location-chart" card_class="icz-sectionchart" graph-data={this.state.locationdata} />
                                </div>
                            </div>
                        </Col>
                    </div>

                    <div className="icz-row">
                        <div className="icz-sectioncardwrapper w-100">
                            <div className="icz-sectioncard d-flex flex-row flex-wrap">
                                <Col sm={12} className="icz-cardheader">
                                    <Col className="icz-cardtitle">
                                        Audience Demographics
                                    </Col>
                                    <Col className="icz-cardfilter">

                                    </Col>
                                </Col>
                                <Col sm={12} lg={6} className="d-flex flex-column align-items-start">
                                    <div className="icz-cardheader d-flex flex-column align-items-start">
                                        <div className="icz-cardtitle">
                                            Male
                                        </div>
                                    </div>
                                    <div className="d-flex flex-row flex-wrap w-100">
                                        <Col xs={12} lg={3} className="d-flex flex-column justify-center align-items-center">
                                            <IconMale className="icz-audienceicon" />
                                            <div className="icz-cardheader d-flex flex-column align-items-center">
                                                <div className="icz-sectiontitle">
                                                    60%
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={7} className="">
                                            <HorizontalBarChart chart_id="male_demographic" chart_class="icz-sectionchart" graph-data={this.state.maleDemoData} bar_color={"#11A1FD"} />
                                        </Col>
                                    </div>
                                </Col>
                                <Col sm={12} lg={6} className="d-flex flex-column align-items-start">
                                    <div className="icz-cardheader d-flex flex-column align-items-start">
                                        <div className="icz-cardtitle">
                                            Female
                                        </div>
                                    </div>
                                    <div className="d-flex flex-row flex-wrap w-100">
                                        <Col xs={3} className="d-flex flex-column justify-center align-items-center">
                                            <IconFemale className="icz-audienceicon" />
                                            <div className="icz-cardheader d-flex flex-column align-items-center">
                                                <div className="icz-sectiontitle">
                                                    60%
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={8} className="">
                                            <HorizontalBarChart chart_id="female_demographic" chart_class="icz-sectionchart" graph-data={this.state.femaleDemoData} bar_color={"#FF9931"} />
                                        </Col>
                                    </div>
                                </Col>
                            </div>
                        </div>
                    </div>

                    <div className="icz-row">
                        <div className="icz-sectioncardwrapper w-100">
                            <div className="icz-sectioncard">
                                <div className="icz-devicecardbody">
                                    <Col className="icz-devicecardtitle">
                                        Device
                                    </Col>
                                    <div className="icz-devicesection">
                                        <div className="icz-devicelbldata">
                                            <div className="icz-iconwrapper align-item-center"><IconDesktop className="icz-icon" /></div>
                                            <div className="label-txt px-20" lg={12}>Desktop
                                                <p>0</p>
                                            </div>
                                        </div>

                                        <div className="icz-devicelbldata">
                                            <div className="icz-iconwrapper align-item-center"><IconTablet className="icz-icon" /></div>
                                            <div className="label-txt px-20" lg={12}>Tablet
                                                <p>0</p>
                                            </div>
                                        </div>

                                        <div className="icz-devicelbldata">
                                            <div className="icz-iconwrapper align-item-center"><IconMobile className="icz-icon" /></div>
                                            <div className="label-txt px-20" lg={12}>Mobile
                                                <p>0</p>
                                            </div>
                                        </div>

                                        <div className="icz-devicelbldata">
                                            <div className="icz-iconwrapper align-item-center"><IconLaptop className="icz-icon" /></div>
                                            <div className="label-txt px-20" lg={12}>Other
                                                <p>0</p>
                                            </div>
                                        </div>

                                        <div className="icz-devicelbldata">
                                            <div className="icz-iconwrapper align-item-center"><IconAllDevices className="icz-icon" /></div>
                                            <div className="label-txt px-20" lg={12}>Total
                                                <p>0</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="icz-row">
                        <Col className="icz-sectioncardwrapper" sm={12} lg={12}>
                            <div className="icz-sectioncard">
                                <div className="icz-cardheader">
                                    <Col className="icz-cardtitle">
                                        Performance
                                    </Col>
                                    <Col className="icz-cardfilter">
                                        <SourceDropdown />
                                    </Col>
                                </div>
                                <div className="icz-dchartsection">
                                    <Col className="" sm={12} lg={9}>
                                        <MetricsLineChart card_id="google_metrics" card_class="icz-sectionchart" graph-data={this.state.metrics} />

                                    </Col>
                                    <Col className="icz-chartdetailssection icz-detailscroll " sm={12} lg={3}>
                                        <div className="icz-detailsdatasection w-100">
                                            <div className="icz-detailsdatatab d-flex justify-content-between align-items-end">
                                                <div className="d-flex align-items-center">
                                                    <div className="icz-iconwrapper">
                                                        <IconCTR className="icz-icon" />
                                                    </div>
                                                    <div className="icz-titlewrapper">
                                                        <div className="icz-title">
                                                            39%
                                                        </div>
                                                        <div className="icz-subtitle">
                                                            CTR
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="icz-detailsdatatab d-flex justify-content-between align-items-end">
                                                <div className="d-flex align-items-center">
                                                    <div className="icz-iconwrapper">
                                                        <IconCTC className="icz-icon" />
                                                    </div>
                                                    <div className="icz-titlewrapper">
                                                        <div className="icz-title">
                                                            32%
                                                        </div>
                                                        <div className="icz-subtitle">
                                                            CTR
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="icz-detailsdatatab d-flex justify-content-between align-items-end">
                                                <div className="d-flex align-items-center">
                                                    <div className="icz-iconwrapper">
                                                        <IconConversion className="icz-icon" />
                                                    </div>
                                                    <div className="icz-titlewrapper">
                                                        <div className="icz-title">
                                                            45
                                                        </div>
                                                        <div className="icz-subtitle">
                                                            Conversions
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="icz-detailsdatatab d-flex justify-content-between align-items-end">
                                                <div className="d-flex align-items-center">
                                                    <div className="icz-iconwrapper">
                                                        <IconCPL className="icz-icon" />
                                                    </div>
                                                    <div className="icz-titlewrapper">
                                                        <div className="icz-title">
                                                            <NumberFormatter Number={12700} IsNumber={true} />
                                                        </div>
                                                        <div className="icz-subtitle">
                                                            CPL
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="icz-detailsdatatab d-flex justify-content-between align-items-end">
                                                <div className="d-flex align-items-center">
                                                    <div className="icz-iconwrapper">
                                                        <IconImpressions className="icz-icon" />
                                                    </div>
                                                    <div className="icz-titlewrapper">
                                                        <div className="icz-title">
                                                            <NumberFormatter Number={11987} IsNumber={true} />
                                                        </div>
                                                        <div className="icz-subtitle">
                                                            CPI
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="icz-detailsdatatab d-flex justify-content-between align-items-end">
                                                <div className="d-flex align-items-center">
                                                    <div className="icz-iconwrapper">
                                                        <IconCTR className="icz-icon" />
                                                    </div>
                                                    <div className="icz-titlewrapper">
                                                        <div className="icz-title">
                                                            <NumberFormatter Number={358} IsNumber={true} />
                                                        </div>
                                                        <div className="icz-subtitle">
                                                            Clicks Conversion
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </Col>
                    </div>

                    <div className="icz-row">
                        <div className="icz-sectioncardwrapper w-100">
                            <Col className="icz-sectioncardwrapper" sm={12} lg={12}>
                                <div className="icz-sectioncard">
                                    <div className="icz-cardheader">
                                        <Col className="icz-cardtitle">
                                            Summary
                                        </Col>
                                        <Col className="icz-cardfilter">
                                            <MetricsDropdown />
                                        </Col>
                                    </div>
                                    <div className="">
                                        <FacebookDatatable />
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </div>
                </Wrapper>
            </div>
        )
    }
}
