import html2canvas from 'html2canvas'
import IconDownload from '../../../../assets/images/icons/downloadIcon';
import { Dropdown } from "../../../shared/components/dropdown/dropdown";


const ChartExport = (props) => {

    console.log("ChartExport",props);
   // const options =[{ "value": "Data", "label": "Data" }, { "value": "Image", "label": "Images" } ];
    const options =[ { "value": "Image", "label": "Images" }];
    const export_href=props.export_href;
    const downloadName=props.downloadName;
    const exportAsPicture = () => {
        var html = document.getElementsByTagName('HTML')[0]
        var body = document.getElementsByTagName('BODY')[0]
        var htmlWidth = html.clientWidth;
        var bodyWidth = body.clientWidth;

        var data = document.getElementById(props.ChartId)
        var newWidth = data.scrollWidth - data.clientWidth


        if (newWidth > data.clientWidth) {
            htmlWidth += newWidth
            bodyWidth += newWidth
        }

        html.style.width = htmlWidth + 'px'
        body.style.width = bodyWidth + 'px'


        html2canvas(data).then((canvas) => {
            var image = canvas.toDataURL('image/png', 1.0);
            return image
        }).then((image) => {
            var imageName = (props.exportName).replace(/\s+/g, '-').toLowerCase();
            saveAs(image, imageName + '.png')
            html.style.width = null
            body.style.width = null
        })
    }


    const saveAs = (blob, fileName) => {
        var elem = window.document.createElement('a');
        elem.href = blob
        elem.download = fileName;
        elem.style = 'display:none;';
        (document.body || document.documentElement).appendChild(elem);
        if (typeof elem.click === 'function') {
            elem.click();
        } else {
            elem.target = '_blank';
            elem.dispatchEvent(new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true
            }));
        }
        URL.revokeObjectURL(elem.href);
        elem.remove()
    }

    const exportAsCSV = () => {
        document.getElementById("exoprt-data").click();
    }
    const exportAs= (e) => {
        if(e.toLowerCase()=="image"){
            exportAsPicture();
        }
        else if(e.toLowerCase()=="data"){
            exportAsCSV();
        }
    }
    

    return (
        <>
            <Dropdown dropdown_options={options} dropdown_placeholder="Export" parentCallback={exportAs} />
            <a href={export_href} id='exoprt-data' download={downloadName} ></a>
        </>)
};

export default ChartExport;
