import React, { useEffect, useState } from "react";

import MenuItem from "./MenuItem";

import IconHome from '../../../assets/images/icons/homeIcon';
import IconClose from "../../../assets/images/icons/closeicon";
import IconAryabot from '../../../assets/images/icons/aryabot';
import IconPerformance from '../../../assets/images/icons/performanceIcon';
import IconUserGroup from '../../../assets/images/icons/usergroupIcon';
import IconVideo from '../../../assets/images/icons/videoIcon';
import IconWebsite from '../../../assets/images/icons/websiteIcon';
import IconCRM from '../../../assets/images/icons/crmIcon';
import IconHashtag from '../../../assets/images/icons/hashtagIcon';
import IconComparison from '../../../assets/images/icons/comparisonIcon';
import IconReviews from '../../../assets/images/icons/reviewsIcon';
import IconCart from '../../../assets/images/icons/cartIcon';
import IconStore from '../../../assets/images/icons/storeIcon';


var sidebarIconClass = "icz-sidebaricon";

export const menuItems = [
  {
    name: "Dashboard",
    exact: true,
    to: "/",
    iconClassName: <IconHome className={sidebarIconClass} />,
  },
  {
    name: "Performance",
    exact: true,
    to: `/performance`,
    iconClassName: <IconPerformance className={sidebarIconClass} />,
  },
  {
    name: "Social",
    exact: true,
    to: `/social`,
    iconClassName: <IconUserGroup className={sidebarIconClass} />,
  },
  {
    name: "Video",
    exact: true,
    to: `/video`,
    iconClassName: <IconVideo className={sidebarIconClass} />,
  },
  {
    name: "Website",
    exact: true,
    to: `/website`,
    iconClassName: <IconWebsite className={sidebarIconClass} />,
  },
  {
    name: "Ecom",
    exact: true,
    to: ``,
    iconClassName: <IconCart className={sidebarIconClass} />,
    subMenus: [
      { name: "Ecom", to: "/ecom" },
      { name: "Customer", to: "/ecom/customer" },
      { name: "Engagement", to: "/ecom/engagement" },
      { name: "Traffic", to: "/ecom/traffic" },
      { name: "Earned", to: "/ecom/earned" },
      { name: "Paid", to: "/ecom/paid" },
      { name: "Sales", to: "/ecom/sales" },
      { name: "Realtime", to: "/ecom/realtime" },
    ],
  },
  {
    name: "RI",
    exact: true,
    to: ``,
    iconClassName: <IconStore className={sidebarIconClass} />,
    subMenus: [
      { name: "Summary", to: "/" },
      { name: "Summary IN", to: "/?country=in" },
      { name: "Summary UK", to: "/?country=uk" },
      { name: "Sales Summary", to: "/ri/salessummary" },
      { name: "Business Overview", to: "/ri/businessoverview" },
      { name: "Sell Through", to: "/ri/sellthrough" },
      { name: "Store Health", to: "/ri/storehealth" },
      // { name: "Customer", to: "/ri/customer" }
    ],
  },
  {
    name: "CRM",
    to: `/crm`,
    iconClassName: <IconCRM className={sidebarIconClass} />
  },
  {
    name: "Hashtag",
    to: `/hashtags`,
    iconClassName: <IconHashtag className={sidebarIconClass} />
  },
  {
    name: "Comparison",
    to: `/comparison`,
    iconClassName: <IconComparison className={sidebarIconClass} />
  },
  {
    name: "Reviews",
    to: `/reviews`,
    iconClassName: <IconReviews className={sidebarIconClass} />
  },
  {
    name: "Arya Bot",
    to: `/aryabot`,
    iconClassName: <IconAryabot className={sidebarIconClass} />
  },

];

const SideMenu = (props) => {
  const [inactive, setInactive] = useState(true);

  useEffect(() => {
    if (inactive) {
      removeActiveClassFromSubMenu();
    }

    props.onCollapse(inactive);
  }, [inactive]);

  const removeActiveClassFromSubMenu = () => {
    document.querySelectorAll(".icz-submenu").forEach((el) => {
      el.classList.remove("active");
    });
  };

  const handleChange = e => {
    setInactive(!inactive)
  };

  useEffect(() => {
    let menuItems = document.querySelectorAll(".icz-sidebarlink");
    menuItems.forEach((el) => {
      el.addEventListener("click", (e) => {
        const next = el.nextElementSibling;
        removeActiveClassFromSubMenu();
        menuItems.forEach((el) => el.classList.remove("active"));
        el.classList.toggle("active");
        if (next !== null) {
          next.classList.toggle("active");
        }
      });
    });
  }, []);

  return (

    <div className={`icz-sidebarContainer  ${inactive ? "inactive" : ""}`}>
      <div className="top-section">
        <div onClick={() => setInactive(!inactive)} className="toggle-menu-btn">
          {inactive ? "" : <IconClose className={sidebarIconClass} />}
        </div>
      </div>

      <div className="icz-sidebarwrapper">
        <ul className="icz-sidebarul">
          {menuItems.map((menuItem, index) => (
            <MenuItem
              key={index}
              name={menuItem.name}
              exact={menuItem.exact}
              to={menuItem.to}
              subMenus={menuItem.subMenus || []}
              iconClassName={menuItem.iconClassName}
              parentCallBack={handleChange}
              onClick={(e) => {
                if (inactive) {
                  setInactive(false);
                }
              }}
            />
          ))}

        </ul>
      </div>

    </div>
  );
};

export default SideMenu;