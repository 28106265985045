import React, { Component } from 'react'
import Container from 'react-bootstrap/Container';
import Col from "react-bootstrap/esm/Col";
import IconDownload from '../../../../assets/images/icons/downloadIcon'
import IndiaMap from '../../../shared/components/map/indiamap/indiamap'
import { ProgressBar } from 'react-bootstrap';
import RiMapFilter from "../ri-mapfilter/RiMapFilter";
import CustomerOverview from './RiCustomerOverview';

export default class RICustomer extends Component {
  render() {
    const progress1 = 65; const progress2 = 25; const progress3 = 10;
    return (
        <Container className="p-0" fluid>
            <div className="d-flex flex-column">
                


                <Col lg={12} sm={12} className="icz-ridatacontainer p-0">
                    <CustomerOverview/>
                </Col>
            </div>
        </Container>
    );
}
}
