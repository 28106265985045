import React, { Component } from 'react';
import Wrapper from "../../../helpers/wrapper";
import Col from "react-bootstrap/esm/Col";
import {Sparklines, SparklinesCurve} from 'react-sparklines';
import { Dropdown } from "../../../shared/components/dropdown/dropdown"
import IconDownload from '../../../../assets/images/icons/downloadIcon'
import MultiLineMultiAxesChart from '../../../shared/components/charts/MultiLineMultiAxesChart';
import RiDataTable from '../../../shared/components/datatable/RiDataTable'

export class SellthroughPerformance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdown_options: [{ "value": "soh", "label": "SOH" }, { "value": "nsv", "label": "NSV" }, { "value": "salemix", "label": "Sales Mix%" }, { "value": "units", "label": "Units" }, { "value": "asp", "label": "ASP" }, { "value": "wks", "label": "Wks on Floor" }, { "value": "fwd", "label": "Fwd Week Cover" }],
            performancetrend: [{ "month": "27 Oct", "value1": 38, "value2": 8, "value3": 18 }, { "month": "28 OCT", "value1": 45, "value2": 6, "value3": 13 }, { "month": "29 Oct", "value1": 38, "value2": 14, "value3": 18 }, { "month": "30 Oct", "value1": 18, "value2": 20, "value3": 24 }, { "month": "Oct 31", "value1": 15, "value2": 20, "value3": 32 }, { "month": "Nov 1", "value1": 9, "value2": 29, "value3": 35 }, { "month": "Nov 2", "value1": 10, "value2": 31, "value3": 39 }, { "month": "Nov 3", "value1": 20, "value2": 28, "value3": 44 }, { "month": "Nov 4", "value1": 23, "value2": 30, "value3": 48 }, { "month": "Nov 5", "value1": 37, "value2": 20, "value3": 33 }, { "month": "Nov 6", "value1": 40, "value2": 16, "value3": 28 }, { "month": "Nov 7", "value1": 34, "value2": 11, "value3": 18 }, { "month": "Nov 8", "value1": 50, "value2": 16, "value3": 27 }],
            linesparkgraph: [{ "date": "2012-07-27", "value": 13 }, { "date": "2012-07-28", "value": 10 }, { "date": "2012-07-29", "value": 15 }, { "date": "2012-07-30", "value": 15 }, { "date": "2012-07-31", "value": 15 }, { "date": "2012-08-01", "value": 15 }, { "date": "2012-08-02", "value": 15 }, { "date": "2012-08-03", "value": 19 }, { "date": "2012-08-04", "value": 16 }, { "date": "2012-08-05", "value": 15 }, { "date": "2012-08-06", "value": 19 }, { "date": "2012-08-07", "value": 17 }, { "date": "2012-08-08", "value": 18 }],
            headers: [{ name: "Category Name", field: "title1", sortable: true }, { name: "Inward", field: "title2", sortable: true }, { name: "SOH", field: "title3", sortable: true }, { name: "Units Sold", field: "title4", sortable: true }, { name: "Sell Thru%", field: "title5", sortable: true }, { name: "Wks on Floor", field: "title6", sortable: true }, { name: "Fwd Wk CVR", field: "title7", sortable: true }, { name: "ROS%", field: "title8", sortable: true }, { name: "Markdown", field: "title9", sortable: true }, { name: "Comment", field: "title10", sortable: true }, { name: "Trend", field: "title11", sortable: true } ],
            tableDatatop: [{"title1":"PEPE","title2":"10","title3":"100k","title4":"1k","title5":"1%","title6":"4", "title7":"25","title8":"0.5%","title9":"50%","title10":"High","title11":<Sparklines  data={[5, 10, 5, 20, 8]}><SparklinesCurve style={{ strokeWidth: 4, stroke: "#11a1fd", fill: "none" }}/></Sparklines>}, {"title1":"LEVIS","title2":"10","title3":"100k","title4":"1k","title5":"1%","title6":"4", "title7":"25","title8":"0.5%","title9":"50%","title10":"High","title11": <Sparklines data={[5, 10, 5, 20, 8]}><SparklinesCurve style={{ strokeWidth: 4, stroke: "#11a1fd", fill: "none" }}/></Sparklines>}],
            tableDatabottom: [{"title1":"SPYKER","title2":"10","title3":"100k","title4":"1k","title5":"1%","title6":"4", "title7":"25","title8":"0.5%","title9":"50%","title10":"Low","title11":<Sparklines data={[5, 10, 5, 20, 8]}><SparklinesCurve style={{ strokeWidth: 4, stroke: "#11a1fd", fill: "none" }}/></Sparklines>}, {"title1":"LEE","title2":"10","title3":"100k","title4":"1k","title5":"1%","title6":"4", "title7":"25","title8":"0.5%","title9":"50%","title10":"Low","title11": <Sparklines data={[5, 10, 5, 20, 8]}><SparklinesCurve style={{ strokeWidth: 4, stroke: "#11a1fd", fill: "none" }}/></Sparklines>}]
            
        }
    }

    // componentDidMount() {
    //     this.getData();
    // }

    // getData = () => {

    //     fetch("https://jsonplaceholder.typicode.com/comments")
    //         .then(response => response.json())
    //         .then(json => {
    //             // this.state.hideLoader();
    //             // this.setState.comments(json);
    //             console.log("==================getdata=========================", json);
    //             this.setState({ is_table_data_loaded: true, table_data: json });
    //         });

    // }

    render() {
        return (
            <Wrapper>
                <div className="icz-row">
                    <div className="icz-sectioncardwrapper w-100">
                        <div className="icz-sectioncard d-flex flex-row flex-wrap">
                            <Col sm={12} className="icz-cardheader flex-wrap">
                                <Col lg={4} className="icz-cardtitle">
                                    Performance Trend
                                </Col>
                                <Col lg={8} className="icz-cardfilter">
                                    <Dropdown dropdown_options={this.state.dropdown_options} dropdown_placeholder="SOH" parentCallback={this.handleCallback} />

                                    <div className="icz-cardbtnwrapper">
                                        <button className="icz-cardthemebtn">
                                            <IconDownload className="icz-cardicon" /> </button>
                                    </div>
                                </Col>
                            </Col>
                            <Col lg={12} className="">
                                <MultiLineMultiAxesChart chart_id="performance_trend" chart_class="icz-sectionchart" graph-data={this.state.performancetrend} metric_1={"Sell Through"} metric_2={"Markdown"} metric_3={"SOH (L)"} />
                            </Col>
                        </div>
                    </div>
                </div>

                <div className="icz-row">
                    <div className="icz-sectioncardwrapper w-100">
                        <div className="icz-sectioncard">
                            <div className="icz-cardbody">
                            <RiDataTable tableTitle="Top" Headers={this.state.headers} TableSize={2} TableData={this.state.tableDatatop} className="MainDiv icz-table" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="icz-row">
                    <div className="icz-sectioncardwrapper w-100">
                        <div className="icz-sectioncard">
                           <div className="icz-cardbody">
                            <RiDataTable tableTitle="Bottom" Headers={this.state.headers} TableSize={2} TableData={this.state.tableDatabottom} className="MainDiv icz-table" />
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        );
    }
}
