const IconShare = (props) => {
    return (

        <svg className={props.className} width="54" height="54" viewBox="0 0 54 54" fill="none" stroke="#486F88" xmlns="http://www.w3.org/2000/svg">
            <path d="M41.25 16.15C41.25 17.0697 40.9773 17.9687 40.4663 18.7334C39.9554 19.4981 39.2292 20.0941 38.3795 20.446C37.5298 20.798 36.5949 20.8901 35.6928 20.7107C34.7908 20.5312 33.9623 20.0884 33.312 19.438C32.6617 18.7877 32.2188 17.9592 32.0394 17.0572C31.8599 16.1552 31.952 15.2202 32.304 14.3705C32.6559 13.5208 33.2519 12.7946 34.0166 12.2837C34.7813 11.7727 35.6803 11.5 36.6 11.5C37.8333 11.5 39.016 11.9899 39.8881 12.862C40.7601 13.734 41.25 14.9167 41.25 16.15Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22.65 27C22.65 27.9197 22.3773 28.8187 21.8663 29.5834C21.3554 30.3481 20.6292 30.9441 19.7795 31.296C18.9298 31.648 17.9949 31.7401 17.0928 31.5607C16.1908 31.3812 15.3623 30.9384 14.712 30.2881C14.0616 29.6377 13.6188 28.8092 13.4394 27.9072C13.2599 27.0052 13.352 26.0702 13.704 25.2205C14.0559 24.3709 14.6519 23.6446 15.4166 23.1337C16.1813 22.6227 17.0803 22.35 18 22.35C18.6107 22.35 19.2153 22.4703 19.7795 22.704C20.3437 22.9377 20.8563 23.2802 21.2881 23.712C21.7198 24.1438 22.0624 24.6564 22.296 25.2205C22.5297 25.7847 22.65 26.3894 22.65 27Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M41.25 37.85C41.25 38.7697 40.9773 39.6687 40.4663 40.4334C39.9554 41.1981 39.2292 41.7941 38.3795 42.146C37.5298 42.498 36.5949 42.5901 35.6928 42.4106C34.7908 42.2312 33.9623 41.7884 33.312 41.138C32.6617 40.4877 32.2188 39.6592 32.0394 38.7572C31.8599 37.8552 31.952 36.9202 32.304 36.0705C32.6559 35.2208 33.2519 34.4946 34.0166 33.9837C34.7813 33.4727 35.6803 33.2 36.6 33.2C37.8333 33.2 39.016 33.6899 39.8881 34.562C40.7601 35.434 41.25 36.6167 41.25 37.85Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22.015 29.341L32.602 35.51" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M32.586 18.491L22.015 24.66" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default IconShare;