import React, { Component } from 'react'
import Col from 'react-bootstrap/esm/Col';
import SearchFilter from '../../shared/components/searchfilter/searchfilter';
import Wrapper from '../../helpers/wrapper';
import { DetailsCard } from '../../shared/components/cards/detailscard/detailscard';
import { Carousel } from '../../shared/components/carousel/carousel';
import IconCRM from '../../../assets/images/icons/crmIcon';
import ProductComparison from '../../shared/components/compare/Video/ProductComparision';
import { products } from '../../shared/components/compare/Video/Products';

export default class Overview extends Component {
    constructor(props) {
        super(props);
        this.state = {

            search: '',
            BadgeData: ['DiwaliOffer', 'Offer', '365DaysDiscount', 'MensFashion'],
            trendtopic: '',
        }
    }

    setSearch = (search) => {
        console.log('search', search);
        if (!search) return this.state.BadgeData;
        console.log('trendtopic', this.state.trendtopic);

        return this.state.BadgeData.filter((topic) => {
            return (
                this.state.trendtopic = topic.toLowerCase().includes(search.toLowerCase())
            ),
                console.log('trendtopic 2', this.state.trendtopic)
        });
    };

    trendingTopics = () => {
        if (!this.state.search) return this.state.BadgeData;

        return this.state.BadgeData.filter((topic) => {
            return (
                topic.toLowerCase().includes(this.state.search.toLowerCase())
            );
        });
    };

    render() {
        const CarouselSettings = {
            slidesToShow: 5,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                }
            ]
        }
        return (
            <Wrapper>
                <div className="icz-row icz-datacontainer">
                    <Col className="icz-sectioncardwrapper p-0" sm={12}>
                        <div className="icz-sectioncard p-0">
                            <div className="icz-cardheader">
                                <Col className="icz-cardtitle">
                                    Trending
                                </Col>
                                <Col className="icz-cardfilter">
                                    <SearchFilter Placeholder="Search Hashtags/Aspects" AriaLable="trending_topics" AiraDescribedBy="search_trending_topics" value={this.state.search} onChange={(e) => this.setSearch(e.target.value)} />
                                </Col>
                            </div>
                            <div className="icz-badgescontainer">
                                {console.log(this.state.trendtopic),
                                    this.state.BadgeData == 0 ?
                                        <div className="w-100 text-center text-secondary-grey">No Topics Found</div> :
                                        this.state.BadgeData.map((trendtopic, index) => {
                                            return (
                                                <div key={index} className="icz-badgewrapper">
                                                    <input type="checkbox" className="btn-check icz-checkboxbadge" id={trendtopic.toLowerCase()} autoComplete="off" />
                                                    <label className="btn icz-checkboxbadgebtn" htmlFor={trendtopic.toLowerCase()}>{`#` + trendtopic}</label>
                                                </div>
                                            )
                                        })
                                }
                            </div>
                        </div>
                    </Col>
                </div>

                <div className="icz-carouselcontainer">
                    <Carousel
                        Settings={CarouselSettings}
                        class={"icz-cardcontainer"}
                        SliderCards={[

                            <DetailsCard
                            index="1"
                            CardClass="icz-cardwrapper"
                            CardIcon={<IconCRM />}
                            CardTitle="Total Video"
                            RowOneCurrentValue="2245418"
                            RowOnePreviousValue="85540"
                            RowOneTitle="Previous"
                            IsNumber={true}
                        />,

                        <DetailsCard
                            index="1"
                            CardClass="icz-cardwrapper"
                            CardIcon={<IconCRM />}
                            CardTitle="Total Views"
                            RowOneCurrentValue="2245418"
                            RowOnePreviousValue="85540"
                            RowOneTitle="Previous"
                            IsNumber={true}
                        />,

                        <DetailsCard
                            index="1"
                            CardClass="icz-cardwrapper"
                            CardIcon={<IconCRM />}
                            CardTitle="Total Engagement"
                            RowOneCurrentValue="2245418"
                            RowOnePreviousValue="85540"
                            RowOneTitle="Previous"
                            IsNumber={true}
                        />,

                        <DetailsCard
                            index="1"
                            CardClass="icz-cardwrapper"
                            CardIcon={<IconCRM />}
                            CardTitle="View Rate"
                            RowOneCurrentValue="2245418"
                            RowOnePreviousValue="85540"
                            RowOneTitle="Previous"
                            IsNumber={true}
                        />
                        ]}>

                    </Carousel>
                </div>

                <div className="icz-row">
                    <Col className="icz-sectioncardwrapper" sm={12}>
                        <div className="icz-sectioncard">
                           <div className="">
                            <ProductComparison products={products} />
                            </div>
                        </div>
                    </Col>
                </div>

            </Wrapper>
        )
    }
}
